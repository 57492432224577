import { useState } from 'react';
import { useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { useIntl } from 'react-intl';
import { Button, Icon, Modal, Input } from 'calls/components/index.js';
import { useConferenceConfigurations, useScreenType } from 'calls/hooks/index.js';
import { ButtonType } from 'constants/enums.js';
import { Dropdown } from 'components/index.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import { StyledCameraBookmarks, StyledAddNewCameraBookmarksButton } from 'calls/styles/styled-components/index.js';
import CustomButton from 'components/Button.jsx';

/**
 * @param {object} props
 * @param {import('calls/RemoteHelloParticipant.js').default} props.helloDevice
 * @param {any[]} props.bookmarks
 * @param {object} props.activePreset
 * @param {object} props.selectedCamera
 * @param {function} props.setActivePreset
 */
const CameraBookmarks = ({ helloDevice, bookmarks, activePreset, setActivePreset, selectedCamera }) => {
	const intl = useIntl();
	const screenType = useScreenType();
	const conferenceConfigs = useConferenceConfigurations();
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const [toggleBookmark, setToggleBookmark] = useState(true);
	const [bookmark, setBookmark] = useState('');
	const [bookmarkToDelete, setBookmarkToDelete] = useState(null);
	const [isDeleteBookmarkModalOpen, setIsDeleteBookmarkModalOpen] = useState(false);
	const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
	const locale = getPreferredLanguageLocale();
	const bookmarksLimit = 9;

	const deleteBookmark = id => {
		if (!id) {
			return;
		}
		const dataToSend = isNewExperience
			? JSON.stringify({
					cameraId: selectedCamera?.id,
					bookmarkId: id,
			  })
			: id;

		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.REMOVE_BOOKMARK, enums.MediaTypes.CAMERA, dataToSend);
	};

	const editBookmark = id => {
		if (!id) {
			return;
		}
		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.EDIT_BOOKMARK, enums.MediaTypes.CAMERA, id);
	};

	const setCurrentPosition = async id => {
		if (!id) {
			return;
		}
		const dataToSend = isNewExperience
			? JSON.stringify({
					bookmarkId: id,
					cameraId: selectedCamera?.id,
			  })
			: id;

		const response = await helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.MOVE_TO_BOOKMARK,
			enums.MediaTypes.CAMERA,
			dataToSend
		);

		if (response.ok) {
			setActivePreset(bookmarks?.find(bookmark => bookmark.id === id));
		}
	};

	const addNewBookmark = text => {
		if (!text) {
			return;
		}
		if (bookmarks.length >= bookmarksLimit) {
			return;
		}
		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.ADD_BOOKMARK, enums.MediaTypes.CAMERA, text);
		setIsBookmarkModalOpen(null);
	};

	const handleDeleteBookmark = (itemId, itemName) => {
		if (!itemId) {
			return;
		}
		setBookmarkToDelete({ itemId, itemName });
		setIsDeleteBookmarkModalOpen(true);
	};

	return (
		<>
			<StyledCameraBookmarks
				$isDarkMode={conferenceConfigs.isDarkMode}
				$fixedPosition={screenType.isSmall}
				$isRightToLeft={locale === LOCALES.ARABIC}>
				<Button onClick={() => setToggleBookmark(prevState => !prevState)}>
					<div>
						<div>
							<Icon name='bookmark' />
							<div>
								<p>{intl.formatMessage({ id: 'presets' })}</p>
								<span>
									{activePreset && (
										<>
											{intl.formatMessage({ id: 'activePreset' })} {activePreset?.name}
										</>
									)}
									{!activePreset && intl.formatMessage({ id: 'noActivePreset' })}
								</span>
							</div>
						</div>
						<Icon name={toggleBookmark ? 'expand_less' : 'expand_more'} />
					</div>
				</Button>
				{toggleBookmark && (
					<div>
						{bookmarks?.length === 0 && <p>{intl.formatMessage({ id: 'noPresets' })}</p>}
						{bookmarks?.map(item => (
							<div key={item.id}>
								<CustomButton onClick={() => setCurrentPosition(item.id)} text={!item.isEdit && item.name} />
								<Dropdown position='right' icon='more_vert'>
									<div>
										<div>
											<CustomButton
												onClick={() => setCurrentPosition(item.id)}
												text={intl.formatMessage({ id: 'goToPreset' })}
												icon='my_location'
											/>
										</div>
										<div>
											<CustomButton
												onClick={() => editBookmark(item.id)}
												text={intl.formatMessage({ id: 'updateToCurrentPosition' })}
												icon='edit'
											/>
										</div>
										<div>
											<CustomButton
												onClick={() => handleDeleteBookmark(item.id, item.name)}
												text={intl.formatMessage({ id: 'deletePreset' })}
												icon='delete'
											/>
										</div>
									</div>
								</Dropdown>
							</div>
						))}
						{/* bookmarksLimit - 1 => (because 1 is for the home position) */}
						{bookmarks?.length < bookmarksLimit - 1 && (
							<StyledAddNewCameraBookmarksButton>
								<CustomButton
									onClick={() => setIsBookmarkModalOpen(true)}
									disabled={bookmarks.length >= bookmarksLimit}
									text={intl.formatMessage({ id: 'addNewPreset' })}
									icon='add'
								/>
							</StyledAddNewCameraBookmarksButton>
						)}
					</div>
				)}
			</StyledCameraBookmarks>
			{isBookmarkModalOpen && (
				<Modal
					onDismiss={() => {
						setIsBookmarkModalOpen(null);
						setBookmark(null);
					}}
					title={intl.formatMessage({ id: 'createPreset' })}>
					<Modal.Content>
						<Input>
							<Input.Label>{translate('presetName')}</Input.Label>
							<Input.Field
								value={bookmark}
								placeholder={intl.formatMessage({ id: 'typePresetDescription' })}
								onChange={e => setBookmark(e.target.value)}
							/>
							<Input.Description>{translate('presetChangeDescription')}</Input.Description>
						</Input>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							variant={ButtonType.SUBMIT}
							onClick={() => {
								addNewBookmark(bookmark);
								setIsBookmarkModalOpen(null);
								setBookmark(null);
							}}>
							{intl.formatMessage({ id: 'createNewPreset' })}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{bookmarkToDelete && isDeleteBookmarkModalOpen && (
				<Modal onDismiss={() => setIsDeleteBookmarkModalOpen(false)} title={intl.formatMessage({ id: 'deleteConfirmation' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureToDeletePreset', {
								value: bookmarkToDelete.itemName,
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								deleteBookmark(bookmarkToDelete.itemId);
								setIsDeleteBookmarkModalOpen(false);
							}}>
							{translate('delete')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default CameraBookmarks;
