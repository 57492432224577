import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Form from 'components/Form.jsx';
import FormInput from 'components/FormInput.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { IMaskInput } from 'react-imask'; 
import Button from 'components/Button.jsx';
import ActionButtons from 'containers/Monitoring/Whiteboard/ActionButtons.jsx';

const CareTeam = props => {
	const intl = useIntl();
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [dataToEdit, setDataToEdit] = useState(props.data);

	const nursePhoneRef = useRef(null);
	const pcaPhoneRef = useRef(null);
	const familyMemberPhoneRef = useRef(null);
	const caseManagerPhoneRef = useRef(null);

	const maxDoctors = 5;

	const handleAccept = (value, type, ref) => {
		const maskedValue = ref.current.maskRef?.masked?.value;
		setDataToEdit({
			...dataToEdit,
			[type]: {
				maskedValue: value && maskedValue ? maskedValue : '',
				unmaskedValue: `+${value}`,
			},
		});
	};

	const addDoctor = () => {
		let updatedDoctors = [''];
		if (dataToEdit.doctors?.length < maxDoctors) {
			updatedDoctors = [...dataToEdit.doctors, ''];
		}

		if (dataToEdit.doctors?.length < maxDoctors || !dataToEdit.doctors) {
			setDataToEdit({ ...dataToEdit, doctors: updatedDoctors });
		}
	};

	const updateDoctor = (index, newName) => {
		const updatedDoctors = [...dataToEdit.doctors];
		updatedDoctors[index] = newName;
		setDataToEdit({ ...dataToEdit, doctors: updatedDoctors });
	};

	const deleteDoctor = index => {
		const updatedDoctors = [...dataToEdit.doctors];
		updatedDoctors.splice(index, 1);
		setDataToEdit({ ...dataToEdit, doctors: updatedDoctors });
	};

	const careTeamMembers = {
		nurse: { name: props.data?.nurse || '', number: props.data?.nursePhone?.maskedValue || '' },
		pca: { name: props.data?.pca || '', number: props?.data?.pcaPhone?.maskedValue || '' },
		familyMember: { name: props.data?.member || '', number: props.data?.familyContact?.maskedValue || '' },
		caseManager: { name: props.data?.caseManager || '', number: props.data?.caseManagerPhone?.maskedValue || '' },
	};

	useEffect(() => {
		setDataToEdit(props.data);
	}, [props.data]);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('careTeam')}</p>
				<div className='timeline-box-actions'>
					<ActionButtons
						handleEditClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}
						handleExpandClick={() => setExpanded(prevState => !prevState)}
						expanded={expanded}
					/>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('doctors')}</td>
									<td>
										{props.data.doctors?.map((item, index) => (
											<React.Fragment key={index}>
												{item}
												{index < props.data.doctors?.length - 1 ? ', ' : ''}
											</React.Fragment>
										))}
									</td>
								</tr>
								{Object.entries(careTeamMembers).map(([memberType, details]) => (
									<tr>
										<td>{translate(memberType)}</td>
										<td>
											{details.name}
											<br />
											{details.number}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
					{showEditForm && (
						<Form className='profile-information-form'>
							<div className='flex flex-space-between flex-wrap'>
								{dataToEdit.doctors?.map((doctor, index) => (
									<div className='flex full-width flex-align-end' key={index}>
										<FormInput
											text={`${intl.formatMessage({ id: 'doctor' })} ${index + 1}`}
											id={`doctor-${index}`}
											placeholder={intl.formatMessage({ id: 'doctor' })}
											type='text'
											value={doctor}
											onChange={event => updateDoctor(index, event.target.value)}
											className='text-input flex-1'
											maxLength={256}
										/>
										<i className='material-icons-outlined left-s font-18' onClick={() => deleteDoctor(index)}>
											delete
										</i>
									</div>
								))}
								{(dataToEdit.doctors?.length < maxDoctors || !dataToEdit.doctors) && (
									<Button
										className='button'
										type='button'
										onClick={addDoctor}
										disabled={dataToEdit.doctors?.length >= maxDoctors}
										text={translate('addDoctor')}
									/>
								)}
								<FormInput
									text={translate('nurse')}
									id='nurse'
									placeholder={intl.formatMessage({ id: 'nurse' })}
									type='text'
									value={dataToEdit.nurse}
									onChange={event => setDataToEdit({ ...dataToEdit, nurse: event.target.value })}
									className='text-input'
									maxLength={256}
								/>
								<label>
									{translate('nursePhoneNumber')}

									<IMaskInput
										mask='+1 (000) 000-0000'
										unmask={true}
										lazy={false}
										ref={nursePhoneRef}
										placeholder={intl.formatMessage({ id: 'nursePhoneNumber' })}
										defaultValue={dataToEdit.nursePhone?.unmaskedValue}
										onAccept={value => handleAccept(value, 'nursePhone', nursePhoneRef)}
										className='number-input-wo-arrows text-input'
									/>
								</label>
								<FormInput
									text={translate('pca')}
									id='pca'
									placeholder={intl.formatMessage({ id: 'pca' })}
									type='text'
									value={dataToEdit.pca}
									onChange={event => setDataToEdit({ ...dataToEdit, pca: event.target.value })}
									className='text-input'
									maxLength={256}
								/>
								<label>
									{translate('pcaPhoneNumber')}

									<IMaskInput
										mask='+1 (000) 000-0000'
										unmask={true}
										lazy={false}
										ref={pcaPhoneRef}
										placeholder={intl.formatMessage({ id: 'pcaPhoneNumber' })}
										defaultValue={dataToEdit.pcaPhone?.unmaskedValue}
										onAccept={value => handleAccept(value, 'pcaPhone', pcaPhoneRef)}
										className='number-input-wo-arrows text-input'
									/>
								</label>
								<FormInput
									text={translate('caseManager')}
									id='caseManager'
									placeholder={intl.formatMessage({ id: 'caseManager' })}
									type='text'
									value={dataToEdit.caseManager}
									onChange={event => setDataToEdit({ ...dataToEdit, caseManager: event.target.value })}
									className='text-input'
									maxLength={256}
								/>
								<label>
									{translate('caseManagerPhone')}
									<IMaskInput
										mask='+1 (000) 000-0000'
										unmask={true}
										lazy={false}
										ref={caseManagerPhoneRef}
										placeholder={intl.formatMessage({ id: 'caseManagerPhone' })}
										defaultValue={dataToEdit.caseManagerPhone?.unmaskedValue}
										onAccept={value => handleAccept(value, 'caseManagerPhone', caseManagerPhoneRef)}
										className='number-input-wo-arrows text-input'
									/>
								</label>
								<FormInput
									text={translate('familyMember')}
									id='familyMember'
									placeholder={intl.formatMessage({ id: 'familyMember' })}
									type='text'
									value={dataToEdit.member}
									onChange={event => setDataToEdit({ ...dataToEdit, member: event.target.value })}
									className='text-input'
									maxLength={256}
								/>
								<label>
									{translate('familyContact')}
									<IMaskInput
										mask='+1 (000) 000-0000'
										unmask={true}
										lazy={false}
										ref={familyMemberPhoneRef}
										placeholder={intl.formatMessage({ id: 'familyContact' })}
										defaultValue={dataToEdit.familyContact?.unmaskedValue}
										onAccept={value => handleAccept(value, 'familyContact', familyMemberPhoneRef)}
										className='number-input-wo-arrows text-input'
									/>
								</label>
								<Button
									className={classNames('button', props.isSubmitButtonLoading ? 'loading' : '')}
									onClick={() => props.setData(dataToEdit)}
									text={translate('save')}
								/>
							</div>
						</Form>
					)}
				</>
			)}
		</>
	);
};

export default CareTeam;
