import { getPatientAiAlertsCount } from 'api/alerts.js';
import { Alert, Grid, Loader } from 'components/index.js';
import { FallPreventionAlertTypes } from 'constants/ai.js';
import { AiAlertId } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import FallDetection from 'icons/Dashboard/FallDetection.jsx';
import GettingOutOfBed from 'icons/Dashboard/GettingOutOfBed.jsx';
import Rails from 'icons/Dashboard/Rails.jsx';
import { timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const ChartWrapper = styled.div`
	border: 1px solid var(--gray-15);
	background: var(--gray-0);
	padding: var(--spacing-l);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	h4 {
		margin-bottom: 0.5em;
		padding-top: 0;
		font-size: 14px;
		font-weight: 500;
	}
	> div:first-of-type {
		display: flex;
		gap: 10px;
		flex-grow: 1;
		div {
			flex: 1 1 0;
			padding: var(--spacing-m);
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: var(--orange-1-o10);
			h4,
			p {
				margin: 0;
				padding: 0;
				font-size: 16px;
				color: var(--orange-1);
			}
			h4 {
				font-weight: 500;
			}
		}
	}
	> div:last-of-type {
		display: flex;
		justify-content: center;
		gap: 30px;
		padding: var(--spacing-m);
		p {
			margin: 0;
			padding: 0;
			font-size: 14px;
		}
	}
`;

const getAlertCount = (alerts = [], typeId) => alerts.find(alert => alert.aiAlertType === typeId)?.count ?? 0;

const FallPreventionAlerts = ({ dateRange, selectedTimezone }) => {
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [alertsCount, setAlertsCount] = useState([]);
	const [error, setError] = useState('');

	const fetchAiAlertsStatistics = useCallback(
		debounce(async params => {
			const response = await getPatientAiAlertsCount(params);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setAlertsCount(response.aiAlertTypeCounts);
			setIsLoading(false);
			setError('');
		}, 500),
		[]
	);

	useEffect(() => {
		const { patient } = location.state;

		const params = {
			userId: patient?.healthcareUserId,
			alertTypes: FallPreventionAlertTypes,
			start: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
		};

		fetchAiAlertsStatistics(params);
	}, [location, dateRange, fetchAiAlertsStatistics, selectedTimezone.zone]);

	return (
		<ChartWrapper>
			<h3 className='semi-bold font-14'>
				{translate('fallPrevention')} {translate('alerts')}
			</h3>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='100%' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div>
					<div>
						<Rails />
						<h4>
							{translate('rails')} {translate('alerts')}
						</h4>
						<p>{getAlertCount(alertsCount, AiAlertId.RAILS)}</p>
					</div>
					<div>
						<GettingOutOfBed />
						<h4>
							{translate('getOutOfBed')} {translate('alerts')}
						</h4>
						<p>{getAlertCount(alertsCount, AiAlertId.PATIENT_GETTING_OUT_OF_BED)}</p>
					</div>
					<div>
						<FallDetection />
						<h4>
							{translate('fallDetection')} {translate('alerts')}
						</h4>
						<p>{getAlertCount(alertsCount, AiAlertId.FALL_DETECTION)}</p>
					</div>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</ChartWrapper>
	);
};
export default FallPreventionAlerts;
