import { ConditionType } from 'constants/enums.js';
import { gatewayApi, teamBotApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getMonitoringSessions = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/monitoring-sessions`);
		return data.monitoringSessions;
	} catch (error) {
		return { error };
	}
};

export const getMonitoringSessionDevices = async (sessionId, includeDeviceInfo = false) => {
	try {
		const queryParams = new URLSearchParams({
			...(includeDeviceInfo && {
				'device-path': 'true',
			}),
		});

		const { data } = await gatewayApi.get(
			`v1/healthcare/tenants/${getCompanyId()}/monitoring-sessions/${sessionId}/devices${
				queryParams.toString() ? `?${queryParams.toString()}` : ''
			}`
		);
		return data.sessionDevices;
	} catch (error) {
		return { error };
	}
};

export const saveMonitoringSession = async dataToSend => {
	try {
		const { data } = await gatewayApi.post(`/v1.1/healthcare/monitoring-sessions`, dataToSend);
		return data.monitoringSessions;
	} catch (error) {
		return { error };
	}
};

export const updateMonitoringSessionDevices = async (sessionId, dataToSend) => {
	try {
		const { data } = await gatewayApi.put(`v1.1/healthcare/monitoring-sessions/${sessionId}/devices`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMonitoringSession = async sessionId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/healthcare/monitoring-sessions/${sessionId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTeamMembers = async channelId => {
	try {
		const { data } = await teamBotApi.get(`api/v1/front-line-pools/${channelId}/channel/msteam/members`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendGroupMessage = async (channelId, text) => {
	try {
		const { data } = await teamBotApi.post(`api/v1/front-line-pools/${channelId}/channel/msteam/notify/message`, { text });
		return data;
	} catch (error) {
		return { error };
	}
};

export const callSpecificNurse = async ({ channelId, userId, subject }) => {
	try {
		const { data } = await teamBotApi.post(`api/v1/front-line-pools/${channelId}/channel/msteam/call`, {
			userId,
			subject,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPrecautions = async ownerId => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/patients/${ownerId}/conditions?conditionType=${ConditionType.PRECAUTION}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPrecautions = async (ownerId, dataToSend) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/patients/${ownerId}/conditions`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};