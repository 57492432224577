import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import Form from 'components/Form.jsx';
import { Loader, Modal } from 'components';
import translate from 'i18n-translations/translate.jsx';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import {
	findByCityAndCountry,
	searchPlaceOrTimeZone,
	getFormatedTimeZone,
} from 'infrastructure/helpers/timezone/timeZonesHelper.js';
import Highlighter from 'react-highlight-words';
import { addTimeZone } from 'api/timezone.js';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'components/Alert.jsx';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import _ from 'lodash';
import classNames from 'classnames';

const SelectTimeZone = ({ currentHospital, onModalClose }) => {
	const dispatch = useDispatch();
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const userSession = useSelector(state => state.user.userSession);
	const [searchValue, setSearchValue] = useState('');
	const [showItemDropdown, setShowItemDropdown] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSearchNursesLoading, setIsSearchDoctorsLoading] = useState(false);
	const [selectedPlace, setSelectedPlace] = useState(null);
	const ref = useRef(null);
	const intl = useIntl();
	const [searchResult, setSearchResult] = useState([]);
	const [error, setError] = useState(null);
	const onSearchTypeTimeout = useRef(null);

	useEffect(() => {
		const fetchTimezone = async () => {
			if (currentHospital.timezone) {
				const place = findByCityAndCountry(currentHospital.timezone.city, currentHospital.timezone.country);
				setSelectedPlace({ ...place, formattedTimeZone: getFormatedTimeZone(place.timezone).formattedTimeZone } ?? null);
			}
		};
		fetchTimezone();
	}, [currentHospital]);

	useEffect(() => {
		if (searchValue) {
			handleSeach(searchValue);
		}
		return () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
		};
	}, [searchValue]);

	const handleSeach = search => {
		setIsSearchDoctorsLoading(true);
		if (onSearchTypeTimeout.current) clearTimeout(onSearchTypeTimeout.current);
		onSearchTypeTimeout.current = setTimeout(async () => {
			const cityLookup = searchPlaceOrTimeZone(search);
			const mapedLookUp = cityLookup.map(item => {
				return {
					timezone: item.timezone,
					city: item.city,
					country: item.country,
					province: item.province,
					formattedTimeZone: getFormatedTimeZone(item.timezone).formattedTimeZone,
					state_ansi: item.state_ansi,
				};
			});
			setSearchResult(mapedLookUp);
			setIsSearchDoctorsLoading(false);
		}, 500);
	};

	useOutsideClick(ref, () => {
		setSearchValue('');
		setShowItemDropdown(false);
	});

	const handleSubmit = async () => {
		setIsLoading(true);
		const params = {
			healthSystemId: userSession.healthSystem.id,
			regionId: userSession.regionId,
			hospitalId: currentHospital.hospitalId,
			timezone: selectedPlace ?? { timezone: null },
		};
		const response = await addTimeZone(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const treeData = _.cloneDeep(tree);
			const hospital = findSectorById(treeData, currentHospital.hospitalId);
			if (hospital) {
				hospital.timezone = selectedPlace;
			}
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
		setIsLoading(false);
	};

	const onSelectTimeZone = item => {
		setSelectedPlace(item);
		setShowItemDropdown(false);
		setSearchValue('');
	};

	const getHighlighter = word => <Highlighter searchWords={searchValue.split(' ')} autoEscape={true} textToHighlight={word} />;

	const getFullTimeZone = item => (
		<p className='gray-color'>
			<p> {getHighlighter(item.country)}</p>
			{item.province === item.city ? getHighlighter(item.province) : getHighlighter(`${item.city} | ${item.province}`)}
			<p>{getHighlighter(item.formattedTimeZone)}</p>
		</p>
	);

	return (
		<Modal
			modalSelector='editSectorModal'
			display={true}
			onModalSubmit={handleSubmit}
			onModalClose={onModalClose}
			className={classNames('assign-doctor-modal', isLoading ? 'none-pointer-events' : '')}
			shouldSubmitOnEnter={false}
			isSubmitDisabled={false}
			primaryButtonLoading={isLoading}
			position='right'>
			<Form
				className={isLoading ? 'disabled' : ''}
				title={translate('setTimeZone')}
				onSubmit={event => event.preventDefault()}
				description={intl.formatMessage({ id: 'timeZoneModalDesc' })}>
				<div className='mi-input-wrapper'>
					<input
						type='text'
						onChange={event => {
							setSearchValue(event.target.value);
							setShowItemDropdown(true);
							if (!event.target.value) {
								setShowItemDropdown(false);
							}
						}}
						value={searchValue}
						placeholder={intl.formatMessage({ id: 'searchPlaceTimeZone' })}
					/>
					{!isSearchNursesLoading && searchResult.length > 0 && showItemDropdown && (
						<div ref={ref}>
							{searchResult.map(item => (
								<div key={item.state_ansi} className='cursor-pointer timezone-item' onClick={() => onSelectTimeZone(item)}>
									{getFullTimeZone(item)}
								</div>
							))}
						</div>
					)}
					{searchResult.length === 0 && searchValue && !isSearchNursesLoading && (
						<div>
							<div>
								<p>{translate('noResultsFound')}</p>
							</div>
						</div>
					)}
					{isSearchNursesLoading && (
						<div className='mi-loading-div position-relative' style={{ minHeight: '50px' }}>
							<Loader />
						</div>
					)}
				</div>
				<div className='mi-selected-list'>
					<p>{translate('selectedTimeZone')}</p>
					{selectedPlace && (
						<div key={selectedPlace.state_ansi} className='position-relative medical-icons'>
							<i className='material-icons cursor-pointer' onClick={() => setSelectedPlace(null)}>
								close
							</i>
							<p className='gray-color'>
								<p> {selectedPlace.country}</p>
								{selectedPlace.province === selectedPlace.city
									? selectedPlace.province
									: `${selectedPlace.city} | ${selectedPlace.province}`}
								<p>{selectedPlace.formattedTimeZone}</p>
							</p>
						</div>
					)}
				</div>
			</Form>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</Modal>
	);
};

export default SelectTimeZone;
