import styled from 'styled-components';
import {
	findDeviceById,
	findSectorById,
	getAiSettingsConfigurations,
	getConfigurationValue,
	getStorage,
	skipDuplicatedObjects,
	updateConfigsList,
} from 'infrastructure/helpers/commonHelpers.js';
import SocketEvents from 'constants/socket-events.js';
import WarningDetection from 'components/WarningDetectionMayo.jsx';
import {
	AiAlertActivityType,
	AiAlertId,
	AiAlertNotificationType,
	AiAlertSign,
	AiAlertType,
	AlertCategory,
	AlertTypes,
	DeviceListLevel,
	PatientWarnings,
	PatientAiSetting,
	UserRoles,
} from 'constants/enums.js';
import { Alert } from 'components/index.js';
import { forwardAiAlert } from 'api/alerts.js';
import React from 'react';
import { getUserRole } from 'infrastructure/auth.js';
import { getTeams } from 'api/teams.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import _ from 'lodash';
import { dangerSound, fallAttentionSound, ivBagSound, personPositionSound, railSound } from 'constants/soundAlerts.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { injectIntl } from 'react-intl';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import callsActionCreators from 'state/calls/actions.js';
import { actionCreators as healthDataActionCreators } from 'state/healthData/actions.js';
import { getDeviceOwnerPatient, getPatientsAiSettings } from 'api/patients.js';
import {
	CallWorkflowType,
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
	configurableMonitoringMenu,
} from 'constants/configurationEnums.js';
import { getDeviceList, getDeviceSettings } from 'api/devices.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import { getInterventionsCount } from 'api/interventions.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import RoundingTimeline from 'calls/views/RoundingTimeline.jsx';
import classNames from 'classnames';
import { isSettingEnabled } from 'infrastructure/helpers/aiHelper.js';
import { getAiVoiceMessageByType } from 'infrastructure/helpers/alertsHelper.js';
import { AlertTypeToSettingTypeMap } from 'constants/alerts.js';
/**
 * @type {import('styled-components').StyledComponent<"section", any, {  }, never>}
 */
const StyledConferenceAIAlert = styled.section`
	position: absolute;
	top: auto;
	bottom: 0px;
	left: 45%;
	transform: translate(-45%, -70px);
	z-index: 9;

	> .detection-wrapper {
		bottom: 0;
	}
`;

class RoundingAi extends React.Component {
	state = {
		videoFeeds: [],
		conferenceInfo: null,
		selectedFeed: null,
		channels: [],
		error: null,
		adminConfigurations: configurableMonitoringMenu,
		isFallPrevention: false,
		isAiDrawingsActive: false,
	};

	playingAlert = null;
	alertInfoTimeout = null;

	componentDidMount = async () => {
		this.getTeams();
		const feed = this.getMappedFeed(this.props.helloDeviceId);
		if (feed) {
			this.setState(
				prevState => ({
					videoFeeds: [...prevState.videoFeeds, feed],
				}),
				async () => {
					await this.addFeed(this.props.helloDeviceId);
					if (this.props.notificationAiAlert) {
						this.onAiAlert(this.props.notificationAiAlert);
					}
				}
			);
		}
		this.context.on(SocketEvents.Alerts.NEW_AI_ALERT, this.onAiAlert);
		this.context.on(SocketEvents.Alerts.NEW_AI_ALERT_NOTIFICATION, this.onAiAlert);
		this.context.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, this.onAiAlertActivity);
		this.context.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, this.onPatientAiSettingsUpdated);
	};

	componentDidUpdate(prevProps) {
		if (this.props.notificationAiAlert && prevProps.notificationAiAlert?.id !== this.props.notificationAiAlert?.id) {
			this.onAiAlert(this.props.notificationAiAlert);
		}
	}

	componentWillUnmount() {
		this.context.off(SocketEvents.Alerts.NEW_AI_ALERT, this.onAiAlert);
		this.context.off(SocketEvents.Alerts.NEW_AI_ALERT_NOTIFICATION, this.onAiAlert);
		this.context.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, this.onAiAlertActivity);
		this.context.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, this.onPatientAiSettingsUpdated);
		if (this.playingAlert) {
			this.playingAlert.pause();
			this.playingAlert.currentTime = 0;
		}
	}

	onAiAlertActivity = data => {
		const { videoFeeds } = this.state;
		if (!videoFeeds) {
			return;
		}
		const selectedFeed = videoFeeds.find(item => item.deviceId === data.deviceId);
		if (
			[AiAlertActivityType.ACKNOWLEDGED, AiAlertActivityType.AI_FAKE_ALERT, AiAlertActivityType.AI_FORWARD_TO_NURSES].includes(
				data.aiAlertActivityTypeId
			) &&
			data.deviceId === selectedFeed?.deviceId &&
			data?.workflowTypes.includes(CallWorkflowType.ROUNDING)
		) {
			const alertId = Object.keys(AiAlertId).find(key => AiAlertId[key] === data?.alertTypeId);
			this.onAlertCloseClick(AiAlertType[alertId]);
			const obj = { ...this.props.patientNotes.savedMode[data.deviceId], draftsCount: data.interventionsTotalCount };
			this.props.patientNotesActions.setPatientDataSaved(obj, data.deviceId);
		}
	};

	getMappedFeed = (deviceId, otherHsCompanyPathInfo = null) => {
		let sector = findSectorById(this.props.healthSystems.treeData.tree, deviceId);
		if (!sector && otherHsCompanyPathInfo) {
			sector = otherHsCompanyPathInfo;
		}
		if (!sector || this.state.videoFeeds.some(feed => feed.deviceId === deviceId)) {
			return null;
		}
		const { roomId, hospitalId, departmentId, floorId, aiPrivacyStatus, deviceFamily } = sector;
		return {
			warning: {},
			roomId,
			hospitalId,
			departmentId,
			floorId,
			aiPrivacyStatus,
			deviceOwner: { patientFormattedName: '', id: null, age: null },
			deviceId,
			alertInfo: {},
			ivBagPercentage: 0,
			healthSystemId: this.props.user.userSession.healthSystem.id,
			activeAlerts: [],
			deviceFamily,
		};
	};

	addFeed = async deviceId => {
		const treeData = this.props.healthSystems.treeData.tree;
		const sector = findSectorById(treeData, deviceId);
		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		let isDefaultOwner = false;
		const devicesResponse = await getDeviceList(DeviceListLevel.ROOM, selectedFeed.roomId);
		if (devicesResponse.error) {
			this.setState({ error: devicesResponse.error.message });
		} else {
			isDefaultOwner = devicesResponse[0].isDefaultOwner;
		}
		if (!isDefaultOwner) {
			const deviceOwnerResponse = await getDeviceOwnerPatient(deviceId);
			let deviceOwner = { patientFormattedName: '' };
			if (deviceOwnerResponse.error) {
				this.setState({ error: deviceOwnerResponse.error.message });
			} else {
				deviceOwner = { ...deviceOwnerResponse };
			}
			const foundIndex = this.state.videoFeeds.findIndex(feed => feed.deviceId === deviceId);
			if (foundIndex === -1) {
				return;
			}
			await this.fetchDraftsCount(deviceOwner.healthcareUserId, deviceId);
			this.setState(
				prevState => {
					const feeds = [...prevState.videoFeeds];

					if (feeds.length > 0 && feeds?.[foundIndex]) {
						feeds[foundIndex].deviceOwner = deviceOwner;
						feeds[foundIndex].isDefaultOwner = isDefaultOwner;
					}
					return { ...prevState, videoFeeds: feeds, selectedFeed };
				},
				async () => {
					if (deviceOwner?.healthcareUserId) {
						await this.fetchPatientAiSettings({
							patientId: deviceOwner?.healthcareUserId,
							deviceId,
							roomId: selectedFeed.roomId,
						});
					}
					if (selectedFeed.roomId) {
						await this.fetchRoomSettings(selectedFeed.roomId);
					}
				}
			);
		}
		const foundIndex = this.state.videoFeeds.findIndex(feed => feed.deviceId === deviceId);
		if (foundIndex === -1) {
			return;
		}
		this.setState(prevState => {
			const feeds = [...prevState.videoFeeds];

			if (feeds.length > 0 && feeds?.[foundIndex]) {
				feeds[foundIndex].isDefaultOwner = isDefaultOwner;
				if (sector) {
					feeds[foundIndex].roomType = sector.roomType;
				}
			}

			return { ...prevState, videoFeeds: feeds };
		});
	};

	onPatientAiSettingsUpdated = async ({ patientAiSettings, deviceId, workflowType }) => {
		const { videoFeeds } = this.state;
		if (!videoFeeds) {
			return;
		}
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed || selectedFeed?.deviceId !== deviceId || workflowType === CallWorkflowType.MONITORING) {
			return;
		}
		const isAiDrawingsEnabled = isSettingEnabled(patientAiSettings, PatientAiSetting.AI_DRAWING);

		const deviceSettingsRes = await getDeviceSettings(deviceId);
		if (deviceSettingsRes.error) {
			this.setState({ error: deviceSettingsRes.error.message });
			return;
		}

		this.onAiSettingClick(isAiDrawingsEnabled, selectedFeed);
		this.props.aiSettingsActions.setPatientAiSettings({ deviceId, settings: patientAiSettings });
	};

	fetchDraftsCount = async (patientId, deviceId) => {
		const response = await getInterventionsCount(patientId, deviceId);
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}
		const obj = { ...this.props.patientNotes.savedMode[deviceId], draftsCount: response.totalCount };
		this.props.patientNotesActions.setPatientDataSaved(obj, deviceId);
	};

	fetchRoomSettings = async roomId => {
		const [adminRoomSettings, myRoomSettings, adminAiSettings, aiRoomSettings] = await Promise.all([
			getRoomSettings(roomId, SettingsCategory.MONITORING),
			getUserPreferences(UserSettingTypes.Monitoring, roomId),
			getRoomSettings(roomId, SettingsCategory.AI_SETTINGS),
			getUserPreferences(UserSettingTypes.AiSettings, roomId),
		]);
		const responseError = adminRoomSettings.error || myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error;
		if (responseError) {
			this.setState({ error: responseError.message });
			return;
		}
		const response = updateConfigsList(adminRoomSettings, myRoomSettings);
		const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings, CallWorkflowType.ROUNDING);
		const resError = response.error || aiResponse.error;
		if (resError) {
			this.setState({ error: resError });
		} else {
			this.setState({
				adminConfigurations: response.configs,
				adminAiSettingsConfigurations: aiResponse.configs,
			});
		}
	};

	fetchPatientAiSettings = async ({ patientId, deviceId, roomId }) => {
		const response = await getPatientsAiSettings({ patientId, deviceId, roomId, workflowType: CallWorkflowType.ROUNDING });
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}
		const mappedResponse = { deviceId, settings: response.patientAiSettings };
		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}

		const isAiDrawingsEnabled = isSettingEnabled(response.patientAiSettings, PatientAiSetting.AI_DRAWING);

		this.props.aiSettingsActions.setPatientAiSettings(mappedResponse);
		this.onAiSettingClick(isAiDrawingsEnabled, selectedFeed);
	};

	onAiAlert = data => {
		const { type, signType, workflowTypes = [] } = data;
		if (!workflowTypes.includes(CallWorkflowType.ROUNDING)) {
			return;
		}
		if (signType === AiAlertSign.INFORMATION && type === AiAlertType.HANDS_DISINFECTED) {
			this.updateVideoFeedAlertInfo(data);
		} else {
			this.playVoiceOver({ ...data, isAiAlert: true });
		}
	};

	updateVideoFeedAlertInfo = data => {
		const alertData = { imgUrl: null, text: null };
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === data.deviceId);
		if (!selectedFeed) {
			return;
		}

		if (data.type === AiAlertType.HANDS_DISINFECTED) {
			alertData.text = translate('handsDisinfectedSuccessfully');
			alertData.imgUrl = `${healthCareCdnUrl}alert-history/hand-washing-green.svg`;
		}

		selectedFeed.alertInfo = { ...data, ...alertData };
		this.setState({ videoFeeds: newVideoFeeds });
		this.alertInfoTimeout = setTimeout(() => {
			this.onAlertInfoClose(data.deviceId);
		}, 5000);
	};

	onAiSettingClick = (status, feed) => {
		this.setState(prevState => ({
			videoFeeds: prevState.videoFeeds.map(videoFeed => {
				if (videoFeed.deviceId === feed?.deviceId) {
					let feed = { ...videoFeed };
					feed.isAiDrawingsActive = status;
					return feed;
				}
				return videoFeed;
			}),
		}));
	};

	playVoiceOver = async ({ type, deviceId, alertId, warningIndex, isAiAlert, patientUserId }) => {
		const newVideoFeeds = _.cloneDeep(this.state.videoFeeds);
		let selectedFeedIndex = -1;
		if (deviceId) {
			selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === deviceId);
		}

		if (!deviceId && patientUserId) {
			selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceOwner.userId === patientUserId && item.isBioBeatActive);
		}
		if (selectedFeedIndex === -1) {
			return;
		}
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);

		if (selectedFeed?.aiToastDetails && Object.keys(selectedFeed?.aiToastDetails).length > 0) {
			selectedFeed.aiToastDetails = {};
		}

		if (this.shouldShowAlert(deviceId, selectedFeed)) {
			if (this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}

			const hasNotificationType = notificationType => this.getNotificationOptions(type, deviceId).includes(notificationType);

			const hasSilentMode = hasNotificationType(AiAlertNotificationType.SILENT_MODE);
			const hasToastMessageType = hasNotificationType(AiAlertNotificationType.TOAST_MESSAGE);
			const hasMobileAppType = hasNotificationType(AiAlertNotificationType.MOBILE_APP);

			if (hasSilentMode) {
				return;
			}
			if (hasMobileAppType) {
				await this.forwardAiAlert(selectedFeed, alertId);
				return;
			}
			if ([AiAlertType.PATIENT_INACTIVE, AiAlertType.PATIENT_INACTIVE_INFORMATION].includes(type) && hasToastMessageType) {
				this.updateVideoFeedAiToastMessage({ deviceId, alertId });
				return;
			}

			const { text, alertCategory } = this.getHealthConcern(type);

			const alertToSave = {
				value: true,
				type,
				text,
				alertId,
				isAiAlert,
				alertCategory,
				index: warningIndex,
				isToastMessage: hasToastMessageType,
			};

			let newAlerts = [alertToSave, ...selectedFeed?.activeAlerts];
			this.getHealthConcern(alertToSave?.type, true);

			if (!alertToSave?.isToastMessage) {
				this.tryToPlayAlertSound(deviceId, alertToSave?.isAiAlert);
			}

			selectedFeed.warning = { ...alertToSave };
			selectedFeed.activeAlerts = skipDuplicatedObjects(newAlerts);
			newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
			this.setState(prevState => ({
				videoFeeds: newVideoFeeds,
				selectedFeed: prevState.selectedFeed?.deviceId === selectedFeed.deviceId ? selectedFeed : prevState.selectedFeed,
			}));
		}
	};

	stopVoiceOver = (type, deviceId, alertToClose) => {
		const { videoFeeds } = this.state;
		const newFeeds = [...videoFeeds];
		const selectedFeedIndex = newFeeds.findIndex(item => item.deviceId === deviceId);
		const [selectedFeed] = newFeeds.splice(selectedFeedIndex, 1);

		const isAlertOnFeed = alertToClose === type;
		let newAlerts = [];
		if (alertToClose) {
			newAlerts = selectedFeed.activeAlerts?.filter(alert => alert.type !== alertToClose);
			selectedFeed.activeAlerts = newAlerts;
		}
		const alertsWithoutToast = newAlerts?.filter(alert => !alert.isToastDetails);

		if (alertsWithoutToast.length > 0) {
			if (isAlertOnFeed) {
				if (this.playingAlert) {
					this.playingAlert.currentTime = 0;
					this.playingAlert.pause();
				}
				const alertToShow = alertsWithoutToast[0];
				this.getHealthConcern(alertToShow?.type, alertToShow?.index, true);

				if (!alertToShow?.isToastMessage) {
					this.tryToPlayAlertSound(deviceId, alertToShow?.isAiAlert);
				}

				selectedFeed.warning = { ...alertToShow };
			}
		} else {
			if (type === PatientWarnings.FALL_DETECTED) {
				this.onFallDetected(false, selectedFeed);
			}
			if (this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}
			selectedFeed.warning = {};
		}
		newFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState(prevState => ({
			videoFeeds: newFeeds,
			selectedFeed: prevState.selectedFeed?.deviceId === selectedFeed.deviceId ? selectedFeed : prevState.selectedFeed,
		}));
	};

	onFallDetected = async (showFall, feed) => {
		const { conferenceInfo } = this.state;
		if (!conferenceInfo) {
			return;
		}

		const data = {
			isFallDetected: showFall,
			helloDeviceId: feed.deviceId,
			conferenceId: conferenceInfo.conferenceId,
			participantId: feed.participantId,
			patientId: null,
			addToHealthMeasurements: false,
		};
		this.callManager.sendFallDetected(data);
	};

	tryToPlayAlertSound = async (deviceId, isAiAlert = false) => {
		const aiSettingsList = _.cloneDeep(this.props.aiSettings);
		const found = aiSettingsList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const isSoundEnabled = found.settings.some(
			item => item.settingTypeId === PatientAiSetting.SOUND_ON_AI_ALERT && item.isEnabled
		);
		if (!isSoundEnabled && isAiAlert) {
			return;
		}

		try {
			this.playingAlert.loop = true;
			await this.playingAlert.play();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Autoplay failed:', error);
		}
	};

	getHealthConcern = (type, playVoiceOver = false) => {
		let text = null;
		let imgIcon = null;
		let voiceOver = null;
		let alertCategory = null;
		switch (type) {
			case AiAlertType.FALL_DETECTION: {
				voiceOver = fallAttentionSound;
				text = translate('pleaseCheckPatient');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.FALL_DETECTION;
				break;
			}
			case AiAlertType.PATIENT_SITTING: {
				voiceOver = personPositionSound;
				text = translate('patientSitting');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_STANDING: {
				voiceOver = personPositionSound;
				text = translate('patientStanding');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_LYING_FRONT: {
				voiceOver = personPositionSound;
				text = translate('patientLyingFront');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_LYING_SIDE: {
				voiceOver = personPositionSound;
				text = translate('patientLyingSide');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_LYING_RIGHT_SIDE: {
				voiceOver = personPositionSound;
				text = translate('patientLyingRightSide');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_LYING_LEFT_SIDE: {
				voiceOver = personPositionSound;
				text = translate('patientLyingLeftSide');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_FACE_DOWN: {
				voiceOver = personPositionSound;
				text = translate('patientFaceDown');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_INACTIVE:
			case AiAlertType.PATIENT_INACTIVE_INFORMATION: {
				voiceOver = personPositionSound;
				text = translate('patientInactive');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PRESSURE_INJURY;
				break;
			}
			case AiAlertType.PATIENT_GETTING_OUT_OF_BED: {
				voiceOver = personPositionSound;
				text = translate('patientGettingOutOfBed');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.GETTING_OUT_OF_BED;
				break;
			}
			case AiAlertType.RAILS: {
				voiceOver = railSound;
				text = translate('railDownMessage');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.RAILS;
				break;
			}
			case AiAlertType.PATIENT_MOBILITY: {
				voiceOver = personPositionSound;
				text = translate('personIsDetectedWalking');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PATIENT_MOBILITY;
				break;
			}
			case AiAlertType.IV_BAG:
			case AiAlertType.LOW_IV_BAG: {
				voiceOver = ivBagSound;
				text = translate('ivBagCheck');
				imgIcon = 'infusion-room.svg';
				alertCategory = AlertCategory.IV_BAG;
				break;
			}
			case AiAlertType.PATIENT_GETTING_OUT_OF_CHAIR: {
				voiceOver = personPositionSound;
				text = translate('personGettingOutOfChairMessage');
				imgIcon = 'patientGettingOutOfChair.svg';
				alertCategory = AlertCategory.PATIENT_MOBILITY;
				break;
			}
			case AiAlertType.SNEEZING:
			case AiAlertType.SNORIG:
			case AiAlertType.COUGHING:
			case AiAlertType.CRYING_BABY:
			case AiAlertType.SCREAMING:
			case AiAlertType.HELP:
			case AiAlertType.LOW_PRIORITY_ALARM:
			case AiAlertType.MEDIUM_PRIORITY_ALARM:
			case AiAlertType.HIGH_PRIORITY_ALARM:
			case AiAlertType.ALARM_OFF:
			case AiAlertType.VITAL_SIGN_ALARM: {
				text = getAiVoiceMessageByType(type);
				voiceOver = personPositionSound;
				alertCategory = AlertCategory.AI_VOICE_ANALYSIS;
				break;
			}
			default: {
				voiceOver = dangerSound;
				text = translate('pleaseCheckPatient');
				imgIcon = 'skeletonFallDetection.svg';
				alertCategory = AlertCategory.PATIENT_MOBILITY;
				break;
			}
		}
		if (playVoiceOver && voiceOver) {
			this.playingAlert = voiceOver;
		}

		return { text, imgIcon, alertCategory };
	};

	updateVideoFeedAiToastMessage = ({ deviceId, alertId }) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		if (Object.keys(selectedFeed.warning).length !== 0) {
			return;
		}

		const toastToSave = {
			message: translate('patientIsInactiveToastMessage'),
			text: translate('patientInactive'),
			buttonText: translate('forwardToNursesBtn'),
			imgUrl: `${healthCareCdnUrl}alert-history/inactive-same-position.svg`,
			alertId,
			deviceId,
		};

		selectedFeed.aiToastDetails = toastToSave;
		this.setState({ videoFeeds: newVideoFeeds });
	};

	forwardAiAlert = async (feed, alertId) => {
		const { hospital, department, floor, room } = this.getHierarchyNaming(feed.deviceId);
		const dataToSend = {
			conversationId: this.getSelectedFloor(feed.deviceId)?.conversationId,
			alertId,
			hospital,
			department,
			floor,
			room,
		};
		const response = await forwardAiAlert(dataToSend);
		if (response.error) {
			this.setState({ error: response.error.message });
		}
	};

	getHierarchyNaming = deviceId => {
		const newTree = [...this.props.healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		if (!device?.breadcrumb) {
			return {};
		}
		const { breadcrumb } = device;
		return {
			hospital: breadcrumb[0].name,
			department: breadcrumb[1]?.name,
			floor: breadcrumb[2]?.name,
			room: breadcrumb[3]?.name,
		};
	};

	getSelectedFloor = deviceId => {
		const { channels } = this.state;
		const newTree = [...this.props.healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		return channels.find(channel => channel.floorId === device?.floorId);
	};

	shouldShowAlert = (deviceId, selectedFeed) => {
		let shouldShowAlert = true;
		const parsedAlerts = getStorage().snoozedAlerts ? JSON.parse(getStorage().snoozedAlerts) : [];
		const foundFeed = parsedAlerts.find(item => item.deviceId === selectedFeed.deviceId);
		const shouldCheckForSnooze = !deviceId && foundFeed && selectedFeed.bioBeatTaskId === foundFeed.taskId;

		if (shouldCheckForSnooze) {
			const now = new Date();
			const snoozedTime = foundFeed?.time;
			const diffMs = now.getTime() - new Date(snoozedTime).getTime();
			const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
			if (diffMins < foundFeed?.snoozeTime) {
				shouldShowAlert = false;
			}
		}
		return shouldShowAlert;
	};

	getNotificationOptions = (aiAlertType, deviceId) => {
		const foundSettingTypeId = AlertTypeToSettingTypeMap?.[aiAlertType];
		const foundAiSettings = this.props.aiSettings.find(item => item.deviceId === deviceId);
		if (!foundSettingTypeId || !foundAiSettings) {
			return [];
		}
		return foundAiSettings.settings.find(item => item.settingTypeId === foundSettingTypeId)?.notificationOptions || [];
	};

	onAlertInfoClose = (deviceId, isToastMessage = false) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		if (isToastMessage) {
			selectedFeed.aiToastDetails = {};
		} else {
			selectedFeed.alertInfo = {};
			clearTimeout(this.alertInfoTimeout);
		}
		this.setState({ videoFeeds: newVideoFeeds });
	};

	getTeams = async () => {
		if (getUserRole() !== UserRoles.NURSE || !this.props.user.userSession.healthSystem.id) {
			return;
		}
		const response = await getTeams(this.props.user.userSession.healthSystem.id);
		if (!response.error) {
			let channels = [];
			response.teams.forEach(team => {
				channels = channels.concat(team.channels.map(channel => ({ ...channel, team })));
			});
			this.setState({
				channels,
			});
		}
	};

	sendAutomaticAlert = async (alertId, isAiAlert) => {
		const { hospital, department, floor, room } = this.getHierarchyNaming(this.state.selectedFeed?.deviceId);
		const dataToSend = {
			conversationId: this.getSelectedFloor(this.state.selectedFeed.deviceId)?.conversationId,
			alertId,
			hospital,
			department,
			floor,
			room,
		};
		let response = null;
		if (isAiAlert) {
			response = await forwardAiAlert(dataToSend);
		}
		if (response.error) {
			this.setState({ error: response.error.message });
		}
	};

	onAlertCloseClick = alert => {
		if (this.state.selectedFeed?.warning?.value) {
			this.stopVoiceOver(this.state.selectedFeed.warning?.type, this.state.selectedFeed?.deviceId, alert);
		} else {
			const isToastMessage =
				this.state.selectedFeed?.aiToastDetails && Object.keys(this.state.selectedFeed?.aiToastDetails).length > 0;
			this.onAlertInfoClose(this.state.selectedFeed?.deviceId, isToastMessage);
		}
	};

	toggleFallPrevention = (status, deviceId) => {
		this.setState(prevState => ({
			videoFeeds: prevState.videoFeeds.map(videoFeed => {
				if (videoFeed.deviceId === deviceId) {
					let feed = { ...videoFeed };
					feed.isFallPrevention = status;
					return feed;
				}
				return videoFeed;
			}),
		}));
	};

	setIvBagPercentage = (deviceId, value) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		selectedFeed.ivBagPercentage = value;
		this.setState({ videoFeeds: newVideoFeeds });
	};

	render() {
		const filteredFeeds = this.state.videoFeeds.filter(item => item?.deviceId);
		const showForwardToNurses = getConfigurationValue(this.state.adminConfigurations[MonitoringSettings.ForwardToNurses]);

		const aiAsideClasses = classNames({
			'right-side-monitoring rounding-right-aside': !this.props.isConference,
			'right-side right-side-monitoring conference-whiteboard-aside rounding-right-aside':
				this.props.isConference && this.props.isAiOpen,
			'dark-rounding-whiteboard': this.props.isConference && this.props.isAiOpen && this.props.isDarkMode,
		});

		return (
			<>
				{this.props.isConference && (
					<StyledConferenceAIAlert>
						{filteredFeeds.map(feed => (
							<WarningDetection
								showForwardToNurses={showForwardToNurses}
								warningObject={feed.warning}
								alertType={AlertTypes.DANGER}
								isFeedExpanded={true}
								onForwardToNurses={this.sendAutomaticAlert}
								onAlertCloseClick={this.onAlertCloseClick}
								numberOfFeeds={1}
								feedDeviceId={this.state.selectedFeed?.deviceId}
								setSnoozeType={() => null}
							/>
						))}
					</StyledConferenceAIAlert>
				)}
				{this.props.isAiOpen && (
					<div className={classNames('new-experience', this.props.isDarkMode ? 'dark-mode' : '')}>
						<aside className={aiAsideClasses}>
							{filteredFeeds.map(feed => (
								<div className='monitoring-timeline rounding-whiteboard-wrapper' key={feed.deviceId}>
									<RoundingTimeline
										isDarkMode={this.props.user.darkMode}
										conferenceInfo={this.state.conferenceInfo}
										feed={feed}
										setIvBagPercentage={this.setIvBagPercentage}
										hierarchyNaming={this.getHierarchyNaming(feed?.deviceId)}
										isNoteShowing={this.state.isNoteShowing}
										toggleFallPrevention={this.toggleFallPrevention}
										numberOfFeeds={1}
										setSnoozeType={() => null}
										stopVoiceOver={this.stopVoiceOver}
										onAlertInfoClose={this.onAlertInfoClose}
										isDefaultOwner={feed?.isDefaultOwner}
										patientId={this.props?.deviceOwner?.patientId || feed?.deviceOwner?.healthcareUserId}
										conversationId={this.getSelectedFloor(feed?.deviceId)?.conversationId}
										onAiSettingClick={status => this.onAiSettingClick(status, feed)}
										aiSettings={this.props.aiSettings}
									/>
								</div>
							))}
						</aside>
					</div>
				)}

				{this.state.error && (
					<Alert display={this.state.error} fixed={true} hideCloseButton={true} message={this.state.error} variant='dark' />
				)}
			</>
		);
	}
}

RoundingAi.contextType = SocketContext;

const mapStateToProps = state => {
	return {
		healthSystems: state.healthSystems,
		patients: state.myPatients.patients,
		incomingCallRenderedElsewhere: state.calls.incomingCallRenderedElsewhere,
		user: state.user,
		aiSettings: state.aiSettingsList.aiSettings,
		helloName: state.company.companySettings.helloName,
		configurations: state.configurations,
		healthSystemConfigurations: state.configurations.healthSystemConfigurations,
		watchMeasurementsVisible: state.watchMeasurementsList.watchMeasurementsVisible,
		patientNotes: state.patientNotes,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		healthSystemActions: bindActionCreators(healthSystemsActionCreators, dispatch),
		setIncomingCallRenderedElsewhere: isRenderedElsewhere =>
			dispatch(callsActionCreators.setIncomingCallRenderedElsewhere(isRenderedElsewhere)),
		aiSettingsActions: bindActionCreators(aiSettingsActionCreators, dispatch),
		healthDataActions: bindActionCreators(healthDataActionCreators, dispatch),
		patientNotesActions: bindActionCreators(patientNotesActionCreators, dispatch),
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RoundingAi));
