import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Modal from 'components/Modal.jsx';
import FormInput from 'components/FormInput.jsx';
import {
	createNote,
	createMedicalInfo,
	deleteMedicalInfo,
	createEditPrescription,
	getMedicinesAndStrengths,
	getClinicalProcedures,
	getClinicalDiagnoses,
} from 'api/medicalInfo.js';
import { AlertTypes, MedicalInfoTypes, PrescriptionUnits } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers.js';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import Textarea from 'components/Textarea.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import SpeechToText from 'SpeechToText.jsx';
import _ from 'lodash';

const CallWrapUp = props => {
	const [error, setError] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [searchPrescriptionValue, setSearchPrescriptionValue] = useState('');
	const [itemsToSelect, setItemsToSelect] = useState([]);
	const [isAddNotesModalVisible, setIsAddNotesModalVisible] = useState(false);
	const [isAddDiagnoseVisible, setIsAddDiagnoseVisible] = useState(false);
	const [isAddProcedureVisible, setIsAddProcedureVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedPrescription, setSelectedPrescription] = useState(null);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);
	const [showItemDropdown, setShowItemDropdown] = useState(false);
	const [medicalItemExists, setMedicalItemExists] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const intl = useIntl();
	const conferenceConfigurations = useConferenceConfigurations();

	const prescriptionOptionsArray = [
		{ title: intl.formatMessage({ id: 'dosage' }), type: 'dosage' },
		{
			title: intl.formatMessage({ id: 'frequency' }),
			type: 'frequency',
			optionalText: intl.formatMessage({ id: 'timesAday' }),
		},
		{
			title: intl.formatMessage({ id: 'duration' }),
			type: 'duration',
			optionalText: intl.formatMessage({ id: 'numberOfDays' }),
		},
		{ title: intl.formatMessage({ id: 'refills' }), type: 'refills' },
	];

	useEffect(() => {
		const getClinicalItems = async type => {
			if (searchValue === '') {
				setItemsToSelect([]);
				return;
			}
			const response =
				type === MedicalInfoTypes.PROCEDURES ? await getClinicalProcedures(searchValue) : await getClinicalDiagnoses(searchValue);
			if (response.error) {
				setError(response.error.message);
			} else {
				setItemsToSelect(response);
			}
		};
		if (isAddDiagnoseVisible || isAddProcedureVisible) {
			const type = isAddDiagnoseVisible ? MedicalInfoTypes.DIAGNOSES : MedicalInfoTypes.PROCEDURES;
			getClinicalItems(type);
		}
	}, [searchValue, isAddDiagnoseVisible, isAddProcedureVisible]);

	useEffect(() => {
		const fetchMedicinesAndStrengths = async () => {
			const response = await getMedicinesAndStrengths(searchPrescriptionValue);
			if (response?.error) {
				setError(response.error.message);
			} else {
				const filteredItems = selectedPrescription?.medicine
					? response?.filter(item => item.rxCuisCode !== selectedPrescription?.medicine?.rxCuisCode)
					: response;
				setItemsToSelect(searchPrescriptionValue ? filteredItems : []);
			}
		};
		fetchMedicinesAndStrengths();
	}, [searchPrescriptionValue, selectedPrescription]);

	useEffect(() => {
		if (selectedPrescription?.medicine) {
			setSearchPrescriptionValue('');
		}
	}, [selectedPrescription]);

	const toggleAddNotesModal = () => {
		setIsAddNotesModalVisible(prevStateNotesModalVisibility => !prevStateNotesModalVisibility);
	};

	const itemExists = code =>
		(props.diagnoses?.length && props.diagnoses?.some(item => item.code === code)) ||
		(props.procedures?.length && props.procedures?.some(item => item.code === code));

	const saveItem = async ({ code, description }) => {
		setSelectedItem(null);
		setMedicalItemExists(null);
		const type = isAddDiagnoseVisible ? MedicalInfoTypes.DIAGNOSES : MedicalInfoTypes.PROCEDURES;
		const params = {
			visitId: props.visitId,
			code,
			description,
			type,
		};
		if (itemExists(code)) {
			setIsAddDiagnoseVisible(false);
			setIsAddProcedureVisible(false);
			setMedicalItemExists(
				translate('medicalItemExists', {
					value:
						type === MedicalInfoTypes.PROCEDURES
							? intl.formatMessage({ id: 'anotherProcedure' })
							: intl.formatMessage({ id: 'diagnose' }),
				})
			);
			return;
		}
		const response = await createMedicalInfo(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const dataToAdd = {
				code,
				description,
				id: response.id,
			};
			props.setMedicalInfo([...props[type], dataToAdd], type);
		}
		setItemsToSelect([]);
		setSearchValue('');
		closeModal();
	};

	const selectItem = item => {
		setSelectedItem(item);
		setSearchValue(`${item.code} - ${item.description}`);
		setShowItemDropdown(false);
	};

	const addNote = async (values, { resetForm }) => {
		const { title } = values;
		if (!title || !recognizedTranscription) {
			return;
		}
		const params = {
			visitId: props.visitId,
			title,
			text: recognizedTranscription,
		};
		const response = await createNote(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const newNote = {
				title,
				text: recognizedTranscription,
				id: response.id,
			};
			props.setMedicalInfo([...props.notes, newNote], MedicalInfoTypes.NOTES);
		}
		setRecognizedTranscription('');
		setIsAddNotesModalVisible(false);
		resetForm();
	};

	const deleteItem = async (id, type) => {
		setIsDeleting(true);
		if (!id) {
			return;
		}
		const params = {
			visitId: props.visitId,
			id,
			type,
		};
		const response = await deleteMedicalInfo(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const items = props[type].filter(item => item.id !== id);
			props.setMedicalInfo(items, type);
			setSelectedItemId(null);
		}
		setIsDeleting(false);
	};

	const handleDiagnoseChange = e => {
		setSearchValue(e.target.value);
		setShowItemDropdown(true);
	};

	const closeModal = () => {
		setSearchValue('');
		setIsAddProcedureVisible(false);
		setIsAddDiagnoseVisible(false);
	};

	useEffect(() => {
		const handleBeforeUnload = event => {
			event.preventDefault();
			// eslint-disable-next-line no-param-reassign
			event.returnValue = '';
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, []);

	const savePrescription = async (values, { validateForm }) => {
		setMedicalItemExists(null);
		const validationErrors = await validateForm();
		if (Object.keys(validationErrors).length) {
			return;
		}
		const dosage = parseInt(values.dosage, 10);
		const frequency = parseInt(values.frequency, 10);
		const duration = parseInt(values.duration, 10);
		const refills = parseInt(values.refills, 10);
		const params = {
			...selectedPrescription,
			dosageUnitId: PrescriptionUnits.DOSAGE_UNIT_ID,
			dosage,
			frequency,
			duration,
			quantityToDispense: dosage * frequency * duration,
			refills,
		};
		if (
			props.prescriptions.length &&
			props.prescriptions.some(item => item?.medicine?.rxCuisCode === selectedPrescription?.medicine?.rxCuisCode) &&
			!params.id
		) {
			setSelectedPrescription(null);
			setMedicalItemExists(
				translate('medicalItemExists', {
					value: intl.formatMessage({ id: 'prescription' }),
				})
			);
			return;
		}
		setIsLoading(true);
		const response = await createEditPrescription(params, props.visitId);
		if (response?.error) {
			setError(response.error.message);
		} else {
			const item = {
				...params,
				id: response.id,
			};
			const items = response.id
				? [...props.prescriptions, item]
				: [...props.prescriptions.filter(prescription => prescription.id !== params.id), params];
			props.setMedicalInfo(items, MedicalInfoTypes.PRESCRIPTIONS);
			setSelectedPrescription(null);
		}
		setIsLoading(false);
	};

	const getMinOrMaxValue = (value, isIncrease) => {
		if (isIncrease) {
			return value === 99 ? value : value + 1;
		}
		return value === 1 ? value : value - 1;
	};

	const medicalItem = (prescription, type, optionalText) => (
		<div>
			<h5>{translate(type)}</h5>
			<p>
				{prescription[type]} {optionalText && <span>{optionalText}</span>}
			</p>
		</div>
	);

	const setDeleteItemModal = (itemId, type) => {
		setSelectedItemId(itemId);
		setSelectedType(type);
	};

	const handleAllowedValues = (event, setFieldValue, type) => {
		const { value } = event.target;
		if (!value || value === '0') {
			setFieldValue(type, 1);
			return;
		}
		setFieldValue(type, parseInt(value, 10));
	};

	const medicalRadioChecks = (isDefault, onlyAsNeeded, untilDirectedToStop, checkedType, textToTranslate) => {
		if (!selectedPrescription.onlyAsNeeded && !selectedPrescription.untilDirectedToStop) {
			selectedPrescription.isDefault = true;
		}
		return (
			<div
				className='flex medical-radio-check cursor-pointer'
				onClick={() =>
					setSelectedPrescription(prevState => ({
						...prevState,
						isDefault: isDefault,
						onlyAsNeeded: onlyAsNeeded,
						untilDirectedToStop: untilDirectedToStop,
					}))
				}>
				<input type='radio' name='choice' checked={selectedPrescription[checkedType]} onChange={() => null} />
				<h5>{translate(textToTranslate)}</h5>
			</div>
		);
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					<div
						className={classNames('call-wrapup-wrapper', conferenceConfigurations.isDarkMode ? 'dark-call-wrapup-wrapper' : '')}>
						<main>
							<div className='flex flex-space-between'>
								<div className='flex'>
									<ProfilePicture
										profilePicture={props.patientProfilePicture}
										className='doctor-request-img available-doctor-request-img'
										fullName={props.patientName}
									/>
									<div>
										<h4>{props.patientName}</h4>
									</div>
								</div>
								<div className='flex'>
									<Button onClick={props.cancelVisit} text={translate('cancelVisit')} /> 
									<Button onClick={props.submitVisit} text={translate('submitWrapUp')} />
								</div>
							</div>
							<div className='call-wrapup-wrapper-details'>
								<div>
									<div className='flex flex-space-between'>
										<h3>{translate('notes')}</h3>
										<div className='flex add-wrap-up-item cursor-pointer' onClick={toggleAddNotesModal}>
											<img src={`${healthCareCdnUrl}add-new.svg`} alt='add new icon' />
											<p>
												{translate('addAnother', {
													value: intl.formatMessage({ id: 'note' }).toLowerCase(),
												})}
											</p>
										</div>
									</div>
									<div className='notes-inner-wrapper'>
										{props.notes.map(item => (
											<div className='flex'>
												<div>
													<p>{item.title}</p>
													<p>{item.text}</p>
												</div>
												<i
													onClick={() => setDeleteItemModal(item.id, MedicalInfoTypes.NOTES)}
													className='material-icons cursor-pointer'>
													close
												</i>
											</div>
										))}
										{props.notes.length === 0 && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.NOTES }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
								<div>
									<div className='flex flex-space-between'>
										<h3>{translate('prescriptions')}</h3>
										<div
											className='flex add-wrap-up-item cursor-pointer'
											onClick={() =>
												setSelectedPrescription({
													dosage: 1,
													frequency: 1,
													duration: 1,
													quantityToDispense: 1,
													refills: 1,
													patientInstructions: '',
													noteToPharmacist: '',
													dispenseAsWritten: false,
													untilDirectedToStop: false,
													onlyAsNeeded: false,
												})
											}>
											<img src={`${healthCareCdnUrl}add-new.svg`} alt='add new icon' />
											<p>
												{translate('addAnother', {
													value: intl.formatMessage({ id: 'prescription' }),
												})}
											</p>
										</div>
									</div>
									<div className='notes-inner-wrapper'>
										{props.prescriptions.map(prescription => (
											<div className='simple-txt-modal-inner prescriptions-modal' key={prescription.id}>
												<div>
													<div className='flex prescriptions-modal-icons'>
														<i onClick={() => setSelectedPrescription(prescription)} className='material-icons'>
															edit
														</i>
														<i
															className='material-icons'
															onClick={() => setDeleteItemModal(prescription.id, MedicalInfoTypes.PRESCRIPTIONS)}>
															close
														</i>
													</div>
													<h4>{`${prescription.medicine.name} ${prescription.medicine.strengthAndForm}`}</h4>
													<div className='flex'>
														{medicalItem(prescription, 'dosage', translate('capsule'))}
														{medicalItem(prescription, 'frequency', translate('timesAday'))}
													</div>
													{medicalItem(prescription, 'duration', translate('daysNo'))}
													<div className='flex'>
														{medicalItem(prescription, 'quantityToDispense')}
														{medicalItem(prescription, 'refills')}
													</div>
													<div className='flex'>
														{medicalItem(prescription, 'patientInstructions')}
														{medicalItem(prescription, 'noteToPharmacist')}
													</div>
													{prescription.dispenseAsWritten && (
														<div>
															<h5>{translate('dispensedAsWritten')}</h5>
														</div>
													)}
												</div>
											</div>
										))}

										{props.prescriptions.length === 0 && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.PRESCRIPTIONS }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
								<div>
									<div className='flex flex-space-between'>
										<h3>{translate('diagnoses')} (ICD-10-CM)</h3>
										<div
											className='flex add-wrap-up-item cursor-pointer'
											onClick={() => {
												setIsAddProcedureVisible(false);
												setIsAddDiagnoseVisible(true);
											}}>
											<img src={`${healthCareCdnUrl}add-new.svg`} alt='add new icon' />
											<p>
												{translate('addAnother', {
													value: intl.formatMessage({ id: 'diagnose' }),
												})}
											</p>
										</div>
									</div>
									<div>
										{props.diagnoses.map(diagnose => (
											<div key={diagnose.id} className='flex'>
												<div>
													<p>{diagnose.description}</p>
													<p>{diagnose.code}</p>
												</div>
												<i
													onClick={() => setDeleteItemModal(diagnose.id, MedicalInfoTypes.DIAGNOSES)}
													className='material-icons cursor-pointer'>
													close
												</i>
											</div>
										))}
										{props.diagnoses.length === 0 && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.DIAGNOSES }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
								<div>
									<div className='flex flex-space-between'>
										<h3>{translate('procedures')} (CPT-4)</h3>
										<div
											className='flex add-wrap-up-item cursor-pointer'
											onClick={() => {
												setIsAddProcedureVisible(true);
												setIsAddDiagnoseVisible(false);
											}}>
											<img src={`${healthCareCdnUrl}add-new.svg`} alt='add new icon' />
											<p>
												{translate('addAnother', {
													value: intl.formatMessage({ id: 'anotherProcedure' }),
												})}
											</p>
										</div>
									</div>
									<div>
										{props.procedures.map(procedure => (
											<div key={procedure.id} className='flex'>
												<div>
													<p>{procedure.description}</p>
													<p>{procedure.code}</p>
												</div>
												<i
													onClick={() => setDeleteItemModal(procedure.id, MedicalInfoTypes.PROCEDURES)}
													className='material-icons cursor-pointer'>
													close
												</i>
											</div>
										))}
										{props.procedures.length === 0 && (
											<p className='call-wrapup-blank'>
												{translate('noAdded', {
													value: intl.formatMessage({ id: MedicalInfoTypes.PROCEDURES }).toLowerCase(),
												})}
											</p>
										)}
									</div>
								</div>
							</div>
						</main>
					</div>
					<Formik
						initialValues={{
							text: '',
							title: '',
						}}
						onSubmit={addNote}
						validateOnBlur={false}
						validateOnChange={false}
						validationSchema={Yup.object().shape({
							title: Yup.string()
								.required(
									intl.formatMessage({
										id: 'titleIsRequired',
									})
								)
								.trim(),
						})}>
						{formikProps => {
							const { values, errors, touched, handleChange, handleSubmit } = formikProps;
							return (
								<Modal
									display={isAddNotesModalVisible}
									onModalClose={toggleAddNotesModal}
									shouldSubmitOnEnter={false}
									onModalSubmit={handleSubmit}
									position='center'
									className={classNames(
										'standard-modal-wrapper call-wrap-up-modal-wrapper',
										conferenceConfigurations.isDarkMode ? 'dark-call-wrap-up-modal-wrapper' : ''
									)}>
									<div className='standard-modal-inner simple-txt-modal-inner call-wrap-up-modal'>
										<div>
											<h4>{translate('addNote')}</h4>
											<FormInput
												name='title'
												type='text'
												labelClassName='full-width'
												className='full-width'
												text={translate('title')}
												value={values.title}
												onChange={handleChange}
												maxLength={32}
												placeholder={intl.formatMessage({ id: 'title' })}
											/>
											{errors.title && touched.title && <span className='red-error create-app-error'>{errors.title}</span>}

											<Textarea
												disabled={isSpeechToText}
												maxLength={400}
												onChange={event => setRecognizedTranscription(event.target.value)}
												value={
													isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription
												}
												maxNumberLimit={40}
												rows={10}
												name='text'
												placeholder={intl.formatMessage({ id: 'message' })}
												icon={
													<SpeechToText
														setRecognizedTranscription={setRecognizedTranscription}
														setLiveTranscription={setLiveTranscription}
														setIsSpeechToText={setIsSpeechToText}
														isSpeechToText={isSpeechToText}
													/>
												}
											/>
										</div>
									</div>
								</Modal>
							);
						}}
					</Formik>
					<Modal
						display={isAddProcedureVisible || isAddDiagnoseVisible}
						shouldSubmitOnEnter={false}
						onModalClose={closeModal}
						onModalSubmit={() => saveItem(selectedItem)}
						position='center'
						className={classNames(
							'standard-modal-wrapper call-wrap-up-modal-wrapper',
							conferenceConfigurations.isDarkMode ? 'dark-call-wrap-up-modal-wrapper' : ''
						)}
						isSubmitDisabled={!selectedItem}>
						<div className='standard-modal-inner simple-txt-modal-inner call-wrap-up-modal'>
							<div>
								<h4>
									{translate('add')} {isAddDiagnoseVisible ? translate('diagnose') : translate('procedure')}
								</h4>
								<div>
									<div className='mi-input-wrapper'>
										<input type='text' onChange={handleDiagnoseChange} value={searchValue} />
										{itemsToSelect.length > 0 && showItemDropdown && (
											<div>
												{itemsToSelect.map(item => (
													<div key={item.code} className='flex cursor-pointer' onClick={() => selectItem(item)}>
														<p>{item.code}</p>
														<p>{item.description}</p>
													</div>
												))}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</Modal>
					<Modal
						display={selectedItemId}
						onModalSubmit={_.debounce(() => deleteItem(selectedItemId, selectedType), 500)}
						onModalClose={() => setSelectedItemId(null)}
						primaryButtonLabel={translate('delete')}
						isSubmitDisabled={isDeleting}
						position='center'>
						<form>
							<h3>{translate('delete')}</h3>
							{selectedType && (
								<p>
									{translate('areYouSureDeleteItem', {
										value: intl
											.formatMessage({
												id: selectedType.slice(0, -1),
											})
											.toLowerCase(),
									})}
								</p>
							)}
						</form>
					</Modal>
					{selectedPrescription && (
						<Modal
							display={selectedPrescription}
							position='center'
							className={classNames(
								'standard-modal-wrapper modal-wrapper-wo-btn call-wrap-up-modal-wrapper',
								conferenceConfigurations.isDarkMode ? 'dark-call-wrap-up-modal-wrapper' : ''
							)}
							onModalClose={() => setSelectedPrescription(null)}>
							<Formik
								initialValues={{
									dosage: selectedPrescription.dosage,
									frequency: selectedPrescription.frequency,
									duration: selectedPrescription.duration,
									quantityToDispense: selectedPrescription.quantityToDispense,
									refills: selectedPrescription.refills,
									isDefault: selectedPrescription.isDefault,
									onlyAsNeeded: selectedPrescription.onlyAsNeeded,
									untilDirectedToStop: selectedPrescription.untilDirectedToStop,
								}}
								onSubmit={savePrescription}
								validationSchema={Yup.object().shape({
									dosage: Yup.number()
										.required(`${intl.formatMessage({ id: 'dosage' })} ${intl.formatMessage({ id: 'isRequired' })}`)
										.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
									frequency: Yup.number()
										.required(`${intl.formatMessage({ id: 'frequency' })} ${intl.formatMessage({ id: 'isRequired' })}`)
										.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
									duration: Yup.number()
										.required(`${intl.formatMessage({ id: 'duration' })} ${intl.formatMessage({ id: 'isRequired' })}`)
										.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
									quantityToDispense: Yup.number().required(
										`${intl.formatMessage({ id: 'quantityToDispense' })} ${intl.formatMessage({
											id: 'isRequired',
										})}`
									),
									refills: Yup.number()
										.required(`${intl.formatMessage({ id: 'refills' })} ${intl.formatMessage({ id: 'isRequired' })}`)
										.max(99, `${intl.formatMessage({ id: 'maxLengthIs' })} 99`),
								})}>
								{formikProps => {
									const { values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue } = formikProps;
									return (
										<>
											<div className='standard-modal-inner modal-wrapper-wo-btn-inner simple-txt-modal-inner prescriptions-modal call-wrap-up-modal'>
												<div>
													<div>
														<h4>{translate('prescriptions')}</h4>
													</div>
													{medicalRadioChecks(true, false, false, 'isDefault', 'default')}
													<div className='flex'>
														{medicalRadioChecks(false, true, false, 'onlyAsNeeded', 'onlyAsNeeded')}
														{medicalRadioChecks(false, false, true, 'untilDirectedToStop', 'orUntilDiretedToStop')}
													</div>
													<div className='mi-input-wrapper'>
														<input
															type='text'
															onChange={event => setSearchPrescriptionValue(event.target.value)}
															value={searchPrescriptionValue}
														/>
														{itemsToSelect.length > 0 && (
															<div>
																{itemsToSelect.map(item => (
																	<div
																		key={item.rxCuisCode}
																		className='flex cursor-pointer'
																		onClick={() => setSelectedPrescription(prevState => ({ ...prevState, medicine: item }))}>
																		<p>{item.name}</p>
																		<p>{item.strengthAndForm}</p>
																	</div>
																))}
															</div>
														)}
													</div>
													{selectedPrescription.medicine && (
														<div>
															<h4>{`${selectedPrescription.medicine.name}${selectedPrescription.medicine.strengthAndForm}`}</h4>
														</div>
													)}
													{prescriptionOptionsArray.map(item => (
														<div className='flex mi-input-counter-wrapper' key={item.type}>
															<div>
																<h5>{item.title}</h5>
																{item.optionalText && <span>{item.optionalText}</span>}
															</div>
															<div>
																<div className='flex mi-input-counter'>
																	<div onClick={() => setFieldValue(item.type, getMinOrMaxValue(values[item.type], false))}>
																		<i className='material-icons'>remove</i>
																	</div>
																	<input
																		onKeyDown={handleOnKeyDownNumeric}
																		type='number'
																		id={item.type}
																		onChange={handleChange}
																		value={parseInt(values[item.type], 10)}
																		max={99}
																		onBlur={event => handleAllowedValues(event, setFieldValue, item.type)}
																	/>
																	<div onClick={() => setFieldValue(item.type, getMinOrMaxValue(values[item.type], true))}>
																		<i className='material-icons'>add</i>
																	</div>
																</div>
																{errors[item.type] && touched[item.type] && (
																	<span className='red-error' data-cy='errorMessage'>
																		{errors[item.type]}
																	</span>
																)}
															</div>
														</div>
													))}
													<div className='flex mi-input-counter-wrapper quantity-to-dispense'>
														<h5 className='break-word'>{translate('quantityToDispense')}:</h5>
														<input
															disabled
															value={values.dosage * values.frequency * values.duration}
															type='number'
															id='quantityToDispense'
															className='flex-1'
															style={{ marginLeft: 'var(--spacing-m)' }}
															onChange={() => null}
														/>
													</div>
													<div className='flex'>
														<div>
															<h5>{translate('patientInstructions')}</h5>
															<Textarea
																maxLength={500}
																maxNumberLimit={50}
																rows={10}
																value={selectedPrescription.patientInstructions}
																onChange={event => {
																	const patientInstructions = event.target.value;
																	setSelectedPrescription(prevState => ({ ...prevState, patientInstructions }));
																}}
															/>
														</div>
														<div>
															<h5>{translate('noteToPharmacist')}</h5>
															<Textarea
																maxLength={500}
																maxNumberLimit={50}
																rows={10}
																value={selectedPrescription.noteToPharmacist}
																onChange={event => {
																	const noteToPharmacist = event.target.value;
																	setSelectedPrescription(prevState => ({ ...prevState, noteToPharmacist }));
																}}
															/>
														</div>
													</div>
													<div
														className='flex medical-radio-check cursor-pointer'
														onClick={() =>
															setSelectedPrescription(prevState => ({
																...prevState,
																dispenseAsWritten: !prevState.dispenseAsWritten,
															}))
														}>
														<input type='checkbox' checked={selectedPrescription.dispenseAsWritten} onChange={() => null} />
														<h5>{translate('dispensedAsWritten')}</h5>
													</div>
													<div className='flex simple-modal-btn-wrapper'>
														<Button
															type='button'
															onClick={() => setSelectedPrescription(null)}
															isLoading={isSubmitting}
															text={translate('cancel')}
															className='small top-30'
														/>
														<Button
															onClick={handleSubmit}
															isDisabled={!selectedPrescription.medicine}
															type='button'
															isLoading={isSubmitting}
															text={translate('save')}
															className='small top-30'
														/>
													</div>
												</div>
											</div>
										</>
									);
								}}
							</Formik>
						</Modal>
					)}
					<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
					<PopUpAlert
						alertType={AlertTypes.DANGER}
						display={medicalItemExists}
						onAlertClose={() => setMedicalItemExists(null)}
						contentText={medicalItemExists}
						isSilent={true}
						center={true}
						className='medical-info-exists'
					/>
				</>
			)}
		</>
	);
};

export default CallWrapUp;
