import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import CreateCompanyDetails from 'containers/CreateCompanyDetails.jsx';
import CreateCompanySettings from 'containers/CreateCompanySettings.jsx';
import CompanyInvite from 'containers/CompanyInvite.jsx';
import translate from 'i18n-translations/translate.jsx';
import { AudioRoutingOption, CameraUseOption, UserRoleIds, UserRoles } from 'constants/enums.js';
import { createCompany, checkIfOwnerEmailExists, getOrganizationDetails, getCompanyDetails } from 'api/companies.js';
import Alert from 'components/Alert.jsx';
import { capitalizeEveryFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import CreateCompanyCallSettings from 'containers/CreateCompanyCallSettings.jsx';
import {
	CompanySettings,
	defaultCustomAudioRoutingList,
	configurableMonitoringMenu,
	configurableRoundingMenu,
	configurableGeneralAndMenuSettings,
	configurableIntegrationMenu,
	configurableRoomModesSettings,
	MonitoringFeatureFlagCategories,
	RoundingFeaturesCategories,
	configurableRoomType,
	configurableTelemedicineRoomModesSettings,
	configurableHelloCareEngagement,
	CompanyCallSettings,
	ThemeTypes,
	configurableAISettings,
	configurablePatientTabs,
	AiSettingFeaturesCategory,
	PatientTabsFeaturesCategories,
	UserExperienceFeaturesCategories,
	configurableUserExperienceSettings,
	configurableDashboardSettings,
	configurableWhiteboardSettings,
	configurableRoomTypesSettings,
	configurableWorkflowTypes,
	getConfigurationWithDiffTypeKey,
	configurableVoiceCommandsSettings,
} from 'constants/configurationEnums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserRole } from 'infrastructure/auth.js';
import CreateEditFeatureFlagsCategories from 'components/CreateEditFeatureFlagsCategories.jsx';
import { prepareAiConfigsForSubmit, getCompanyAiConfigs } from 'infrastructure/helpers/aiHelper.js';

const CreateEditCompanyTabs = props => {
	const stopEditLoading = useRef(props.stopEditLoading);
	const intl = useIntl();
	const initialData = useMemo(() => {
		return {
			companyDetails: { name: '', website: '', email: '', address: '', profilePicture: '' },
			companySettings: {
				walkingRunningDistance: '',
				weight: '',
				bodyTemperature: '',
				height: '',
				bloodGlucose: '',
				dateFormat: '',
				timeFormat: '',
				selectedLanguage: 'English',
				logoToPreview: '',
				helloDevice: '',
				huddleCamera: '',
				nurseDisplayName: '',
				doctorDisplayName: '',
				beingMonitoredMessage: '',
				theme: ThemeTypes.LIGHT,
				virtualAdmission: false,
				manualAdmission: false,
				ehrAdmission: false,
			},
			monitoringSettings: configurableMonitoringMenu(getUserRole(), true),
			roundingSettings: configurableRoundingMenu(),
			generalAndMenuSettings: configurableGeneralAndMenuSettings(UserRoles.SUPER_ADMIN),
			integrationTypesSettings: configurableIntegrationMenu(),
			roomTypesSettings: configurableRoomTypesSettings(),
			roomModesSettings: configurableRoomModesSettings(),
			telemedicineModesSettings: configurableTelemedicineRoomModesSettings(),
			helloCareEngagementSettings: configurableHelloCareEngagement(),
			whiteboardSettings: configurableWhiteboardSettings(),
			aiSettings: configurableAISettings(),
			patients: configurablePatientTabs(),
			userExperience: configurableUserExperienceSettings(getUserRole()),
			dashboardSettings: configurableDashboardSettings(),
			voiceCommandsSettings: configurableVoiceCommandsSettings(),
			callSettings: {
				viewPatient: {
					value: false,
					isLocked: false,
				},
				providerMic: {
					value: false,
					isLocked: false,
				},
				patientMicrophone: {
					value: false,
					isLocked: false,
				},
				providerCamera: {
					value: false,
					isLocked: false,
				},
				patientCamera: {
					value: false,
					isLocked: false,
				},
				rsProviderMic: {
					value: false,
					isLocked: false,
				},
				rsPatientMic: {
					value: false,
					isLocked: false,
				},
				rsProviderCamera: {
					value: false,
					isLocked: false,
				},
				rsPatientCamera: {
					value: false,
					isLocked: false,
				},
				monitoring: { value: true, isLocked: false },
				routingTalkToPatient: {
					value: AudioRoutingOption.ALWAYS_FROM_HELLO,
					isLocked: false,
				},
				cameraUse: { value: false, variant: CameraUseOption.SINGLE_CAMERA_USE },
				routingMonitoring: { value: AudioRoutingOption.ALWAYS_FROM_HELLO, isLocked: false },
				routingViewPatient: { value: AudioRoutingOption.ALWAYS_FROM_HELLO, isLocked: false },
				devices: _.cloneDeep(defaultCustomAudioRoutingList(intl)),
				isCustomConfigurations: false,
			},
			companyInvite: {
				vcp: {
					email: '',
					inviteTypeId: UserRoleIds.VCP,
				},
				dc: {
					email: '',
					inviteTypeId: UserRoleIds.DIGITAL_CLINICIAN,
				},
				superUser: {
					email: '',
					inviteTypeId: UserRoleIds.SUPER_USER,
				},
			},
		};
	}, [intl]);

	const [error, setError] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const [dataToSubmit, setDataToSubmit] = useState(_.cloneDeep(initialData));
	const [enabledTab, setEnabledTab] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [requiredDataIsValid, setRequiredDataIsValid] = useState(false);
	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

	const featureFlagsTabs = [
		{ id: 0, title: translate('roleCustomization') },
		{ id: 1, title: translate('monitoring') },
		{ id: 2, title: translate('rounding') },
		{ id: 3, title: translate('integrations') },
		{ id: 5, title: translate('roomTypes') },
		{ id: 6, title: translate('aiSettings') },
		{ id: 7, title: translate('userExperience') },
		{ id: 8, title: translate('patients') },
		{ id: 9, title: translate('dashboard') },
		{ id: 10, title: translate('voiceCommands') },
	];

	const featureFlagsPanels = [
		{ settingCategory: 'generalAndMenuSettings' },
		{
			settingCategory: 'monitoringSettings',
			categoryOrder: Object.values(MonitoringFeatureFlagCategories),
		},
		{
			settingCategory: 'roundingSettings',
			categoryOrder: Object.values(RoundingFeaturesCategories),
		},
		{ settingCategory: 'integrationTypesSettings' },
		{
			settingCategory: 'roomTypesSettings',
		},
		{
			settingCategory: 'aiSettings',
			categoryOrder: Object.values(AiSettingFeaturesCategory),
			featureTypes: configurableWorkflowTypes(),
			typeTitle: 'workflow',
		},
		{
			settingCategory: 'userExperience',
			categoryOrder: Object.values(UserExperienceFeaturesCategories),
		},
		{
			settingCategory: PatientTabsFeaturesCategories.PATIENTS,
			categoryOrder: Object.values(PatientTabsFeaturesCategories),
		},
		{ settingCategory: 'dashboardSettings' },
		{ settingCategory: 'voiceCommandsSettings' },
	];

	const telemedicineModesTabs = [
		{ id: 0, title: translate('roomModes') },
		{ id: 1, title: translate('telemedicineMode') },
		{ id: 2, title: translate('helloCareEngagement') },
		{ id: 3, title: translate('whiteboard') },
	];

	const telemedicineModesPanels = [
		{ settingCategory: 'roomModesSettings', categoryOrder: null, featureTypes: configurableRoomType(), typeTitle: 'roomType' },
		{
			settingCategory: 'telemedicineModesSettings',
		},
		{
			settingCategory: 'helloCareEngagementSettings',
		},
		{
			settingCategory: 'whiteboardSettings',
		},
	];

	useEffect(() => {
		const fetchInitialConfigurationValue = (configurations, type, defaultValue) =>
			configurations.companySettings.find(item => item.settingTypeId === type && !item.deviceTypeId)?.value || defaultValue;

		const fetchCallSettingsInitialConfigurationValue = (configurations, type, defaultValue) =>
			!configurations.companySettings.find(item => item.settingTypeId === type && !item.deviceTypeId)
				? defaultValue
				: configurations.companySettings.find(item => item.settingTypeId === type && !item.deviceTypeId).value === 'true';

		const fetchCallSettingsInitialConfigurationVariant = (configurations, type, defaultValue) =>
			!configurations.companySettings.find(item => item.settingTypeId === type && !item.deviceTypeId)
				? defaultValue
				: configurations.companySettings.find(item => item.settingTypeId === type && !item.deviceTypeId).variant;

		const fetchInitialConfigurationLocked = (configurations, type) =>
			configurations.companySettings.find(item => item.settingTypeId === type && !item.deviceTypeId)?.isLocked || false;

		const getCompanyAudioRoutingBasedOnDevices = (res, arr) => {
			arr.forEach(item => {
				if (res.companySettings.find(x => x.deviceTypeId === item.enum)) {
					const setting = { ...item };
					setting.value.talkToPatient =
						res.companySettings.find(
							x => x.deviceTypeId === item.enum && x.settingTypeId === CompanySettings.TALK_TO_PATIENT_ROUTE
						)?.value || item.value.talkToPatient;
					setting.value.monitoring =
						res.companySettings.find(x => x.deviceTypeId === item.enum && x.settingTypeId === CompanySettings.MONITORING_ROUTE)
							?.value || item.value.monitoring;
					setting.value.viewPatient =
						res.companySettings.find(x => x.deviceTypeId === item.enum && x.settingTypeId === CompanySettings.VIEW_PATIENT_ROUTE)
							?.value || item.value.viewPatient;
					setting.isLocked = res.companySettings.find(x => x.deviceTypeId === item.enum)?.isLocked || item.isLocked;
				}
			});
			return arr;
		};

		const configurationTypeExist = (configResponse, keyType, objKey) =>
			getConfigurationWithDiffTypeKey(configResponse.settingTypeId, configResponse[keyType]) === objKey;

		const updateSettingsFromResponse = (configurationsResponse, settings, keyType) => {
			const updatedSettings = { ...settings };
			const updateSettingsByKey = objKey => {
				const companyConfig = configurationsResponse.companySettings.find(item => {
					if (configurationTypeExist(item, keyType, +objKey)) {
						return true;
					}
					return item.settingTypeId === +objKey;
				});
				updatedSettings[objKey].value = companyConfig?.value === 'true';
				if (companyConfig?.variant && updatedSettings[objKey].options) {
					updatedSettings[objKey].variant = updatedSettings[objKey].options.find(opt => opt.value === companyConfig.variant);
				}
				if (updatedSettings[objKey]?.isURLField) {
					const url = companyConfig?.value ?? '';
					updatedSettings[objKey].value = url === 'false' ? '' : url;
				}
				if (companyConfig?.aiConfigurations) {
					updatedSettings[objKey].aiConfigurations = getCompanyAiConfigs(companyConfig.aiConfigurations, intl);
				}
			};
			Object.keys(updatedSettings).forEach(updateSettingsByKey);
			return updatedSettings;
		};

		const fetchInitialData = async () => {
			const [configurationsResponse, detailsResponse] = await Promise.all([
				getOrganizationDetails(props.selectedCompany?.id),
				getCompanyDetails(props.selectedCompany?.id),
			]);
			if (configurationsResponse.error || detailsResponse.error) {
				setError(configurationsResponse?.error?.message || detailsResponse?.error?.message);
				stopEditLoading.current();
				return;
			}

			const initial = _.cloneDeep(initialData);

			const monitoringConfigs = { ...initial.monitoringSettings };
			const roundingConfigs = { ...initial.roundingSettings };
			const generalAndMenuConfigs = { ...initial.generalAndMenuSettings };
			const integrationTypesConfigs = { ...initial.integrationTypesSettings };
			const roomModesConfigs = { ...initial.roomModesSettings };
			const roomTypesConfigs = { ...initial.roomTypesSettings };
			const telemedicineModesConfigs = { ...initial.telemedicineModesSettings };
			const helloCareEngagementConfigs = { ...initial.helloCareEngagementSettings };
			const whiteboardConfigs = { ...initial.whiteboardSettings };
			const aiSettingsConfigs = { ...initial.aiSettings };
			const patients = { ...initial.patients };
			const userExperienceConfigs = { ...initial.userExperience };
			const dashboardConfigs = { ...initial.dashboardSettings };
			const voiceCommandsConfigs = { ...initial.voiceCommandsSettings };

			setDataToSubmit({
				...initial,
				companyDetails: {
					name: detailsResponse.name,
					website: detailsResponse.website,
					email: detailsResponse.email,
					address: detailsResponse.address || '',
					profilePicture: detailsResponse.picture || '',
				},
				companySettings: {
					...initial.companySettings,
					walkingRunningDistance: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.LENGTH_UNIT,
						initial.companySettings.walkingRunningDistance
					),
					weight: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.WEIGHT_UNIT,
						initial.companySettings.weight
					),
					bodyTemperature: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.TEMPERATURE_UNIT,
						initial.companySettings.bodyTemperature
					),
					height: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.HEIGHT_UNIT,
						initial.companySettings.height
					),
					bloodGlucose: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.BLOOD_GLUCOSE_UNIT,
						initial.companySettings.bloodGlucose
					),
					dateFormat: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.DATE_FORMAT,
						initial.companySettings.dateFormat
					),
					timeFormat: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.TIME_FORMAT,
						initial.companySettings.timeFormat
					),
					selectedLanguage: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.LANGUAGE,
						initial.companySettings.selectedLanguage
					),
					helloDevice: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.HELLO_DEVICE,
						initial.companySettings.helloDevice
					),
					huddleCamera: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.HUDDLE_CAMERA,
						initial.companySettings.huddleCamera
					),
					nurseDisplayName: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.VCP_DISPLAY_NAME,
						initial.companySettings.nurseDisplayName
					),
					doctorDisplayName: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.DOCTOR_DISPLAY_NAME,
						initial.companySettings.doctorDisplayName
					),
					beingMonitoredMessage: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.BEING_MONITORED_MESSAGE,
						initial.companySettings.beingMonitoredMessage
					),
					theme: fetchInitialConfigurationValue(configurationsResponse, CompanySettings.THEME, initial.companySettings.theme),
					virtualAdmission: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.PATIENT_VIRTUAL_ADMISSION,
						initial.companySettings.virtualAdmission
					),
					manualAdmission: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.PATIENT_MANUAL_ADMISSION,
						initial.companySettings.manualAdmission
					),
					ehrAdmission: fetchInitialConfigurationValue(
						configurationsResponse,
						CompanySettings.PATIENT_EHR_ADMISSION,
						initial.companySettings.ehrAdmission
					),
				},
				monitoringSettings: updateSettingsFromResponse(configurationsResponse, monitoringConfigs),
				roundingSettings: updateSettingsFromResponse(configurationsResponse, roundingConfigs),
				generalAndMenuSettings: updateSettingsFromResponse(configurationsResponse, generalAndMenuConfigs),
				integrationTypesSettings: updateSettingsFromResponse(configurationsResponse, integrationTypesConfigs),
				roomModesSettings: updateSettingsFromResponse(configurationsResponse, roomModesConfigs, 'roomTypeId'),
				roomTypesSettings: updateSettingsFromResponse(configurationsResponse, roomTypesConfigs),
				telemedicineModesSettings: updateSettingsFromResponse(configurationsResponse, telemedicineModesConfigs),
				helloCareEngagementSettings: updateSettingsFromResponse(configurationsResponse, helloCareEngagementConfigs),
				whiteboardSettings: updateSettingsFromResponse(configurationsResponse, whiteboardConfigs),
				aiSettings: updateSettingsFromResponse(configurationsResponse, aiSettingsConfigs, 'callWorkflowTypeId'),
				patients: updateSettingsFromResponse(configurationsResponse, patients),
				userExperience: updateSettingsFromResponse(configurationsResponse, userExperienceConfigs),
				dashboardSettings: updateSettingsFromResponse(configurationsResponse, dashboardConfigs),
				voiceCommandsSettings: updateSettingsFromResponse(configurationsResponse, voiceCommandsConfigs),
				callSettings: {
					...initial.callSettings,
					providerMic: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.PROVIDER_MIC,
							initial.callSettings.providerMic.value
						),
						isLocked: false,
					},
					patientMicrophone: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.PATIENT_MIC,
							initial.callSettings.patientMicrophone.value
						),
						isLocked: false,
					},
					providerCamera: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.PROVIDER_CAMERA,
							initial.callSettings.providerCamera.value
						),
						isLocked: false,
					},
					patientCamera: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.PATIENT_CAMERA,
							initial.callSettings.patientCamera.value
						),
						isLocked: false,
					},
					rsProviderMic: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.RS_PROVIDER_MIC,
							initial.callSettings.rsProviderMic.value
						),
						isLocked: false,
					},
					rsPatientMic: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.RS_PATIENT_MIC,
							initial.callSettings.rsPatientMic.value
						),
						isLocked: false,
					},
					rsProviderCamera: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.RS_PROVIDER_CAM,
							initial.callSettings.rsProviderCamera.value
						),
						isLocked: false,
					},
					rsPatientCamera: {
						value: fetchCallSettingsInitialConfigurationValue(
							configurationsResponse,
							CompanyCallSettings.RS_PATIENT_CAM,
							initial.callSettings.rsPatientCamera.value
						),
						isLocked: false,
					},
					routingTalkToPatient: {
						value: fetchInitialConfigurationValue(
							configurationsResponse,
							CompanySettings.TALK_TO_PATIENT_ROUTE,
							initial.callSettings.routingTalkToPatient.value
						),
						isLocked: fetchInitialConfigurationLocked(configurationsResponse, CompanySettings.TALK_TO_PATIENT_ROUTE),
					},
					routingMonitoring: {
						value: fetchInitialConfigurationValue(
							configurationsResponse,
							CompanySettings.MONITORING_ROUTE,
							initial.callSettings.routingTalkToPatient.value
						),
						isLocked: fetchInitialConfigurationLocked(configurationsResponse, CompanySettings.MONITORING_ROUTE),
					},
					routingViewPatient: {
						value: fetchInitialConfigurationValue(
							configurationsResponse,
							CompanySettings.VIEW_PATIENT_ROUTE,
							initial.callSettings.routingViewPatient.value
						),
						isLocked: fetchInitialConfigurationLocked(configurationsResponse, CompanySettings.VIEW_PATIENT_ROUTE),
					},
					cameraUse: {
						value: true,
						variant: fetchCallSettingsInitialConfigurationVariant(
							configurationsResponse,
							CompanySettings.CAMERA_USE,
							initial.callSettings.cameraUse.variant
						),
					},
					isCustomConfigurations: !!configurationsResponse.companySettings.find(item => item.deviceTypeId),
					devices: getCompanyAudioRoutingBasedOnDevices(configurationsResponse, _.cloneDeep(initialData).callSettings.devices),
				},
			});

			stopEditLoading.current();
		};

		if (props.selectedCompany?.id) {
			fetchInitialData();
		}
	}, [initialData, intl, props.selectedCompany?.id]);

	const tabChange = async (tab, current) => {
		if (!props.selectedCompany && current === tabsObj.COMPANY_DETAILS && dataToSubmit.companyDetails.email) {
			const response = await checkIfOwnerEmailExists(dataToSubmit.companyDetails.email);
			if (response.error) {
				setError(response.error);
				return;
			}
			if (response.exists) {
				setEnabledTab(null);
				setRequiredDataIsValid(false);
				return;
			}
			setCurrentTab(tab);
		} else {
			setCurrentTab(tab);
		}
	};

	const tabList = [
		{ id: 0, title: translate('companyDetails'), icon: 'employment-history' },
		{ id: 1, title: translate('companySettings'), icon: 'configurations' },
		{ id: 2, title: translate('callSettings'), icon: 'call-settings' },
		{ id: 3, title: translate('featureFlags'), icon: 'feature-flags' },
		{ id: 4, title: translate('customizeRoom'), icon: 'custom-room-modes' },
		!props.selectedCompany && { id: 5, title: translate('invite'), icon: 'contacts' },
	].filter(Boolean);

	const tabsObj = {
		COMPANY_DETAILS: 0,
		COMPANY_SETTINGS: 1,
		CALL_SETTINGS: 2,
		COMPANY_INVITE: 3,
		COMPANY_CONFIRMATION: 4,
	};

	useEffect(() => {
		const {
			walkingRunningDistance,
			weight,
			bodyTemperature,
			height,
			bloodGlucose,
			dateFormat,
			timeFormat,
			selectedLanguage,
			helloDevice,
			huddleCamera,
			nurseDisplayName,
			doctorDisplayName,
			beingMonitoredMessage,
			theme,
		} = dataToSubmit.companySettings;
		if (
			walkingRunningDistance &&
			weight &&
			bodyTemperature &&
			height &&
			bloodGlucose &&
			dateFormat &&
			timeFormat &&
			selectedLanguage &&
			helloDevice &&
			huddleCamera &&
			nurseDisplayName &&
			doctorDisplayName &&
			beingMonitoredMessage &&
			theme
		) {
			setRequiredDataIsValid(true);
		}
	}, [dataToSubmit]);

	const allowNextTab = (params, page) => {
		const setValues = { ...dataToSubmit, [page]: params };
		setEnabledTab(currentTab + 1);
		if (JSON.stringify(setValues) !== JSON.stringify(dataToSubmit)) {
			setDataToSubmit(setValues);
		}
	};

	const getAudioRoutingArr = callSettings => {
		let audioRoutingArr = [];

		if (callSettings.isCustomConfigurations) {
			const devicesAudioRouting = callSettings.devices.map(item => [
				{
					settingTypeId: CompanySettings.TALK_TO_PATIENT_ROUTE,
					value: item.value.talkToPatient,
					deviceTypeId: item.enum,
					isLocked: item.isLocked,
				},
				{
					settingTypeId: CompanySettings.MONITORING_ROUTE,
					value: item.value.monitoring,
					deviceTypeId: item.enum,
					isLocked: item.isLocked,
				},
				{
					settingTypeId: CompanySettings.VIEW_PATIENT_ROUTE,
					value: item.value.viewPatient,
					deviceTypeId: item.enum,
					isLocked: item.isLocked,
				},
			]);
			audioRoutingArr = devicesAudioRouting.flat();
		} else {
			audioRoutingArr = [
				{
					settingTypeId: CompanySettings.TALK_TO_PATIENT_ROUTE,
					value: callSettings.routingTalkToPatient.value.toString(),
					isLocked: callSettings.routingTalkToPatient.isLocked,
				},
				{
					settingTypeId: CompanySettings.MONITORING_ROUTE,
					value: callSettings.routingMonitoring.value.toString(),
					isLocked: callSettings.routingMonitoring.isLocked,
				},
				{
					settingTypeId: CompanySettings.VIEW_PATIENT_ROUTE,
					value: callSettings.routingViewPatient.value.toString(),
					isLocked: callSettings.routingViewPatient.isLocked,
				},
			];
		}
		return audioRoutingArr;
	};

	const submitForm = async companyInviteParams => {
		setIsLoading(true);

		const {
			companyDetails,
			companySettings,
			callSettings,
			monitoringSettings,
			roundingSettings,
			generalAndMenuSettings,
			integrationTypesSettings,
			roomTypesSettings,
			roomModesSettings,
			telemedicineModesSettings,
			helloCareEngagementSettings,
			whiteboardSettings,
			dashboardSettings,
			voiceCommandsSettings,
			aiSettings,
			patients,
		} = dataToSubmit;
		const invitesArray = [];
		Object.entries(companyInviteParams).forEach(([, value]) => {
			if (value.email) {
				invitesArray.push(value);
			}
		});

		const languagesArr = [
			{
				id: 1,
				content: intl.formatMessage({ id: 'english' }),
				value: 'English',
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'albanian' }),
				value: 'Albanian',
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'arabic' }),
				value: 'Arabic',
			},
			{
				id: 4,
				content: intl.formatMessage({ id: 'spanish' }),
				value: 'Spanish',
			},
			{
				id: 5,
				content: intl.formatMessage({ id: 'german' }),
				value: 'German',
			},
		];

		const companySettingsArr = [
			{
				settingTypeId: CompanySettings.LENGTH_UNIT,
				value: companySettings.walkingRunningDistance.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.WEIGHT_UNIT,
				value: companySettings.weight.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.TEMPERATURE_UNIT,
				value: companySettings.bodyTemperature.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.HEIGHT_UNIT,
				value: companySettings.height.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.BLOOD_GLUCOSE_UNIT,
				value: companySettings.bloodGlucose.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.DATE_FORMAT,
				value: companySettings.dateFormat.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.TIME_FORMAT,
				value: companySettings.timeFormat.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.LANGUAGE,
				value: companySettings.selectedLanguage
					? languagesArr.find(item => companySettings.selectedLanguage === item.value).value
					: 'English',
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.HELLO_DEVICE,
				value: companySettings.helloDevice,
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.HUDDLE_CAMERA,
				value: companySettings.huddleCamera,
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.VCP_DISPLAY_NAME,
				value: companySettings.nurseDisplayName,
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.DOCTOR_DISPLAY_NAME,
				value: companySettings.doctorDisplayName,
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.BEING_MONITORED_MESSAGE,
				value: companySettings.beingMonitoredMessage,
				isLocked: false,
			},
			{
				settingTypeId: CompanyCallSettings.PROVIDER_MIC,
				value: callSettings.providerMic.value.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanyCallSettings.PROVIDER_CAMERA,
				value: callSettings.providerCamera.value.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.THEME,
				value: companySettings.theme.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.CAMERA_USE,
				value: callSettings.cameraUse.value?.toString(),
				variant: callSettings.cameraUse.variant,
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.PATIENT_VIRTUAL_ADMISSION,
				value: companySettings.virtualAdmission.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.PATIENT_MANUAL_ADMISSION,
				value: companySettings.manualAdmission.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanySettings.PATIENT_EHR_ADMISSION,
				value: companySettings.ehrAdmission.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanyCallSettings.PATIENT_MIC,
				value: callSettings.patientMicrophone.value.toString(),
				isLocked: false,
			},
			{
				settingTypeId: CompanyCallSettings.PATIENT_CAMERA,
				value: callSettings.patientCamera.value.toString(),
				isLocked: false,
			},
		];

		const featureFlags = Object.entries({
			...monitoringSettings,
			...roundingSettings,
			...generalAndMenuSettings,
			...integrationTypesSettings,
			...roomModesSettings,
			...roomTypesSettings,
			...telemedicineModesSettings,
			...helloCareEngagementSettings,
			...whiteboardSettings,
			...aiSettings,
			...dashboardSettings,
			...voiceCommandsSettings,
			...patients,
		}).flatMap(([key, config]) => {
			return {
				settingTypeId: config.settingTypeId ? config.settingTypeId : key,
				value: config.value.toString(),
				...(config.variant ? { variant: config.variant.value } : {}),
				...(config.roomTypeId ? { roomTypeId: config.roomTypeId } : {}),
				...(config.callWorkflowTypeId ? { callWorkflowTypeId: config.callWorkflowTypeId } : {}),
				...(config.aiConfigurationTypeId &&
					config.value && {
						aiConfigurations: prepareAiConfigsForSubmit(config.aiConfigurations),
					}),
			};
		});

		const params = {
			email: companyDetails.email,
			name: capitalizeEveryFirstLetter(companyDetails.name.trim()),
			website: companyDetails.website,
			companySettings: [...companySettingsArr, ...getAudioRoutingArr(callSettings), ...featureFlags],
			users: invitesArray,
		};

		const response = await createCompany(params);
		if (response.error) {
			setError(response.error);
			setIsLoading(false);
			return;
		}
		setIsLoading(false);
		setIsSubmitSuccessful(true);
	};

	const disableNextTabs = () => {
		setEnabledTab(null);
		setRequiredDataIsValid(false);
	};

	return (
		<>
			<Tabs
				className={classNames('create-company-tabs', props.selectedCompany ? 'edit-company-tabs' : '')}
				activeIndex={currentTab}
				onChange={index => tabChange(index, currentTab)}
				isVertical={props.selectedCompany}>
				<TabList>
					{tabList.map((item, i) => {
						let isDisabled = i > currentTab && i !== enabledTab;

						if (i === tabsObj.COMPANY_INVITE && requiredDataIsValid) {
							isDisabled = false;
						}

						if (currentTab === tabsObj.COMPANY_INVITE && isSubmitSuccessful) {
							isDisabled = i < currentTab;
						}

						return (
							<Tab className={`${isDisabled && !props.selectedCompany ? 'disabled' : ''}`} key={item.id}>
								{props.selectedCompany && (
									<>
										<img
											src={`${healthCareCdnUrl}admin/super-admin/${item.icon}${currentTab === i ? '-1.svg?v2' : '.svg?v2'}`}
											alt={`${item.title} icon`}
										/>{' '}
									</>
								)}
								{item.title}
							</Tab>
						);
					})}
				</TabList>
				<TabPanels>
					<TabPanel>
						<CreateCompanyDetails
							setDataToSubmit={companyDetails => setDataToSubmit(prevState => ({ ...prevState, companyDetails }))}
							dataToSubmit={dataToSubmit.companyDetails}
							goToNextTab={() => setCurrentTab(currentTab + 1)}
							allowNextTab={params => allowNextTab(params, 'companyDetails')}
							currentTab={currentTab}
							disableNextTabs={disableNextTabs}
							selectedCompany={props.selectedCompany}
						/>
					</TabPanel>
					<TabPanel>
						<CreateCompanySettings
							goToPreviousTab={() => setCurrentTab(currentTab - 1)}
							goToNextTab={() => setCurrentTab(currentTab + 1)}
							setDataToSubmit={companySettings => setDataToSubmit(prevState => ({ ...prevState, companySettings }))}
							dataToSubmit={dataToSubmit.companySettings}
							allowNextTab={params => allowNextTab(params, 'companySettings')}
							currentTab={currentTab}
							disableNextTabs={disableNextTabs}
							selectedCompany={props.selectedCompany}
						/>
					</TabPanel>
					<TabPanel>
						<CreateCompanyCallSettings
							goToPreviousTab={() => setCurrentTab(currentTab - 1)}
							goToNextTab={() => setCurrentTab(currentTab + 1)}
							setDataToSubmit={callSettings => setDataToSubmit(prevState => ({ ...prevState, callSettings }))}
							dataToSubmit={dataToSubmit.callSettings}
							allowNextTab={params => allowNextTab(params, 'callSettings')}
							currentTab={currentTab}
							disableNextTabs={disableNextTabs}
							selectedCompany={props.selectedCompany}
						/>
					</TabPanel>
					<TabPanel key='1'>
						<CreateEditFeatureFlagsCategories
							title='featureFlags'
							description='featureFlagsDescription'
							tabsList={featureFlagsTabs}
							panelsList={featureFlagsPanels}
							goToPreviousTab={() => setCurrentTab(currentTab - 1)}
							goToNextTab={() => setCurrentTab(currentTab + 1)}
							setDataToSubmit={featureFlagsSettings => setDataToSubmit(prevState => ({ ...prevState, featureFlagsSettings }))}
							dataToSubmit={{
								monitoringSettings: dataToSubmit.monitoringSettings,
								roundingSettings: dataToSubmit.roundingSettings,
								generalAndMenuSettings: dataToSubmit.generalAndMenuSettings,
								integrationTypesSettings: dataToSubmit.integrationTypesSettings,
								roomTypesSettings: dataToSubmit.roomTypesSettings,
								aiSettings: dataToSubmit.aiSettings,
								patients: dataToSubmit.patients,
								userExperience: dataToSubmit.userExperience,
								dashboardSettings: dataToSubmit.dashboardSettings,
								voiceCommandsSettings: dataToSubmit.voiceCommandsSettings,
							}}
							allowNextTab={params => allowNextTab(params, 'featureFlags')}
							selectedCompany={props.selectedCompany}
							disableNextTabs={disableNextTabs}
							setError={setError}
							cameraNames={{
								helloName: dataToSubmit?.companySettings?.helloDevice,
								huddleName: dataToSubmit?.companySettings?.huddleCamera,
							}}
						/>
					</TabPanel>
					<TabPanel key='2'>
						<CreateEditFeatureFlagsCategories
							title='customizeRoom'
							tabsList={telemedicineModesTabs}
							panelsList={telemedicineModesPanels}
							goToPreviousTab={() => setCurrentTab(currentTab - 1)}
							goToNextTab={() => setCurrentTab(currentTab + 1)}
							setDataToSubmit={featureFlagsSettings => setDataToSubmit(prevState => ({ ...prevState, featureFlagsSettings }))}
							dataToSubmit={{
								roomModesSettings: dataToSubmit.roomModesSettings,
								telemedicineModesSettings: dataToSubmit.telemedicineModesSettings,
								helloCareEngagementSettings: dataToSubmit.helloCareEngagementSettings,
								whiteboardSettings: dataToSubmit.whiteboardSettings,
							}}
							allowNextTab={params => allowNextTab(params, 'featureFlags')}
							selectedCompany={props.selectedCompany}
							disableNextTabs={disableNextTabs}
							setError={setError}
						/>
					</TabPanel>

					{!props.selectedCompany && (
						<TabPanel>
							<CompanyInvite
								goToPreviousTab={() => setCurrentTab(currentTab - 1)}
								goToNextTab={() => setCurrentTab(currentTab + 1)}
								setDataToSubmit={companyInvite => setDataToSubmit(prevState => ({ ...prevState, companyInvite }))}
								dataToSubmit={dataToSubmit.companyInvite}
								submitForm={submitForm}
								isLoading={isLoading}
								isSubmitSuccessful={isSubmitSuccessful}
								companyEmail={dataToSubmit.companyDetails.email}
							/>
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default CreateEditCompanyTabs;
