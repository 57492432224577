import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Input from 'components/Common/FormElements/Input.jsx';
import { createInvite, checkIfCompanyMemberExists } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { ExternalIdentityProviders, InviteTypes, TaskStatus } from 'constants/enums.js';
import Modal from 'components/Modal.jsx';
import { companyAdUserExists, createAdUserInvite } from 'api/activeDirectory.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';

const InviteAdmins = props => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const userSession = useSelector(state => state.user.userSession);
	const [extraEmailsCount, setExtraEmailsCount] = useState(0);
	const [emailError, setEmailError] = useState(null);
	const [error, setError] = useState(null);
	const adUsersTab = 2;
	const nonAdAzureUser = [
		ExternalIdentityProviders.PING_FEDERATE,
		ExternalIdentityProviders.OKTA,
		ExternalIdentityProviders.DUO_SSO,
	].includes(props.adConfigType);

	useEffect(() => {
		const handleTaskStatusUpdated = data => {
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				setError(null);
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setError(translate('somethingWentWrong'));
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket]);

	const addExtraInputField = () => {
		setExtraEmailsCount(prevState => prevState + 1);
	};

	const checkEmail = async val => {
		setEmailError(null);
		if (!val) {
			return true;
		}
		const schema = Yup.string().email().required();

		let response = null;
		let companyResponse = null;
		if (await schema.isValid(val)) {
			response = await checkIfCompanyMemberExists(val);
			if (response.error) {
				setEmailError(`${intl.formatMessage({ id: 'emailExists' })}: ${response?.error?.message}`);
				return true;
			}
			if (props.currentTab !== adUsersTab) {
				return !response.exists;
			}
			if (response.exists) {
				return false;
			}
			if (props.currentTab === adUsersTab) {
				const params = {
					usersHealthSystemId: null,
				};
				companyResponse = await companyAdUserExists(val, params);
				if (companyResponse.error) {
					setEmailError(companyResponse.error.message);
					return true;
				}
				return companyResponse.exists;
			}
		}
		return true;
	};

	const onSumbitHandler = async (values, { resetForm }) => {
		if (!emailError) {
			props.onLoading();
			const newValues = Object.keys(values).reduce((object, key) => {
				const newObject = { ...object };
				if (!['firstName', 'lastName'].includes(key)) {
					newObject[key] = values[key];
				}
				return newObject;
			}, {});
			const emails = Object.values(newValues).filter(item => item !== '');
			const params = {
				users: nonAdAzureUser
					? emails.map(email => ({
							email,
							inviteTypeId: props.inviteTypeId,
							firstName: values.firstName,
							lastName: values.lastName,
						}))
					: emails.map(email => ({
							email,
							inviteTypeId: props.inviteTypeId,
						})),
			};
			let response;
			if (props.currentTab === adUsersTab) {
				params.usersHealthSystemId = userSession.healthSystem.id;
				response = await createAdUserInvite(params);
				if (response.error) {
					setError(response.error);
				}
			} else {
				response = await createInvite(params);
			}
			if (response.error) {
				props.onError();
			} else {
				props.onSuccess();
				resetForm({
					firstName: '',
					lastName: '',
					email: '',
					email0: '',
					email1: '',
					email2: '',
					email3: '',
				});
			}
		}
	};

	const onModalClose = resetForm => {
		props.onModalClose();
		resetForm();
	};

	return (
		<div className='user-management-form'>
			<Formik
				initialValues={{
					firstName: '',
					lastName: '',
					email: '',
					email0: '',
					email1: '',
					email2: '',
					email3: '',
				}}
				onSubmit={onSumbitHandler}
				validationSchema={Yup.object().shape({
					firstName: Yup.string().test('requiredIfApplicable', intl.formatMessage({ id: 'firstNameRequired' }), value =>
						props.currentTab === adUsersTab && nonAdAzureUser ? !!value : true
					),
					lastName: Yup.string().test('requiredIfApplicable', intl.formatMessage({ id: 'lastNameRequired' }), value =>
						props.currentTab === adUsersTab && nonAdAzureUser ? !!value : true
					),
					email: Yup.string()
						.required(intl.formatMessage({ id: 'emailRequired' }))
						.email()
						.test(
							'existing-email',
							intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
							val => checkEmail(val)
						),
					email0: Yup.string()
						.email()
						.test(
							'existing-email',
							intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
							val1 => checkEmail(val1)
						),
					email1: Yup.string()
						.email()
						.test(
							'existing-email',
							intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
							val => checkEmail(val)
						),
					email2: Yup.string()
						.email()
						.test(
							'existing-email',
							intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
							val => checkEmail(val)
						),
					email3: Yup.string()
						.email()
						.test(
							'existing-email',
							intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
							val => checkEmail(val)
						),
				})}>
				{formikProps => {
					// bind the submission handler remotely
					const { resetForm } = formikProps;
					props.bindSubmitForm(formikProps.submitForm);
					return (
						<Modal
							display={props.display}
							position='right'
							onModalClose={() => onModalClose(resetForm)}
							onModalSubmit={props.onModalSubmit}
							isLoading={false}>
							<Form>
								<h3>
									{props.inviteTypeId === InviteTypes.SUPER_ADMIN.type && translate('addSuperAdminUsers')}
									{props.inviteTypeId !== InviteTypes.SUPER_ADMIN.type && translate('addAdminUsers')}
								</h3>

								<>
									{props.currentTab === adUsersTab && nonAdAzureUser && (
										<>
											<Field
												name='firstName'
												type='firstName'
												label={translate('firstName')}
												placeholder={intl.formatMessage({ id: 'firstName' })}
												component={Input}
											/>
											<Field
												name='lastName'
												type='lastName'
												label={translate('lastName')}
												placeholder={intl.formatMessage({ id: 'lastName' })}
												component={Input}
											/>
										</>
									)}
									<Field
										name='email'
										type='email'
										label={translate('enterEmail')}
										placeholder={intl.formatMessage({ id: 'email' })}
										description={translate('writeEmailOfAdminToInvite')}
										component={Input}
									/>

									{[...Array(extraEmailsCount).keys()].map(key => (
										<Field
											key={key}
											name={`email${key}`}
											type='email'
											placeholder={intl.formatMessage({ id: 'email' })}
											component={Input}
										/>
									))}
									{[...Array(extraEmailsCount).keys()].length < 4 && props.currentTab !== adUsersTab && (
										<div className='cursor-pointer'>
											<span
												className='action'
												onClick={addExtraInputField}
												data-tooltip={intl.formatMessage({ id: 'addNewField' })}
												data-position='right'>
												<i className='material-icons-outlined'>add_box</i>
											</span>
										</div>
									)}
								</>
								{props.success && (
									<Alert
										display={props.success}
										message={translate('usersAddedOnHelloHealth')}
										variant='success'
										onClose={props.onCloseAlert}
									/>
								)}
								{props.error && (
									<Alert
										display={props.error}
										message={translate('somethingWentWrong')}
										variant='dark'
										onClose={props.onCloseAlert}
									/>
								)}
								{props.emailError && (
									<Alert display={props.emailError} message={props.emailError} variant='error' onClose={props.onCloseAlert} />
								)}
								<Alert display={emailError} message={emailError} variant='error' onClose={() => setEmailError(null)} />
								<Alert display={error} message={error} variant='error' onClose={() => setError(null)} />
							</Form>
						</Modal>
					);
				}}
			</Formik>
		</div>
	);
};

export default InviteAdmins;
