import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Grid, Loader } from 'components';
import translate from 'i18n-translations/translate';
import AlertHistoryModal from 'components/AlertHistoryModal';
import { AlertTabs, AnalyticsCategory, SettingsButtons, WearableProviderIds, WearablesStatus } from 'constants/enums.js';
import {
	getAiAlertActivities,
	getManualAlertActivities,
	getPatientManualAlerts,
	getMeasurementAlertActivities,
	getPatientAiAlerts,
	getPatientMeasurementAlerts,
	getHelloRecordingsPerPatient,
	getBlobUrlForRecording,
	logPlayVideoRecording,
	getAlertAnalytics,
} from 'api/alerts.js';
import { getDeviceOwnerPatient } from 'api/patients.js';
import PatientAiSettings from 'components/PatientAiSettings.jsx';
import VitalSignsThreshold from 'containers/VitalSignsThreshold.jsx';
import { getAiSettingsConfigurations, getConfigurationValue, updateConfigsList } from 'infrastructure/helpers/commonHelpers.js';
import MediaViewer from 'views/Partials/MediaViewer.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import HealthDataSetup from 'components/HealthDataSetup.jsx';
import {
	configurableMonitoringMenu,
	MonitoringSettings,
	UserSettingTypes,
	SettingsCategory,
	configurableAISettings,
	AiSetting,
} from 'constants/configurationEnums.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import { getExternalDevices } from 'api/lifeSignals.js';
import classNames from 'classnames';
import AiHistory from 'components/Monitoring/AiHistory.jsx';
import RecordingsHistory from 'components/Monitoring/RecordingsHistory.jsx';
import HealthDataHistory from 'components/Monitoring/HealthDataHistory.jsx';
import AiAlertsList from 'components/Monitoring/AiAlertsList.jsx';
import ManualAlertsList from 'components/Monitoring/ManualAlertsList.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import AiGeneralSettings from 'containers/Monitoring/AiGeneralSettings.jsx';
import Button from 'components/Button.jsx';

const MonitoringAlertTimeline = ({
	newEventFired,
	careEventFired,
	deviceId,
	deviceDetails,
	onClose,
	onAiSettingClick,
	conversationId,
	roomId,
	roomName,
	isDefaultOwner,
	isBioBeatActive,
	toggleBioBeat,
	conferenceId,
	participantId,
	toggleLifeSignals,
	isLifeSignalsActive,
	isNoteShowing,
	areEmergencyButtonsHiden,
	setLifeSignalsWearableId,
	setBiobeatWearableId,
	isDarkMode,
	alertToMap,
	isFallPrevention,
	toggleFallPrevention,
	isFromAlertCenter = false,
	userId,
	onAlertInfoClose,
	feed,
	stopVoiceOver,
	setIvBagPercentage,
}) => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(true);
	const [currentTab, setCurrentTab] = useState(-1);
	const [alertDetails, setAlertDetails] = useState(null);
	const [measurementAlerts, setMeasurementAlerts] = useState([]);
	const [aiAlerts, setAiAlerts] = useState([]);
	const [manualAlerts, setManualAlerts] = useState([]);
	const [error, setError] = useState('');
	const [patientId, setPatientId] = useState(null);
	const [isScrollLoading, setIsScrollLoading] = useState(true);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [currentButton, setCurrentButton] = useState(
		isFromAlertCenter ? SettingsButtons.AI_SETUP : SettingsButtons.HEALTH_DATA_SETUP
	);
	const [selectedRecording, setSelectedRecording] = useState(null);
	const [patientUserId, setPatientUserId] = useState(null);
	const [recordings, setRecordings] = useState([]);
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [lifeSignalsWearable, setLifeSignalsWearable] = useState(null);
	const [biobeatWearable, setBiobeatWearable] = useState(null);
	const [patientPositions, setPatientPositions] = useState([]);
	const [ivBagFluidsLevels, setIvBagFluidLevels] = useState([]);
	const [selectedAiHistoryItem, setSelectedAiHistoryItem] = useState(null);
	const socket = useContext(SocketContext);
	const companyId = getCompanyId();

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [adminRoomSettings, myRoomSettings, adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(roomId, SettingsCategory.MONITORING),
				getUserPreferences(UserSettingTypes.Monitoring, roomId),
				getRoomSettings(roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, roomId),
			]);
			const responseError = adminRoomSettings.error || myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			const response = updateConfigsList(adminRoomSettings, myRoomSettings);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			const resError = response.error || aiResponse.error;
			if (resError) {
				setError(resError);
			} else {
				setAdminAiConfigurations(aiResponse.configs);
				setAdminConfigurations(response.configs);
			}
			setFirstEnabledTab(response?.configs);
		};

		setIsLoading(true);
		fetchRoomSettings();
	}, []);

	const setFirstEnabledTab = configs => {
		if (configs && getConfigurationValue(configs[MonitoringSettings.MonitoringHealthData]) && !isFromAlertCenter) {
			toggleTab(AlertTabs.MEASUREMENT);
		} else {
			toggleTab(AlertTabs.AI);
		}
	};

	useEffect(() => {
		if (alertToMap?.id && getConfigurationValue(adminConfigurations[MonitoringSettings.MonitoringHealthData])) {
			setMeasurementAlerts(prevState => {
				prevState.unshift(alertToMap);
				return prevState;
			});
		}
	}, [alertToMap?.id]);

	useEffect(() => {
		setIsLoading(true);
		const fetchHelloDeviceOwner = async id => {
			const response = await getDeviceOwnerPatient(id);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPatientId(response.healthcareUserId);
				setPatientUserId(response.userId);
			}
			setIsLoading(false);
		};
		if (!isDefaultOwner) {
			fetchHelloDeviceOwner(deviceId);
		}
	}, [deviceId, isDefaultOwner]);

	useEffect(() => {
		const getWearable = async () => {
			const response = await getExternalDevices({ patientId: patientUserId, status: WearablesStatus.ACTIVE });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (!response.error && response?.patientExternalIotDevices?.length > 0) {
				if (
					getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]) &&
					response.patientExternalIotDevices.some(
						wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.LIFE_SIGNALS
					)
				) {
					const found = response.patientExternalIotDevices.find(
						wearable => wearable.externalIotDeviceType.vendorId === WearableProviderIds.LIFE_SIGNALS
					);
					if (found) {
						setLifeSignalsWearable(found);
						setLifeSignalsWearableId(deviceId, found.iotDeviceId);
					}
				}
				if (
					getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) &&
					response.patientExternalIotDevices.some(
						wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.BIO_BEAT
					)
				) {
					const found = response.patientExternalIotDevices.find(
						wearable => wearable.externalIotDeviceType.vendorId === WearableProviderIds.BIO_BEAT
					);
					if (found) {
						setBiobeatWearable(found);
						setBiobeatWearableId(deviceId, found.iotDeviceId);
					}
				}
			}
		};

		if (
			!isDefaultOwner &&
			patientUserId &&
			(getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) ||
				getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]))
		) {
			getWearable();
		}
	}, [deviceId, patientUserId, isDefaultOwner, adminConfigurations]);

	useEffect(() => {
		setIsLoading(true);
		if (!patientId) {
			setIsLoading(false);
			return;
		}
		if (
			currentTab === AlertTabs.MEASUREMENT &&
			currentButton === SettingsButtons.HEALTH_HISTORY &&
			getConfigurationValue(adminConfigurations[MonitoringSettings.MonitoringHealthData])
		) {
			fetchMeasurementAlerts(0);
		}
		if (currentTab === AlertTabs.AI) {
			if (currentButton === SettingsButtons.AI_ALERTS) {
				fetchAiAlerts();
			}
			if (currentButton === SettingsButtons.AI_HISTORY) {
				setSelectedAiHistoryItem({
					label: intl.formatMessage({ id: 'patientMobility' }),
					value: AnalyticsCategory.PATIENT_POSITION,
				});
			}
		}
		if (currentTab === AlertTabs.MANUAL) {
			fetchManualAlerts();
		}
		if (currentTab === AlertTabs.RECORDING) {
			fetchRecordings();
		}
		setIsLoading(false);
	}, [currentTab, patientId, currentButton]);

	useEffect(() => {
		if (selectedAiHistoryItem?.value) {
			fetchAnalytics({ isScroll: false, shouldResetPageIndex: true });
		}
	}, [selectedAiHistoryItem?.value]);

	useEffect(() => {
		if (!newEventFired || patientId === null) return;

		const fetchManualAlerts = async () => {
			const params = {
				pageSize: 10,
				pageIndex: 0,
				patientId,
				category: null,
			};
			const response = await getPatientManualAlerts(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setManualAlerts(prevState => [response.patientManualAlerts[0], ...prevState]);
				careEventFired(null);
			}
			setIsScrollLoading(false);
			setIsLoading(false);
		};
		fetchManualAlerts();
	}, [patientId, newEventFired, careEventFired]);

	const fetchMeasurementAlerts = async index => {
		const params = {
			pageSize: 10,
			pageIndex: index || pageIndex,
			userId: patientId,
		};
		const response = await getPatientMeasurementAlerts(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			if (pageIndex > 0) {
				setMeasurementAlerts(prevState => [...prevState, ...response.measurementAlerts]);
			} else {
				setMeasurementAlerts(response.measurementAlerts);
			}
			setPageIndex(prevState => prevState + 1);
			setHasReachedEnd(measurementAlerts.length === response.totalCount);
		}
		setIsScrollLoading(false);
		setIsLoading(false);
	};

	const fetchAiAlerts = async () => {
		const params = {
			pageSize: 10,
			pageIndex,
			userId: patientId,
		};
		const response = await getPatientAiAlerts(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			if (pageIndex > 0) {
				setAiAlerts(prevState => [...prevState, ...response.aiAlerts]);
			} else {
				setAiAlerts(response.aiAlerts);
			}
			setPageIndex(prevState => prevState + 1);
			setHasReachedEnd(aiAlerts.length === response.totalCount);
		}
		setIsScrollLoading(false);
		setIsLoading(false);
	};

	const fetchAnalytics = async ({ isScroll = true, shouldResetPageIndex = false }) => {
		if (!isScroll) {
			setIsLoading(true);
		}

		let pageToRequest = pageIndex;

		if (shouldResetPageIndex) {
			pageToRequest = 0;
		}

		if (isScroll) {
			pageToRequest = pageIndex + 1;
		}

		const params = {
			pageSize: 10,
			pageIndex: shouldResetPageIndex ? 0 : pageToRequest,
			patientId,
			category: selectedAiHistoryItem?.value,
		};

		if (shouldResetPageIndex) {
			setPageIndex(0);
			setHasReachedEnd(false);
		}

		const response = await getAlertAnalytics(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			let endOfScroll = false;
			if (selectedAiHistoryItem?.value === AnalyticsCategory.PATIENT_POSITION) {
				setPatientPositions(pageToRequest > 0 ? prevState => [...prevState, ...response.analytics] : response.analytics);
				setIvBagFluidLevels([]);
				endOfScroll = patientPositions.length === response.totalCount;
			}
			if (selectedAiHistoryItem?.value === AnalyticsCategory.IV_BAG_FLUID_LEVEL) {
				setIvBagFluidLevels(pageToRequest > 0 ? prevState => [...prevState, ...response.analytics] : response.analytics);
				setPatientPositions([]);
				endOfScroll = ivBagFluidsLevels.length === response.totalCount;
			}

			if (isScroll) {
				setPageIndex(prevState => prevState + 1);
				setHasReachedEnd(endOfScroll);
			}
		}
		setIsScrollLoading(false);
		setIsLoading(false);
	};

	const fetchManualAlerts = async () => {
		const params = {
			pageSize: 10,
			pageIndex,
			patientId,
			category: null,
		};
		const response = await getPatientManualAlerts(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			if (pageIndex > 0) {
				setManualAlerts(prevState => [...prevState, ...response.patientManualAlerts]);
			} else {
				setManualAlerts(response.patientManualAlerts);
			}

			setPageIndex(prevState => prevState + 1);
			setHasReachedEnd(manualAlerts.length === response.totalCount);
		}
		setIsScrollLoading(false);
		setIsLoading(false);
	};

	const getMeasurementDetails = async id => {
		const response = await getMeasurementAlertActivities(id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAlertDetails(response.measurementAlertActivities);
	};

	const getAiDetails = async id => {
		const response = await getAiAlertActivities(id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAlertDetails(response.aiAlertActivities);
	};

	const handleScroll = async event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !isScrollLoading && !hasReachedEnd) {
			setIsScrollLoading(true);
			if (currentTab === AlertTabs.MEASUREMENT) {
				fetchMeasurementAlerts();
			}
			if (currentTab === AlertTabs.AI) {
				if (currentButton === SettingsButtons.AI_ALERTS) {
					fetchAiAlerts();
				}
				if (currentButton === SettingsButtons.AI_HISTORY) {
					fetchAnalytics({ isScroll: true, shouldResetPageIndex: false });
				}
			}
			if (currentTab === AlertTabs.MANUAL) {
				fetchManualAlerts();
			}
		}
	};

	const getManualAlertDetails = async id => {
		const response = await getManualAlertActivities(id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAlertDetails(response.manualAlertActivities);
	};

	const toggleTab = tab => {
		setPageIndex(0);
		setHasReachedEnd(false);
		switch (tab) {
			case AlertTabs.MEASUREMENT:
				setCurrentTab(AlertTabs.MEASUREMENT);
				setCurrentButton(SettingsButtons.HEALTH_DATA_SETUP);
				break;
			case AlertTabs.AI:
				setCurrentTab(AlertTabs.AI);
				setCurrentButton(SettingsButtons.AI_SETUP);
				break;
			case AlertTabs.MANUAL:
				setCurrentTab(AlertTabs.MANUAL);
				break;
			case AlertTabs.RECORDING:
				setCurrentTab(AlertTabs.RECORDING);
				break;
			default:
				break;
		}
	};

	const fetchRecordings = async () => {
		const response = await getHelloRecordingsPerPatient(roomId, patientId);
		if (response.error) {
			setIsLoading(false);
			return;
		}
		setRecordings(response.monitoringRecords);
		setIsLoading(false);
	};

	const openRecordedVideo = async record => {
		const [response, logResponse] = await Promise.all([
			getBlobUrlForRecording(record.blobName, companyId),
			logPlayVideoRecording(record.id),
		]);
		if (response.error || logResponse.error) {
			const responseError = response.error || logResponse.error;
			setError(responseError.message);
			return;
		}
		setSelectedRecording(response.result.uri.uri);
	};

	const handleChipClick = type => {
		setCurrentButton(type);
		setHasReachedEnd(false);
		setPageIndex(0);
	};

	const anyCareEventConfigEnabled =
		getConfigurationValue(adminConfigurations[MonitoringSettings.CareEventsForNurses]) ||
		getConfigurationValue(adminConfigurations[MonitoringSettings.CareEventsForSafetyCompanions]);

	const shouldShowPatientAiSettings = () =>
		currentButton === SettingsButtons.AI_SETUP &&
		patientId &&
		!isLoading &&
		!getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SILENT_MODE]);

	useEffect(() => {
		const onAiAlertActivity = data => {
			if (!data || currentButton !== SettingsButtons.AI_ALERTS) {
				return;
			}
			fetchAiAlerts();
		};
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);
		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, onAiAlertActivity);
		};
	}, [socket, currentButton]);

	return (
		<div className='monitoring-timeline-wrapper' onScroll={handleScroll}>
			<div className='timeline-close cursor-pointer' onClick={onClose}>
				<i className='material-icons'>close</i>
			</div>
			<>
				{!alertDetails && (
					<>
						<span className='room-name'>{deviceDetails}</span>
						<div className='flex flex-space-between monitoring-alert-title-wrapper'>
							{!isFromAlertCenter && getConfigurationValue(adminConfigurations[MonitoringSettings.MonitoringHealthData]) && (
								<div
									className={classNames(
										'cursor-pointer monitoring-alert-title',
										currentTab === AlertTabs.MEASUREMENT ? 'active' : ''
									)}
									onClick={() => toggleTab(AlertTabs.MEASUREMENT)}>
									<p>{translate('healthData')}</p>
								</div>
							)}
							<div
								className={classNames('cursor-pointer monitoring-alert-title', currentTab === AlertTabs.AI ? 'active' : '')}
								onClick={() => toggleTab(AlertTabs.AI)}>
								<p>{translate('ai')}</p>
							</div>
							{anyCareEventConfigEnabled && (
								<div
									className={classNames('cursor-pointer monitoring-alert-title', currentTab === AlertTabs.MANUAL ? 'active' : '')}
									onClick={() => toggleTab(AlertTabs.MANUAL)}>
									<p>{translate('careEvents')}</p>
								</div>
							)}
							{!isFromAlertCenter && getConfigurationValue(adminConfigurations[MonitoringSettings.Recording]) && (
								<div
									className={classNames(
										'cursor-pointer monitoring-alert-title',
										currentTab === AlertTabs.RECORDING ? 'active' : ''
									)}
									onClick={() => toggleTab(AlertTabs.RECORDING)}>
									<p>{translate('recordings')}</p>
								</div>
							)}
						</div>
					</>
				)}
				{!alertDetails &&
					currentTab === AlertTabs.MEASUREMENT &&
					!isFromAlertCenter &&
					getConfigurationValue(adminConfigurations[MonitoringSettings.MonitoringHealthData]) && (
						<>
							<div className='flex alert-subtabs'>
								{!isFromAlertCenter && (
									<>
										<Button
											className={classNames(
												'setting-alert-btn',
												currentButton === SettingsButtons.HEALTH_DATA_SETUP ? 'active' : ''
											)}
											onClick={() => handleChipClick(SettingsButtons.HEALTH_DATA_SETUP)}
											text={translate('telemetry')}
										/>
									</>
								)}
								<Button
									className={classNames('setting-alert-btn', currentButton === SettingsButtons.HEALTH_HISTORY ? 'active' : '')}
									onClick={() => handleChipClick(SettingsButtons.HEALTH_HISTORY)}
									text={translate('history')}
								/>
								<Button
									className={classNames('setting-alert-btn', currentButton === SettingsButtons.SAFE_RANGES ? 'active' : '')}
									onClick={() => handleChipClick(SettingsButtons.SAFE_RANGES)}
									text={translate('safeRanges')}
								/>
							</div>
							{currentButton === SettingsButtons.HEALTH_HISTORY && !isLoading && (
								<HealthDataHistory measurementAlerts={measurementAlerts} getMeasurementDetails={getMeasurementDetails} />
							)}
							{currentButton === SettingsButtons.SAFE_RANGES && !isLoading && (
								<VitalSignsThreshold patientId={patientId} isFromMonitoring={true} />
							)}
							{currentButton === SettingsButtons.HEALTH_DATA_SETUP && !isLoading && !isFromAlertCenter && (
								<HealthDataSetup
									deviceId={deviceId}
									conferenceId={conferenceId}
									participantId={participantId}
									patientUserId={patientUserId}
									isBioBeatActive={isBioBeatActive}
									toggleBioBeat={toggleBioBeat}
									isLifeSignalsActive={isLifeSignalsActive}
									toggleLifeSignals={toggleLifeSignals}
									healthDataConfigurableMonitoring={{
										[MonitoringSettings.SmartWatchVitalSigns]: getConfigurationValue(
											adminConfigurations[MonitoringSettings.SmartWatchVitalSigns]
										),
										[MonitoringSettings.BiobeatVitalSigns]: getConfigurationValue(
											adminConfigurations[MonitoringSettings.BiobeatVitalSigns]
										),
										[MonitoringSettings.LifeSignals]: getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]),
									}}
									lifeSignalsWearable={lifeSignalsWearable}
									biobeatWearable={biobeatWearable}
									isDarkMode={isDarkMode}
								/>
							)}
						</>
					)}
				{!alertDetails && currentTab === AlertTabs.AI && (
					<>
						<div className='flex alert-subtabs'>
							<Button
								className={classNames('setting-alert-btn', currentButton === SettingsButtons.AI_SETUP ? 'active' : '')}
								onClick={() => handleChipClick(SettingsButtons.AI_SETUP)}
								text={translate('setup')}
							/>
							<Button
								className={classNames('setting-alert-btn', currentButton === SettingsButtons.AI_GENERAL_SETTINGS ? 'active' : '')}
								onClick={() => handleChipClick(SettingsButtons.AI_GENERAL_SETTINGS)}
								text={translate('general')}
							/>
							<Button
								className={classNames('setting-alert-btn', currentButton === SettingsButtons.AI_ALERTS ? 'active' : '')}
								onClick={() => handleChipClick(SettingsButtons.AI_ALERTS)}
								text={translate('alerts')}
							/>
							<Button
								className={classNames('setting-alert-btn', currentButton === SettingsButtons.AI_HISTORY ? 'active' : '')}
								onClick={() => handleChipClick(SettingsButtons.AI_HISTORY)}
								text={translate('history')}
							/>
						</div>
						{currentButton === SettingsButtons.AI_HISTORY && !isLoading && (
							<AiHistory
								isDarkMode={isDarkMode}
								selectedAiHistoryItem={selectedAiHistoryItem}
								setSelectedAiHistoryItem={setSelectedAiHistoryItem}
								patientPositions={patientPositions}
								ivBagFluidsLevels={ivBagFluidsLevels}
							/>
						)}
						{currentButton === SettingsButtons.AI_ALERTS && !isLoading && (
							<AiAlertsList aiAlerts={aiAlerts} isDarkMode={isDarkMode} getAiDetails={getAiDetails} />
						)}

						{shouldShowPatientAiSettings() && (
							<PatientAiSettings
								onAlertInfoClose={onAlertInfoClose}
								deviceId={deviceId}
								patientId={patientId}
								onAiSettingClick={onAiSettingClick}
								adminAiSettingsConfigurations={adminAiSettingsConfigurations}
								areEmergencyButtonsHiden={areEmergencyButtonsHiden}
								isNoteShowing={isNoteShowing}
								isDarkMode={isDarkMode}
								isFallPrevention={isFallPrevention}
								toggleFallPrevention={toggleFallPrevention}
								userId={userId}
								feedWarning={feed?.warning}
								stopVoiceOver={stopVoiceOver}
								roomId={roomId}
								deviceFamily={feed?.deviceFamily}
								setIvBagPercentage={setIvBagPercentage}
							/>
						)}
						{currentButton === SettingsButtons.AI_GENERAL_SETTINGS && (
							<AiGeneralSettings
								patientId={patientId}
								deviceId={deviceId}
								isDarkMode={isDarkMode}
								roomId={roomId}
								adminAiSettingsConfigurations={adminAiSettingsConfigurations}
								onAiSettingClick={onAiSettingClick}
							/>
						)}
					</>
				)}

				{!alertDetails && currentTab === AlertTabs.MANUAL && anyCareEventConfigEnabled && (
					<ManualAlertsList
						manualAlerts={manualAlerts}
						isLoading={isLoading}
						isDarkMode={isDarkMode}
						getManualAlertDetails={getManualAlertDetails}
					/>
				)}
				{!alertDetails &&
					currentTab === AlertTabs.RECORDING &&
					!isFromAlertCenter &&
					getConfigurationValue(adminConfigurations[MonitoringSettings.Recording]) && (
						<RecordingsHistory
							recordings={recordings}
							isLoading={isLoading}
							roomName={roomName}
							isDarkMode={isDarkMode}
							openRecordedVideo={openRecordedVideo}
						/>
					)}
				{alertDetails && (
					<>
						<div className='flex'>
							<div className='cursor-pointer --blue-color go-back' onClick={() => setAlertDetails(null)}>
								{translate('goBack')}
							</div>
							<div className='monitoring-alert-details-title'>{translate('eventDetails')}</div>
						</div>
						<AlertHistoryModal
							alertDetails={alertDetails}
							isFromMonitoring={true}
							isManual={currentTab === AlertTabs.MANUAL}
							isMeasurementAlert={currentTab === AlertTabs.MEASUREMENT}
						/>
					</>
				)}
			</>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			{selectedRecording && (
				<MediaViewer
					display={true}
					onModalClose={() => setSelectedRecording(null)}
					attachments={[]}
					monitoringSession={true}
					setSelectedAttachment={() => setSelectedRecording({ typeId: 2, url: null })}
					conversationId={conversationId}
					isFromMonitoring={true}
					selectedRecording={selectedRecording}
				/>
			)}
		</div>
	);
};

export default MonitoringAlertTimeline;
