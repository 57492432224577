import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { Grid, Textarea } from 'components';
import { SymptomsLength, VisitReasons } from 'constants/enums.js';
import { primaryCareImageUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';

const AdditionalHealthData = props => {
	const intl = useIntl();
	const [searchBox, setSearchBox] = useState('');
	const [healthConcern, setHealthConcern] = useState({
		reasonOfVisit: props.additionalData.reasonOfVisit,
		additionalNotes: props.additionalData.additionalNotes,
		attachments: props.additionalData.attachments,
		medicalVisitReasonText: props.additionalData.medicalVisitReasonText,
		answer1: props.additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 1)?.answer,
		answer2: props.additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 2)?.answer,
		answer3: props.additionalData.medicalQuestionAnswers.find(item => item.medicalQuestionId === 3)?.answer,
	});

	const visitReasons = [
		{
			id: 0,
			value: VisitReasons.INSTRUCTION,
			text: intl.formatMessage({ id: 'instruction' }),
		},
		{
			id: 1,
			value: VisitReasons.THERAPY_DESCRIPTION,
			text: intl.formatMessage({ id: 'therapyDescription' }),
		},
		{
			id: 2,
			value: VisitReasons.CHECK_UP,
			text: intl.formatMessage({ id: 'checkUp' }),
		},
		{
			id: 3,
			value: VisitReasons.RE_CHECK_UP,
			text: intl.formatMessage({ id: 'reCheckUp' }),
		},
		{
			id: 4,
			value: VisitReasons.REFERRAL,
			text: intl.formatMessage({ id: 'referral' }),
		},
		{
			id: 5,
			value: VisitReasons.OTHER,
			text: intl.formatMessage({ id: 'other' }),
		},
	];

	const [selectedVisitOption, setSelectedVisitOption] = useState(
		visitReasons.find(item => item.value === props.additionalData.reasonOfVisit).value
	);

	useEffect(() => {
		const healthConcernKeys = Object.keys(healthConcern);
		if (
			healthConcernKeys.some(item => item.startsWith('answer')) ||
			healthConcernKeys.some(item => item.startsWith('additionalNotes')) ||
			healthConcernKeys.some(item => item.startsWith('attachments'))
		) {
			const medicalQuestionAnswers = props.questions.map(question => ({
				medicalQuestionId: question.id,
				answer: healthConcern[`answer${question.id}`],
			}));
			const additionalData = {
				medicalQuestionAnswers,
				additionalNotes: healthConcern.additionalNotes,
				attachments: healthConcern.attachments,
				reasonOfVisit: +healthConcern.reasonOfVisit,
				medicalVisitReasonText: healthConcern.medicalVisitReasonText,
			};
			props.setAnswers(additionalData);
		}
	}, [healthConcern]);

	const onSearch = event => {
		const searchBoxValue = event.target.value;
		setSearchBox(searchBoxValue);
	};

	const addSymptom = symptom => {
		if (props.selectedSymptoms.length < SymptomsLength.MAX_SYMPTOMS) {
			setSearchBox('');
			props.addSymptom(symptom);
		}
	};

	const filteredSymptoms = props.symptoms.filter(item => item.name.toLowerCase().includes(searchBox.toLowerCase()));
	const searchWords = searchBox.split(' ');

	const handleChange = event => {
		setHealthConcern({ ...healthConcern, [event.target.name]: event.target.value });
		if (event.target.name !== 'reasonOfVisit') {
			return;
		}
		const foundVisitReason = visitReasons.find(item => item.value === +event.target.value);
		setSelectedVisitOption(foundVisitReason?.value || VisitReasons.OTHER);
	};

	useEffect(() => {
		props.setIsEditProfile();
	}, []);

	return (
		<Grid columns='1fr' stretch='100%'>
			<div className='check-in-patient-wrapper select-doctor-tabs-wrapper'>
				<div className='full-page-input-wrapper additional-health select-doctor-wrapper position-relative'>
					<div className='flex flex-space-between primary-care-title-wrapper'>
						<Button imgIcon={`${primaryCareImageUrl}back-arrow.svg`} onClick={props.goBack} text={translate('goBack')} />
						<div className='flex'>
							<Button imgIcon={`${primaryCareImageUrl}next-arrow.svg`} onClick={props.nextTab} text={translate('next')} />
						</div>
					</div>
					<div className='flex flex-align-center column-direction'>
						<div className='check-in-title'>
							<h3>{translate('patientHealthDetails')}</h3>
							<p>{translate('selectSignsSymptomsAssociatedWithHealthConcerns')}</p>
						</div>
						<div className='flex column-direction position-relative'>
							<>
								<label>{translate('reasonOfVisit')}</label>
								<select name='reasonOfVisit' value={selectedVisitOption} onChange={event => handleChange(event)}>
									{visitReasons.map(item => (
										<option value={item.value}>{item.text}</option>
									))}
								</select>
							</>

							{+healthConcern.reasonOfVisit === VisitReasons.OTHER && (
								<label className='flex column-direction'>
									{translate('other')}
									<input
										type='text'
										name='medicalVisitReasonText'
										placeholder={intl.formatMessage({ id: 'other' })}
										value={healthConcern.medicalVisitReasonText}
										onChange={event => handleChange(event)}
										maxLength={127}
									/>
									<span className='red-error'>{props.additionalData.errorMessage}</span>
								</label>
							)}

							<label>{translate('searchForSymptoms')}</label>
							<input
								type='search'
								disabled={props.selectedSymptoms.length >= SymptomsLength.MAX_SYMPTOMS}
								placeholder={intl.formatMessage({
									id: props.selectedSymptoms.length < SymptomsLength.MAX_SYMPTOMS ? 'typeSymptoms' : 'maxSymptomsNo',
								})}
								onChange={onSearch}
								value={capitalizeFirstLetter(searchBox)}
								maxLength={100}
							/>
							{searchBox !== '' && (
								<div className='full-page-input-results' data-cy='symptomResults'>
									{filteredSymptoms.length > 0 &&
										filteredSymptoms.map(symptom => (
											<div key={symptom.id} onClick={() => addSymptom(symptom)} className='cursor-pointer'>
												<Highlighter searchWords={searchWords} autoEscape={true} textToHighlight={symptom.name} />
											</div>
										))}
									{filteredSymptoms.length === 0 && (
										<div>
											<span>{translate('noSymptomsFound')}</span>
										</div>
									)}
								</div>
							)}
						</div>
						<div className='flex column-direction'>
							<label>{translate('mySymptoms')}</label>
							<div className='patient-symptoms-list'>
								{!props.selectedSymptoms.length && <span>{intl.formatMessage({ id: 'selectedSymptoms' })}</span>}
								<div className='flex flex-wrap'>
									{props.selectedSymptoms.length > 0 &&
										props.selectedSymptoms.map(symptom => (
											<div className='symptom-btn' key={symptom.id}>
												<i className='material-icons' onClick={() => props.removeSymptom(symptom)}>
													close
												</i>
												{symptom.name}
											</div>
										))}
								</div>
							</div>
						</div>
						{props.questions.map(item => (
							<div className='flex column-direction' key={item.id}>
								<label>{item.question}</label>
								<input
									type='search'
									placeholder={intl.formatMessage({ id: 'typeHere' })}
									maxLength={50}
									value={capitalizeFirstLetter(healthConcern[`answer${item.id}`])}
									onChange={handleChange}
									name={`answer${item.id}`}
								/>
							</div>
						))}
						<div className='flex column-direction'>
							<label>{translate('additionalNotes')}</label>
							<Textarea
								maxLength={500}
								maxNumberLimit={50}
								rows={10}
								placeholder={intl.formatMessage({ id: 'typeHere' })}
								value={capitalizeFirstLetter(healthConcern.additionalNotes)}
								onChange={handleChange}
								name='additionalNotes'
							/>
						</div>
					</div>
				</div>
			</div>
		</Grid>
	);
};

export default AdditionalHealthData;
