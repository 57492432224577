import styled from 'styled-components';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { IconButton } from 'calls/components/index.js';
import { getCallsButtonColor } from 'infrastructure/helpers/commonHelpers.js';

/**
 * @type {import('styled-components').StyledComponent<div, any, { $isDarkMode: boolean, $outsideCall: boolean }, never>}
 */
const StyledRectangleButton = styled.div`
	background-color: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-2-v2)' : '')};
	border-radius: var(--spacing-s);

	button {
		border: ${props => (props.$outsideCall ? `1px solid var(--gray-8-o05)` : `none`)};
		flex-direction: column;
		text-align-last: center;
		border-radius: var(--spacing-s);
		padding: ${LightTheme.spacing[1]}px;
		min-width: ${props => (props.$outsideCall ? 100 : 50)}px;
		max-width: 190px;

		svg {
			margin-bottom: ${LightTheme.spacing[1] / 2}px;
		}

		img {
			max-width: ${LightTheme.spacing[5]}px;
		}

		p {
			text-transform: none;
			white-space: nowrap;
			font-size: ${LightTheme.spacing[3]}px;
			margin: 0;
			padding: 0;
			text-overflow: ellipsis;
			overflow: hidden;
			width: 100%;
			color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.graySix)};

			&.active {
				color: ${LightTheme.colors.blueFour} !important;
			}
		}
	}
`;

const RectangleButton = ({
	darkMode,
	isActive,
	onClick,
	icon: Icon,
	iconWidth = 18,
	iconHeight = 18,
	label,
	outsideCall = false,
	disabled = null,
}) => (
	<StyledRectangleButton $isDarkMode={darkMode} $outsideCall={outsideCall}>
		<IconButton
			disabled={disabled}
			background={isActive ? LightTheme.colors.blueFive : null}
			color={isActive ? LightTheme.colors.blueFour : getCallsButtonColor(darkMode)}
			borderColor={isActive ? LightTheme.colors.blueOne05 : null}
			onClick={onClick}>
			<Icon width={iconWidth} height={iconHeight} color={isActive ? LightTheme.colors.blueFour : getCallsButtonColor(darkMode)} />
			<p className={isActive ? 'active' : ''}>{label}</p>
		</IconButton>
	</StyledRectangleButton>
);

export default RectangleButton;
