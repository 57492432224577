import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { Alert, Modal } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { addConfig, updateConfig } from 'api/eManager.js';
import { getCompanyId, getUserId } from 'infrastructure/auth.js';
import { ECareConfigurationTypes } from 'constants/e-care-manager-types.js';
import EcareManagerAddEditBaseUrl from 'containers/Configurations/EcareManagerAddEditBaseUrl.jsx';
import HillRoomAddEdit from 'containers/Integrations/HillRoomAddEdit.jsx';
import EcareApiKey from 'containers/Configurations/EcareApiKey.jsx';
import EcareManagerEpicCerner from 'containers/Configurations/EcareManagerEpicCerner.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { configurationTypeIds, groupTypes } from 'constants/integrationEnums.js';
import PatientInfotainment from 'containers/Integrations/PatientInfotainment.jsx';
import { object, string, ValidationError } from 'yup';

const EpicAddEdit = props => {
	const intl = useIntl();
	const [selectedConfig, setSelectedConfig] = useState({ value: null, label: null, groupId: null });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [fieldErrors, setFieldErrors] = useState(null);
	const [configParams, setConfigParams] = useState({
		nodeId: null,
		parentNodeId: null,
		nodeName: null,
		nodeLevel: null,
		configJson: '',
		configurationTypeId: null,
		validations: {},
	});
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const shouldShowConfigType = settingTypeId =>
		getConfigurationValue(companyConfigurations[settingTypeId]) || props.itemToEdit?.configurationTypeId;

	const showApiKey = () => {
		if (props.itemToEdit) {
			return true;
		}
		if (!props.hasApiConfig) {
			return true;
		}
		return false;
	};

	const configTypes = [
		showApiKey() && {
			value: configurationTypeIds.ECARE_API_KEY,
			label: intl.formatMessage({ id: 'eCareBaseUrl' }),
			groupId: groupTypes.ECM.id,
		},
		{
			value: configurationTypeIds.ECARE_BASE_URL,
			label: intl.formatMessage({ id: 'eCareBaseUrl' }),
			groupId: groupTypes.ECM.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.EPIC) && {
			value: configurationTypeIds.EPIC,
			label: 'Epic',
			groupId: groupTypes.SMART_FHIR.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.CERNER) && {
			value: configurationTypeIds.CERNER,
			label: 'Cerner',
			groupId: groupTypes.SMART_FHIR.id,
		},
		shouldShowConfigType(IntegrationTypesSettings.HILL_ROM) && {
			value: configurationTypeIds.HILL_ROM,
			label: 'Hill Room',
			groupId: groupTypes.NURSE_CALLING.id,
		},
		{
			value: configurationTypeIds.MOVIES,
			label: intl.formatMessage({ id: 'movies' }),
			groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		},
		{
			value: configurationTypeIds.LIVE_TV_CHANNELS,
			label: intl.formatMessage({ id: 'liveTvChannels' }),
			groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		},
		{
			value: configurationTypeIds.MUSIC,
			label: intl.formatMessage({ id: 'music' }),
			groupId: groupTypes.PATIENT_INFOTAINMENT.id,
		},
	].filter(item => Boolean(item) && item.groupId === props.groupId);

	useEffect(() => {
		const fetchConfigTypes = async () => {
			if (props.itemToEdit) {
				const foundItem = configTypes.find(item => item.value === props.itemToEdit.configurationTypeId);
				setSelectedConfig(foundItem);
			}
		};
		fetchConfigTypes();
	}, []);

	const isConfigValid = () => {
		let result;
		switch (configParams.configurationTypeId) {
			case ECareConfigurationTypes.ELERT_BASE_URL: {
				result = JSON.parse(configParams.configJson).baseUrl !== '';
				break;
			}
			default: {
				result = true;
			}
		}
		return result;
	};

	const handleYupErrors = error => {
		if (error instanceof ValidationError) {
			const fieldErrors = error.inner.reduce((acc, err) => {
				if (err.path) {
					acc[err.path] = err.message;
				}
				return acc;
			}, {});
			setFieldErrors(fieldErrors);
		}
		return { error: true };
	};

	const validate = async values => {
		let userSchema = {};

		if (selectedConfig?.value === ECareConfigurationTypes.REID) {
			userSchema = object({
				partnerName: string().required(intl.formatMessage({ id: 'partnerNameCannotBeEmpty' })),
			});
		}

		if (Object.keys(userSchema).length > 0) {
			try {
				const isValid = await userSchema?.validate(values, { abortEarly: false });
				return { error: !isValid };
			} catch (error) {
				return handleYupErrors(error);
			}
		}

		return { error: false };
	};

	const onSubmit = async () => {
		setFieldErrors(null);
		if (!isConfigValid() || !configParams.configurationTypeId) {
			return;
		}

		const parsedConfigJson = JSON.parse(configParams.configJson);
		const { error } = await validate(parsedConfigJson);
		if (error) {
			return;
		}

		setIsLoading(true);
		const paramsToSend = {
			tenantId: getCompanyId(),
			configurationTypeId: configParams.configurationTypeId,
			nodeId: configParams.nodeId,
			parentNodeId: configParams.parentNodeId,
			nodeName: configParams.nodeName,
			nodeLevel: configParams.nodeLevel,
			configJson: configParams.configJson,
			userId: getUserId(),
		};
		let response;
		if (props.itemToEdit) {
			paramsToSend.id = props.itemToEdit.id;
			response = await updateConfig(paramsToSend);
		} else {
			response = await addConfig(paramsToSend);
		}
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setIsLoading(false);
		props.setIsRefetch({ id: response.result.id });
		props.setIsAddOpen(false);
	};

	const isSubmitDisabled = () => configParams.validations && Object.keys(configParams.validations).length > 0;

	return (
		<Modal
			modalSelector='baseUrlKeyModal'
			position='right'
			onModalSubmit={onSubmit}
			onModalClose={() => props.setIsAddOpen(prevState => !prevState)}
			shouldSubmitOnEnter={false}
			display={true}
			isSubmitDisabled={isSubmitDisabled()}
			isLoading={isLoading}>
			<form>
				<h3>{translate('addConfiguration')}</h3>
				<div className='input'>
					<p className='label'>{translate('selectConfiguration')}</p>
					<p className='font-14'>{translate('selectConfigurationDesc')}</p>
					<Select
						isDisabled={props.itemToEdit}
						value={selectedConfig}
						placeholder={intl.formatMessage({ id: 'selectConfiguration' })}
						classNamePrefix='react-select'
						options={configTypes}
						onChange={setSelectedConfig}
					/>
					{!configParams.configurationTypeId && <small>{translate('configurationTypeRequired')}</small>}
					<br />
					<br />
					{selectedConfig?.value === configurationTypeIds.ECARE_API_KEY && (
						<EcareApiKey itemToEdit={props.itemToEdit} setConfigParams={setConfigParams} />
					)}
					{selectedConfig?.value === configurationTypeIds.ECARE_BASE_URL && (
						<EcareManagerAddEditBaseUrl itemToEdit={props.itemToEdit} setConfigParams={setConfigParams} />
					)}
					{selectedConfig?.groupId === groupTypes.SMART_FHIR.id && (
						<EcareManagerEpicCerner itemToEdit={props.itemToEdit} setConfigParams={setConfigParams} type={selectedConfig.value} />
					)}
					{selectedConfig?.groupId === groupTypes.NURSE_CALLING.id && (
						<HillRoomAddEdit itemToEdit={props.itemToEdit} setConfigParams={setConfigParams} fieldErrors={fieldErrors} />
					)}
					{selectedConfig?.groupId === ECareConfigurationTypes.PATIENT_INFOTAINMENT && (
						<PatientInfotainment
							itemToEdit={props.itemToEdit}
							setConfigParams={setConfigParams}
							configurationTypeId={selectedConfig.value}
						/>
					)}
				</div>
			</form>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default EpicAddEdit;
