import * as React from 'react';

function Mic(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox="0 0 24 24" fill='none' {...props}>
			<path d='M9 4.8a3 3 0 013-3v0a3 3 0 013 3V12a3 3 0 01-3 3v0a3 3 0 01-3-3V4.8z' stroke='currentColor' strokeWidth={1.5} />
			<path
				d='M12 21.6V18m0 0v0a6 6 0 006-6v0-1.2M12 18v0a6 6 0 01-6-6v0-1.2M9.148 22.05h5.7'
				stroke='currentColor'
				strokeWidth={1.5}
				strokeLinecap='round'
			/>
		</svg>
	);
}

export default Mic;
