import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const Collapse = props => (
	<svg
		width={30}
		height={31}
		viewBox='0 0 30 31'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		color={LightTheme.colors.graySeven}
		{...props}>
		<g filter='url(#filter0_d_218_32)'>
			<path d='M11 16L5 22' stroke='currentColor' strokeWidth='1.5' strokeMiterlimit='10' />
			<path d='M25 2L19 8' stroke='currentColor' strokeWidth='1.5' strokeMiterlimit='10' />
			<path d='M11 22V16H5' stroke='currentColor' strokeWidth='1.5' strokeLinejoin='round' />
			<path d='M19 2V8H25' stroke='currentColor' strokeWidth='1.5' strokeLinejoin='round' />
			<path
				d='M13.9999 2.99976H7.99988C7.46944 2.99976 6.96089 3.2105 6.58582 3.58557C6.21074 3.96064 5.99988 4.46932 5.99988 4.99976V10.9998'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeMiterlimit='10'
			/>
			<path
				d='M15.9999 21.9998H21.9999C22.5303 21.9998 23.0391 21.789 23.4142 21.414C23.7893 21.0389 23.9999 20.5302 23.9999 19.9998V13.9998'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeMiterlimit='10'
			/>
		</g>
	</svg>
);

export default Collapse;
