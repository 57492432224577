const GettingOutOfBed = props => (
	<svg width='31' height='30' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M20.665 24.375H8.66504V26.25H5.66504V19.375H6.16504H18.79'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M6.16504 19.375L8.04004 11.875V3.75H24.29V6.625'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.91504 16.1249H8.16504H8.91504C9.91504 16.1249 10.79 15.9999 11.665 15.6249L12.04 15.4999C12.915 15.2499 13.915 14.9999 14.79 14.9999H17.04C17.665 14.9999 18.1651 14.8749 18.7901 14.9999'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M19.415 12.25H8.04004' stroke='#E0AF4C' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
		<path
			d='M20.6604 14.646V19.8935C20.6608 20.0137 20.638 20.1328 20.5933 20.244C20.5487 20.3552 20.4831 20.4562 20.4002 20.5413C20.3174 20.6264 20.219 20.6939 20.1107 20.74C20.0023 20.786 19.8862 20.8097 19.7689 20.8096C19.6517 20.8097 19.5356 20.786 19.4272 20.74C19.3189 20.6939 19.2205 20.6264 19.1377 20.5413C19.0548 20.4562 18.9892 20.3552 18.9446 20.244C18.8999 20.1328 18.8771 20.0137 18.8774 19.8935V14.17C18.8774 13.5425 19.1205 12.9408 19.5532 12.4971C19.986 12.0534 20.5728 11.8042 21.1848 11.8042H23.2029H25.1879C25.7999 11.8042 26.3867 12.0534 26.8195 12.4971C27.2522 12.9408 27.4953 13.5425 27.4953 14.17V19.8935C27.4953 20.0135 27.4723 20.1324 27.4276 20.2434C27.3828 20.3543 27.3171 20.4551 27.2343 20.54C27.1515 20.6249 27.0532 20.6922 26.945 20.7381C26.8368 20.784 26.7209 20.8076 26.6038 20.8075C26.4867 20.8076 26.3707 20.784 26.2625 20.7381C26.1543 20.6922 26.056 20.6249 25.9732 20.54C25.8904 20.4551 25.8247 20.3543 25.7799 20.2434C25.7352 20.1324 25.7122 20.0135 25.7123 19.8935V14.646'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeMiterlimit='10'
		/>
		<path d='M20.6604 19.8955V27.5' stroke='#E0AF4C' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
		<path d='M25.7122 19.8955V27.5' stroke='#E0AF4C' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
		<path d='M23.1328 19.8955V27.5' stroke='#E0AF4C' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
		<path
			d='M23.1303 10.5374C24.285 10.5374 25.221 9.57761 25.221 8.39368C25.221 7.20976 24.285 6.25 23.1303 6.25C21.9756 6.25 21.0396 7.20976 21.0396 8.39368C21.0396 9.57761 21.9756 10.5374 23.1303 10.5374Z'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
		/>
	</svg>
);
export default GettingOutOfBed;
