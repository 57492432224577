import { APP_CONFIG } from 'constants/global-variables.js';
import { isDocumentAttachment, isPictureAttachment, isVideoAttachment } from 'infrastructure/helpers/commonHelpers.js';

export function getMediaCdnURL(fileName, fileType) {
	if (isDocumentAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}files/${fileName}`;
	}
	if (isPictureAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}images/${fileName}`;
	}
	if (isVideoAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}videos/${fileName}`;
	}
	throw new Error('Invalid file type');
}

export const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
	const targetWidth = mediaHeight * aspect;
	if (targetWidth <= mediaWidth) {
		const width = targetWidth;
		const x = (mediaWidth - targetWidth) / 2;
		return {
			unit: 'px',
			x,
			y: 0,
			width,
			height: mediaHeight,
			aspect,
		};
	} else {
		const height = mediaWidth / aspect;
		const y = (mediaHeight - height) / 2;
		return {
			unit: 'px',
			x: 0,
			y,
			width: mediaWidth,
			height,
			aspect,
		};
	}
};

export const getCroppedImg = (image, cropObj, fileName) => {
	let fileUrl;
	const canvas = document.createElement('canvas');
	const scale = image.naturalWidth > 580 ? image.naturalWidth / 580 : 1;
	canvas.width = cropObj.width * scale;
	canvas.height = cropObj.height * scale;
	const ctx = canvas.getContext('2d');
	ctx.drawImage(
		image,
		cropObj.x * scale,
		cropObj.y * scale,
		cropObj.width * scale,
		cropObj.height * scale,
		0,
		0,
		cropObj.width * scale,
		cropObj.height * scale
	);
	return new Promise((resolve, reject) => {
		canvas.toBlob(blob => {
			if (!blob) {
				return;
			}
			blob.name = fileName;
			window.URL.revokeObjectURL(fileUrl);
			fileUrl = window.URL.createObjectURL(blob);
			const reader = new FileReader();
			reader.onerror = reject;
			reader.onloadend = () => resolve(fileUrl);
			reader.readAsDataURL(blob);
		}, 'image/png');
	});
};
