import { getPressureInjuryHistory } from 'api/patients.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import EmptyState from 'components/EmptyState.jsx';
import Loader from 'components/Loader.jsx';
import { PatientPositionTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import LeftLateralIcon from 'icons/Monitoring/LeftLateral.jsx';
import ProneIcon from 'icons/Monitoring/Prone.jsx';
import RightLateralIcon from 'icons/Monitoring/RightLateral.jsx';
import SupineIcon from 'icons/Monitoring/Supine.jsx';
import UpInAChairIcon from 'icons/Monitoring/UpInAChair.jsx';
import { getAnalyticsTypeById } from 'infrastructure/helpers/aiHelper.js';
import { getDateWithSeconds } from 'infrastructure/helpers/dateHelper.js';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const StyledPatientPositionHistory = styled.div`
	display: flex;
	flex-direction: column;
	padding: var(--spacing-l);

	${props =>
		props.$scroll &&
		css`
			overflow: auto;
			max-height: 250px;
		`}

	${props =>
		(props.$isLoading || props.$hasError) &&
		css`
			align-items: center;
			padding: var(--spacing-xl);
		`}

	.history-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: var(--spacing-m) 0;
	}

	.history-info {
		display: flex;
		align-items: center;

		svg {
			margin-right: 14px;
			width: 32px !important;
			height: 32px !important;
		}

		& > div {
			& > p:first-of-type {
				font-weight: 400;
				color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
				font-size: 14px;
				line-height: 16px;
			}

			& > p:last-of-type {
				color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
				font-size: 12px;
				font-weight: 500;
				line-height: 14px;
				margin-top: 3px;
				opacity: 0.5;
			}
		}
	}

	.history-icon {
		padding: 5px;
		background: #5390ff1a;
		display: flex;
		align-items: center;
		border-radius: 3px;
		margin-left: var(--spacing-m);
	}

	.error-message {
		color: var(--red-4) !important;
	}
`;

const PatientPositionHistory = ({ patientId, isDarkMode }) => {
	const [history, setHistory] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const user = useSelector(state => state.user);
	const intl = useIntl();

	const hasReachedEnd = useRef(null);

	const getPatientPositionIcon = position => {
		switch (position) {
			case PatientPositionTypes.SUPINE:
				return (
					<SupineIcon color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)} />
				);
			case PatientPositionTypes.PRONE:
				return (
					<ProneIcon color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)} />
				);
			case PatientPositionTypes.LEFT_LATERAL:
				return (
					<LeftLateralIcon
						color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
					/>
				);
			case PatientPositionTypes.RIGHT_LATERAL:
				return (
					<RightLateralIcon
						color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
					/>
				);
			default:
				return (
					<UpInAChairIcon
						color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
					/>
				);
		}
	};

	const getPressureInjuryDuration = (startTime, endTime) => {
		const diffMilliseconds = moment(endTime).diff(moment(startTime));
		const diffDuration = moment.duration(diffMilliseconds);

		const days = diffDuration.days();
		const hours = diffDuration.hours();
		const minutes = diffDuration.minutes();
		const seconds = diffDuration.seconds();
		if (days === 0 && hours === 0 && minutes === 0) {
			return `${seconds} ${intl.formatMessage({ id: 'seconds' })}`;
		}
		if (days === 0 && hours === 0 && minutes !== 0) {
			return `${minutes}m ${seconds}s`;
		}
		if (days === 0 && hours !== 0 && minutes !== 0) {
			return `${hours}h ${minutes}m ${seconds}s`;
		}
		return `${days}d ${hours}h`;
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
		}
	};

	useEffect(() => {
		const getPatientPositionHistory = async () => {
			setError(null);
			setIsLoading(true);
			const params = {
				patientId,
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
			};
			const response = await getPressureInjuryHistory(params);
			if (response.error) {
				setError(translate('somethingWentWrong'));
				setIsLoading(false);
				return;
			}
			if (pagination.pageIndex === 0) {
				setHistory(response.pressureInjuryHistory);
			} else {
				setHistory(prevState => [...prevState, ...(response.pressureInjuryHistory || [])]);
			}
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
			hasReachedEnd.current = response?.pressureInjuryHistory?.length < 10;
			setIsLoading(false);
		};

		getPatientPositionHistory();
	}, [pagination.pageIndex, pagination.pageSize, patientId]);

	return (
		<StyledPatientPositionHistory
			$isLoading={isLoading}
			$hasError={error}
			$scroll={history.length > 0}
			$isDarkMode={isDarkMode}
			onScroll={handleScroll}>
			{error && <p className='error-message'>{error}</p>}
			{isLoading && <Loader />}
			{!history.length && !isLoading && !error && <EmptyState title={translate('noHistoryFound')} image='no-files.svg' />}
			{history.map(data => (
				<div className='history-container' key={data.detection}>
					<div className='history-info'>
						{getPatientPositionIcon(data.analyticTypeId)}
						<div>
							<p>
								{translate('pressureInjuryHistoryDescription', {
									value1: getPressureInjuryDuration(moment(data.startTime), moment(data.endTime)),
									value2: translate(getAnalyticsTypeById(data.analyticTypeId)),
								})}
							</p>
							<p>
								{translate('pressureInjuryHistoryTime', {
									value1: getDateWithSeconds(data.startTime),
									value2: getDateWithSeconds(data.endTime),
								})}
							</p>
						</div>
					</div>
				</div>
			))}
		</StyledPatientPositionHistory>
	);
};

export default PatientPositionHistory;
