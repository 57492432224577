import React, { useContext, useEffect, useRef, useState } from 'react';
import Pagination from 'components/Common/Pagination.jsx';
import { Alert, CustomTable, Input } from 'components/index.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useIntl } from 'react-intl';
import FleetManagementDeviceActions from 'components/FleetManagement/FleetManagementDeviceActions.jsx';
import FleetManagementDeviceStatus from 'components/FleetManagement/FleetManagementDeviceStatus.jsx';
import { isValidJSON } from 'infrastructure/helpers/commonHelpers.js';
import { DeviceConnectionStatus } from 'constants/enums.js';
import { getMultipleLevelFleetDevices } from 'api/fleetManagement.js';
import { useSelector } from 'react-redux';
import SocketEvents from 'constants/socket-events.js';

const ptzDevice = 'PTZ Device';
const getMacAddress = macAddress => (macAddress ? macAddress.trim().replace(' ', ' | ') : 'N/A');

const getOnlineDevices = devices => {
	return devices.reduce((accumulator, currentValue) => {
		if (currentValue.isOnline) {
			accumulator.push(currentValue.solHelloDeviceId);
		}
		currentValue.connectedDevices.forEach(companion => {
			if (companion.isOnline && companion.deviceModel !== ptzDevice) {
				accumulator.push(companion.solHelloDeviceId);
			}
		});
		return accumulator;
	}, []);
};

const FleetManagementDevices = ({ setFilterValues, filterValues }) => {
	const intl = useIntl();
	const socket = useContext(SocketContext);
	const userSession = useSelector(state => state.user.userSession);
	const delayTimerRef = useRef(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 1 });
	const [devices, setDevices] = useState([]);
	const [onlineDevices, setOnlineDevices] = useState([]);

	useEffect(() => {
		const onDeviceOffline = data => setOnlineDevices(previousState => [...previousState.filter(id => id !== data.helloDeviceId)]);
		const onDeviceOnline = data => setOnlineDevices(previousState => [...previousState, data.helloDeviceId]);

		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		};
	}, [socket]);

	useEffect(() => {
		setIsLoading(true);
		const lastLevelIds = filterValues[filterValues.lastLevel.name].map(item =>
			isValidJSON(item.value) ? JSON.parse(item.value).id : item.value
		);

		const fetchDevices = async () => {
			const params = {
				...filterValues,
				currentPage: pagination.pageIndex,
				pageSize: pagination.pageSize,
				levelType: filterValues.lastLevel.id,
				levelIds: lastLevelIds,
				...(filterValues.status && { isOnline: filterValues.status === DeviceConnectionStatus.ONLINE }),
				connectionType: filterValues.connection,
			};

			const response = await getMultipleLevelFleetDevices(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				const initialOnlineDevices = getOnlineDevices(response.data);
				setTotalCount(response.totalRows);
				setDevices(response.data);
				setOnlineDevices(initialOnlineDevices);
			}
			setIsLoading(false);
		};

		fetchDevices();
		return () => {
			clearTimeout(delayTimerRef.current);
		};
	}, [filterValues, pagination, userSession]);

	const devicesHeader = [
		{ title: intl.formatMessage({ id: 'room' }), id: 'roomName' },
		{ title: intl.formatMessage({ id: 'deviceType' }), id: 'deviceType' },
		{ title: intl.formatMessage({ id: 'deviceModel' }), id: 'deviceModel' },
		{ title: intl.formatMessage({ id: 'serialNumber' }), id: 'serialNumber', columnClass: 'break-word' },
		{ title: `${intl.formatMessage({ id: 'device' })} ${intl.formatMessage({ id: 'status' })}`, id: 'isOnline' },
		{ title: intl.formatMessage({ id: 'macUpperAddress' }), id: 'macAddress', columnClass: 'break-word' },
		{ title: intl.formatMessage({ id: 'connectionType' }), id: 'connectionType' },
		{ title: intl.formatMessage({ id: 'network' }), id: 'network' },
		{ title: intl.formatMessage({ id: 'osVersion' }), id: 'firmwareRevision', columnClass: 'break-word' },
		{ title: intl.formatMessage({ id: 'appVersion' }), id: 'appVersion', columnClass: 'break-word' },
		{ title: `${intl.formatMessage({ id: 'connected' })} ${intl.formatMessage({ id: 'devices' })}`, id: 'connectedDevices' },
		{ title: intl.formatMessage({ id: 'actions' }), id: 'actions' },
	];

	const getConnectedDevices = children => {
		if (children.length === 0) {
			return [];
		}

		return children.map(child => {
			return {
				...child,
				id: child.deviceModel,
				serialNumber: child.serialNumber ?? 'N/A',
				isOnline: (
					<FleetManagementDeviceStatus
						onlineDevices={onlineDevices}
						lastOnline={child.lastOnline}
						solHelloDeviceId={child.solHelloDeviceId}
					/>
				),
				macAddress: getMacAddress(child.macAddress),
				connectionType: (
					<>
						{child.isWiFiConnected && <span>WiFi </span>}
						{child.isEthernetConnected && <span>Ethernet</span>}
						{!child.isWiFiConnected && !child.isEthernetConnected && 'N/A'}
					</>
				),
				network: (
					<>
						{child.ssId && <span>SSID {child.ssId} </span>}
						{child.ipAddress && <span>IP {child.ipAddress}</span>}
						{!child.ssId && !child.ipAddress && 'N/A'}
					</>
				),
				firmwareRevision: child.firmwareRevision ?? child.osVersion ?? 'N/A',
				appVersion: child.appVersion ?? 'N/A',
				connectedDevices: child.deviceModel !== ptzDevice && '',
				actions: child.deviceModel !== ptzDevice && (
					<FleetManagementDeviceActions device={child} onlineDevices={onlineDevices} />
				),
			};
		});
	};

	const devicesRows = () => {
		if (devices.length === 0) {
			return [];
		}

		return devices.map(device => {
			const isAnyChildOffline = device.connectedDevices.some(device => !onlineDevices.includes(device.solHelloDeviceId));

			return {
				children: getConnectedDevices(device.connectedDevices),
				id: device.solHelloDeviceId,
				roomName: device.roomName ?? 'N/A',
				deviceType: device.deviceType,
				deviceModel: device.deviceModel,
				serialNumber: device.serialNumber,
				isOnline: (
					<FleetManagementDeviceStatus
						onlineDevices={onlineDevices}
						lastOnline={device.lastOnline}
						solHelloDeviceId={device.solHelloDeviceId}
					/>
				),
				macAddress: getMacAddress(device.macAddress),
				connectionType: (
					<>
						{device.isWiFiConnected && <span>WiFi </span>}
						{device.isEthernetConnected && <span>Ethernet</span>}
						{!device.isWiFiConnected && !device.isEthernetConnected && 'N/A'}
					</>
				),
				network: (
					<>
						{device.ssId && <span>SSID: {device.ssId} </span>}
						{device.ipAddress && <span>IP: {device.ipAddress}</span>}
						{!device.ssId && !device.ipAddress && 'N/A'}
					</>
				),
				firmwareRevision: device.firmwareRevision ?? 'N/A',
				appVersion: device.appVersion ?? 'N/A',
				connectedDevices: (
					<div className='connected-devices'>
						<span>{device.connectedDevices.length}</span>
						{isAnyChildOffline && (
							<span
								data-tooltip={intl.formatMessage({ id: 'deviceOfflineStatus' })}
								data-position='top'
								className='material-symbols-outlined icon'>
								warning
							</span>
						)}
					</div>
				),
				actions: <FleetManagementDeviceActions device={device} onlineDevices={onlineDevices} isHelloDevice />,
			};
		});
	};

	const handleSearch = e => {
		setIsLoading(true);
		clearTimeout(delayTimerRef.current);
		const timer = setTimeout(() => {
			setFilterValues(prevState => ({ ...prevState, search: e.target.value }));
		}, 500);

		delayTimerRef.current = timer;
	};
	return (
		<div className='fleet-component table-devices'>
			<div>
				<div className='table-devices-header'>
					<label>{intl.formatMessage({ id: 'devices' })}</label>
					<Input
						type='text'
						prefixIcon='search'
						variant='filled'
						placeholder={intl.formatMessage({ id: 'searchByRoomOrSN' })}
						onChange={handleSearch}
					/>
				</div>
				<CustomTable
					headers={devicesHeader}
					rows={!isLoading ? devicesRows() : []}
					isLoading={isLoading}
					stickyHeader={true}
					isNested={true}
				/>
				<Pagination
					totalCount={totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex - 1}
					onChange={(pageSize, pageIndex) => {
						setIsLoading(true);
						setPagination({ pageSize, pageIndex: pageIndex + 1 });
					}}
				/>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default FleetManagementDevices;
