import { MeasurementTypes, MeasurementUnits } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const imageUrl = `${healthCareCdnUrl}rpm/`;
const requestsImgUrl = `${healthCareCdnUrl}requests/`;

export const RpmDevices = {
	I_HEALTH_BLOOD_PRESSURE_CUFF: 6,
	I_HEALTH_GLUCOMETER: 7,
	I_HEALTH_PULSE_OXIMETER: 8,
	I_HEALTH_SCALE: 9,
	I_HEALTH_THERMOMETER: 10,
	LIFE_SIGNALS_PATCH: 11,
	MINTTI_STETHOSCOPE: 12,
};

export const rpmMeasurements = [
	{
		id: 1,
		type: MeasurementTypes.WEIGHT,
		name: MeasurementTypes.WEIGHT,
		img: `${imageUrl}selected-measurements/scale.svg`,
		activeImg: `${imageUrl}selected-measurements/scale-1.svg`,
		measurementImg: `${imageUrl}measurements/scale.svg`,
		devices: [{ id: RpmDevices.I_HEALTH_SCALE, value: 'iHealth Scale' }],
	},
	{
		id: 2,
		type: MeasurementTypes.BLOOD_PRESSURE,
		name: MeasurementTypes.BLOOD_PRESSURE,
		img: `${imageUrl}selected-measurements/blood-pressure.svg`,
		activeImg: `${imageUrl}selected-measurements/blood-pressure-1.svg`,
		measurementImg: `${imageUrl}measurements/blood-pressure.svg`,
		devices: [
			{ id: RpmDevices.I_HEALTH_BLOOD_PRESSURE_CUFF, value: 'iHealth Blood Pressure cuff' },
			{ id: RpmDevices.LIFE_SIGNALS_PATCH, value: 'LifeSignals Patch' },
		],
	},
	{
		id: 3,
		type: MeasurementTypes.DIABETES,
		name: MeasurementTypes.BLOOD_GLUCOSE,
		img: `${imageUrl}selected-measurements/blood-glucose.svg`,
		activeImg: `${imageUrl}selected-measurements/blood-glucose-1.svg`,
		measurementImg: `${imageUrl}measurements/blood-glucose.svg`,
		devices: [{ id: RpmDevices.I_HEALTH_GLUCOMETER, value: 'iHealth Glucometer' }],
	},
	{
		id: 4,
		type: MeasurementTypes.STETHOSCOPE,
		name: MeasurementTypes.HEART_LUNGS_AUSCULTATION,
		img: `${imageUrl}selected-measurements/respiratory.svg`,
		activeImg: `${imageUrl}selected-measurements/respiratory-1.svg`,
		measurementImg: `${imageUrl}measurements/respiratory.svg`,
		devices: [{ id: RpmDevices.MINTTI_STETHOSCOPE, value: 'Mintti Stethcosope' }],
	},
	{
		id: 5,
		type: MeasurementTypes.HEART_RATE,
		name: MeasurementTypes.HEART_RATE,
		img: `${imageUrl}selected-measurements/heart-rate.svg`,
		activeImg: `${imageUrl}selected-measurements/heart-rate-1.svg`,
		measurementImg: `${imageUrl}measurements/heart-rate.svg`,
		devices: [
			{ id: RpmDevices.I_HEALTH_PULSE_OXIMETER, value: 'iHealth Pulse Oximeter' },
			{ id: RpmDevices.LIFE_SIGNALS_PATCH, value: 'LifeSignals Patch' },
		],
	},
	{
		id: 6,
		type: MeasurementTypes.OXYGEN,
		name: MeasurementTypes.OXYGEN_SATURATION,
		img: `${imageUrl}selected-measurements/oxygen-saturation.svg`,
		activeImg: `${imageUrl}selected-measurements/oxygen-saturation-1.svg`,
		measurementImg: `${imageUrl}measurements/oxygen-saturation.svg`,
		devices: [
			{ id: RpmDevices.I_HEALTH_PULSE_OXIMETER, value: 'iHealth Pulse Oximeter' },
			{ id: RpmDevices.LIFE_SIGNALS_PATCH, value: 'LifeSignals Patch' },
		],
	},
	{
		id: 7,
		type: MeasurementTypes.TEMPERATURE,
		name: MeasurementTypes.TEMPERATURE,
		img: `${imageUrl}selected-measurements/thermometer.svg`,
		activeImg: `${imageUrl}selected-measurements/thermometer-1.svg`,
		measurementImg: `${imageUrl}measurements/thermometer.svg`,
		devices: [
			{ id: RpmDevices.I_HEALTH_THERMOMETER, value: 'iHealth Thermometer' },
			{ id: RpmDevices.LIFE_SIGNALS_PATCH, value: 'LifeSignals Patch' },
		],
	},
];

export const vsmImageUrl = `${healthCareCdnUrl}vsm/`;

export const VitalSignsList = [
	{
		id: 0,
		type: MeasurementTypes.BLOOD_PRESSURE,
		icon: `${vsmImageUrl}blood-pressure.svg`,
	},
	{
		id: 1,
		type: MeasurementTypes.HEART_RATE,
		icon: `${vsmImageUrl}heart-rate.svg`,
	},
	{
		id: 2,
		type: MeasurementTypes.OXYGEN,
		icon: `${vsmImageUrl}oxygen-saturation.svg`,
	},
	{
		id: 3,
		type: MeasurementTypes.DIABETES,
		icon: `${vsmImageUrl}blood-glucose.svg?v2`,
	},
	{
		id: 4,
		type: MeasurementTypes.TEMPERATURE,
		icon: `${vsmImageUrl}thermometer.svg`,
	},
	{
		id: 5,
		type: MeasurementTypes.WEIGHT,
		icon: `${vsmImageUrl}scale.svg`,
	},
];

export const RpmTableHeaders = [
	'ewsScore',
	'bloodPressure',
	'heartRate',
	'oxygenSaturation',
	'bloodGlucose',
	'bodyTemperature',
	'weight',
	'actions',
];

export const AnswerType = {
	SINGLE_CHOICE: 1,
	MULTIPLE_CHOICE: 2,
	SHORT_ANSWER: 3,
	PARAGRAPH: 4,
	CUSTOM_MULTIPLE_CHOICE: 5,
	CUSTOM_SINGLE_CHOICE: 6,
};

export const SurveyType = {
	GENERIC: 1,
	SAFETY_RANGE: 2,
	SCHEDULED: 3,
};

export const ScheduledSurveyType = {
	DAILY: 1,
	WEEKLY: 2,
	MONTHLY: 3,
};

export const RpmTabsList = [
	{
		id: 0,
		title: translate('rpmProgram'),
		mainImg: `${requestsImgUrl}1-gray.svg`,
		activeImg: `${requestsImgUrl}1-blue.svg`,
		pastImg: `${requestsImgUrl}1-blue.svg`,
	},
	{
		id: 1,
		title: translate('survey'),
		mainImg: `${requestsImgUrl}2-gray.svg`,
		activeImg: `${requestsImgUrl}2-blue.svg`,
		pastImg: `${requestsImgUrl}2-blue.svg`,
	},
	{
		id: 2,
		title: translate('careTeam'),
		mainImg: `${requestsImgUrl}3-gray.svg`,
		activeImg: `${requestsImgUrl}3-blue.svg`,
		pastImg: `${requestsImgUrl}3-blue.svg`,
	},
];

export const MedicationStatus = {
	SKIPPED: 'skipped',
	TAKEN: 'taken',
};

export const PatientMonitoringTab = {
	REMOTE: 0,
	CONTINUOUS: 1,
};

export const CpmTableHeaders = ['patient', 'respiration', 'oxygen', 'heartRate', 'bloodPressure', 'bodyTemperature', 'actions'];

export const PatientMonitoringTabs = ['remotePatientMonitoring', 'continuousPatientMonitoring'];

export const NoMeasurementsValueText = 'N/A';

export const LifeSignalsMeasurements = [
	{
		id: 1,
		name: 'respiration',
		value: 'RR',
		unit: MeasurementUnits.BREATHS_PER_MIN,
		img: 'respiratory',
		type: MeasurementTypes.RESPIRATORY_RATE,
	},
	{
		id: 2,
		name: 'oxygen',
		value: 'SPO2',
		unit: MeasurementUnits.PERCENTAGE,
		img: 'oxygen-saturation',
		type: MeasurementTypes.OXYGEN,
		shortName: 'SPO2',
	},
	{
		id: 3,
		name: 'heartRate',
		value: 'HR',
		unit: MeasurementUnits.BPM,
		img: 'heart-rate',
		type: MeasurementTypes.HEART_RATE,
		shortName: 'HR',
	},

	{
		id: 4,
		name: 'bloodPressure',
		value: 'BPSYS',
		secondValue: 'BPDIA',
		unit: MeasurementUnits.MMHG,
		img: 'blood-pressure',
		type: MeasurementTypes.BLOOD_PRESSURE,
		shortName: 'BP',
	},
	{
		id: 5,
		name: 'bodyTemperature',
		value: 'BODYTEMP',
		unit: MeasurementUnits.CELSIUS,
		img: 'thermometer',
		type: MeasurementTypes.TEMPERATURE,
	},
];

const LifeSignalsAlertTypes = {
	LOW: 'LOW',
	MEDIUM: 'MEDIUM',
	HIGH: 'HIGH',
};

export const getAlertClassName = alert => {
	const alertType = alert[2];
	switch (alertType) {
		case LifeSignalsAlertTypes.LOW:
			return 'low-alert';
		case LifeSignalsAlertTypes.MEDIUM:
			return 'medium-alert';
		case LifeSignalsAlertTypes.HIGH:
			return 'high-alert';
		default:
			return '';
	}
};

export const patchDeviceDetails = {
	id: 6,
	type: 'patch',
	name: 'lifesignalsPatch',
	measurementImg: `${imageUrl}measurements/lifesignals-patch.svg`,
	devices: [{ id: RpmDevices.LIFE_SIGNALS_PATCH, value: 'LifeSignals Patch' }],
};
