import React from 'react';

const AlertInformation = ({ alertInfo, onAlertClose, icon }) => (
	<div className='monitoring-alert-info-wrapper'>
		<div>{icon}</div>
		<p>{alertInfo?.text}</p>
		<span className='timeline-close alert-info-btn cursor-pointer' onClick={() => onAlertClose(alertInfo?.deviceId)}>
			<i className='material-icons'>close</i>
		</span>
	</div>
);

export default AlertInformation;
