import translate from 'i18n-translations/translate.jsx';
import React from 'react';
import Select from 'react-select';
import { Button } from 'components/index.js';

const TVConfigFilters = props => {
	return (
		<>
			<Select
				value={props.selectedHospitalId ? props.hospitals.find(x => x.value === props.selectedHospitalId) : null}
				isDisabled={props.isHospitalDropdownDisabled}
				classNamePrefix='react-select'
				options={props.hospitals}
				onChange={props.setSelectedHospital}
			/>
			{props.showDepartmentsFilter && (
				<Select
					value={props.selectedDepartmentId ? props.departments?.find(x => x.value === props.selectedDepartmentId) : null}
					isDisabled={props.isDepartmentDropdownDisabled}
					classNamePrefix='react-select'
					options={props.departments}
					onChange={props.setSelectedDepartment}
				/>
			)}
			<Button text={translate('addTVConfiguration')} onClick={props.toggleConfigModal} />
		</>
	);
};

export default TVConfigFilters;
