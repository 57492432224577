import { getMonitoringKPIs } from 'api/dashboard.js';
import { Grid, Loader } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import Patients from 'icons/Dashboard/Patients.jsx';
import Rooms from 'icons/Dashboard/Rooms.jsx';
import Sessions from 'icons/Dashboard/Sessions.jsx';
import { formatTimeDurationWithSeconds, timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ChartWrapper = styled.div`
	border: 1px solid var(--gray-15);
	background: var(--gray-0);
	padding: var(--spacing-xl);
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	> div:first-of-type {
		margin-top: var(--spacing-xl);
		display: flex;
		gap: 10px;
		flex-grow: 1;
		div {
			flex: 1 1 0;
			padding: var(--spacing-m);
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: ${props => (props.$type === 'video' ? 'var(--blue-light-5-o10)' : 'var(--purple-2-o10)')};
			h4,
			p {
				margin: 0;
				padding: 0;
				font-size: 14px;
			}
			h4 {
				font-size: 26px;
				font-weight: 400;
			}
		}
	}
	> div:last-of-type {
		display: flex;
		justify-content: center;
		gap: 30px;
		padding: var(--spacing-m);
		p {
			margin: 0;
			padding: 0;
			font-size: 14px;
			&:last-child {
				font-weight: 600;
				font-size: 16px;
			}
		}
	}
`;

const MonitoringTypes = {
	video: 0,
	ambient: 1,
};

const MonitoringKPIs = ({
	type,
	start,
	end,
	selectedHealthSystem,
	selectedHospital,
	selectedDepartment,
	selectedFloor,
	selectedRoom,
	selectedTimezone,
	setError,
	additionalFilters,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [monitoringStats, setMonitoringStats] = useState({ rooms: 0, patients: 0, sessions: 0 });
	const [monitoringDuration, setMonitoringDuration] = useState({ average: '0s', total: '0s' });
	const controller = useRef(null);

	const fetchData = useCallback(
		debounce(async (params, signal) => {
			setIsLoading(true);
			const response = await getMonitoringKPIs(params, signal);

			if (!response.error) {
				const { totalRooms, totalSessions, totalPatients, avgDuration, totalDuration } = response.sessionKpis;
				setMonitoringStats({ rooms: totalRooms, patients: totalPatients, sessions: totalSessions });
				setMonitoringDuration({
					average: formatTimeDurationWithSeconds({ seconds: avgDuration, joinWith: ' ', showDays: true }),
					total: formatTimeDurationWithSeconds({ seconds: totalDuration, joinWith: ' ', showDays: true }),
				});
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(response.error.message);
			}
			setIsLoading(false);
		}, 500),
		[]
	);

	useEffect(() => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const params = {
			start: timezoneToUTCTimestamp(start, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(end, selectedTimezone.zone),
			sessionType: MonitoringTypes[type],
			healthSystemId: selectedHealthSystem,
			regionId: selectedHospital?.regionId,
			hospitalId: selectedHospital?.value,
			departmentId: selectedDepartment?.value,
			floorId: selectedFloor?.value,
			roomId: selectedRoom?.value,
			...additionalFilters,
		};

		fetchData(params, signal);
	}, [start, end, selectedHealthSystem, selectedHospital, selectedDepartment, selectedFloor, selectedRoom, additionalFilters]);

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	const getMonitoringTypeColor = type => {
		const themes = {
			video: 'var(--blue-light-5)',
			ambient: 'var(--purple-2)',
		};
		return themes[type];
	};

	return (
		<ChartWrapper $type={type}>
			<h3>
				{translate(type)} {translate('monitoringSessions')}
			</h3>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='200px' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<>
					<div>
						<div>
							<Rooms color={getMonitoringTypeColor(type)} />
							<h4>{monitoringStats.rooms}</h4>
							<p>{translate('rooms')}</p>
						</div>
						<div>
							<Patients color={getMonitoringTypeColor(type)} />
							<h4>{monitoringStats.patients}</h4>
							<p>{translate('patients')}</p>
						</div>
						<div>
							<Sessions color={getMonitoringTypeColor(type)} />
							<h4>{monitoringStats.sessions}</h4>
							<p>{translate('sessions')}</p>
						</div>
					</div>
					<div>
						<div>
							<p>{translate('averageSessionDuration')}</p>
							<p>{monitoringDuration.average}</p>
						</div>
						<div>
							<p>{translate('totalSessionsDuration')}</p>
							<p>{monitoringDuration.total}</p>
						</div>
					</div>
				</>
			)}
		</ChartWrapper>
	);
};

export default MonitoringKPIs;
