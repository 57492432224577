import ProfilePicture from 'components/ProfilePicture.jsx';
import { genderItems } from 'constants/genderItems.js';
import translate from 'i18n-translations/translate.jsx';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import React from 'react';
import PatientDetailCell from 'containers/HospitalAtHome/PatientDetailCell.jsx';
import Button from 'components/Button.jsx';
import LightTheme from 'calls/styles/LightTheme.js';
import Expand from 'icons/General/Expand.jsx';
import Collapse from 'icons/General/Collapse.jsx';

const PatientDetails = ({
	setPatientDetailsView,
	setSelectedPatient,
	selectedPatient,
	goToOverview,
	talkToPatient,
	allExpanded,
	setAllExpanded,
}) => (
	<div className='patient-details-header'>
		<div className='flex'>
			<Button
				className='care-logs-btn'
				onClick={() => {
					setPatientDetailsView(false);
					setSelectedPatient(null);
					goToOverview();
				}}
				icon='chevron_left'
			/>

			<div>
				<div className='flex flex-align-center'>
					<ProfilePicture
						fullName={`${selectedPatient.firstName} ${selectedPatient.lastName}`}
						profilePicture={selectedPatient.profilePicture}
						className='doctor-request-img'
					/>
					<span className='full-name-span'>{`${selectedPatient.firstName} ${selectedPatient.lastName}`}</span>
				</div>
			</div>
		</div>
		<div>
			<Button
				svgIcon={allExpanded ? <Collapse /> : <Expand />}
				background='transparent'
				color={LightTheme.colors.grayTen}
				border='none'
				onClick={() => setAllExpanded(prevState => !prevState)}
			/>
		</div>
		<div className='flex column-direction patient-detail'>
			<div>
				<PatientDetailCell
					firstLabel='MRN'
					firstValue={selectedPatient.mrn}
					secondLabel={translate('language')}
					secondValue={selectedPatient.language}
				/>
				<PatientDetailCell
					firstLabel={translate('dateOfBirth')}
					firstValue={monthDayYear(selectedPatient.birthdate)}
					secondLabel={translate('location')}
					secondValue={selectedPatient.location}
				/>
				<PatientDetailCell
					firstLabel={translate('sex')}
					firstValue={genderItems.find(x => x.genderId === selectedPatient.genderId?.toString())?.text}
				/>
			</div>
			{allExpanded && (
				<>
					<div>
						<PatientDetailCell
							firstLabel={translate('admitDate')}
							firstValue={selectedPatient.admitDate}
							secondLabel={translate('product')}
							secondValue={selectedPatient.product}
						/>
						<PatientDetailCell
							firstLabel={translate('phaseOfCare')}
							firstValue={selectedPatient.phaseCare}
							secondLabel={translate('sponsor')}
							secondValue={selectedPatient.sponsor}
						/>
						<PatientDetailCell
							firstLabel={translate('diagnosis')}
							firstValue={selectedPatient.diagnosis}
							secondLabel={translate('payer')}
							secondValue={selectedPatient.payer}
						/>
					</div>
				</>
			)}
		</div>

		<div className='flex patient-info-call'>
			<div className='patient-info-audio' onClick={() => talkToPatient(selectedPatient.solHelloDeviceId ?? null)} />
		</div>
	</div>
);

export default PatientDetails;
