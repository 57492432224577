import { RoundingTypes } from 'constants/dashboard.js';
import { AiAlertSign } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { buildParamsFromArray, buildQueryString } from 'infrastructure/helpers/commonHelpers.js';

export const getRoleKpis = async (
	{
		start,
		end,
		healthSystemId = null,
		regionId = null,
		hospitalId = null,
		departmentId = null,
		floorId = null,
		roomId = null,
		providerId = null,
		timezone = null,
		excludeUnder = null,
		excludeStatus = [],
	},
	signal
) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/callstats/role-kpis?${buildParamsFromArray('exclude-status', excludeStatus)}`,
			{
				params: {
					start,
					end,
					healthSystemId,
					regionId,
					hospitalId,
					departmentId,
					floorId,
					roomId,
					providerId,
					timezone,
					'exclude-under': excludeUnder,
				},
				signal,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHourlySessionsTimeline = async (
	{
		start,
		end,
		healthSystemId = null,
		regionId = null,
		hospitalId = null,
		departmentId = null,
		floorId = null,
		roomId = null,
		timezone = null,
		excludeUnder = null,
		excludeStatus = [],
	},
	signal
) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/callstats/hourly-timeline?${buildParamsFromArray('exclude-status', excludeStatus)}`,
			{
				params: {
					start,
					end,
					healthSystemId,
					regionId,
					hospitalId,
					departmentId,
					floorId,
					roomId,
					timezone,
					'exclude-under': excludeUnder,
				},
				signal,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHourlyPatientKpiTimeline = async ({ fromDate, toDate, levelType, levelId }) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/patient-kpi`, {
			params: {
				'level-id': levelId,
				'level-type': levelType,
				'from-date': fromDate,
				'to-date': toDate,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const downloadConferencesCsv = async ({
	start,
	end,
	healthSystemId = null,
	regionId = null,
	hospitalId = null,
	departmentId = null,
	floorId = null,
	roomId = null,
	providerId = null,
	fields = '',
	timezone = null,
}) => {
	try {
		const url = `v1.1/tasks/companies/${getCompanyId()}/conferences${fields ? `?fields=${fields}` : ''}`;
		const { data } = await gatewayApi.get(url, {
			params: {
				start,
				end,
				healthSystemId,
				regionId,
				hospitalId,
				departmentId,
				floorId,
				roomId,
				providerId,
				timezone,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendEmailDashboardData = async ({
	start,
	end,
	healthSystemId = null,
	regionId = null,
	hospitalId = null,
	departmentId = null,
	floorId = null,
	roomId = null,
	providerId = null,
	fields = '',
	timezone = null,
	excludeUnder = null,
	excludeStatus = [],
}) => {
	try {
		const url = `v1/tasks/organization/${getCompanyId()}/rooms/conferences${
			fields ? `?fields=${fields}` : ''
		}${buildParamsFromArray('exclude-status', excludeStatus)}`;
		const { data } = await gatewayApi.get(url, {
			params: {
				start,
				end,
				healthSystemId,
				regionId,
				hospitalId,
				departmentId,
				floorId,
				roomId,
				providerId,
				timezone,
				'exclude-under': excludeUnder,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getProviders = async (
	{
		start,
		end,
		healthSystemId = null,
		regionId = null,
		hospitalId = null,
		departmentId = null,
		floorId = null,
		roomId = null,
		pageIndex = 0,
		pageSize = 20,
		timezone = null,
		searchPattern = null,
		excludeUnder = null,
		excludeStatus = [],
	},
	signal
) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/callstats/providers?${buildParamsFromArray('exclude-status', excludeStatus)}`,
			{
				params: {
					start,
					end,
					pageIndex,
					pageSize,
					healthSystemId,
					regionId,
					hospitalId,
					departmentId,
					floorId,
					roomId,
					timezone,
					searchPattern,
					'exclude-under': excludeUnder,
				},
				signal,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientsBasedOnProvider = async ({
	start,
	end,
	healthSystemId = null,
	regionId = null,
	hospitalId = null,
	departmentId = null,
	floorId = null,
	roomId = null,
	providerId = null,
	roleId = null,
	pageIndex = 0,
	pageSize = 20,
	timezone = null,
	excludeUnder = null,
	excludeStatus = [],
}) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/callstats/providers/${providerId}/role/${roleId}/patients?${buildParamsFromArray(
				'exclude-status',
				excludeStatus
			)}`,
			{
				params: {
					start,
					end,
					pageIndex,
					pageSize,
					healthSystemId,
					regionId,
					hospitalId,
					departmentId,
					floorId,
					roomId,
					providerId,
					timezone,
					'exclude-under': excludeUnder,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getKpis = async ({ levelId, levelType, fromDate, toDate }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/
		${getCompanyId()}/kpi`,
			{
				params: {
					'level-id': levelId,
					'level-type': levelType,
					'from-date': fromDate,
					'to-date': toDate,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAssignedMembers = async ({ healthSystemId, search = '', pageIndex = 0, pageSize = 20 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/assigned-members?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAiAlerts = async (
	{ fromDate, toDate, levelType, levelId, patientId = null, timezone = null, signType = AiAlertSign.WARNING },
	signal
) => {
	const params = { fromDate, toDate, levelType, levelId, patientId, timezone, signType };
	try {
		const { data } = await gatewayApi.get(`v1/alerts/ai/organization/${getCompanyId()}/statistics?${buildQueryString(params)}`, {
			signal,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthAlerts = async ({ fromDate, toDate, levelType, levelId, timezone = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/alerts/measurement/organization/${getCompanyId()}/statistics`, {
			params: {
				'level-id': levelId,
				'level-type': levelType,
				'from-date': fromDate,
				'to-date': toDate,
				...(timezone && {
					'time-zone': timezone,
				}),
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientCentricReport = async (
	{ pageSize, pageIndex, from, to, search = '', healthSystemId, hospitalId, departmentId, floorId, roomId },
	signal
) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/patients/centric-reports`, {
			params: {
				'page-size': pageSize,
				'page-index': pageIndex,
				from,
				to,
				search: search,
				'health-system': healthSystemId,
				hospital: hospitalId,
				department: departmentId,
				floor: floorId,
				room: roomId,
			},
			signal,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientSession = async ({
	patientId,
	start,
	end,
	companyId = getCompanyId(),
	timezone = null,
	pageSize = 10,
	pageIndex = 0,
	solHelloDeviceId = null,
	excludeUnder = null,
	excludeStatus = [],
}) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/conferences/patients/${patientId}?${buildParamsFromArray('exclude-status', excludeStatus)}`,
			{
				params: {
					start,
					end,
					companyId,
					pageSize,
					pageIndex,
					timezone,
					solHelloDeviceId,
					'exclude-under': excludeUnder,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMonitoringKPIs = async (
	{
		start,
		end,
		sessionType,
		healthSystemId = null,
		regionId = null,
		hospitalId = null,
		departmentId = null,
		floorId = null,
		roomId = null,
		nurseId = null,
		excludeUnder = null,
		excludeStatus = [],
	},
	signal
) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/callstats/monitoring-sesisons/${sessionType}/kpis?${buildParamsFromArray(
				'exclude-status',
				excludeStatus
			)}`,
			{
				params: {
					start,
					end,
					regionId,
					hospitalId,
					departmentId,
					floorId,
					roomId,
					nurseId,
					'exclude-under': excludeUnder,
				},
				signal,
			}
		);

		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoundingKPIs = async (
	{
		start,
		end,
		callOrigin,
		healthSystemId = null,
		regionId = null,
		hospitalId = null,
		departmentId = null,
		floorId = null,
		roomId = null,
		nurseId = null,
		excludeUnder = null,
		excludeStatus = [],
	},
	type,
	signal
) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/callstats/${type.value}-sessions/${
				type.type === RoundingTypes.VIEW_PATIENT.type ? '' : `${callOrigin}/`
			}kpis?${buildParamsFromArray('exclude-status', excludeStatus)}`,
			{
				params: {
					start,
					end,
					regionId,
					hospitalId,
					departmentId,
					floorId,
					roomId,
					nurseId,
					'exclude-under': excludeUnder,
				},
				signal,
			}
		);

		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientReport = async ({ healthSystemId, patientId, from, to, timezone = null }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/tasks/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients/${patientId}/report`,
			{ params: { from, to, timezone } }
		);
		return data;
	} catch (error) {
		return { error };
	}
};
