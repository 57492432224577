import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import Alert from 'components/Alert.jsx';
import { editRegionName, createNewRegion, editHealthSystemName, deleteOrgUnit } from 'api/healthSystems.js';
import { DeviceListLevel, HealthSystemType, KeyCodes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystems } from 'api/healthSystems.js';

const EditHealthSystemModal = props => {
	const [isConfirmDeleteRegionModalOpen, setIsConfirmDeleteRegionModalOpen] = useState(false);
	const [currentRegions, setCurrentRegions] = useState([]);
	const [healthSystemName, setHealthSystemName] = useState('');
	const [isRegionSelected, setIsRegionSelected] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [workflowTypeId, setWorkflowTypeId] = useState(HealthSystemType.DEFAULT);
	const [regionSaved, setRegionSaved] = useState(false);
	const [error, setError] = useState(null);
	const [selectedRegionId, setSelectedRegionId] = useState(null);
	const [isEditSystemModalLoading, setIsEditSystemModalLoading] = useState(false);
	const [healthSystemNameError, setHealthSystemNameError] = useState('');
	const [regionNameError, setRegionNameError] = useState('');
	const dispatch = useDispatch();
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const selectedHealthSystem = healthSystems.allHealthSystems.find(item => item.id === userSession.healthSystem.id);

	const intl = useIntl();

	const healthSystemActionsEnum = {
		EDIT_HEALTH_SYSTEM: 0,
		EDIT_REGION_NAME: 1,
		CREATE_NEW_REGION: 2,
		DELETE_HEALTH_SYSTEM: 3,
		DELETE_REGION: 4,
	};

	useEffect(() => {
		if (!selectedHealthSystem) {
			return;
		}
		setCurrentRegions(selectedHealthSystem.regions);
		setHealthSystemName(selectedHealthSystem ? selectedHealthSystem.name : '');
		setWorkflowTypeId(selectedHealthSystem.workflowTypeId);
	}, [userSession, healthSystems, intl]);

	const editHealthSystemActions = async data => {
		setIsEditSystemModalLoading(true);
		let result = null;
		setShowAlert(false);
		setHealthSystemNameError('');
		setRegionNameError('');
		switch (data.action) {
			case healthSystemActionsEnum.EDIT_HEALTH_SYSTEM:
				result = await editHealthSystemName(data.healthSystemId, data.newHealthSystemName, data.workflowTypeId);
				break;
			case healthSystemActionsEnum.EDIT_REGION_NAME:
				result = await editRegionName(data.healthSystemId, data.regionId, data.newRegionName);
				break;
			case healthSystemActionsEnum.CREATE_NEW_REGION:
				result = await createNewRegion(data.healthSystemId, data.newRegionName);
				break;
			case healthSystemActionsEnum.DELETE_HEALTH_SYSTEM:
				result = await deleteOrgUnit(DeviceListLevel.HEALTH_SYSTEM, data.healthSystemId);
				break;
			case healthSystemActionsEnum.DELETE_REGION:
				result = await deleteOrgUnit(DeviceListLevel.REGION, data.regionId);
				break;
			default:
				break;
		}
		const healthSystemsRes = await getHealthSystems();
		if (healthSystemsRes.error) {
			setError(healthSystemsRes.error.message);
		} else {
			dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
			setShowAlert(true);
		}
		setIsEditSystemModalLoading(false);
		return result;
	};

	const saveHealthSystemName = async (event, value, isValid) => {
		if (event.which !== KeyCodes.ENTER || !isValid) {
			return;
		}
		if (checkIfHealthSystemExists(value)) {
			setHealthSystemNameError(intl.formatMessage({ id: 'healthSystemExists' }));
			return;
		}
		await editHealthSystemActions({
			action: healthSystemActionsEnum.EDIT_HEALTH_SYSTEM,
			healthSystemId: userSession.healthSystem.id,
			newHealthSystemName: value,
			workflowTypeId: workflowTypeId,
		});
		props.onHealthSystemChange();
	};

	const setRegionInputValue = async (event, index) => {
		const { value } = event.target;
		setCurrentRegions(prevState => {
			const regionsCopy = [...prevState];
			const regions = regionsCopy.map(currentRegion => ({ ...currentRegion }));
			regions[index].name = value;
			return regions;
		});
	};

	const checkIfHealthSystemExists = hsName => {
		if (hsName === healthSystemName) {
			return false;
		}
		setHealthSystemNameError(null);
		return healthSystems.allHealthSystems.some(healthSystem => healthSystem.name.toLowerCase() === hsName.toLowerCase());
	};

	const regionExist = region =>
		selectedHealthSystem.regions.some(r => r.name.toLowerCase() === region.name.toLowerCase() && r.id === region.id);

	const saveRegion = async (event, region) => {
		if (event.which != KeyCodes.ENTER || (regionExist(region) && !region.isNewRegion) || isEditSystemModalLoading) {
			return;
		}
		setError(null);
		if (event.target.value.trim() === '') {
			setRegionNameError(intl.formatMessage({ id: 'regionCannotBeEmpty' }));
			return;
		}
		if (regionExist(region) && region.isNewRegion) {
			setRegionNameError(intl.formatMessage({ id: 'regionExists' }));
			return;
		}
		setRegionSaved(false);
		const regionNew = { ...region };
		if (regionNew.isNewRegion) {
			const response = await editHealthSystemActions({
				action: healthSystemActionsEnum.CREATE_NEW_REGION,
				healthSystemId: userSession.healthSystem.id,
				newRegionName: regionNew.name,
			});
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			regionNew.id = response.region.id;
			regionNew.isNewRegion = false;
		} else {
			const response = await editHealthSystemActions({
				action: healthSystemActionsEnum.EDIT_REGION_NAME,
				healthSystemId: userSession.healthSystem.id,
				regionId: regionNew.id,
				newRegionName: regionNew.name,
			});
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
		}
		setRegionSaved(true);
	};

	const removeRegion = regionId => {
		setCurrentRegions(prevState => {
			const regionsCopy = [...prevState];
			const regions = regionsCopy.filter(currentRegion => currentRegion.id !== regionId);
			return regions;
		});
	};

	const addNewRegion = () => {
		setCurrentRegions(prevState => {
			const regionsCopy = [...prevState];
			const regions = regionsCopy.map(currentRegion => ({ ...currentRegion }));
			regions.push({
				isNewRegion: true,
				name: '',
				id: Date.now().toString(),
			});
			return regions;
		});
	};

	const deleteSelected = async () => {
		if (isRegionSelected) {
			const region = currentRegions.find(currentRegion => currentRegion.id === selectedRegionId);
			if (!region.isNewRegion) {
				await editHealthSystemActions({
					action: healthSystemActionsEnum.DELETE_REGION,
					regionId: selectedRegionId,
				});
			}
			removeRegion(selectedRegionId);
		} else {
			await editHealthSystemActions({
				action: healthSystemActionsEnum.DELETE_HEALTH_SYSTEM,
				healthSystemId: userSession.healthSystem.id,
			});
			props.onHealthSystemDelete();
		}
		toggleConfirmDeleteModal();
	};

	const toggleConfirmDeleteModal = regionId => {
		if (regionId && currentRegions.length < 2) {
			setError(intl.formatMessage({ id: 'atLeastOneRegion' }));
			return;
		}
		setIsConfirmDeleteRegionModalOpen(prevState => !prevState);
		setIsRegionSelected(!!regionId);
		setSelectedRegionId(regionId);
	};

	const canDeleteHealthSystem = () => healthSystems.allHealthSystems.length > 1;

	const getValidationSchema = () =>
		Yup.object().shape({
			healthSystemName: Yup.string()
				.required(intl.formatMessage({ id: 'healthSystemRequired' }))
				.min(
					2,
					intl.formatMessage({
						id: 'healthSystemMinLength',
					})
				)
				.max(
					100,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				)
				.trim(),
		});

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				healthSystemName,
			}}
			validationSchema={getValidationSchema}
			validateOnChange={true}
			onSubmit={() => null}>
			{formikProps => {
				const { values, errors, handleChange, handleSubmit } = formikProps;
				return (
					<>
						<Modal
							modalSelector='editHealthSystemModal'
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
							display={props.isOpen}
							isLoading={isEditSystemModalLoading}
							onModalSubmit={handleSubmit}
							position='center'
							onModalClose={props.onModalClose}
							closeButtonText={translate('close')}
							shouldSubmitOnEnter={false}
							primaryButtonLabel=''>
							<Form title={translate('manageHealthSystem')} height={550} className='manage-hs-form'>
								<div>
									<Input
										label={translate('healthSystemType')}
										description={
											workflowTypeId === HealthSystemType.PRIMARY_CARE ? translate('primaryHealthcare') : translate('default')
										}
										maxLength={127}
										variant='margin-top-m'
									/>
								</div>
								<label>
									<Input
										label={translate('editHealthSystem')}
										description={translate('changesWillBeAppliedToThisHS')}
										maxLength={127}
									/>
								</label>
								<div className='hs-list' data-cy='manageHealthSystem'>
									<input
										onChange={handleChange}
										onKeyDown={event => saveHealthSystemName(event, values.healthSystemName, !errors.healthSystemName)}
										name='healthSystemName'
										type='text'
										value={values.healthSystemName}
									/>
									{canDeleteHealthSystem() && (
										<span style={{ cursor: 'pointer' }} onClick={() => toggleConfirmDeleteModal()}>
											<i className='material-icons-outlined delete'>delete</i>
										</span>
									)}
								</div>
								{(errors.healthSystemName || healthSystemNameError) && (
									<span className='red-error create-app-error'>{errors.healthSystemName || healthSystemNameError}</span>
								)}
								<div className='region-actions flex flex-space-between'>
									<small className='press-enter-message'>{translate('pressEnterToSave')}</small>
								</div>
								<Input
									label={translate('regions')}
									description={translate('availableRegionsInThisHS')}
									variant='margin-top-m'
									maxLength={127}
								/>
								{currentRegions.map((region, index) => (
									<div className='hs-list' data-cy='manageRegions'>
										<input
											onChange={event => setRegionInputValue(event, index)}
											onKeyDown={event => saveRegion(event, region)}
											type='text'
											value={region.name}
											maxLength={127}
										/>
										<span style={{ cursor: 'pointer' }} onClick={() => toggleConfirmDeleteModal(region.id)}>
											<i className='material-icons-outlined delete'>delete</i>
										</span>
									</div>
								))}
								<div className='region-actions flex flex-space-between '>
									<small className='press-enter-message'>{translate('pressEnterToSave')}</small>
									<small className='add-region-btn' onClick={addNewRegion}>
										+{translate('addNewRegion')}
									</small>
								</div>
								{regionNameError && <span className='red-error create-app-error'>{regionNameError}</span>}
								<Alert
									display={showAlert}
									fixed={true}
									hideCloseButton={true}
									message={intl.formatMessage({ id: 'changesSaved' })}
									variant='success'
								/>
							</Form>
							<Alert
								display={regionSaved}
								fixed
								hideCloseButton
								message={intl.formatMessage({ id: 'regionSaved' })}
								variant='success'
							/>
							<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
						</Modal>
						<Modal
							modalSelector='healthSystemModalCenter'
							display={isConfirmDeleteRegionModalOpen}
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
							position='center'
							primaryButtonLabel={translate('delete')}
							onModalSubmit={deleteSelected}
							onModalClose={() => setIsConfirmDeleteRegionModalOpen(false)}>
							<Form title={translate(isRegionSelected ? 'deleteRegion' : 'deleteHS')} onSubmit={event => event.preventDefault()}>
								{isRegionSelected && translate('areYouSureToDeleteRegion')}
								{!isRegionSelected && translate('areYouSureToDeleteHS')}
							</Form>
						</Modal>
					</>
				);
			}}
		</Formik>
	);
};

export default EditHealthSystemModal;
