import React, { useEffect, useState } from 'react';
import { Button } from 'components/index.js';
import FormInput from 'components/FormInput.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import {
	addMonitoringSettingOption,
	deleteMonitoringSettingOption,
	getMonitoringSettingOptions,
} from 'api/adminConfigurations.js';
import { containsLetters } from 'infrastructure/helpers/commonHelpers.js';

const MonitoringSettingReasonsAdmin = ({ healthSystemId, setError, shouldShowExpanded, title, placeholder, settingTypeId }) => {
	const intl = useIntl();
	const [newOption, setNewOption] = useState('');
	const [options, setOptions] = useState([]);
	const [customOptionError, setCustomOptionError] = useState('');
	const [isButtonLoading, setIsButtonLoading] = useState(false);

	useEffect(() => {
		const fetchOptions = async () => {
			const response = await getMonitoringSettingOptions(healthSystemId, settingTypeId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = response.teamSettingOptions.map(item => ({
				...item,
				id: item.id,
			}));
			setOptions(mapped);
		};

		if (healthSystemId) {
			fetchOptions();
		}
	}, [intl, healthSystemId]);

	const saveOption = async () => {
		setCustomOptionError('');
		const maxOptions = 30;

		if (!newOption) {
			return;
		}

		if (options.find(item => item.option.toLowerCase() === newOption.toLowerCase())) {
			setCustomOptionError(intl.formatMessage({ id: 'optionAlreadyExists' }));
			return;
		}

		if (!containsLetters(newOption)) {
			setCustomOptionError(intl.formatMessage({ id: 'descriptionMustHaveLetter' }));
			return;
		}

		if (options.length >= maxOptions) {
			setCustomOptionError(intl.formatMessage({ id: 'maxNumberOfOptionsReached' }));
			return;
		}

		setIsButtonLoading(true);

		const response = await addMonitoringSettingOption(healthSystemId, settingTypeId, newOption);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			setIsButtonLoading(false);
			return;
		}

		const listResponse = await getMonitoringSettingOptions(healthSystemId, settingTypeId);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			setIsButtonLoading(false);
			return;
		}
		const mapped = listResponse.teamSettingOptions.map(item => ({
			...item,
			id: item.id,
		}));
		setOptions(mapped);
		setNewOption('');
		setIsButtonLoading(false);
	};

	const deleteOption = async id => {
		setCustomOptionError('');

		if (!id) {
			return;
		}
		if (options.length <= 1) {
			setCustomOptionError(intl.formatMessage({ id: 'atLeastOneMustBeSet' }));
			return;
		}
		const response = await deleteMonitoringSettingOption(healthSystemId, settingTypeId, id);
		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		setOptions(prevState => prevState.filter(item => item.id !== id));
	};

	if (!shouldShowExpanded) {
		return <></>;
	}

	return (
		<>
			<div className='position-relative feature-flag-options flex'>
				<p>{translate(title)}</p>
				<ul>
					{options.length === 0 && <li>{translate('noAddedReasons')}</li>}
					{options.map(item => (
						<li key={item.id}>
							{item.option}
							<i className='material-icons-outlined' onClick={() => deleteOption(item.id)}>
								delete
							</i>
						</li>
					))}
				</ul>

				<FormInput
					labelClassName='feature-flag-txt-label full-width'
					id={title}
					placeholder={intl.formatMessage({ id: placeholder })}
					type='text'
					value={newOption}
					onChange={e => setNewOption(e.target.value)}
					className='text-input feature-flag-txt-input'
					maxLength={127}
				/>
				{customOptionError && <p className='--red-color error'>{customOptionError}</p>}

				<Button
					type='button'
					onClick={saveOption}
					className={classNames('align-self-end', { disabled: !newOption })}
					text={translate('addNew')}
					isDisabled={!newOption || isButtonLoading}
					isLoading={isButtonLoading}
				/>
			</div>
		</>
	);
};

export default MonitoringSettingReasonsAdmin;
