import cities from 'infrastructure/helpers/timezone/cities.js';

export const getFormatedTimeZone = timeZone => {
	const formatter = new Intl.DateTimeFormat('EN-AU', {
		hour: 'numeric',
		minute: 'numeric',
		timeZone: timeZone,
		timeZoneName: 'short',
	});
	const startingDate = new Date();
	const dateInNewTimezone = formatter.format(startingDate);
	const [time, amPm, gmt] = dateInNewTimezone.split(' ');
	return {
		formattedTimeZone: `${timeZone} (${gmt.replace('GMT', 'UTC')}) ${time} ${amPm.toUpperCase()}`,
		date: startingDate,
	};
};

export const findPartialMatch = (itemsToSearch = [], searchString = '') =>
	searchString.split(' ').every(word => itemsToSearch.join().toLowerCase().indexOf(word.toLowerCase()) >= 0);

export const searchPlaceOrTimeZone = searchString => {
	if (searchString) {
		const exactMatched = cities.filter(item =>
			[
				item.city?.toLowerCase(),
				item.state_ansi?.toLowerCase(),
				item.province?.toLowerCase(),
				item.country?.toLowerCase(),
				item.timezone?.toLowerCase(),
			].includes(searchString.toLowerCase())
		);
		if (exactMatched.length > 0) {
			return exactMatched.slice(0, 10);
		}
		const cityLookup = cities.filter(item =>
			findPartialMatch([item.city, item.state_ansi, item.province, item.country, item.timezone], searchString)
		);
		if (cityLookup && cityLookup.length) {
			return cityLookup.slice(0, 10);
		} else {
			return [];
		}
	} else {
		return [];
	}
};

export const searchByCity = (city = '') => cities.filter(item => item.city.toLowerCase() === city?.toLowerCase());

export const findByCityAndCountry = (city = '', country = '') =>
	cities.find(item => item.city.toLowerCase() === city.toLowerCase() && item.country.toLowerCase() === country.toLowerCase());
