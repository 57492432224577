import { getPatientSession } from 'api/dashboard.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { Alert, Badge, CustomTable, Modal } from 'components/index.js';
import { DefaultTimezone, formatDateHeader } from 'constants/dashboard.js';
import { CallStatus } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getCallStatus, getCallType } from 'infrastructure/helpers/callLogsHelper.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { formatTimeDurationWithSeconds, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

const Session = () => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();
	const location = useLocation();
	const [sessions, setSessions] = useState([]);
	const [sessionDetails, setSessionDetails] = useState(null);
	const [showSessionDetailsModal, setShowSessionDetailsModal] = useState(false);
	const [timezone, setTimezone] = useState(DefaultTimezone);

	useEffect(() => {
		const fetchPatientSession = async () => {
			const {
				patient,
				filters: { additionalFilters },
			} = location.state;
			setIsLoading(true);
			const params = {
				patientId: patient.healthcareUserIntId,
				solHelloDeviceId: patient.solHelloDeviceId,
				start: Math.floor(moment(patient.admissionDate).utc(true).valueOf() / 1000),
				end: Math.floor(
					(patient.dischargeDate ? moment(patient.dischargeDate).utc(true).valueOf() : moment().utc().valueOf()) / 1000
				),
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				...additionalFilters,
			};
			const response = await getPatientSession(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setSessions(prevState => [...prevState, ...response.sessions]);
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
			}
			setIsLoading(false);
		};
		fetchPatientSession();
		setTimezone(location.state.filters.selectedTimezone);
	}, [location, pagination.pageIndex, pagination.pageSize]);

	const openSessionDetailsModal = session => {
		const { usedAudio, usedScreen, usedVideo } = session;
		setSessionDetails({
			usedAudio: translate(usedAudio ? 'yes' : 'no'),
			usedVideo: translate(usedVideo ? 'yes' : 'no'),
			usedScreen: translate(usedScreen ? 'yes' : 'no'),
		});
		setShowSessionDetailsModal(true);
	};

	const displayCallStatus = status => {
		const translationKey = stringToCamelCase(getCallStatus(status));
		return (
			<Badge
				text={translationKey ? translate(`${translationKey}OnList`) : 'N/A'}
				variant={status === CallStatus.SUCCESSFUL.type ? 'green' : 'red'}
			/>
		);
	};

	const displaySessions = () => {
		if (!sessions || sessions.length === 0) {
			return [];
		}
		return sessions.map(item => ({
			callOrigin: translate(item.isInitiator ? 'incoming' : 'outgoing'),
			type: getCallType(item.callType),
			careEventType: item?.careEventTypeName || 'N/A',
			careGiver: (
				<div className='flex flex-align-center'>
					<ProfilePicture
						fullName={item.provider}
						profilePicture={item.profilePicture ?? null}
						className='provider-intervening-img-wrapper'
					/>
					<p style={{ margin: 'var(--spacing-s)', padding: '0' }}>{item.provider}</p>
				</div>
			),
			room: item.room,
			startTime: utcToTimezone(item.startTime, timezone.zone),
			endTime: utcToTimezone(item.endedTime, timezone.zone),
			duration: formatTimeDurationWithSeconds({ seconds: item.duration, joinWith: ', ', showDays: false, intl }),
			status: displayCallStatus(item.status),
			sessionDetails: (
				<span className='text-underline cursor-pointer' onClick={() => openSessionDetailsModal(item)}>
					{translate('viewDetails')}
				</span>
			),
		}));
	};

	return (
		<div className='margin-top-sl'>
			<div className='patients-table-wrapper'>
				<CustomTable
					headers={[
						{ title: translate('callOrigin'), id: 'callOrigin' },
						{ title: translate('sessionType'), id: 'type' },
						{ title: translate('careEventType'), id: 'careEventType' },
						{ title: translate('careGiver'), id: 'careGiver' },
						{ title: translate('room'), id: 'room' },
						{ title: formatDateHeader(intl, 'sessionStart', timezone.zone), id: 'startTime' },
						{ title: formatDateHeader(intl, 'sessionEnd', timezone.zone), id: 'endtime' },
						{ title: translate('sessionDuration'), id: 'duration' },
						{ title: translate('sessionStatus'), id: 'status' },
						{ title: translate('sessionDetails'), id: 'sessionDetails' },
					]}
					rows={displaySessions()}
					isLoading={isLoading}
					setPagination={setPagination}
				/>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			<Modal
				display={showSessionDetailsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={() => setShowSessionDetailsModal(false)}>
				<form className='call-details'>
					<h3 className='title'>{translate('sessionDetails')}</h3>
					{!!sessionDetails && (
						<CustomTable
							headers={[
								{ title: translate('audioUsed'), id: 'usedAudio' },
								{ title: translate('videoUsed'), id: 'usedVideo' },
								{ title: translate('screenUsed'), id: 'usedScreen' },
							]}
							rows={[sessionDetails]}
						/>
					)}
				</form>
			</Modal>
		</div>
	);
};

export default Session;
