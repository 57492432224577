import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import { CompanyCallSettings, CompanySettings, SettingsCategory } from 'constants/configurationEnums.js';
import Alert from 'components/Alert.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getRoomSettings, getTeamSettings, updateRoomSettings, updateTeamSettings } from 'api/adminConfigurations.js';
import { useSelector } from 'react-redux';
import { generateCustomStyles } from 'constants/react-select-style.js';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { DeviceListLevel } from 'constants/enums.js';

const DefaultCamSettings = ({ sectorId, healthSystemId, levelId, isSector = true }) => {
	const intl = useIntl();
	const companySettings = useSelector(state => state.company.companySettings);
	const user = useSelector(state => state.user);

	const cameraTypes = [
		{
			id: 1,
			label: `${companySettings.helloName} ${intl.formatMessage({ id: 'camera' })}`,
			value: 'hello',
		},
		{
			id: 2,
			label: `${companySettings.huddleName} ${intl.formatMessage({ id: 'camera' })}`,
			value: 'external-camera',
		},
	];

	const [error, setError] = useState(null);
	const [selectedRoundingCamera, setSelectedRoundingCamera] = useState(null);
	const [selectedMonitoringCamera, setSelectedMonitoringCamera] = useState(null);

	useEffect(() => {
		const getValue = value => cameraTypes.find(item => item.value === value);
		const fetchSectorSettings = async () => {
			const response =
				levelId === DeviceListLevel.ROOM
					? await getRoomSettings(sectorId, SettingsCategory.CALL)
					: await getTeamSettings({
							teamId: sectorId,
							levelId,
							settingsCategory: levelId === DeviceListLevel.HEALTH_SYSTEM ? null : [SettingsCategory.CALL],
					  });
			if (response.error) {
				setError(response.error.message);
				return;
			}

			const foundRoundingCamera = response.settings.find(
				item => item.settingTypeId === CompanyCallSettings.ROUNDING_DEFAULT_CAMERA
			);

			const foundMonitoringCamera = response.settings.find(
				item => item.settingTypeId === CompanyCallSettings.MONITORING_DEFAULT_CAMERA
			);

			setSelectedRoundingCamera(foundRoundingCamera?.value ? getValue(foundRoundingCamera.value) : null);
			setSelectedMonitoringCamera(foundMonitoringCamera?.value ? getValue(foundMonitoringCamera?.value) : null);
		};
		fetchSectorSettings();
	}, [levelId, sectorId]);

	const selectDefaultCamera = async (settingTypeId, value) => {
		setError(null);
		const result = {
			settingTypeId,
			value,
			isLocked: false,
		};
		const params = {
			teamSettings: [result],
			teamId: sectorId,
			isOverride: true,
			healthSystemId,
		};
		const responseOrg =
			levelId === DeviceListLevel.ROOM ? await updateRoomSettings(sectorId, [result]) : await updateTeamSettings(params);
		if (responseOrg.error) {
			setError(responseOrg.error.message);
		}
	};

	return (
		<>
			<Form className={classNames('digital-background hello-background', isSector ? 'sector-calls-settings' : '')}>
				<h3>{translate('defaultCamera')}</h3>
				<div className='flex call-config-settings'>
					<div className='call-config-type'>
						<div>
							<img src={`${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`} alt='icon' />
						</div>
						<div className='call-config-description'>
							<p>{translate('selectDefaultCamera')}</p>
							<span>
								{translate('selectedDefaultCameraDescription')}{' '}
								{translate('defaultCameraDescription', { value: <b>{companySettings.huddleName}</b> })}
							</span>
						</div>
					</div>
				</div>
				<div className='flex camera-settings'>
					<div>
						<label htmlFor='rounding'>{translate('rounding')}</label>
						<ReactSelect
							id='rounding'
							options={cameraTypes}
							value={selectedRoundingCamera}
							onChange={event => {
								setSelectedRoundingCamera(event);
								selectDefaultCamera(CompanyCallSettings.ROUNDING_DEFAULT_CAMERA, event.value);
							}}
							placeholder={intl.formatMessage({ id: 'selectDefaultCamera' })}
							classNamePrefix='react-select'
							className='react-select bottom-15 top-s'
						/>
					</div>
					<div className='left-15'>
						<label htmlFor='monitoring'>{translate('monitoring')}</label>
						<ReactSelect
							id='monitoring'
							options={cameraTypes}
							value={selectedMonitoringCamera}
							onChange={event => {
								setSelectedMonitoringCamera(event);
								selectDefaultCamera(CompanyCallSettings.MONITORING_DEFAULT_CAMERA, event.value);
							}}
							placeholder={intl.formatMessage({ id: 'selectDefaultCamera' })}
							classNamePrefix='react-select'
							className='react-select bottom-15 top-s'
						/>
					</div>
				</div>
			</Form>

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default DefaultCamSettings;
