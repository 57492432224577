import React, { useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Alert, Grid } from 'components';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as companyConfigurationActionCreators } from 'state/company/actions.js';
import { mobileSettings } from 'constants/configurationEnums.js';
import { updateOrganizationSettings } from 'api/companies.js';

const RpmConfigurations = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedConfig, setSelectedConfig] = useState({
		value: 0,
		label: intl.formatMessage({ id: 'mobile' }),
	});
	const companyMobileConfigs = useSelector(state => state.company.mobileConfigurations);

	const settingsEnum = {
		MOBILE: 0,
		OTHER: 2,
	};

	const preferenceTypes = {
		[settingsEnum.MOBILE]: {
			[mobileSettings.Ear]: 'ear',
			[mobileSettings.Throat]: 'throat',
			[mobileSettings.Heart]: 'heart',
			[mobileSettings.Lungs]: 'lungs',
			[mobileSettings.HeartRate]: 'heartRate',
			[mobileSettings.OxygenSaturation]: 'oxygenSaturation',
			[mobileSettings.Temperature]: 'temperature',
			[mobileSettings.Skin]: 'skin',
			[mobileSettings.BloodPressure]: 'bloodPressure',
			[mobileSettings.ECG]: 'theecg',
			[mobileSettings.Weight]: 'weight',
			[mobileSettings.Spirometer]: 'spirometer',
			[mobileSettings.Abdomen]: 'abdomen',
			[mobileSettings.BloodGlucose]: 'bloodGlucose',
		},
	};

	const toggleItem = async key => {
		if (isLoading) {
			return;
		}
		const companySettings = [
			{
				settingTypeId: key,
				value: (!companyMobileConfigs[key]).toString(),
			},
		];
		const response = await updateOrganizationSettings({
			companySettings,
		});
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const toDispatch = {
			...companyMobileConfigs,
			[key]: !companyMobileConfigs[key],
		};
		dispatch(companyConfigurationActionCreators.setCompanyMobileConfigurations(toDispatch));
		setIsLoading(false);
	};

	return (
		<div className='general-menu-wrapper'>
			<Grid columns='300px'>
				<Select
					placeholder={intl.formatMessage({ id: 'select' })}
					value={selectedConfig}
					classNamePrefix='custom-select'
					options={[{ value: settingsEnum.MOBILE, label: intl.formatMessage({ id: 'mobile' }) }]}
					onChange={setSelectedConfig}
				/>
			</Grid>
			<div className='configurable-settings-modal-inner no-padding'>
				<div>
					{Object.entries(companyMobileConfigs).map(([key]) => (
						<React.Fragment key={key}>
							{preferenceTypes[selectedConfig.value][key] && (
								<div className='flex' key={key} onClick={() => toggleItem(+key)}>
									<p className='flex-1'>{translate(preferenceTypes[selectedConfig.value][key])}</p>
									<div className='rounded-slider-switch'>
										<input type='checkbox' checked={companyMobileConfigs[+key]} onChange={() => null} />
										<span className='rounded-slider' />
									</div>
								</div>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default RpmConfigurations;
