import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { healthCareCdnUrl, APP_CONFIG } from 'constants/global-variables.js';
import { HealthSystemType, UserRoles } from 'constants/enums.js';
import { getProperUrl } from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';

const LeftNavigation = props => {
	const getUrl = link => {
		return window.location.href.indexOf(link) > -1;
	};
	const versionName = APP_CONFIG.buildNumber.split('.v')[1];
	const waitingRoomCount = useSelector(state => state.user.waitingRoomCount);
	const sessionsQueueCount = useSelector(state => state.user.sessionsQueueCount);
	const userSession = useSelector(state => state.user.userSession);
	const darkMode = useSelector(state => state.user.darkMode);
	const configurations = useSelector(state => state.configurations);
	const darkModeUrl = `${healthCareCdnUrl}dark-mode/`;

	const shouldShowDarkModeIcons =
		darkMode &&
		[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) &&
		userSession.healthSystem.workflowTypeId !== HealthSystemType.PRIMARY_CARE;

	return (
		<ul className='left-navigation' data-cy='leftNavigation' style={{ background: props.companySettings.background }}>
			<li className='logo'>
				<span
					onClick={() => {
						const homeUrl = getProperUrl(
							configurations,
							userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE
						);
						props.openCheckInNewPatient(homeUrl);
					}}>
					{props.companyDetails.logoToPreview && (
						<img className='cursor-pointer' src={props.companyDetails.logoToPreview} alt='logo' />
					)}
				</span>
			</li>
			{props.links.map(item => (
				<li key={item.id}>
					<NavLink
						className={classNames(getUrl(item.linkTo) ? 'active' : '', item.isWordBreak ? 'break-word' : '')}
						to={item.linkTo}
						exact={item.exact}
						activeClassName='active'
						onClick={event => props.onItemClicked(item, event)}>
						{!shouldShowDarkModeIcons && (
							<>
								{getUrl(item.linkTo) && item.selectedImage && <img src={item.selectedImage} alt='icon' />}
								{!getUrl(item.linkTo) && item.image && <img src={item.image} alt='logo' />}
							</>
						)}
						{shouldShowDarkModeIcons && (
							<div>
								{getUrl(item.linkTo) && item.darkModeUrl && (
									<img src={`${darkModeUrl}${item.darkModeUrl}-white.svg`} alt='icon' />
								)}
								{!getUrl(item.linkTo) && item.darkModeUrl && (
									<img src={`${darkModeUrl}${item.darkModeUrl}-gray.svg`} alt='logo' />
								)}
							</div>
						)}
						{!item.image && <i className='material-icons-outlined'>{item.icon}</i>}
						<span>{item.title}</span>
						{item.hasWaitingRoomCount && waitingRoomCount > 0 && <div className='notification-counter'>{waitingRoomCount}</div>}
						{item.hasSessionsQueueCount && sessionsQueueCount > 0 && (
							<div className='notification-counter'>{sessionsQueueCount}</div>
						)}
					</NavLink>
				</li>
			))}
			<li className='left-nav-vers'>
				<img src={`${healthCareCdnUrl}left-menu/info-icon.svg`} alt='build-no-ico' />
				<span>{versionName ? `v${versionName}` : ''}</span>
			</li>
		</ul>
	);
};

export default LeftNavigation;
