import React, { useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { SingleValue, formatOptionLabel } from 'infrastructure/helpers/careEventsHelper.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import Alert from 'components/Alert.jsx';
import { getMembers } from 'api/users.js';
import { CompanyRoles, MembersType } from 'constants/enums.js';

const TransferFeeds = props => {
	const healthSystems = useSelector(state => state.healthSystems);
	const [nurses, setNurses] = useState([]);
	const [selectedNurse, setSelectedNurse] = useState(null);
	const [error, setError] = useState('');

	const intl = useIntl();

	const getNurses = async (value = '') => {
		const params = {
			searchValue: value,
			roles: [CompanyRoles.VIRTUAL_CARE_PROVIDER, CompanyRoles.VIRTUAL_SITTER],
			filterType: MembersType.ALL_MEMBERS,
			healthSystems: healthSystems.allHealthSystems?.length > 0 ? healthSystems.allHealthSystems.map(item => item.id) : [],
			...(value && {
				pageSize: 50,
				pageIndex: 0,
			}),
		};
		const response = await getMembers(params);
		if (response.error) {
			setError(response.error.message);
			return [];
		}
		const newOptions = response.members.map(user => ({
			value: user?.userInfo?.id,
			label: `${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`,
			email: user?.userInfo?.email,
			profilePicture: user?.userInfo?.profilePicture,
		}));
		setNurses(newOptions);
		return newOptions;
	};

	return (
		<>
			<Modal
				display={true}
				position='center'
				onModalClose={props.onClose}
				onModalSubmit={() => props.submitTransfer(selectedNurse?.value)}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal reorder-feeds-modal'
				primaryButtonLabel={props.intl.formatMessage({ id: 'transfer' })}
				shouldSubmitOnEnter={false}
				isSubmitDisabled={!selectedNurse?.value}>
				<Form height={300} className='reorder-form transfer-form'>
					<div className='reorder-feeds-wrapper'>
						<h3 className='margin-bottom-l'>{translate('handoverTo')}</h3>
						<label className='care-events-select'>
							<label onClick={() => getNurses('')}>
								<AsyncSelect
									loadOptions={getNurses}
									placeholder={intl.formatMessage({ id: 'selectSitterNurse' })}
									onChange={setSelectedNurse}
									getOptionValue={option => option.value}
									classNamePrefix='custom-select'
									defaultOptions={nurses}
									isClearable
									value={selectedNurse}
									formatOptionLabel={formatOptionLabel}
									components={{ SingleValue }}
									styles={generateCustomStyles({ darkMode: props.isDarkMode })}
								/>
							</label>
						</label>
					</div>
				</Form>
			</Modal>
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default injectIntl(TransferFeeds);
