import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import {
	DeviceFamilyTypes,
	ExcludedAiSettings,
	ExcludedAiSettingsHello3,
	IncludedAiSettings,
	PatientAiSetting,
	RailType,
	SensitivityType,
} from 'constants/enums.js';
import { Alert, CustomDropdown } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getPrecautions, setPrecautions } from 'api/monitoring.js';
import _ from 'lodash';
import {
	AiSetting,
	CallWorkflowType,
	SettingsCategory,
	UserSettingTypes,
	configurableAISettings,
} from 'constants/configurationEnums.js';
import { getAiSettingsConfigurations, getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import { updatePatientAiSettings } from 'api/patients.js';
import ToastMessage from 'components/ToastMessage.jsx';
import { isAnySettingActive } from 'infrastructure/helpers/aiHelper.js';
import Button from 'components/Button.jsx';
import { FallPreventionSettingTypes, FallPreventionTypes } from 'constants/ai.js';
import PrecautionsIcon from 'icons/Monitoring/PrecautionsIcon.jsx';

const initialPrecautions = [
	{
		id: '22631001',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#F3C752',
	},
	{
		id: '77272004',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#E270DA',
	},
	{
		id: '441862004',
		abbreviation: 'ISO',
		name: 'Isolation',
		textColor: '#FFFFFF',
		boxColor: '#D64F2D',
	},
	{
		id: '413322009',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#282D30',
		boxColor: '#88D9FB',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
	{
		id: '49436004',
		abbreviation: 'HP',
		name: 'Hospice',
		textColor: '#FFFFFF',
		boxColor: '#000000',
	},
	{
		id: '71388002',
		abbreviation: 'D',
		name: 'Detox',
		textColor: '#282D30',
		boxColor: '#F2A356',
	},
	{
		id: '233604007',
		abbreviation: 'PO2',
		name: 'Pulling O2',
		textColor: '#282D30',
		boxColor: '#B6D7E4',
	},
	{
		id: '35489007',
		abbreviation: 'IC',
		name: 'Impulsive/Confused',
		textColor: '#282D30',
		boxColor: '#D3D3D3',
	},
	{
		id: '43998006',
		abbreviation: 'NR',
		name: 'Non-redirectable',
		textColor: '#FFFFFF',
		boxColor: '#4BA5F8',
	},
];

const PrecautionsBox = ({
	deviceId,
	roomId,
	deviceOwnerId,
	aiSettings,
	toggleFallPrevention,
	isDefaultOwner,
	deviceFamily,
	showPrecautions,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [expandedBox, setExpandedBox] = useState(true);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const [precautions, setPrecautionsList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchPrecautions = async () => {
			setIsLoading(true);
			const response = await getPrecautions(deviceOwnerId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const newPrecautions = _.cloneDeep(initialPrecautions).map(x => {
				const precaution = response.conditions.find(p => p.code === x.id);
				if (precaution) {
					x.active = true;
				}
				return x;
			});
			setPrecautionsList(newPrecautions);
		};

		if (!isDefaultOwner && deviceOwnerId && showPrecautions) {
			fetchPrecautions();
			setIsLoading(false);
		}
	}, [deviceOwnerId, isDefaultOwner, showPrecautions]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			setIsLoading(true);
			const [adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, roomId),
			]);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			if (aiResponse.error) {
				setError(aiResponse.error);
			} else {
				setAdminAiConfigurations(aiResponse.configs);
			}
		};

		fetchRoomSettings();
		setIsLoading(false);
	}, [roomId]);

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const isAnyFallPreventionConfigEnabled = () =>
		getConfigurationValue(adminAiSettingsConfigurations[AiSetting.RAILS]) ||
		getConfigurationValue(adminAiSettingsConfigurations[AiSetting.GET_OUT_OF_BED]) ||
		getConfigurationValue(adminAiSettingsConfigurations[AiSetting.FALL_DETECTION]);

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);

		const foundInFallPrevention = FallPreventionTypes.find(item => item === settingTypeId);
		const excludedSettings = deviceFamily === DeviceFamilyTypes.HELLO_3 ? ExcludedAiSettingsHello3 : ExcludedAiSettings;
		if (!found) {
			return true;
		}
		let isAiSettingEnabled = isAnySettingActive(found.settings, ExcludedAiSettings, settingTypeId);
		const isIndependentAiActive = isAnySettingActive(found.settings, IncludedAiSettings, settingTypeId, true);
		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings
				.filter(el => !ExcludedAiSettings.includes(el.settingTypeId))
				.reduce((acc, item) => {
					return (
						acc || (!FallPreventionTypes.includes(item.settingTypeId) && item.isEnabled && item.settingTypeId !== settingTypeId)
					);
				}, false);
		}
		return (
			isAiSettingEnabled &&
			value &&
			settingTypeId !== PatientAiSetting.HAND_WASHING &&
			(!excludedSettings.includes(settingTypeId) || isIndependentAiActive)
		);
	};

	const submitFallPrevention = async status => {
		if (shouldDisableClick(PatientAiSetting.FALL_PREVENTION, status)) {
			setIsMoreThanOneAi(true);
			return;
		}
		const isSettingEnabled = type => getConfigurationValue(adminAiSettingsConfigurations[type]);
		const dataToSend = [
			{
				settingTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
				value: isSettingEnabled(AiSetting.GET_OUT_OF_BED) ? SensitivityType.MEDIUM : null,
				isEnabled: isSettingEnabled(AiSetting.GET_OUT_OF_BED) && status,
			},
			{
				settingTypeId: PatientAiSetting.RAILS,
				value: isSettingEnabled(AiSetting.RAILS) ? `${RailType.TOP_RIGHT}-${RailType.TOP_LEFT}` : null,
				isEnabled: isSettingEnabled(AiSetting.RAILS) && status,
			},
			{
				settingTypeId: PatientAiSetting.FALL_DETECTED,
				value: isSettingEnabled(AiSetting.FALL_DETECTION) ? 'true' : 'false',
				isEnabled: isSettingEnabled(AiSetting.FALL_DETECTION) && status,
			},
		];
		toggleFallPrevention(status, deviceId);
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const settings = _.cloneDeep(aiSettingList);
		const initialSettings = settings.find(item => item.deviceId === deviceId).settings;
		const filteredSettings = _.cloneDeep(initialSettings).filter(
			item => !FallPreventionSettingTypes.includes(item.settingTypeId)
		);
		setAiSettingsAction({
			settings: [...filteredSettings, ...dataToSend],
			deviceId: deviceId,
		});
		const sendData = dataToSend.map(item => ({
			isEnabled: item.isEnabled,
			settingTypeId: item.settingTypeId,
			workflowType: CallWorkflowType.MONITORING,
		}));
		const params = {
			patientId: deviceOwnerId,
			deviceId,
			roomId,
			sendData,
		};
		const response = await updatePatientAiSettings(params);
		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId: deviceId,
			});
			return;
		}
	};

	const setFeedColor = async items => {
		const precautionsInitial = [...precautions];
		const noPrecautions = [
			{
				code: '00000000',
				name: 'Stability',
				conditionType: 3,
			},
		];
		const newPrecautions = precautionsInitial.map(precaution => {
			const newPrecaution = { ...precaution };
			newPrecaution.active = !!items.find(item => item.value === precaution.id);
			return newPrecaution;
		});
		const dataToSend = newPrecautions.filter(x => x.active).map(x => ({ conditionType: 3, code: x.id }));
		const response = await setPrecautions(deviceOwnerId, {
			deviceId,
			conditions: dataToSend.length > 0 ? dataToSend : noPrecautions,
		});

		if (response.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		const fallsPrecautionsCode = '22631001';
		let fallPreventionStatus = false;
		const foundAiSettings = aiSettings.find(item => item.deviceId === deviceId);
		if (foundAiSettings) {
			fallPreventionStatus = foundAiSettings.settings.reduce((acc, item) => {
				if (FallPreventionSettingTypes.includes(item.settingTypeId)) {
					return acc || item.isEnabled;
				}
				return acc;
			}, false);
		}
		if (
			items.find(item => item.value === fallsPrecautionsCode) &&
			!fallPreventionStatus &&
			dataToSend.find(precaution => precaution.code === fallsPrecautionsCode) &&
			isAnyFallPreventionConfigEnabled()
		) {
			submitFallPrevention(true);
		}
		if (
			precautionsInitial.find(item => item.id === fallsPrecautionsCode)?.active &&
			!items.find(item => item.value === fallsPrecautionsCode)
		) {
			submitFallPrevention(false);
		}
		setPrecautionsList(newPrecautions);
	};

	const handleKeyDown = event => {
		if (event.key === 'Backspace' && !event.target.value) {
			event.preventDefault();
		}
	};

	const filteredPrecautions = precautions.filter(item => item.active);

	return (
		<div className='monitoring-timeline-box precautions-box'>
			<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
				<p className='timeline-box-title'>
					<PrecautionsIcon />
					{translate('precautions')}
				</p>
				<div className='timeline-box-actions'>
					<Button
						border='none'
						onClick={() => setExpandedBox(prevState => !prevState)}
						icon={expandedBox ? 'expand_less' : 'expand_more'}
					/>
				</div>
			</div>

			{!isLoading && showPrecautions && !isDefaultOwner && expandedBox && deviceOwnerId && (
				<>
					<div
						className={classNames('flex full-height text-align-center gap-m padding-other-top-m flex-wrap', {
							'top-15': filteredPrecautions.length > 0,
						})}>
						{filteredPrecautions.map(item => (
							<div
								className='precaution-box'
								style={{
									background: item.boxColor,
								}}>
								<span style={{ color: item.textColor }}>{item.abbreviation}</span>
							</div>
						))}
						<div className='flex-basis-100'>
							<CustomDropdown
								defaultOptions={
									filteredPrecautions?.length > 0
										? filteredPrecautions.map(item => ({
												value: item.id,
												label: item.name,
										  }))
										: []
								}
								initialOptions={initialPrecautions.map(item => ({
									value: item.id,
									label: item.name,
								}))}
								onSelect={selectedOptions => setFeedColor(selectedOptions)}
								title={intl.formatMessage({ id: 'precautions' })}
								showTitleInPlaceholder={true}
								placeholder=''
								isSearchable={true}
								handleKeyDown={handleKeyDown}
							/>
						</div>
					</div>
					<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
					<ToastMessage
						showToast={isMoreThanOneAi}
						onClose={() => setIsMoreThanOneAi(false)}
						className='video-feed-toast-message'>
						<span>{translate('noMoreThanOneAiType')}</span>
					</ToastMessage>
				</>
			)}
		</div>
	);
};

export default PrecautionsBox;
