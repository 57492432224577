import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import EmptyState from 'components/EmptyState.jsx';
import HelloItems from 'components/HelloItems.jsx';
import { getDevices } from 'api/patients.js';
import HelloList from 'views/HelloDevices/Partials/HelloList.jsx';
import translate from 'i18n-translations/translate.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import Alert from 'components/Alert.jsx';
import { DeviceFamilyTypes } from 'constants/enums.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { useSelector } from 'react-redux';

const MyHelloDevices = () => {
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const [devices, setDevices] = useState([]);
	const [selectedDevice, setSelectedDevice] = useState(null);
	const [error, setError] = useState(null);
	const history = useHistory();
	const socket = useContext(SocketContext);

	useEffect(() => {
		const updateDeviceStatus = data => {
			const devicesList = [...devices];
			const foundIndex = devices.findIndex(device => device.id === data.id);
			if (foundIndex !== -1) {
				devices[foundIndex] = data;
				setDevices(devicesList);
			}
		};
		socket.on(SocketEvents.HelloDevice.ON_UPDATE, updateDeviceStatus);
		return () => {
			socket.off(SocketEvents.HelloDevice.ON_UPDATE, updateDeviceStatus);
		};
	}, [devices, socket]);

	useEffect(() => {
		const getMyDevices = async () => {
			const response = await getDevices(getUserInfo().userId);
			if (response.error) {
				setError(response.error.message);
			} else {
				setDevices(response);
				setSelectedDevice(response[0]);
				if (response.length > 0) {
					history.push(`/my-hello-devices/${response[0].id}`);
				}
			}
		};
		getMyDevices();
	}, [history]);

	useEffect(() => {
		if (selectedDevice?.id) {
			history.push(`/my-hello-devices/${selectedDevice.id}`);
		}
	}, [selectedDevice, history]);

	const onDeviceClick = device => {
		setSelectedDevice(device);
		history.push(`/my-hello-devices/${device.id}`);
	};

	return (
		<MainLayout>
			<Grid columns='1fr 3fr' stretch='100%'>
				<aside className='patients-list'>
					{selectedDevice && devices.length > 0 && (
						<HelloList selectedDevice={selectedDevice} onDeviceClick={onDeviceClick} devices={devices} />
					)}
				</aside>
				<main>
					<div className='hello-items-container'>
						{selectedDevice && (
							<HelloItems
								deviceName={selectedDevice.name}
								deviceId={selectedDevice.id}
								isOnline={selectedDevice.status}
								isHelloDevice={selectedDevice.deviceFamily !== DeviceFamilyTypes.TABLET}
								isFromMyHelloDevices={true}
							/>
						)}
						{devices.length === 0 && (
							<div className='empty-state-wrapper'>
								<EmptyState title={translate('noHelloIsSelected', { value: helloName })} image='no-hello.svg' />
							</div>
						)}
					</div>
				</main>
			</Grid>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default MyHelloDevices;
