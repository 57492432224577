import React from 'react';
import { useSelector } from 'react-redux';
import AlertCenter from 'views/AlertCenter.jsx';
import AlertCenterMayo from 'views/AlertCenterMayo.jsx';

const AlertCenterWrapper = () => {
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	return isNewExperience ? <AlertCenterMayo /> : <AlertCenter />;
};

export default AlertCenterWrapper;
