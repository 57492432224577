

const PatientPrivacy = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeWidth='1.25'
			d='M14 8a2 2 0 11-4 0 2 2 0 014 0zM7 17.15v-2.526c0-.81.488-1.542 1.254-1.804C9.33 12.452 10.88 12 12 12c.733 0 1.652.194 2.5.431'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M19.021 12.679c.886-3.452.979-7.107.979-7.107L12 3 4 5.572S4.163 12 6.122 15.857C8.082 19.715 12 21 12 21s.729-.239 1.714-.82'
		/>
		<path stroke='currentColor' strokeWidth='1.25' d='M21 16.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z' />
		<path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.25' d='M15 16.7l1 1.05 2-2.25' />
	</svg>
);

export default PatientPrivacy;
