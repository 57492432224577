import React, { useEffect, useState } from 'react';
import useHasFocus from 'components/UseHasFocus.jsx';
import { AccessTypes } from 'constants/enums.js';

const IncomingCallBrowserNotification = ({ callerInfo }) => {
	const [currentCallNotification, setCurrentCallNotification] = useState(null);
	const tabHasFocus = useHasFocus();

	useEffect(() => {
		const showNotification = () => {
			if (Notification?.permission === AccessTypes.GRANTED) {
				const notification = new Notification('Incoming Call', {
					body: `Incoming call from room: ${callerInfo?.name}`,
				});

				setCurrentCallNotification(callerInfo);

				notification.onclick = () => {
					window.focus();
					notification.close();
				};
				return;
			}

			if (Notification?.permission !== AccessTypes.DENIED) {
				Notification?.requestPermission().then(permission => {
					if (permission === AccessTypes.GRANTED) {
						showNotification();
						setCurrentCallNotification(callerInfo);
					}
				});
			}
		};

		if (typeof Notification !== "undefined" && Notification && callerInfo && !tabHasFocus && callerInfo !== currentCallNotification) {
			showNotification();
		}
	}, [callerInfo, currentCallNotification, tabHasFocus]);

	return <></>;
};

export default IncomingCallBrowserNotification;
