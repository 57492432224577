import { getHourlySessionsTimeline } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import VerticalBarChart from 'components/Common/Charts/VerticalBarChart.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import { buildDataPerHours, byHourChartLabels, byHourChartOptions, sessionByHours } from 'constants/dashboard.js';
import translate from 'i18n-translations/translate.jsx';
import Average from 'icons/Dashboard/Average.jsx';
import Highest from 'icons/Dashboard/Highest.jsx';
import { timezoneToUTCTimestamp } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const ChartWrapper = styled.div`
	> p {
		font-size: 12px;
		margin: 0;
		padding: 0;
		margin-top: var(--spacing-xl);
		text-align: left;
	}
	> div {
		&:first-of-type {
			margin-top: var(--spacing-xl);
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: var(--spacing-l);

			> div {
				display: flex;
				align-items: center;

				> div {
					margin-left: var(--spacing-l);

					p {
						margin: 0;
						padding: 0;
						text-align: left;
						font-size: 12px;
						color: var(--gray-7);
					}

					h3 {
						font-size: 18px;
					}
				}
			}
		}
	}
`;

const VisitsByHour = props => {
	const intl = useIntl();
	const chartRef = useRef(null);
	const translator = useCallback(id => intl.formatMessage({ id }), [intl]);
	const initialDatasets = useMemo(() => sessionByHours(intl), [intl]);
	const [chartDatasets, setChartDatasets] = useState(initialDatasets);
	const [chartSummary, setChartSummary] = useState({ total: 0, average: 0, max: 0, maxPosition: 0 });
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const controller = useRef(null);

	const fetchData = useCallback(
		debounce(async (params, signal) => {
			setIsLoading(true);
			const response = await getHourlySessionsTimeline(params, signal);

			if (!response.error) {
				handleHourlyTimelineResponse(response.hourlyTimeline);
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(
					translate('somethingWentWrongSpecific', {
						value: translator('sessionsByHour'),
					})
				);
			}
			setIsLoading(false);
		}, 500),
		[props.selectedTimezone]
	);

	useEffect(() => {
		const params = {
			start: timezoneToUTCTimestamp(props.start, props.selectedTimezone.zone),
			end: timezoneToUTCTimestamp(props.end, props.selectedTimezone.zone),
			healthSystemId: props.selectedHealthSystem,
			regionId: props.selectedHospital?.regionId,
			hospitalId: props.selectedHospital?.value,
			departmentId: props.selectedDepartment?.value,
			floorId: props.selectedFloor?.value,
			roomId: props.selectedRoom?.value,
			...props.additionalFilters,
		};

		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;
		fetchData(params, signal);
	}, [
		props.end,
		props.selectedHealthSystem,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedHospital,
		props.selectedRoom,
		props.start,
		props.selectedTimezone,
		fetchData,
		props.currentRole,
		props.selectedProvider,
		props.additionalFilters,
	]);

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	const handleHourlyTimelineResponse = responseData => {
		const modifiedDatasets = initialDatasets.map(dataset => {
			const relevantCalls = responseData.filter(call => dataset.callTypes.includes(call.callType));
			const timezoneOffset = moment.tz(props.selectedTimezone.zone).utcOffset() / 60;
			const timezoneShiftedCalls = relevantCalls.map(item => ({
				...item,
				hour: item.hour + timezoneOffset < 0 ? 24 - (item.hour + timezoneOffset) : item.hour + timezoneOffset,
			}));
			const modifiedDataSet = {
				...dataset,
				data: buildDataPerHours(timezoneShiftedCalls),
				total: relevantCalls.reduce((acc, curr) => (acc += curr.count), 0),
			};
			return modifiedDataSet;
		});

		const sortedTotals = modifiedDatasets
			.map(dataset => ({ label: dataset.label, total: dataset.total }))
			.sort((a, b) => a.total - b.total);
		setChartDatasets(
			modifiedDatasets.map(dataset => {
				const datasetOrder = sortedTotals.indexOf(sortedTotals.find(item => item.label === dataset.label));
				return { ...dataset, order: datasetOrder };
			})
		);

		const hourlySessions = Array.from({ length: 24 }).map((_, hour) =>
			modifiedDatasets.reduce((acc, curr) => acc + curr.data[hour], 0)
		);
		handleChartSummary(hourlySessions);
	};

	const updateChartSummary = () => {
		const chartInstance = chartRef.current.chart;
		let allVisibleSessions = Array.from({ length: 24 }).fill(0);
		chartDatasets.forEach((item, index) => {
			const meta = chartInstance.getDatasetMeta(index);
			if (!meta.hidden) {
				allVisibleSessions = item.data.reduce((acc, currValue, currIndex) => {
					acc[currIndex] += currValue;
					return acc;
				}, allVisibleSessions);
			}
		});
		handleChartSummary(allVisibleSessions);
	};

	const handleChartSummary = hourlySessions => {
		const total = hourlySessions.reduce((acc, curr) => (acc += curr), 0);
		const average = Math.round(total / hourlySessions.length);
		const max = Math.max(...hourlySessions);
		const maxPosition = hourlySessions.indexOf(max);
		setChartSummary({ total, max, average, maxPosition });
	};

	const toggleDataset = (datasetIndex, target) => {
		const chartInstance = chartRef.current.chart;
		const meta = chartInstance.getDatasetMeta(datasetIndex);
		const hidden = meta.hidden === null ? !chartInstance.data.datasets[datasetIndex].hidden : null;
		meta.hidden = hidden;
		chartInstance.update();
		if (target) {
			target.style.opacity = hidden ? 0.3 : 1;
		}
		updateChartSummary();
	};

	return (
		<>
			<ChartWrapper>
				<h3 className='bottom-5'>{translate('sessionsByHour')}</h3>
				<span className='text-align-left'>UTC{moment.tz(props.selectedTimezone.zone).format('Z')}</span>
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='300px' horizAlign='center' vertAlign='center'>
						<Loader />
					</Grid>
				)}
				<>
					{!isLoading && (
						<div>
							<div>
								<Average />
								<div>
									<p>{translator('totalSessions')}</p>
									<h3>
										{chartSummary.total > 0
											? `${chartSummary.total.toLocaleString()} ${translator('sessions')}`
											: translator('noData')}
									</h3>
								</div>
							</div>
							{chartSummary.total > 0 && (
								<div>
									<Average />
									<div>
										<p>{translator('avgSessions')}</p>
										<h3>{`${chartSummary.average} ${translator('sessions')}`}</h3>
									</div>
								</div>
							)}
							{chartSummary.max > 0 && (
								<div>
									<Highest />
									<div>
										<p>{translator('highestVolumeOfSessions')}</p>
										<h3>
											{`${chartSummary.max} ${translator('sessions')} ${translator('from')} ${
												byHourChartLabels[chartSummary.maxPosition]
											} ${translator('to')} ${byHourChartLabels[chartSummary.maxPosition + 1] || byHourChartLabels[0]}`}
										</h3>
									</div>
								</div>
							)}
						</div>
					)}

					{useMemo(
						() =>
							!isLoading && (
								<div className='top-30'>
									<VerticalBarChart
										chartRef={chartRef}
										labels={byHourChartLabels}
										datasets={chartDatasets}
										options={byHourChartOptions(props.xLabelScales, translator)}
										height='350px'
									/>
								</div>
							),
						[chartDatasets, isLoading, props.xLabelScales, translator]
					)}
					{!isLoading && (
						<div className='dashboard-items-legend'>
							{chartDatasets.map((item, index) => (
								<div key={index} onClick={e => toggleDataset(index, e.target)}>
									<span className='legend-marker' style={{ background: item.borderColor }}></span>
									<span>
										<label>{item.label}</label>
										<span>{item.total.toLocaleString()}</span>
									</span>
								</div>
							))}
						</div>
					)}
				</>
			</ChartWrapper>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default VisitsByHour;
