import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import { allPatientAiSettings, formatAiConfigLog } from 'infrastructure/helpers/aiHelper.js';
import { getDateWithSeconds } from 'infrastructure/helpers/dateHelper.js';
import { getPatientAiSettingsHistory } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';

const PatientAiSettingsHistory = ({ adminAiSettingsConfigurations, roomId, deviceId, patientId, workflowTypeId }) => {
	const intl = useIntl();
	const isDarkMode = useSelector(state => state.user.darkMode);
	const hasReachedEnd = useRef(null);
	const socket = useContext(SocketContext);
	const [aiSettingsHistory, setAiSettingsHistory] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isAiSettingUpdated, setIsAiSettingUpdated] = useState(false);

	useEffect(() => {
		const fetchPatientAiSettingsHistory = async () => {
			const response = await getPatientAiSettingsHistory({
				roomId,
				deviceId,
				patientId,
				workflowTypeId,
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
			});
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}

			setAiSettingsHistory(prevState =>
				pagination.pageIndex === 0 ? response.editHistory : [...prevState, ...response.editHistory]
			);

			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
			hasReachedEnd.current = response?.editHistory?.length < pagination.pageSize;
		};

		fetchPatientAiSettingsHistory();
	}, [deviceId, patientId, roomId, workflowTypeId, pagination.pageIndex, pagination.pageSize, isAiSettingUpdated]);

	useEffect(() => {
		const onPatientAiSettingsUpdated = data => {
			if (deviceId !== data.deviceId || data.workflowType !== workflowTypeId) {
				return;
			}
			setIsAiSettingUpdated(prevState => !prevState);
		};
		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		};
	}, [socket, deviceId, workflowTypeId]);

	const getAiType = id =>
		allPatientAiSettings({ adminAiSettingsConfigurations, isDarkMode, intl }).find(el => el.aiTypeId === id);

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
		}
	};

	return (
		<div className='box-max-height' onScroll={handleScroll}>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && aiSettingsHistory.length === 0 && (
				<div className='patient-ai-history-no-data'>
					<p className='font-14'>{translate('noData')}</p>
				</div>
			)}
			{aiSettingsHistory.map((item, index) => (
				<div key={`${item.settingType}${index}`} className='interventions-history'>
					<div className='flex ai-settings-history'>
						<div>{getAiType(item.settingType)?.img}</div>
						<div>
							<p className='margin-left-m'>{formatAiConfigLog(item, getAiType(item.settingType)?.label, intl)}</p>
							<span className='ai-settings-history-time no-padding margin-left-m'>{getDateWithSeconds(item.editedAt)}</span>
						</div>
					</div>
				</div>
			))}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default PatientAiSettingsHistory;
