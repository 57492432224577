const ControlsActions = {
	TOGGLE_CAMERA_CONTROLS: 'TOGGLE_CAMERA_CONTROLS',
	TOGGLE_LIVE_EXAMINATIONS: 'TOGGLE_LIVE_EXAMINATIONS',
	TOGGLE_PARTICIPANTS_VIEW: 'TOGGLE_PARTICIPANTS_VIEW',
	SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS: 'SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS',
	MAIN_PARTICIPANT_SCREEN_CAPTURE: 'MAIN_PARTICIPANT_SCREEN_CAPTURE',
	TOGGLE_GRID_VIEW: 'TOGGLE_GRID_VIEW',
	TOGGLE_FIT_SCREEN: 'TOGGLE_FIT_SCREEN',
	TOGGLE_INVITE_PARTICIPANTS_MODAL: 'TOGGLE_INVITE_PARTICIPANTS_MODAL',
	TOGGLE_MEDIA_PERMISSION_POPUP: 'TOGGLE_MEDIA_PERMISSION_POPUP',
	TOGGLE_HEALTH_MEASUREMENTS: 'isHealthMeasurementsVisible',
	TOGGLE_CAMERA_MEASUREMENTS: 'isCameraMeasurementsVisible',
	TOGGLE_CONVERSATION_MODAL: 'isConversationModalVisible',
	TOGGLE_DIAGNOSES: 'isDiagnosesVisible',
	TOGGLE_PROCEDURES: 'isProceduresVisible',
	TOGGLE_NOTES: 'isNotesVisible',
	TOGGLE_CARE_EVENTS: 'isCareEventsFormVisible',
	TOGGLE_PRESCRIPTIONS: 'isPrescriptionsVisible',
	TOGGLE_PHYSICAL_EXERCISES: 'isPhysicalExercisesVisible',
	TOGGLE_MEDICAL_FORMS: 'isMedicalFormsVisible',
	TOGGLE_AI_OBJECT_DETECTION: 'isObjectDetectionOn',
	TOGGLE_AI_BABY_DETECTION: 'isBabyDetectionOn',
	TOGGLE_AI_RAILS_DETECTION: 'isRailsDetectionOn',
	TOGGLE_AI_SKELETON_DETECTION: 'isSkeletonDetectionOn',
	TOGGLE_AI_PERSON_DETECTION: 'isPersonDetectionOn',
	TOGGLE_AI_ALERT: 'isAiAlertOn',
	TOGGLE_PRIVATE_MODE: 'isPrivateModeOn',
	TOGGLE_MULTI_SKELETON_DETECTION: 'isMultiSkeletonDetectionOn',
	TOGGLE_TRANSFER_TO_DOCTOR_MODAL: 'TOGGLE_TRANSFER_TO_DOCTOR_MODAL',
	TOGGLE_CEC_SUPPORTED_MODAL: 'TOGGLE_CEC_SUPPORTED_MODAL',
	TOGGLE_SELECT_BACKGROUND_MODAL: 'TOGGLE_SELECT_BACKGROUND_MODAL',
	TOGGLE_STREAM_SETTINGS_MODAL: 'TOGGLE_STREAM_SETTINGS_MODAL',
	TOGGLE_MINIMIZED_VIEW: 'TOGGLE_MINIMIZED_VIEW',
	TOGGLE_EMBEDDED_VIEW: 'TOGGLE_EMBEDDED_VIEW',
	TOGGLE_PATIENT_HISTORY: 'TOGGLE_PATIENT_HISTORY',
	IS_RIGHT_TO_LEFT: 'IS_RIGHT_TO_LEFT',
	IS_DARK_MODE: 'IS_DARK_MODE',
	SET_ROUNDING_CONFIGURATIONS: 'SET_ROUNDING_CONFIGURATIONS',
	TOGGLE_TELEMETRY: 'TOGGLE_TELEMETRY',
	SET_HELLO_NAME: 'SET_HELLO_NAME',
	IS_MULTI_BED: 'IS_MULTI_BED',
	TOGGLE_MORE_PARTICIPANTS: 'TOGGLE_MORE_PARTICIPANTS',
	TOGGLE_WHITEBOARD: 'TOGGLE_WHITEBOARD',
	TOGGLE_LIVE_CAPTIONS: 'TOGGLE_LIVE_CAPTIONS',
	TOGGLE_AI: 'TOGGLE_AI',
	TOGGLE_ROOM_SIGN: 'TOGGLE_ROOM_SIGN',
};

export default ControlsActions;
