import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Dropdown from 'components/Dropdown.jsx';
import RebootLogExport from 'components/RebootLogsExport.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles } from 'calls/enums/index.js';
import PairUnpairDeviceRemote from 'containers/PairUnpairDeviceRemote.jsx';
import { sendCommand } from 'api/devices.js';
import { DeviceCommands } from 'constants/enums.js';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import { findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Alert.jsx';

const FleetManagementDeviceActions = props => {
	const intl = useIntl();
	const userRole = getUserRole();
	const [selectedDevice, setSelectedDevice] = useState({
		serialNumber: '',
		deviceId: '',
		pairedRemote: null,
	});
	const [isDeviceRemoteModalOpen, setIsDeviceRemoteModalOpen] = useState(false);
	const [isGetLogsOpen, setIsGetLogsOpen] = useState(false);
	const [isRebootDeviceOpen, setIsRebootDeviceOpen] = useState(false);
	const [isResetDeviceOpen, setIsResetDeviceOpen] = useState(false);
	const [isClearDataOpen, setIsClearDataOpen] = useState(false);
	const [commandReason, setCommandReason] = useState('');
	const [pullLogsText, setPullLogsText] = useState(null);
	const [error, setError] = useState(null);
	const treeData = useSelector(state => state.healthSystems.treeData.tree);

	const handleSendCommand = async ({ command, dynamicData = null }) => {
		const params = {
			deviceId: props.device.solHelloDeviceId,
			reason: commandReason,
			command,
			dynamicData,
		};
		const response = await sendCommand(params);
		if (response.error) {
			setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
			return;
		}
		setCommandReason('');
		setIsRebootDeviceOpen(false);
		setIsResetDeviceOpen(false);
		setIsClearDataOpen(false);
		setError(null);
	};

	const toggleDeviceRemotePairModal = (device = { serialNumber: '', deviceId: '', pairedRemote: '' }) => {
		setIsDeviceRemoteModalOpen(prevState => !prevState);
		setSelectedDevice(device);
	};

	const sendPullLogsCommand = async () => {
		const params = {
			deviceId: props.device.solHelloDeviceId,
			command: DeviceCommands.PULL_LOGS,
			dynamicData: 'logcat',
		};
		const response = await sendCommand(params);
		if (response.error) {
			setPullLogsText(translate('modalLogsWentWrong'));
			return;
		}
		setPullLogsText(translate('modalLogsSuccess'));
	};

	const handlePullLogs = () => {
		setIsGetLogsOpen(true);
		const isDeviceOnline = props.onlineDevices.includes(props.device.solHelloDeviceId);
		if (!isDeviceOnline) {
			setPullLogsText(translate('modalLogsFailure'));
			return;
		}
		sendPullLogsCommand();
	};

	const onModalClose = () => {
		setError(null);
		setIsGetLogsOpen(false);
		setIsRebootDeviceOpen(false);
		setIsResetDeviceOpen(false);
		setIsClearDataOpen(false);
		setCommandReason('');
	};

	return (
		<>
			<Dropdown position='bottom' icon='more_horiz' className='device-options-dropdown'>
				<div className='dropdown__items device-options-wrapper'>
					<ul className='list-group'>
						{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) && (
							<>
								<div className='device-option-details' onClick={() => setIsRebootDeviceOpen(true)}>
									<i
										className='material-icons-outlined boxed-icon device-icon'
										id={props.device.solHelloDeviceId}
										data-position='top'>
										refresh
									</i>
									<span>{intl.formatMessage({ id: 'rebootDevice' })}</span>
								</div>
								<RebootLogExport solHelloDeviceId={props.device.solHelloDeviceId} serialNumber={props.device.serialNumber} />
								<div className='device-option-details' onClick={handlePullLogs}>
									<i
										className='material-icons-outlined boxed-icon device-icon'
										data-cy='getDeviceLogsBtn'
										id={props.deviceId}
										data-position='top'>
										assessment
									</i>
									<span>{intl.formatMessage({ id: 'modalLogsButton' })}</span>
								</div>
								{props.isHelloDevice && (
									<div
										className='device-option-details'
										onClick={() =>
											toggleDeviceRemotePairModal({
												deviceId: props.device.solHelloDeviceId,
												serialNumber: props.device.serialNumber,
												pairedRemote: props.device.pairedRemote,
											})
										}>
										<i
											className='material-icons-outlined boxed-icon device-icon'
											data-cy='remoteBtn'
											id={props.device.solHelloDeviceId}
											data-position='top'>
											settings_remote
										</i>
										<span>{intl.formatMessage({ id: 'remoteControl' })}</span>
									</div>
								)}
								<div className='device-option-details' onClick={() => setIsClearDataOpen(true)}>
									<i
										className='material-icons-outlined boxed-icon device-icon'
										data-cy='remoteBtn'
										id={props.device.solHelloDeviceId}
										data-position='top'>
										delete
									</i>
									<span>{intl.formatMessage({ id: 'clearData' })}</span>
								</div>
								<div className='device-option-details' onClick={() => setIsResetDeviceOpen(true)}>
									<i
										className='material-icons-outlined boxed-icon device-icon'
										data-cy='remoteBtn'
										id={props.device.solHelloDeviceId}
										data-position='top'>
										restart_alt
									</i>
									<span>{intl.formatMessage({ id: 'factoryReset' })}</span>
								</div>
							</>
						)}
					</ul>
				</div>
			</Dropdown>
			{isDeviceRemoteModalOpen && (
				<PairUnpairDeviceRemote
					room={findSectorById(treeData, props.device.solHelloDeviceId)}
					device={selectedDevice}
					onModalClose={toggleDeviceRemotePairModal}
				/>
			)}
			{isGetLogsOpen && (
				<Modal
					className='centered-modal'
					display={isGetLogsOpen}
					position='center'
					hideActionButtons={true}
					onModalClose={onModalClose}>
					<form>
						<h3>{translate('modalLogsTitle')}</h3>
						{pullLogsText}
					</form>
				</Modal>
			)}
			<Modal
				display={isRebootDeviceOpen}
				position='center'
				primaryButtonLabel={translate('reboot')}
				onModalSubmit={() => handleSendCommand({ command: DeviceCommands.REBOOT })}
				onModalClose={onModalClose}>
				<Form title={translate('rebootDevice')} onSubmit={event => event.preventDefault()}>
					<p>
						{translate('rebootDeviceQuestion')} {props.device.serialNumber}
					</p>
					<Input
						type='text'
						value={commandReason}
						validationOptions={{ maxLength: 100 }}
						placeholder={intl.formatMessage({ id: 'rebootDeviceReason' })}
						onChange={e => setCommandReason(e.target.value)}
					/>
				</Form>
			</Modal>
			<Modal
				display={isResetDeviceOpen}
				position='center'
				primaryButtonLabel={translate('reset')}
				onModalSubmit={() => handleSendCommand({ command: DeviceCommands.FACTORY_RESET })}
				onModalClose={onModalClose}>
				<Form title={translate('factoryReset')} onSubmit={event => event.preventDefault()}>
					<p>
						{translate('resetDeviceUndone')} {translate('serialNumber')} {props.device.serialNumber}
					</p>
					<Input
						type='text'
						value={commandReason}
						validationOptions={{ maxLength: 100 }}
						placeholder={intl.formatMessage({ id: 'resetDeviceReason' })}
						onChange={e => setCommandReason(e.target.value)}
					/>
				</Form>
			</Modal>
			<Modal
				display={isClearDataOpen}
				position='center'
				primaryButtonLabel={translate('clear')}
				onModalSubmit={() => handleSendCommand({ command: DeviceCommands.CLEAR_DATA })}
				onModalClose={onModalClose}>
				<Form title={translate('clearData')} onSubmit={event => event.preventDefault()}>
					<p>
						{translate('clearDataQuestion')} {props.device.serialNumber}
					</p>
					<Input
						type='text'
						value={commandReason}
						validationOptions={{ maxLength: 100 }}
						placeholder={intl.formatMessage({ id: 'clearDataReason' })}
						onChange={e => setCommandReason(e.target.value)}
					/>
				</Form>
			</Modal>
			<Alert fixed={true} display={error} variant='dark' message={error} onClose={() => setError(null)} />
		</>
	);
};
export default FleetManagementDeviceActions;
