import React, { useMemo, useState } from 'react';
import { VitalSignsList } from 'constants/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import LineChartComponent from 'components/Common/Charts/LineChart.jsx';
import { buildDataPerHours, byHourChartLabels, byHourChartOptions } from 'constants/dashboard.js';
import { averagesByHour } from 'constants/overview.js';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Button from 'components/Button.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { MeasureDeviceType } from 'constants/enums.js';
import { rpmMeasurements } from 'constants/rpm.js';
import { getRecentMetricsColor } from 'infrastructure/helpers/measurementsHelper.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';

const RecentMetrics = ({ goToRecentMetric = () => null, averages, measurements, isFromOverview = false }) => {
	const intl = useIntl();
	const initialDatasets = useMemo(() => averagesByHour(intl), []);
	const [chartData, setChartData] = useState({ datasets: initialDatasets });
	const [show, setShow] = useState(false);
	const titles = ['measurement', 'device', 'dateAndTime'];
	const displayedMeasurements = isFromOverview ? measurements.slice(0, 5) : measurements;

	const setHourlyTimelineResponse = (data, type) => {
		const modifiedData = initialDatasets.map(dataset => {
			if (dataset.measurmentType) {
				const relevantCalls = data.filter(measurement => dataset.measurmentType === measurement.measurementType);

				const modifiedDataSet = {
					...dataset,
					data: buildDataPerHours(relevantCalls),
				};
				return modifiedDataSet;
			}
			return dataset;
		});

		const filtered = modifiedData.filter(data => data?.measurmentType === type);
		setChartData({ datasets: filtered });
	};

	return useMemo(
		() => (
			<div className={classNames({ 'recent-metrics-container': !isFromOverview })}>
				<div className={classNames('recent-metrics-wrapper', 'flex-item', { 'recent-metrics': !isFromOverview })}>
					<div className='flex flex-space-between margin-bottom-l'>
						<span>{translate('recentMetrics')}</span>
						{isFromOverview && (
							<Button
								variant='white'
								className='flex-align-center'
								onClick={() => goToRecentMetric()}
								text={translate('showAll')}
							/>
						)}
					</div>
					<div className='flex flex-space-between '>
						{titles.map(title => (
							<h5>{translate(title)}</h5>
						))}
					</div>
					{displayedMeasurements.map(measurement => {
						const selectedMeasurement = chartData.datasets[0].measurmentType.includes(measurement.measurementType);

						return (
							<div
								key={measurement.id}
								className={classNames({ 'clicked-item': selectedMeasurement && show })}
								onClick={() => {
									setHourlyTimelineResponse(averages.data, measurement.measurementType);
									setShow(prevState => !prevState);
								}}>
								<div className='details'>
									<div className='flex flex-align-center gap-s justify-content-start'>
										<div className={classNames('device-container', getRecentMetricsColor(measurement.measurementType))}>
											<img src={VitalSignsList.find(item => item.type === measurement.measurementType)?.icon} alt='ico' />
										</div>
										<span>{translate(measurement.measurementType)}</span>
									</div>
									<div className='flex gap-s justify-content-start'>
										<div>
											{measurement.device === MeasureDeviceType.VITAL_KIT && (
												<img src={`${healthCareCdnUrl}measurements-icons/vital-kit-active.svg`} alt='ico' />
											)}
											{measurement.device !== MeasureDeviceType.VITAL_KIT && (
												<img src={rpmMeasurements.find(item => item.type === measurement.measurementType)?.activeImg} alt='ico' />
											)}
										</div>
										<span className='device'>{translate(measurement.device)}</span>
									</div>
									<span>{formattedDate(measurement.date)}</span>
								</div>
								{show && selectedMeasurement && (
									<LineChartComponent
										labels={byHourChartLabels}
										datasets={chartData?.datasets}
										options={byHourChartOptions(chartData.datasets, 1)}
										height='280px'
									/>
								)}
							</div>
						);
					})}
				</div>
			</div>
		),
		[averages.data, chartData, measurements, show]
	);
};

export default RecentMetrics;
