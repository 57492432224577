import React from 'react';
import { getConversationAttachments, getConversationByType, deleteMessage } from 'api/messenger.js';
import { convertUTCDateToLocalDateOrTime } from 'infrastructure/helpers/dateHelper.js';
import { getUserInfo } from 'infrastructure/auth.js';
import {
	isDocumentAttachment,
	isPictureAttachment,
	isVideoAttachment,
	getFirstName,
	decodeHtml,
} from 'infrastructure/helpers/commonHelpers.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import MediaViewer from 'views/Partials/MediaViewer.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx'; 
import { ConversationType } from 'constants/enums.js';

export default class Files extends React.Component {
	filesElement;

	state = {
		attachments: [],
		showDeletePrompt: false,
		selectedMessageId: null,
		isLoading: true,
		conversationId: null,
		reachedEnd: false,
		isLoadingMore: false,
		selectedAttachment: {},
	};

	constructor(props) {
		super(props);
		this.filesElement = React.createRef();
	}

	componentDidMount() {
		this.context.on(SocketEvents.Conversation.ON_NEW_MESSAGE, this.handleNewMessage);
		this.context.on(SocketEvents.Conversation.ON_MESSAGE_DELETED, this.handleOnMessageDeleted);
		this.loadAttachmentsInit();
	}

	componentDidUpdate(prevProps) {
		const { id } = this.props.selectedUser.owner;
		const prevID = prevProps.selectedUser.owner.id;
		if (prevID !== id) {
			this.loadAttachmentsInit();
		}
	}

	componentWillUnmount = () => {
		this.context.off(SocketEvents.Conversation.ON_NEW_MESSAGE, this.handleNewMessage);
		this.context.off(SocketEvents.Conversation.ON_MESSAGE_DELETED, this.handleOnMessageDeleted);
	};

	handleNewMessage = data => {
		const { message } = data;
		if (message.attachments.length > 0) {
			this.setState(prevState => {
				const attachments = [...prevState.attachments];
				const newAttachment = message.attachments[0];
				newAttachment.sender = message.sender;
				newAttachment.messageId = message.id;
				attachments.unshift(newAttachment);
				return { attachments };
			});
		}
	};

	handleScroll = () => {
		const element = this.filesElement.current;
		if (element.offsetHeight + element.scrollTop === element.scrollHeight) {
			this.loadMoreAttachments();
		}
	};

	handleOnMessageDeleted = data => {
		if (this.state.conversationId !== data.conversationId) {
			return;
		}
		this.setState(prevState => {
			const attachments = [...prevState.attachments];
			const foundIndex = attachments.findIndex(item => item.messageId === data.messageId);
			if (foundIndex !== -1) {
				attachments.splice(foundIndex, 1);
			}
			return {
				selectedMessageId: null,
				attachments,
				showDeletePrompt: false,
			};
		});
	};

	handleDeleteFile = () => {
		if (this.state.selectedMessageId) {
			deleteMessage(this.state.selectedMessageId, false);
		}
	};

	handleDialogClose = () => {
		this.setState({ showDeletePrompt: false });
	};

	handleMediaViewerOpen = attachment => {
		this.setState({ selectedAttachment: attachment, showMediaViewer: true });
	};

	handleMediaViewerClose = () => {
		this.setState({ showMediaViewer: false });
	};

	getSenderName(senderId) {
		return getUserInfo().userId === +senderId
			? `${getUserInfo().firstName} ${getUserInfo().lastName}`
			: `${this.props.selectedUser.owner.fullName}`;
	}

	toggleDeletePrompt = messageId => {
		this.setState(prevState => ({
			selectedMessageId: messageId,
			showDeletePrompt: !prevState.showDeletePrompt,
		}));
	};

	async loadAttachmentsInit() {
		const response = await getConversationByType(ConversationType.INDIVIDUAL, this.props.selectedUser.owner.id, 0, false);
		this.setState({ conversationId: response.conversation?.id }, () => {
			getConversationAttachments(this.state.conversationId, {
				getDocuments: true,
				getMedia: true,
				latest: null,
				limit: 20,
			}).then(res => {
				this.setState({
					attachments: res.attachments,
					isLoading: false,
					reachedEnd: res.attachments.length === 0,
				});
			});
		});
	}

	async loadMoreAttachments() {
		if (this.state.reachedEnd) {
			return;
		}
		this.setState({ isLoadingMore: true });
		const { attachments } = await getConversationAttachments(this.state.conversationId, {
			getDocuments: true,
			getMedia: true,
			latest: this.getLatestDate(),
			limit: 20,
		});
		this.setState(prevState => ({
			attachments: prevState.attachments.concat(attachments),
			isLoading: false,
			reachedEnd: attachments.length === 0,
			isLoadingMore: false,
		}));
	}

	getLatestDate = () => {
		if (this.state.attachments.length === 0) {
			return null;
		}
		const dates = this.state.attachments.map(item => item.dateCreated);
		return Math.min(...dates);
	};

	setSelectedAttachment = attachment => {
		this.setState({ selectedAttachment: attachment });
	};

	render() {
		return (
			<>
				{this.state.isLoading && (
					<div className='full-width flex flex-justify-center flex-align-center'>
						<span className='loader gif-loading'>
							<Loader />
						</span>
					</div>
				)}
				{!this.state.isLoading && this.state.attachments.length === 0 && (
					<div className='no-files'>
						<div className='empty-state-wrapper-percent'>
							<EmptyState
								title={translate('noFilesFound', {
									value:
										decodeHtml(this.props.selectedUser.owner.firstName) ||
										getFirstName(decodeHtml(this.props.selectedUser.owner.fullName)),
								})}
								image='no-files.svg'
							/>
						</div>
					</div>
				)}
				{!this.state.isLoading && this.state.attachments.length > 0 && (
					<div className='shared-files'>
						<div className='tablewrap' ref={this.filesElement} onScroll={this.handleScroll}>
							<table>
								<thead>
									<tr>
										<th>{translate('type')}</th>
										<th>{translate('name')}</th>
										<th>{translate('dateTime')}</th>
										<th>{translate('sentBy')}</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{this.state.attachments.map(attachment => {
										return (
											<tr key={attachment.messageId}>
												{isDocumentAttachment(attachment.typeId) && (
													<td>
														<img src='https://static.solaborate.com/homecare-system/shared-files/file.svg' alt='file-icon' />
													</td>
												)}
												{isPictureAttachment(attachment.typeId) && (
													<td>
														<img src='https://static.solaborate.com/homecare-system/shared-files/image.svg' alt='img-icon' />
													</td>
												)}
												{isVideoAttachment(attachment.typeId) && (
													<td>
														<img src='https://static.solaborate.com/homecare-system/shared-files/video.svg' alt='video-icon' />
													</td>
												)}
												{!isDocumentAttachment(attachment.typeId) && (
													<td>
														<span className='cursor-pointer' onClick={() => this.handleMediaViewerOpen(attachment)}>
															{attachment.originalName}
														</span>
													</td>
												)}
												{isDocumentAttachment(attachment.typeId) && (
													<td>
														<a href={attachment.url}>{attachment.originalName}</a>
													</td>
												)}
												<td>{convertUTCDateToLocalDateOrTime(attachment.dateCreated)}</td>
												<td>{this.getSenderName(attachment.sender.objectId)}</td>
												<td>
													<span onClick={() => this.toggleDeletePrompt(attachment.messageId)}>
														<img
															className='delete-file'
															data-cy='deleteAttachment'
															src='https://static.solaborate.com/homecare-system/shared-files/delete.svg'
															alt='delete-icon'
														/>
													</span>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>

						{this.state.isLoadingMore && <span className='circle-loader' />}
						{Object.keys(this.state.selectedAttachment).length > 0 && (
							<MediaViewer
								display={this.state.showMediaViewer}
								onModalClose={this.handleMediaViewerClose}
								attachments={this.state.attachments.filter(item => !isDocumentAttachment(item.typeId))}
								selectedAttachment={this.state.selectedAttachment}
								selectedUser={this.props.selectedUser}
								conversationId={this.state.conversationId}
								setSelectedAttachment={this.setSelectedAttachment}
							/>
						)}
						<Modal
							modalSelector='deleteAttachmentModal'
							display={this.state.showDeletePrompt}
							position='center'
							isLoading={false}
							onModalClose={this.handleDialogClose}
							onModalSubmit={this.handleDeleteFile}
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
							primaryButtonLabel={translate('delete')}>
							<Form title={translate('deleteFileForMyself')} height={225}>
								<p className='modal-paragraph'>{translate('deleteFileWarning')}</p>
								<p className='modal-paragraph'>{translate('actionUndone')}</p>
							</Form>
						</Modal>
					</div>
				)}
			</>
		);
	}
}

Files.contextType = SocketContext;
