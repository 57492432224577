import { combineReducers } from 'redux';
import { healthSystems } from 'state/healthSystems/reducer.js';
import { myPatients } from 'state/patients/reducer.js';
import { notifications } from 'state/notifications/reducer.js';
import { myDoctors } from 'state/doctors/reducer.js';
import { user } from 'state/user/reducer.js';
import { company } from 'state/company/reducer.js';
import { calls } from 'state/calls/reducer.js';
import { aiSettingsList } from 'state/aiSettings/reducer.js';
import { configurations } from 'state/configurations/reducer.js';
import { language } from 'state/language/reducer.js';
import { watchMeasurementsList } from 'state/healthData/reducer.js';
import { devices } from 'state/devices/reducer.js';
import { patientNotes } from 'state/patientNotes/reducer.js';

export default combineReducers({
	healthSystems,
	myPatients,
	notifications,
	myDoctors,
	user,
	company,
	calls,
	aiSettingsList,
	configurations,
	language,
	watchMeasurementsList,
	devices,
	patientNotes,
});
