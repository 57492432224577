import classNames from 'classnames';
import { MeasureDeviceType, MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { getRoleConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { useConferenceConfigurations } from 'calls/hooks/index.js';

const MeasureDevices = props => {
	const conferenceConfigurations = useConferenceConfigurations();
	const { roundingConfigurations } = conferenceConfigurations;

	const setSelectedDeviceType = type => {
		props.setSelectedMeasureDevice(type);
		if (props.isTVClient) {
			props.inviteCompanionParticipant(type);
		}
		const newExaminationTypes = [...props.examinationTypes];
		let enabledTypes = [];
		switch (type) {
			case MeasureDeviceType.VITAL_KIT:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: props.isTVClient
						? [MeasurementTypes.ABDOMEN, MeasurementTypes.SPIROMETER].includes(item.type)
						: [
								MeasurementTypes.ABDOMEN,
								MeasurementTypes.SPIROMETER,
								MeasurementTypes.DIABETES,
								MeasurementTypes.BLOOD_PRESSURE,
								MeasurementTypes.WEIGHT,
								MeasurementTypes.ECG,
						  ].includes(item.type),
				}));
				if (props.isHelloClient) {
					props.setIsQRCode(true);
					props.onGenerateQRCode();
				}
				props.setExaminationTypes(enabledTypes);
				break;
			case MeasureDeviceType.WATCH:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: ![MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN].includes(item.type),
				}));
				props.setExaminationTypes(enabledTypes);
				props.removeVitalKitFromCall();
				break;
			case MeasureDeviceType.IOT_DEVICES:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: props.isTVClient
						? ![
								MeasurementTypes.HEART_RATE,
								MeasurementTypes.OXYGEN,
								MeasurementTypes.TEMPERATURE,
								MeasurementTypes.BLOOD_PRESSURE,
								MeasurementTypes.DIABETES,
								MeasurementTypes.WEIGHT,
						  ].includes(item.type)
						: [MeasurementTypes.ECG, MeasurementTypes.ABDOMEN].includes(item.type),
				}));
				props.setExaminationTypes(enabledTypes);
				props.removeVitalKitFromCall();
				break;
			case MeasureDeviceType.AI_DETECTIONS:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: ![
						MeasurementTypes.HEART_RATE,
						MeasurementTypes.OXYGEN,
						MeasurementTypes.DIABETES,
						MeasurementTypes.BLOOD_PRESSURE,
					].includes(item.type),
				}));
				props.setExaminationTypes(enabledTypes);
				break;
			default:
				break;
		}
	};

	const shouldShowVitalKit = () =>
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.ExamKit) && (props.isTVClient || props.isHelloClient);

	const shouldShowWatch = () => getRoleConfigurationValue(roundingConfigurations, RoundingSettings.Wearables);

	return (
		<div className='full-height flex flex-justify-center flex-align-center'>
			<p className='start-measurement-instruction'>{translate('selectAvailableDevices')}</p>
			<div className='flex device-type-wrapper'>
				{shouldShowVitalKit() && (
					<div
						className={classNames(
							'flex',
							props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT ? 'active' : '',
							props.isAekButtonDisabled ? 'disabled' : ''
						)}
						onClick={() => setSelectedDeviceType(MeasureDeviceType.VITAL_KIT)}>
						<img
							src={`${healthCareCdnUrl}measurements-icons/vital-kit${
								props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT ? '-active' : ''
							}.svg`}
							alt='ico'
						/>
						<p className={`no-margin no-padding ${props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT ? 'active' : ''}`}>
							{translate('examKit')}
						</p>
					</div>
				)}
				{shouldShowWatch() && (
					<div
						className={`flex ${props.selectedMeasureDevice === MeasureDeviceType.WATCH ? 'active' : ''}`}
						onClick={() => setSelectedDeviceType(MeasureDeviceType.WATCH)}>
						<img
							src={`${healthCareCdnUrl}measurements-icons/samsung-watch${
								props.selectedMeasureDevice === MeasureDeviceType.WATCH ? '-active' : ''
							}.svg`}
							alt='ico'
						/>
						<p className={`no-margin no-padding ${props.selectedMeasureDevice === MeasureDeviceType.WATCH ? 'active' : ''}`}>
							{translate('watch')}
						</p>
					</div>
				)}
				{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MedicalDevices) && (
					<div
						className={`flex ${props.selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES ? 'active' : ''}`}
						onClick={() => setSelectedDeviceType(MeasureDeviceType.IOT_DEVICES)}>
						<img
							src={`${healthCareCdnUrl}measurements-icons/iot${
								props.selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES ? '-active' : ''
							}.svg`}
							alt='ico'
						/>
						<p
							className={`no-margin no-padding ${props.selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES ? 'active' : ''}`}>
							{translate('medicalDevices')}
						</p>
					</div>
				)}
				{getRoleConfigurationValue(roundingConfigurations, RoundingSettings.AiMeasurements) && props.isTVClient && (
					<div
						className={classNames('flex', props.selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS ? 'active' : '')}
						onClick={() => setSelectedDeviceType(MeasureDeviceType.AI_DETECTIONS)}>
						<img
							src={`${healthCareCdnUrl}measurements-icons/${
								props.selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS ? 'ai-active' : 'ai-camera'
							}.svg`}
							alt='ico'
						/>
						<p
							className={`no-margin no-padding ${
								props.selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS ? 'active' : ''
							}`}>
							{translate('aiUsingCamera')}
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default MeasureDevices;
