import * as Yup from 'yup';
import {
	AddEditPatientRadioTypes,
	HdlCholesterolLimit,
	MeasurementUnitOptions,
	PatientHeightLimit,
	PatientWaistCircumferenceRangeLimit,
	PatientWeightLimit,
	TotalCholesterolLimit,
} from 'constants/enums.js';
import { checkUserEmailExists } from 'api/users.js';

const validateEmail = async val => {
	if (!val) {
		return true;
	}
	let emailExists = false;
	const schema = Yup.string().email().required();
	if (await schema.isValid(val)) {
		const response = await checkUserEmailExists(val);
		if (response.error) {
			emailExists = false;
		} else {
			emailExists = response;
		}
	}
	return !emailExists;
};

export const getValidationSchema = (intl, isAddNewPatient) =>
	Yup.object().shape({
		firstName: Yup.string()
			.required(intl.formatMessage({ id: 'firstNameCannotBeEmpty' }))
			.matches(/^\D+$/, `${intl.formatMessage({ id: 'firstNameNonNumber' })}`)
			.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
			.trim(),
		lastName: Yup.string()
			.required(intl.formatMessage({ id: 'lastNameCannotBeEmpty' }))
			.matches(/^\D+$/, `${intl.formatMessage({ id: 'lastNameNonNumber' })}`)
			.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
			.trim(),
		idNumber: Yup.string()
			.required(intl.formatMessage({ id: 'idNumberCannotBeEmpty' }))
			.min(
				10,
				intl.formatMessage({
					id: 'idNumberLength',
				})
			)
			.max(
				10,
				intl.formatMessage({
					id: 'idNumberLength',
				})
			)
			.trim(),
		address: Yup.string()
			.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
			.trim(),
		emailAddress:
			isAddNewPatient &&
			Yup.string()
				.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
				.email(intl.formatMessage({ id: 'invalidEmail' }))
				.test('existing-email', intl.formatMessage({ id: 'userAlreadyExists' }), val => validateEmail(val))
				.required(intl.formatMessage({ id: 'emailCannotBeEmpty' }))
				.trim(),
		phoneNumber: Yup.string()
			.max(20, `${intl.formatMessage({ id: 'maxLengthIs' })} 20`)
			.trim(),
		gender: Yup.object().required(intl.formatMessage({ id: 'pleaseSelectSex' })),
		height: Yup.number()
			.when('heightUnit', {
				is: MeasurementUnitOptions.HEIGHT.value[1]?.text,
				then: () =>
					Yup.number()
						.required(intl.formatMessage({ id: 'pleaseWriteHeight' }))
						.min(
							PatientHeightLimit.CENTIMETER.MIN,
							`${intl.formatMessage({ id: 'minHeightIs' })} ${PatientHeightLimit.CENTIMETER.MIN} cm`
						)
						.max(
							PatientHeightLimit.CENTIMETER.MAX,
							`${intl.formatMessage({ id: 'maxHeightIs' })} ${PatientHeightLimit.CENTIMETER.MAX} cm`
						),
			})
			.when('heightUnit', {
				is: MeasurementUnitOptions.HEIGHT.value[2]?.text,
				then: () =>
					Yup.number()
						.required(intl.formatMessage({ id: 'pleaseWriteHeight' }))
						.min(PatientHeightLimit.FEET.MIN, `${intl.formatMessage({ id: 'minHeightIs' })} ${PatientHeightLimit.FEET.MIN} ft`)
						.max(PatientHeightLimit.FEET.MAX, `${intl.formatMessage({ id: 'maxHeightIs' })} ${PatientHeightLimit.FEET.MAX} ft`),
			}),
		weight: Yup.number()
			.when('weightUnit', {
				is: MeasurementUnitOptions.WEIGHT.value[1]?.text,
				then: () =>
					Yup.number()
						.required(intl.formatMessage({ id: 'pleaseWriteWeight' }))
						.min(
							PatientWeightLimit.KILOGRAMS.MIN,
							`${intl.formatMessage({ id: 'minWeightIs' })} ${PatientWeightLimit.KILOGRAMS.MIN} kg`
						)
						.max(
							PatientWeightLimit.KILOGRAMS.MAX,
							`${intl.formatMessage({ id: 'maxWeightIs' })} ${PatientWeightLimit.KILOGRAMS.MAX} kg`
						),
			})
			.when('weightUnit', {
				is: MeasurementUnitOptions.WEIGHT.value[2]?.text,
				then: () =>
					Yup.number()
						.required(intl.formatMessage({ id: 'pleaseWriteWeight' }))
						.min(PatientWeightLimit.LBS.MIN, `${intl.formatMessage({ id: 'minWeightIs' })} ${PatientWeightLimit.LBS.MIN} lbs`)
						.max(PatientWeightLimit.LBS.MAX, `${intl.formatMessage({ id: 'maxWeightIs' })} ${PatientWeightLimit.LBS.MAX} lbs`),
			}),
		totalCholesterol: Yup.number()
			.when('totalCholesterolUnit', {
				is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text,
				then: () =>
					Yup.number()
						.min(
							TotalCholesterolLimit.MMOLL.MIN,
							`${intl.formatMessage({ id: 'minTotalCholesterolIs' })} ${TotalCholesterolLimit.MMOLL.MIN} mmol/L`
						)
						.max(
							TotalCholesterolLimit.MMOLL.MAX,
							`${intl.formatMessage({ id: 'maxTotalCholesterolIs' })} ${TotalCholesterolLimit.MMOLL.MAX} mmol/L`
						),
			})
			.when('totalCholesterolUnit', {
				is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[2]?.text,
				then: () =>
					Yup.number()
						.min(
							TotalCholesterolLimit.MGDL.MIN,
							`${intl.formatMessage({ id: 'minTotalCholesterolIs' })} ${TotalCholesterolLimit.MGDL.MIN} mg/dL`
						)
						.max(
							TotalCholesterolLimit.MGDL.MAX,
							`${intl.formatMessage({ id: 'maxTotalCholesterolIs' })} ${TotalCholesterolLimit.MGDL.MAX} mg/dL`
						),
			}),
		hdlCholesterol: Yup.number()
			.when('hdlCholesterolUnit', {
				is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text,
				then: () =>
					Yup.number()
						.min(
							HdlCholesterolLimit.MMOLL.MIN,
							`${intl.formatMessage({ id: 'minHdlCholesterolIs' })} ${HdlCholesterolLimit.MMOLL.MIN} mmol/L`
						)
						.max(
							HdlCholesterolLimit.MMOLL.MAX,
							`${intl.formatMessage({ id: 'maxHdlCholesterolIs' })} ${HdlCholesterolLimit.MMOLL.MAX} mmol/L`
						),
			})
			.when('hdlCholesterolUnit', {
				is: MeasurementUnitOptions.BLOOD_GLUCOSE.value[2]?.text,
				then: () =>
					Yup.number()
						.min(
							HdlCholesterolLimit.MGDL.MIN,
							`${intl.formatMessage({ id: 'minHdlCholesterolIs' })} ${HdlCholesterolLimit.MGDL.MIN} mg/DL`
						)
						.max(
							HdlCholesterolLimit.MGDL.MAX,
							`${intl.formatMessage({ id: 'maxHdlCholesterolIs' })} ${HdlCholesterolLimit.MGDL.MAX} mg/DL`
						),
			}),
		waistCircumference: Yup.number()
			.when('waistCircumferenceUnit', {
				is: MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[1]?.text,
				then: () =>
					Yup.number()
						.min(
							PatientWaistCircumferenceRangeLimit.CM.MIN,
							`${intl.formatMessage({ id: 'minWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.CM.MIN} cm`
						)
						.max(
							PatientWaistCircumferenceRangeLimit.CM.MAX,
							`${intl.formatMessage({ id: 'maxWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.CM.MAX} cm`
						),
			})
			.when('waistCircumferenceUnit', {
				is: MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[2]?.text,
				then: () =>
					Yup.number()
						.min(
							PatientWaistCircumferenceRangeLimit.INCH.MIN,
							`${intl.formatMessage({ id: 'minWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.INCH.MIN} inch`
						)
						.max(
							PatientWaistCircumferenceRangeLimit.INCH.MAX,
							`${intl.formatMessage({ id: 'maxWaistCircumferenceIs' })} ${PatientWaistCircumferenceRangeLimit.INCH.MAX} inch`
						),
			}),
		tobaccoYesCode: Yup.string().when('isTobaccoSmoker', {
			is: AddEditPatientRadioTypes.YES.toString(),
			then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
		}),
		tobaccoNoCode: Yup.string().when('isTobaccoSmoker', {
			is: AddEditPatientRadioTypes.NO.toString(),
			then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
		}),
		diabeticStatusCode: Yup.string().when('hasDiabet', {
			is: AddEditPatientRadioTypes.YES.toString(),
			then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
		}),
		allergies: Yup.array().when('hasAllergy', {
			is: AddEditPatientRadioTypes.YES.toString(),
			then: () => Yup.array().required(intl.formatMessage({ id: 'pleaseSelectOneOfTheOptionsAbove' })),
		}),
		preExistingCondition: Yup.string().when('hasPreExistingCondition', {
			is: AddEditPatientRadioTypes.YES.toString(),
			then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseWritePreExistingCondition' })),
		}),
	});
