import { getConversationByType } from 'api/messenger.js';
import AlertHistory from 'components/AlertHistory.jsx';
import CustomTable from 'components/CustomTable.jsx';
import EmptyState from 'components/EmptyState.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import { ConversationType } from 'constants/enums.js';
import {
	averages,
	HospitalAtHomeTabs,
	HospitalAtHomeTabsEnum,
	measurements,
	telemetry,
	TelemetryIcons,
} from 'constants/hospital-at-home.js';
import translate from 'i18n-translations/translate.jsx';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Conversation from 'views/Chat/Conversation.jsx';
import OverviewTab from 'containers/HospitalAtHome/OverviewTab.jsx';
import PatientDetails from 'containers/HospitalAtHome/PatientDetails.jsx';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import moment from 'moment';
import { getPatientMedications } from 'api/rpm.js';
import { getPatientAppointments } from 'api/appointments.js';
import { getPatientMeasurementAlerts } from 'api/alerts.js';
import { useSelector } from 'react-redux';
import Alert from 'components/Alert.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { defaultDateFormat } from 'infrastructure/helpers/dateHelper.js';
import Form from 'components/Form.jsx';
import Schedule from 'containers/HospitalAtHome/Schedule.jsx';
import RecentMetrics from 'containers/HospitalAtHome/RecentMetrics.jsx';

const HospitalAtHomePatientInfo = ({ setPatientDetailsView, setSelectedPatient, selectedPatient, talkToPatient, careTeam }) => {
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const [conversationId, setConversationId] = useState(null);
	const [medications, setMedications] = useState([]);
	const [appointments, setAppointments] = useState([]);
	const [alerts, setAlerts] = useState([]);
	const [dateRange, setDateRange] = useState({
		from: moment(new Date()).format('YYYY-MM-DD'),
		to: moment(new Date()).format('YYYY-MM-DD'),
	});
	const [allExpanded, setAllExpanded] = useState(false);
	const [error, setError] = useState(null);
	const healthSystemId = useSelector(state => state.user.userSession.healthSystem.id);

	const intl = useIntl();

	useEffect(() => {
		const getPatientData = async () => {
			const medicationsParams = {
				userId: getUserId(),
				patientId: selectedPatient?.id,
				role: getUserRole(),
				isRpmRequest: false,
				pageIndex: 0,
			};
			const alertsParams = {
				pageSize: 5,
				pageIndex: 0,
				userId: selectedPatient.id,
			};

			const [medicationsResponse, alertsResponse] = await Promise.all([
				getPatientMedications(medicationsParams),
				getPatientMeasurementAlerts(alertsParams),
			]);
			const error = medicationsResponse?.error || alertsResponse?.error;

			if (!medicationsResponse.error) {
				setMedications(medicationsResponse);
			}

			if (!alertsResponse.error) {
				setAlerts(alertsResponse.measurementAlerts);
			}

			if (error) {
				setError(error?.message);
			}
		};

		if (selectedPatient) {
			getPatientData();
		}
	}, [selectedPatient]);

	useEffect(() => {
		const getAppointments = async () => {
			const response = await getPatientAppointments({
				patientId: selectedPatient.id,
				healthSystemId,
				from: dateRange.from,
				to: dateRange.to,
			});

			if (!response.error) {
				setAppointments(response.appointments);
			}
		};

		getAppointments();
	}, [selectedPatient, dateRange, healthSystemId]);

	useEffect(() => {
		if (selectedPatient) {
			loadConversationId();
		}
	}, [conversationId, selectedPatient]);

	const loadConversationId = async () => {
		const response = await getConversationByType(ConversationType.INDIVIDUAL, selectedPatient?.userId, 0);
		setConversationId(response?.conversation?.id);
	};

	const displayMedications = () =>
		medications.map(medication => ({
			medication: medication.medicine.name,
			dosage: medication.dosage,
			frequency: `${medication.frequency} ${intl.formatMessage({ id: medication.frequency > 1 ? 'timesAday' : 'timeAday' })} `,
			duration: `${medication.duration} ${intl.formatMessage({ id: medication.duration > 1 ? 'days' : 'day' })}`,
			refills: medication.refills,
			prescriptionDate: defaultDateFormat(medication.visitedAt),
		}));

	return (
		<>
			<div className='patient-tabs'>
				<PatientDetails
					setPatientDetailsView={setPatientDetailsView}
					setSelectedPatient={setSelectedPatient}
					selectedPatient={selectedPatient}
					goToOverview={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.OVERVIEW)}
					talkToPatient={talkToPatient}
					setAllExpanded={setAllExpanded}
					allExpanded={allExpanded}
				/>
				<Tabs activeIndex={currentTabIndex} onChange={setCurrentTabIndex}>
					<TabList>
						{HospitalAtHomeTabs.map(tab => (
							<Tab>{translate(tab)}</Tab>
						))}
					</TabList>
					<TabPanels>
						<TabPanel>
							<OverviewTab
								goToRecentMetric={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.RECENT_METRICS)}
								goToMedications={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.MEDICATION)}
								goToTelemetry={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.TELEMETRY)}
								goToAlerts={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.ALERTS)}
								goToCareTeam={() => setCurrentTabIndex(HospitalAtHomeTabsEnum.CARE_TEAM)}
								measurements={measurements}
								alerts={alerts}
								careTeam={careTeam}
								appointments={appointments}
								medications={medications}
								averages={averages}
								telemetry={telemetry}
								setDateRange={setDateRange}
								dateRange={dateRange}
								allExpanded={allExpanded}
							/>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper medications-wrapper'>
								<Schedule selectedPatient={selectedPatient} healthSystemId={healthSystemId} />
							</div>
						</TabPanel>

						<TabPanel>
							<div className='patient-tab-wrapper medications-wrapper'>
								<CustomTable
									headers={[
										{ title: translate('medication'), id: 'medication' },
										{ title: translate('dosage'), id: 'dosage' },
										{ title: translate('frequency'), id: 'frequency' },
										{ title: translate('duration'), id: 'duration' },
										{ title: translate('refills'), id: 'refills' },
										{ title: translate('date'), id: 'prescriptionDate' },
									]}
									rows={displayMedications()}
									className='medications-table'
									isEditable={false}
								/>
							</div>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper'>
								<AlertHistory patientId={selectedPatient.id} isCallView={false} showOnlyMeasurementAlerts={true} />
							</div>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper'>
								<RecentMetrics averages={averages} measurements={measurements} />
							</div>
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper grid grid-columns-3 gap-m'>
								{telemetry.map(tel => (
									<div key={tel.id} className='telemetry-wrapper telemetry-tab'>
										{tel.measurements.map(measurement => (
											<div className='flex gap-s padding-l' key={measurement.type}>
												<img src={TelemetryIcons.find(item => item.type === measurement.type)?.icon} alt='ico' />
												<span>{translate(measurement.value)}</span>
											</div>
										))}
									</div>
								))}
							</div>
							{telemetry.length === 0 && <EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />}{' '}
						</TabPanel>
						<TabPanel>
							<div className='patient-tab-wrapper'>
								{careTeam && (
									<Form>
										{careTeam.length === 0 && (
											<div className='flex flex-align-center flex-justify-center'>
												<p>{translate('noResultsFound')}</p>
											</div>
										)}
										<div className='care-team-members-wrapper'>
											<div className='available-doctor-items care-team-members-list'>
												{careTeam.map(item => (
													<div key={item?.userIntId} className='care-team-info'>
														<ProfilePicture
															className='doctor-request-img available-doctor-request-img'
															firstName={item.firstName}
															lastName={item.lastName}
															profilePicture={item.profilePicture}
														/>
														<p className='team-member-name'>
															{item.firstName} {item.lastName}
														</p>
														<span className='additional-info'>{item.email}</span>
														{item.specialty?.name && <span className='additional-info'>{item.specialty.name}</span>}
													</div>
												))}
											</div>
										</div>
									</Form>
								)}
							</div>
						</TabPanel>
						<TabPanel>
							<Conversation
								selectedUser={{
									id: selectedPatient?.userId,
									name: `${selectedPatient?.firstName} ${selectedPatient?.lastName}`,
									profilePicture: selectedPatient?.profilePicture,
								}}
								conversationId={conversationId}
								isFromDoctor={true}
								isPatientDoctorChat={true}
								hideRightSide={false}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</div>
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</>
	);
};

export default HospitalAtHomePatientInfo;
