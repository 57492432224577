import { getProviders } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import CustomTable from 'components/CustomTable.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { formatDateHeader } from 'constants/dashboard.js';
import translate from 'i18n-translations/translate.jsx';
import { getFirstName, getLastName, getRoleValueById } from 'infrastructure/helpers/commonHelpers.js';
import { timezoneToUTCTimestamp, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const ProvidersCentricReport = props => {
	const [providers, setProviders] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();
	const controller = useRef(null);

	const fetchProvidersReport = useCallback(
		debounce(async (params, signal) => {
			const response = await getProviders(params, signal);
			if (!response.error) {
				setProviders(prevState => [...prevState, ...response.providers]);
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
				setIsLoading(false);
			} else if (response.error.code !== 'ERR_CANCELED') {
				setError(
					translate('somethingWentWrongSpecific', {
						value: intl.formatMessage({ id: 'providers' }),
					})
				);
				setIsLoading(false);
			}
		}, 500),
		[]
	);

	useEffect(() => {
		if (controller.current) {
			controller.current.abort();
		}
		controller.current = new AbortController();
		const signal = controller.current.signal;

		const params = {
			start: timezoneToUTCTimestamp(props.start, props.selectedTimezone.zone),
			end: timezoneToUTCTimestamp(props.end, props.selectedTimezone.zone),
			healthSystemId: props.selectedHealthSystem,
			regionId: props.selectedHospital?.regionId,
			hospitalId: props.selectedHospital?.value,
			departmentId: props.selectedDepartment?.value,
			floorId: props.selectedFloor?.value,
			roomId: props.selectedRoom?.value,
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
			searchPattern: props.searchValue || null,
			...props.additionalFilters,
		};

		setIsLoading(true);
		fetchProvidersReport(params, signal);
	}, [
		props.end,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedHealthSystem,
		props.selectedHospital,
		props.selectedRoom,
		props.start,
		props.selectedTimezone,
		pagination.pageIndex,
		pagination.pageSize,
		props.searchValue,
		fetchProvidersReport,
		props.additionalFilters,
	]);

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	useEffect(() => {
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		setProviders([]);
	}, [
		props.end,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedHealthSystem,
		props.selectedHospital,
		props.selectedRoom,
		props.start,
		props.selectedTimezone,
		props.searchValue,
		props.additionalFilters,
	]);

	const getLocation = provider =>
		provider.location && (
			<div>
				{provider.location.healthSystemName && <span>{provider.location.healthSystemName}</span>}
				{provider.location.hospitalName && (
					<span>
						{' >'} {provider.location.hospitalName}
					</span>
				)}
				{provider.location.departmentName && (
					<span>
						{' >'} {provider.location.departmentName}
					</span>
				)}
				{provider.location.floorName && (
					<span>
						{' >'} {provider.location.floorName}
					</span>
				)}
				{provider.location.roomName && (
					<span>
						{' >'} {provider.location.roomName}
					</span>
				)}
			</div>
		);

	const groupMemberRoles = providerId =>
		providers.reduce((acc, item) => {
			const providerRole = props.providersRoles.find(role => role.id === item.companyMemberRoleId);
			if (item.objectId === providerId && providerRole) {
				acc.push(providerRole);
			}
			return acc;
		}, []);

	const displayProviders = () => {
		if (!providers || providers.length === 0) {
			return [];
		}
		return providers.map((provider, index) => ({
			id: provider.objectId + index,
			careGiver: (
				<div className='flex image-container'>
					<ProfilePicture
						fullName={provider.name}
						profilePicture={provider.picture}
						className='provider-intervening-img-wrapper'
					/>
					<p>{provider.name}</p>
				</div>
			),
			role: getRoleValueById(provider.companyMemberRoleId),
			count: provider.count,
			endedTime: utcToTimezone(provider.lastCallDate, props.selectedTimezone.zone),
			location: getLocation(provider),
			actions: (
				<div className='flex position-relative table-buttons'>
					<Button
						variant='white'
						onClick={() => {
							props.setSelectedProvider({
								firstName: getFirstName(provider.name),
								lastName: getLastName(provider.name),
								profilePicture: provider.picture,
								userIntId: provider.objectId,
								memberRoles: groupMemberRoles(provider.objectId),
								...provider,
							});
						}}
						text={intl.formatMessage({ id: 'viewSessions' })}
					/>
				</div>
			),
		}));
	};

	return (
		<>
			<CustomTable
				headers={[
					{ title: translate('providers'), id: 'careGiver' },
					{ title: translate('role'), id: 'role' },
					{ title: translate('completedConferences'), id: 'count' },
					{
						title: formatDateHeader(intl, 'lastSessionDate', props.selectedTimezone.zone),
						id: 'endedTime',
					},
					{ title: translate('location'), id: 'location' },
					{ title: translate('actions'), id: 'actions' },
				]}
				rows={displayProviders()}
				isLoading={isLoading}
				stickyHeader={true}
				setPagination={setPagination}
			/>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default ProvidersCentricReport;
