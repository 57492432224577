import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getTeamConfigurations = async params => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/configurations/tv-device`, {
			params,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addDeviceConfiguration = async (teamId, params) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/team/${teamId}/configurations/tv-device`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteTVDeviceConfiguration = async (teamId, id) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/organizations/${getCompanyId()}/team/${teamId}/configurations/tv-device/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
