import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import Input from 'components/Input.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes } from 'constants/enums.js';
import { reorderObjects } from 'infrastructure/helpers/commonHelpers.js';
import { urlRegex } from 'infrastructure/helpers/validationHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import StatAlarms from 'components/StatAlarms.jsx';
import {
	CallWorkflowType,
	IntegrationTypesSettings,
	MonitoringSettings,
	RoundingSettings,
	configurableWorkflowTypes,
	getConfigurationWithDiffTypeKey,
} from 'constants/configurationEnums.js';
import classNames from 'classnames';
import AiConfigurationFeatureFlags from 'components/AiConfigurationFeatureFlags.jsx';
import { prepareAiConfigsForSubmit, validateAiConfigurations } from 'infrastructure/helpers/aiHelper.js';
import { updateOrganizationSettings } from 'api/companies.js';
import { AiConfigurationSettings } from 'constants/ai.js';

const CreateEditFeatureFlags = props => {
	const intl = useIntl();
	const [featureFlags, setFeaturesFlags] = useState(props.dataToSubmit[props.settingCategory]);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [alertType, setAlertType] = useState(null);
	const [isStatAlarmModalOpen, setIsStatAlarmModalOpen] = useState(false);
	const [selectedType, setSelectedType] = useState(
		props.featureTypes
			? {
					value: props.featureTypes[0].value,
					label: intl.formatMessage({ id: props.featureTypes[0].label }),
				}
			: null
	);
	const [expandedRows, setExpandedRows] = useState([]);
	const [aiErrors, setAiErrors] = useState([]);
	const [isAiError, setIsAiError] = useState(false);
	const isGloboEnabled = props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.GLOBO]?.value;
	const isAmnEnabled = props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.AMN]?.value;

	useEffect(() => {
		if (props.featureTypes) {
			const filtered = filterConfigsByType(selectedType.value);
			setFeaturesFlags(filtered);
		}
	}, [selectedType]);

	const checkAiSettingsValidation = async (aiSettingsFeatureFlags, callworkflowTypeId) => {
		const errorsArr = [];

		AiConfigurationSettings.forEach(async setting => {
			const aiSettingType = aiSettingsFeatureFlags[getConfigurationWithDiffTypeKey(setting, callworkflowTypeId)];
			if (aiSettingType?.value) {
				const aiConfigErrors = validateAiConfigurations(aiSettingType, intl);
				if (Object.keys(aiConfigErrors.errors).length > 0) {
					errorsArr.push(aiConfigErrors);
					if (!expandedRows.includes(aiSettingType.aiConfigurationTypeId)) {
						showConfigDetails(aiSettingType.aiConfigurationTypeId);
					}
				}
			}
		});
		return errorsArr;
	};

	const checkErrorsPerWorkflowType = async aiSettingsFeatureFlags => {
		for (let callWorkflowTypeId of [CallWorkflowType.MONITORING, CallWorkflowType.ROUNDING, CallWorkflowType.BACKGROUND]) {
			const errors = await checkAiSettingsValidation(aiSettingsFeatureFlags, callWorkflowTypeId);
			if (errors.length > 0) {
				if (callWorkflowTypeId !== selectedType?.value) {
					const foundWorkflowType = configurableWorkflowTypes()?.find(item => item.value === callWorkflowTypeId);
					setConfigurationsByType(foundWorkflowType);
				}
				return errors;
			}
		}
		return null;
	};

	const onSubmit = async e => {
		e.preventDefault();
		setAiErrors([]);
		setIsAiError(false);
		const aiSettingsFeatureFlags = props.dataToSubmit.aiSettings;
		const foundErrors = await checkErrorsPerWorkflowType(aiSettingsFeatureFlags || []);
		if (aiSettingsFeatureFlags && foundErrors) {
			setAiErrors(foundErrors);
			setIsAiError(true);
			return;
		}
		if (!props.selectedCompany) {
			props.setDataToSubmit(featureFlags);
			props.goToNextTab();
		} else {
			setIsSaveLoading(true);

			const updatedState = {
				...props.dataToSubmit,
				roundingSettings: {
					...props.dataToSubmit.roundingSettings,
					[RoundingSettings.TranslationServices]: {
						...props.dataToSubmit.roundingSettings?.[RoundingSettings.TranslationServices],
						value: false,
					},
				},
			};
			const updatedFeatureFlags =
				props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.GLOBO].value === false &&
				props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.AMN].value === false
					? updatedState
					: props.dataToSubmit;

			const featureFlagsParams = Object.entries(updatedFeatureFlags).flatMap(([, settings]) =>
				Object.entries(settings).map(([key, config]) => ({
					settingTypeId: config.settingTypeId ? +config.settingTypeId : +key,
					value: config.value.toString(),
					...(config.variant ? { variant: config.variant.value } : {}),
					...(config.roomTypeId ? { roomTypeId: config.roomTypeId } : {}),
					...(config.callWorkflowTypeId ? { callWorkflowTypeId: config.callWorkflowTypeId } : {}),
					...(config.isURLField && { value: !config.value ? 'false' : config.value }),
					...(config.aiConfigurationTypeId &&
						config.value && {
							aiConfigurations: prepareAiConfigsForSubmit(config.aiConfigurations),
						}),
				}))
			);
			const settingsParams = {
				companySettings: featureFlagsParams,
				companyId: props.selectedCompany.id,
			};

			const response = await updateOrganizationSettings(settingsParams);
			if (!response.error) {
				setAlertType(AlertTypes.SUCCESS);
				props.setDataToSubmit(featureFlags);
			} else {
				setAlertType(AlertTypes.DANGER);
				if (props.featureTypes) {
					const filtered = filterConfigsByType(selectedType?.value);
					setFeaturesFlags(filtered);
				} else {
					setFeaturesFlags(props.dataToSubmit[props.settingCategory]);
				}
			}

			setIsSaveLoading(false);
		}
	};

	const toggleValue = (item, value = !item.value) => {
		item.value = value;
		if (item.variant && item.options && item.value) {
			item.variant = item.options[0];
		}
	};

	const disableDependencies = (key, config) => {
		if (config[key].disableDependencies) {
			config[key].disableDependencies.forEach(dep => {
				toggleValue(config[dep], false);
			});
		}
	};

	const setOtherConfigsToDisable = (key, config) => {
		if (config[key].otherConfigsToDisable) {
			config[key].otherConfigsToDisable.forEach(dep => {
				toggleValue(config[dep], false);
			});
		}
	};

	const toggleItem = key => {
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			const copiedConfig = { ...configsCopied[key] };
			toggleValue(copiedConfig);
			disableDependencies(key, configsCopied);
			if (copiedConfig.otherConfigsToDisable && copiedConfig.value) {
				setOtherConfigsToDisable(key, configsCopied);
			}
			return { ...prevState, ...configsCopied };
		});

		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		toggleValue(createCompanyState[key]);

		if (createCompanyState[key].value) {
			showConfigDetails(createCompanyState[key]?.aiConfigurationTypeId);
		} else {
			hideConfigDetails(createCompanyState[key]?.aiConfigurationTypeId);
		}

		disableDependencies(key, createCompanyState);
		if (createCompanyState.otherConfigsToDisable && !createCompanyState.value) {
			setOtherConfigsToDisable(key, createCompanyState);
		}
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const customizeConfig = (key, variant) => {
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			configsCopied[key].variant = configsCopied[key].options.find(opt => opt.value === variant);

			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key].variant = createCompanyState[key].options.find(opt => opt.value === variant);

		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const handleURLInput = (key, value) => {
		const validURL = urlRegex.test(value);
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			configsCopied[key].value = value.trim() ? value : 'false';
			configsCopied[key].valid = validURL;

			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key].value = value;
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const transformArray = array => array.map(item => ({ value: item.value, label: intl.formatMessage({ id: item.label }) }));

	const groupedCategories = Object.entries(featureFlags).reduce((acc, [key, item]) => {
		const { category } = item;
		if (!acc[category]) {
			acc[category] = [];
		}

		const displayItem = !item.dependentFrom || props.dataToSubmit[props.settingCategory][item.dependentFrom]?.value;

		if (displayItem) {
			acc[category].push([key, item]);
		}
		return acc;
	}, []);

	const featureFlagsCategories = props.categoryOrder ? reorderObjects(groupedCategories, props.categoryOrder) : groupedCategories;

	const setConfigurationsByType = type => {
		setSelectedType(type);
		const filtered = filterConfigsByType(type.value);
		setFeaturesFlags(filtered);
	};

	const filterConfigsByType = type =>
		Object.keys(props.dataToSubmit[props.settingCategory]).reduce((acc, key) => {
			if (
				props.dataToSubmit[props.settingCategory][key].roomTypeId === type ||
				props.dataToSubmit[props.settingCategory][key].callWorkflowTypeId === type
			) {
				acc[key] = props.dataToSubmit[props.settingCategory][key];
			}
			return acc;
		}, {});

	const handleAiConfigsChange = ({ key, aiConfigs }) => {
		if (!key || !aiConfigs) {
			return;
		}
		setFeaturesFlags(prevState => {
			const configsCopied = { ...prevState };
			configsCopied[key]['aiConfigurations'] = aiConfigs;
			return configsCopied;
		});
		const createCompanyState = { ...props.dataToSubmit[props.settingCategory] };
		createCompanyState[key]['aiConfigurations'] = aiConfigs;
		props.setDataToSubmit(prevState => ({ ...prevState, [props.settingCategory]: createCompanyState }));
	};

	const showConfigDetails = rowIndex => {
		setExpandedRows(prev => [...prev, rowIndex]);
	};

	const hideConfigDetails = rowIndex => {
		const filteredRows = expandedRows.filter(row => row !== rowIndex);
		setExpandedRows(filteredRows);
	};

	return (
		<div>
			{props.featureTypes && (
				<div className='feature-flags-header'>
					<div>
						<h4>{translate(props.typeTitle)}</h4>
						<Select
							value={selectedType}
							classNamePrefix='react-select'
							options={transformArray(props.featureTypes)}
							onChange={val => {
								setExpandedRows([]);
								setConfigurationsByType(val);
							}}
						/>
					</div>
				</div>
			)}
			{Object.keys(featureFlagsCategories).map(category => {
				const categoryItems = groupedCategories[category];
				return (
					<div className='feature-flags-category' key={category}>
						{category !== props.settingCategory && <h4>{translate(category)}</h4>}
						{categoryItems?.map(
							([key, item]) =>
								(item.title !== 'translationServices' ||
									((isGloboEnabled || isAmnEnabled) && item.title === 'translationServices')) && (
									<Fragment key={key}>
										<div className={classNames('feature-flag flex', item.dependentFrom ? 'left-30' : '')} key={key}>
											{!item.isURLField && (
												<div className='toggle-config'>
													<div className='rounded-slider-switch' onClick={() => toggleItem(+key)}>
														<input type='checkbox' checked={item.value === 'true' || item.value === true} onChange={() => null} />
														<span className='rounded-slider' />
													</div>
													<p>{item.value ? translate('on') : translate('off')}</p>
												</div>
											)}
											<div className={classNames('feature-description', item.isURLField ? 'no-toggle-shown' : '')}>
												<p className='flex-1'>{translate(item.title)}</p>
												<p>
													{translate(item.description, {
														value: 'user',
														huddleName: props.cameraNames?.huddleName,
														helloName: props.cameraNames?.helloName,
													})}
												</p>
												{item.options && item.value && !item.hasButton && (
													<div className='feature-flag-options flex'>
														<p>{translate('customize')}</p>
														<Select
															value={transformArray([item.variant])}
															classNamePrefix='react-select'
															options={transformArray(item.options)}
															onChange={event => customizeConfig(key, event.value)}
														/>
													</div>
												)}
												{item.hasButton && item.value && (
													<div className='flex flex-align-center'>
														{item.variant?.value && (
															<div className='flex flex-space-between stat-alarm-details selected-alarm cursor-pointer active'>
																<div className='flex flex-align-center full-width'>
																	<i className='material-icons-outlined'>notifications_active</i>
																	<span className='font-14 left-s --blue-light-5'>
																		{intl.formatMessage({ id: item.variant?.label }, { value: item.variant?.translateValue })}
																	</span>
																</div>
															</div>
														)}
														<div className='flex stat-alarm-flag cursor-pointer' onClick={() => setIsStatAlarmModalOpen(true)}>
															<img src={`${healthCareCdnUrl}icon/change.svg`} alt='icon' />
															<span>{translate('selectOtherAlarm')}</span>
														</div>
													</div>
												)}
												{item.isURLField && (
													<div className='feature-flag-text-input'>
														<Input
															type='text'
															placeholder={`http://example.com ${intl.formatMessage({ id: 'or' })} https://example.com`}
															value={item.value === 'false' ? '' : item.value}
															inputWidth='300px'
															onChange={event => handleURLInput(key, event.target.value)}
															name='item.title'
														/>
														{!!item.value && !item?.valid && <span className='red-error'>{translate('badURL')}</span>}
													</div>
												)}

												{item.aiConfigurationTypeId && (
													<div className='flex'>
														{expandedRows.includes(item.aiConfigurationTypeId) && (
															<AiConfigurationFeatureFlags
																onAiConfigsChange={handleAiConfigsChange}
																settingTypeId={key}
																aiErrors={aiErrors}
																selectedAiConfig={item}
															/>
														)}
													</div>
												)}
											</div>
											{item.aiConfigurationTypeId && (
												<span
													className={classNames('cursor-pointer flex-shrink-0', props.selectedCompany ? 'ai-config-details' : '')}
													onClick={() =>
														expandedRows.includes(item.aiConfigurationTypeId)
															? hideConfigDetails(item.aiConfigurationTypeId)
															: showConfigDetails(item.aiConfigurationTypeId)
													}>
													<img
														src={`${healthCareCdnUrl}expand-
															${expandedRows.includes(item.aiConfigurationTypeId) ? 'less' : 'more'}.svg`}
														alt='ico'
													/>
												</span>
											)}
										</div>

										{item.dependentConfigsTitle && item.value && (
											<p className='feature-dependent-title'>{translate(item.dependentConfigsTitle)}</p>
										)}
									</Fragment>
								)
						)}
					</div>
				);
			})}
			<div className='create-hs__add'>
				{!props.selectedCompany && (
					<>
						<Button text={translate('goBack')} variant='white' onClick={props.goToPreviousTab} disabled={!props.isValid} />
						&nbsp;&nbsp;
					</>
				)}
				<Button
					type='submit'
					text={translate(props.selectedCompany ? 'save' : 'nextStep')}
					onClick={onSubmit}
					isLoading={isSaveLoading}
				/>
			</div>
			<PopUpAlert
				alertType={alertType}
				display={alertType}
				onAlertClose={() => setAlertType(null)}
				contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
			{aiErrors.length > 0 && props.currentTab !== 5 && (
				<PopUpAlert
					alertType={AlertTypes.DANGER}
					display={isAiError}
					onAlertClose={() => setIsAiError(false)}
					contentText={intl.formatMessage({ id: 'fillRequiredFieldsAi' })}
					isSilent={true}
					center={true}
					selfCloseTimeOut={1500}
				/>
			)}
			<StatAlarms
				isStatAlarmModalOpen={isStatAlarmModalOpen}
				setIsStatAlarmModalOpen={setIsStatAlarmModalOpen}
				customizeConfig={customizeConfig}
				config={props.dataToSubmit[props.settingCategory][MonitoringSettings.StatAlarm]}
			/>
		</div>
	);
};

export default CreateEditFeatureFlags;
