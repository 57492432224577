import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import Select from 'components/Select.jsx';
import TreeView from 'components/TreeView.jsx';
import Alert from 'components/Alert.jsx';
import { SectorTypes, TreeHierarchyType, UserRole } from 'constants/enums.js';
import { buildTree, getHealthSystemDevices } from 'infrastructure/helpers/commonHelpers.js';
import { getRegionSubTree } from 'api/tree.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { reAssignDevice } from 'api/healthSystems.js';
import { createFloor, createFloorInHospital } from 'api/floors.js';
import { createRoom, createRoomInDepartment } from 'api/rooms.js';
import { createDepartment } from 'api/departments.js';
import { createFrontlineHospital, createHospital } from 'api/hospitals.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';

const AssignDeviceModal = props => {
	const [currentHealthSystemData, setCurrentHealthSystemData] = useState({ id: null, regionId: null });
	const [isAddDeviceModalLoading, setIsAddDeviceModalLoading] = useState(false);
	const [tree, setTree] = useState([]);
	const healthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [currentHealthSystems, setCurrentHealthSystems] = useState([]);
	const [currentRegions, setCurrentRegions] = useState([]);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const intl = useIntl();
	const treeHierarchyTypeId = healthSystems.find(item => item.id === userSession.healthSystem.id)?.treeHierarchyTypeId;

	useEffect(() => {
		const selectedHs = healthSystems.find(hs => hs.id === props.healthSystemId);
		setCurrentHealthSystems(healthSystems);
		setCurrentRegions(selectedHs.regions);
		setCurrentHealthSystemData({ id: props.healthSystemId, regionId: selectedHs.regions[0].id });
	}, [healthSystems, props.healthSystemId]);

	useEffect(() => {
		const getTree = async () => {
			if (UserRole.VISITOR === getUserRole()) {
				return;
			}
			const subTreeResponse = await getRegionSubTree(currentHealthSystemData.id, currentHealthSystemData.regionId);
			if (!subTreeResponse.error) {
				const { healthSystem } = subTreeResponse.organization;
				const treeData = buildTree(healthSystem);
				setTree(treeData);
			}
		};
		if (currentHealthSystemData.id) {
			getTree();
		}
	}, [currentHealthSystemData]);

	const changeRegion = regionId => {
		setCurrentHealthSystemData(prevState => ({ ...prevState, regionId }));
	};

	const changeHealthSystem = healthSystemId => {
		const selectedHs = healthSystems.find(hs => hs.id === healthSystemId);
		setCurrentHealthSystemData({ id: healthSystemId, regionId: selectedHs.regions[0].id });
		setCurrentRegions(selectedHs.regions);
	};

	const fetchTreeData = async (healthSystemId, regionId) => {
		let treeData = null;
		if (UserRole.VISITOR === getUserRole()) {
			return treeData;
		}
		const subTreeResponse = await getRegionSubTree(healthSystemId, regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
		}
		return treeData;
	};

	const hasDefaultHierarchy = () =>
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(treeHierarchyTypeId);

	const onNewSector = async selection => {
		const data = {
			companyId: getCompanyId(),
			healthSystemId: currentHealthSystemData.id,
			name: selection.name,
			regionId: currentHealthSystemData.regionId,
		};
		if (selection.type === SectorTypes.HOSPITAL && hasDefaultHierarchy()) {
			await createFrontlineHospital({ ...data, regionId: userSession.regionId });
		} else if (selection.type === SectorTypes.HOSPITAL && !hasDefaultHierarchy()) {
			const response = await createHospital({
				...data,
				regionId: userSession.regionId,
				hasDefaultDepartment: treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (selection.type === SectorTypes.DEPARTMENT) {
			const response = await createDepartment({ ...data, hospitalId: selection.hospitalId });
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (selection.type === SectorTypes.FLOOR && hasDefaultHierarchy()) {
			const response = await createFloor({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (selection.type === SectorTypes.FLOOR && treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			const response = await createFloorInHospital({
				...data,
				hospitalId: selection.hospitalId,
				name: selection.name,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (
			selection.type === SectorTypes.ROOM &&
			(hasDefaultHierarchy() || treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM)
		) {
			const response = await createRoom({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId,
				floorId: selection.floorId,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		} else if (
			selection.type === SectorTypes.ROOM &&
			[TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId)
		) {
			const response = await createRoomInDepartment({
				...data,
				hospitalId: selection.hospitalId,
				departmentId: selection.departmentId || selection.defaultDepartmentId,
				name: selection.name,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
		}
		const treeResponse = await fetchTreeData(currentHealthSystemData.id, currentHealthSystemData.regionId);
		if (treeResponse) {
			setTree(treeResponse);
		}
	};

	const assignDevice = async ({ hospitalId, departmentId, floorId, roomId }) => {
		setIsAddDeviceModalLoading(true);
		const response = await reAssignDevice({ hospitalId, departmentId, floorId, roomId, deviceId: props.helloDeviceId });
		if (response.error) {
			setError(response.error.message);
		} else {
			const treeResponse = await fetchTreeData(userSession.healthSystem.id, userSession.regionId);
			if (treeResponse) {
				dispatch(healthSystemsActionCreators.setTreeData(treeResponse));
			}
			props.setShowAssignDeviceModal();
			props.loadCurrentRoom();
		}
		setIsAddDeviceModalLoading(false);
	};

	return (
		<Modal
			modalSelector='reassignDeviceModal'
			display={props.helloDeviceId}
			isLoading={isAddDeviceModalLoading}
			onModalSubmit={() => null}
			position='right'
			submitButtonText=''
			onModalClose={() => props.setShowAssignDeviceModal(false)}>
			<Form title={intl.formatMessage({ id: 'reAssignDevice' })} onSubmit={evt => evt.preventDefault()}>
				<Select
					labelClassName='input'
					type='text'
					label={intl.formatMessage({ id: 'selectHealthSystem' })}
					name='id'
					valueField='id'
					textField='name'
					value={currentHealthSystemData.id}
					onSelect={event => changeHealthSystem(event.target.value)}
					items={currentHealthSystems}
					description={intl.formatMessage({ id: 'selectHsToManage' })}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
				/>
				<Select
					labelClassName='input'
					type='text'
					label={intl.formatMessage({ id: 'selectLocation' })}
					name='id'
					valueField='id'
					textField='name'
					value={currentHealthSystemData.regionId}
					onSelect={event => changeRegion(event.target.value)}
					items={currentRegions}
					description={intl.formatMessage({ id: 'selectLocationReassign' })}
					placeholder={intl.formatMessage({ id: 'selectLocation' })}
				/>
				<p>{translate('selectRoom')}</p>
				<TreeView onAddDevice={assignDevice} data={tree} onAdd={onNewSector} isReassign={true} />
			</Form>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default AssignDeviceModal;
