import { AiAlertActivityType, AiAlertId, AiAlertNotificationType, AiAnalyticsTypeId, PatientAiSetting } from 'constants/enums.js';
import { AiSetting } from 'constants/configurationEnums.js';

export const AiNotificationTypes = intl => [
	{
		value: AiAlertNotificationType.TOAST_MESSAGE,
		label: intl.formatMessage({ id: 'toastMessage' }),
		isChecked: false,
		isDefault: false,
	},
	{
		value: AiAlertNotificationType.MODAL_ON_FEED,
		label: intl.formatMessage({ id: 'alertOnMonitoringFeed' }),
		isChecked: false,
		isDefault: false,
	},
	{
		value: AiAlertNotificationType.SILENT_MODE,
		label: intl.formatMessage({ id: 'fullySilentMode' }),
		isChecked: false,
		isDefault: false,
	},
	{
		value: AiAlertNotificationType.MOBILE_APP,
		label: intl.formatMessage({ id: 'mobileApp' }),
		isChecked: false,
		isDefault: false,
	},
];

export const StatNotificationTypes = intl => [
	{
		value: AiAlertNotificationType.STAT_ALARM_HELLO,
		label: intl.formatMessage({ id: 'helloDevice' }),
		isChecked: false,
		isDefault: false,
	},
	{
		value: AiAlertNotificationType.STAT_ALARM_DIGITAL_ROOM_SIGN,
		label: intl.formatMessage({ id: 'digitalSign' }),
		isChecked: false,
		isDefault: false,
	},
];

export const RadioButtonTypes = [
	AiAlertNotificationType.TOAST_MESSAGE,
	AiAlertNotificationType.MODAL_ON_FEED,
	AiAlertNotificationType.SILENT_MODE,
	AiAlertNotificationType.MOBILE_APP,
];

export const CheckBoxTypes = [AiAlertNotificationType.STAT_ALARM_HELLO, AiAlertNotificationType.STAT_ALARM_DIGITAL_ROOM_SIGN];

export const FallPreventionSettingTypes = [
	PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
	PatientAiSetting.RAILS,
	PatientAiSetting.FALL_DETECTED,
];

export const SettingsWithConfigValues = [
	PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
	PatientAiSetting.RAILS,
	PatientAiSetting.PRESSURE_INJURY,
	PatientAiSetting.AI_VOICE_ANALYSIS,
];

export const AiModelThresholdType = {
	RAIL_DOWN: 1,
	RAIL_UP: 2,
	GETTING_OUT_OF_BED_LOW: 3,
	GETTING_OUT_OF_BED_MEDIUM: 4,
	GETTING_OUT_OF_BED_HIGH: 5,
	FALL_SKELETON_MODEL: 6,
};

export const AiFieldToUpdate = {
	IS_ENABLED: 'isEnabled',
	VALUE: 'value',
	SNOOZE_TIME: 'snoozeTime',
	NOTIFICATION_OPTIONS: 'notificationOptions',
	PRESSURE_INJURY_BODY_POSITIONS: 'pressureInjuryBodyPositions',
};

export const AiConfigField = {
	VALUES: 'values',
	THRESHOLD_VALUES: 'thresholdValues',
	NOT_ALONE_TRIGGER_ALERT: 'notAloneTriggerAlert',
	SNOOZE_VALUES: 'snoozeValues',
	ALERTS: 'alerts',
	STAT_ALERTS: 'statAlerts',
	COVERED_RAILS_TRIGGER_ALERT: 'coveredRailsTriggerAlert',
	AI_MODE_CHECK_TIMER: 'aiModelCheckTimer',
	BODY_POSITION: 'bodyPositionValues',
	IMBALANCE_ALERT: 'imbalanceAlert',
};

export const propertiesToValidate = [
	AiConfigField.THRESHOLD_VALUES,
	AiConfigField.NOT_ALONE_TRIGGER_ALERT,
	AiConfigField.SNOOZE_VALUES,
	AiConfigField.ALERTS,
	AiConfigField.STAT_ALERTS,
	AiConfigField.COVERED_RAILS_TRIGGER_ALERT,
	AiConfigField.BODY_POSITION,
	AiConfigField.IMBALANCE_ALERT,
];

export const FallPreventionTypes = [PatientAiSetting.FALL_PREVENTION, ...FallPreventionSettingTypes];

export const GettingOutOfBedThresholdValues = [
	{ value: null, type: AiModelThresholdType.GETTING_OUT_OF_BED_LOW },
	{ value: null, type: AiModelThresholdType.GETTING_OUT_OF_BED_MEDIUM },
	{ value: null, type: AiModelThresholdType.GETTING_OUT_OF_BED_HIGH },
];

export const RailsThresholdValues = [
	{ value: null, type: AiModelThresholdType.RAIL_DOWN },
	{ value: null, type: AiModelThresholdType.RAIL_UP },
];

export const FallDetectionThresholdValues = [{ value: null, type: AiModelThresholdType.FALL_SKELETON_MODEL }];

export const AlertAndSettingMapping = {
	[AiAlertId.RAILS]: AiSetting.RAILS,
	[AiAlertId.PATIENT_SITTING]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_STANDING]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_FACE_DOWN]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_FRONT]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_SIDE]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_LEFT_SIDE]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_RIGHT_SIDE]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_INACTIVE]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_INACTIVE_INFORMATION]: AiSetting.PRESSURE_INJURY,
	[AiAlertId.FALL_DETECTION]: AiSetting.FALL_DETECTION,
	[AiAlertId.PATIENT_GETTING_OUT_OF_BED]: AiSetting.GET_OUT_OF_BED,
	[AiAlertId.PATIENT_MOBILITY]: AiSetting.PATIENT_MOBILITY,
	[AiAlertId.LOW_IV_BAG]: AiSetting.IV_BAG_FLUID_LEVEL,
};
export const GeneralAiSettings = [PatientAiSetting.AI_DRAWING, PatientAiSetting.SOUND_ON_AI_ALERT];

export const AiConfigurationSettings = [
	AiSetting.GET_OUT_OF_BED,
	AiSetting.RAILS,
	AiSetting.FALL_DETECTION,
	AiSetting.PRESSURE_INJURY,
	AiSetting.PATIENT_MOBILITY,
	AiSetting.AI_VOICE_ANALYSIS,
];

export const ImbalanceTypes = [
	{ superScript: 'st', value: 1, isChecked: false },
	{ superScript: 'nd', value: 2, isChecked: false },
	{ superScript: 'rd', value: 3, isChecked: false },
	{ superScript: 'th', value: 4, isChecked: false },
	{ superScript: 'th', value: 5, isChecked: false },
	{ superScript: 'th', value: 6, isChecked: false },
	{ superScript: 'th', value: 7, isChecked: false },
	{ superScript: 'th', value: 8, isChecked: false },
];

export const PatientAiSettingEditType = {
	IS_ENABLED: 1,
	NOTIFICATION_OPTIONS_ALERTS: 2,
	NOTIFICATION_OPTIONS_STAT_ALARM_ACTIVATION: 3,
	SNOOZE_TIME: 4,
	RAILS: 5,
	PRESSURE_INJURY_TIMER: 6,
	PRESSURE_INJURY_BODY_POSITIONS: 7,
	GOB_SENSITIVITY: 8,
	IV_BAG_WARNING_VALUE: 9,
	AI_AUDIO_ANALYSIS_EVENTS: 10,
};

export const ValidAnalyticsTypes = [
	AiAnalyticsTypeId.BED_LYING_DOWN,
	AiAnalyticsTypeId.BED_LYING_FRONT,
	AiAnalyticsTypeId.BED_LYING_SIDE,
	AiAnalyticsTypeId.BED_SITTING,
	AiAnalyticsTypeId.WALKING,
	AiAnalyticsTypeId.CHAIR_LYING_DOWN,
	AiAnalyticsTypeId.CHAIR_LYING_FRONT,
	AiAnalyticsTypeId.CHAIR_LYING_SIDE,
	AiAnalyticsTypeId.CHAIR_SITTING,
];

export const FallPreventionAlertTypes = [AiAlertId.RAILS, AiAlertId.PATIENT_GETTING_OUT_OF_BED, AiAlertId.FALL_DETECTION];

export const AiVoiceAnalysisEvent = {
	SNEEZING: 1,
	SNORING: 2,
	COUGHING: 3,
	CRYING_BABY: 4,
	SCREAMING: 5,
	HELP: 6,
	LOW_PRIORITY_ALARM: 7,
	MEDIUM_PRIORITY_ALARM: 8,
	HIGH_PRIORITY_ALARM: 9,
	ALARM_OFF: 10,
	VITAL_SIGNS_ALARM: 11,
};

export const ActivityTypes = [
	AiAlertActivityType.ACKNOWLEDGED,
	AiAlertActivityType.AI_FAKE_ALERT,
	AiAlertActivityType.AI_FORWARD_TO_NURSES,
];
