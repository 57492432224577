import { participant } from '@solaborate/calls';

class RemoteHelloParticipant extends participant.Hello {
	/**
	 * @param {import('@solaborate/calls').models.Participant} model
	 * @param {object} params
	 * @param {string} params.id
	 * @param {string} params.conferenceId
	 * @param {string} params.localParticipantId
	 * @param {import('@solaborate/calls/webrtc').Connection} params.connection
	 * @param {import('@solaborate/calls/webrtc').LocalTrackController} params.localTrackController
	 * @param {participant.RemoteTrackController} params.remoteTrackController
	 * @param {import('@solaborate/calls').Socket} params.socket
	 */
	constructor(model, params) {
		super(model, params);

		this.id = model.id;
		this.objectId = model.objectId;
		this.objectType = model.objectType;
		this.name = model.name;
		this.picture = model.picture;
		this.clientType = model.clientType;
		this.hasCompanion = model.hasCompanion;
		this.isCompanion = model.isCompanion;
		this.remoteTrackController = params.remoteTrackController;
	}

	/**
	 * @param {import('@solaborate/calls').models.Participant} model
	 * @override
	 */
	onModelChanged = model => {
		this.id = model.id;
		this.objectId = model.objectId;
		this.objectType = model.objectType;
		this.name = model.name;
		this.picture = model.picture;
		this.clientType = model.clientType;
		this.hasCompanion = model.hasCompanion ?? this.hasCompanion;
		this.isCompanion = model.isCompanion ?? this.isCompanion;

		super.onModelChanged(model);
	};
}

export default RemoteHelloParticipant;
