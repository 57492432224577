import {
	CompanyCallSettings,
	ConfigurableDigitalBackgroundSettings,
	MonitoringSettings,
	PatientTabs,
	RoomModesSettings,
	RouteSetting,
	configurableRoomType,
	mobileSettings,
} from 'constants/configurationEnums.js';
import _ from 'lodash';
import { getConfigurationMenu, getConfigurationValue, isJSON } from './commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';

export const findConfigurationValue = (list, typeId) =>
	list.find(x => x.settingTypeId === typeId)?.value.toLowerCase() === 'true';

export const buildVisualsSettings = newSettings => {
	const defaultSettings = _.cloneDeep(ConfigurableDigitalBackgroundSettings);
	newSettings.forEach(item => {
		if (defaultSettings[item.settingTypeId]) {
			defaultSettings[item.settingTypeId].value = item.value;
			defaultSettings[item.settingTypeId].isLocked = item.isLocked;
		}
	});
	return defaultSettings;
};

export const buildHealthSystemConfigurations = settingsResponse => {
	const healthSystemConfigsList = [...Object.values(MonitoringSettings), ...Object.values(PatientTabs)];
	const healthSystemConfigs = {};
	settingsResponse.forEach(config => {
		if (healthSystemConfigsList.includes(config.settingTypeId)) {
			healthSystemConfigs[config.settingTypeId] = {
				value: JSON.parse(config.value),
				...(config.variant && { variant: config.variant }),
			};
		}
	});

	return healthSystemConfigs;
};

export const buildCallSettings = settings => {
	const result = {};
	Object.entries(CompanyCallSettings).forEach(([, value]) => {
		const found = settings.find(item => item.settingTypeId === value);

		if (found && !isJSON(found.value)) {
			result[value] = found.value;
		} else {
			result[value] = found ? JSON.parse(found.value) : false;
		}
	});
	return result;
};

export const getAdminGeneralSettingConfigValue = (adminConfigurableMenu, settingTypeId) => {
	const adminConfigs = getConfigurationMenu(adminConfigurableMenu, getUserRole());
	return getConfigurationValue(adminConfigs[settingTypeId]);
};

export const buildMobileCompanyConfigurations = (settings = []) => {
	const existsInConfigArr = key => Object.values(mobileSettings).includes(key);
	const result = {};
	settings.forEach(setting => {
		if (existsInConfigArr(setting.settingTypeId)) {
			result[setting.settingTypeId] = JSON.parse(setting.value);
		}
	});
	return result;
};

export const buildAllConfigurations = (response = []) => {
	const result = {};
	response.forEach(item => {
		if (!item.roomTypeId) {
			result[item.settingTypeId] = {
				value: ['true', 'false'].includes(item.value) ? JSON.parse(item.value) : item.value,
				isLocked: item.isLocked,
				...(item.variant && { variant: item.variant }),
			};
		}
	});

	return result;
};

export const buildRoomModesConfigurations = (response = []) => {
	const result = {};
	const enabledByDefault = [RoomModesSettings.HELLOCARE, RoomModesSettings.HOSPITAL_AT_HOME];

	response.forEach(item => {
		if (item.roomTypeId) {
			result[`${item.settingTypeId}${item.roomTypeId}`] = {
				value: JSON.parse(item.value),
				isLocked: item.isLocked,
				roomTypeId: item.roomTypeId,
			};
		}
	});

	configurableRoomType().forEach(type => {
		enabledByDefault.forEach(mode => {
			result[`${mode}${type.value}`] = {
				value: true,
				isLocked: false,
				roomTypeId: type.value,
			};
		});
	});

	return result;
};

export const mapCompanyRouteSettings = settings =>
	settings.filter(item => Object.values(RouteSetting).includes(item.settingTypeId));

export const getConfiguredRoleDisplayName = (role, companySettings) => {
	switch (role) {
		case UserRoles.NURSE:
			return companySettings.nurseDisplayName;
		case UserRoles.DOCTOR:
			return companySettings.doctorDisplayName;
		default:
			return translate(role.toLowerCase());
	}
};
