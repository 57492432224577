import { getActiveDirectoriesHistory } from 'api/activeDirectory.js';
import {
	getTeamNetworkAccressConfigurationHistory,
	getTeamNetworkBandwidthConfigurationHistory,
	getTeamNetworkWiFiBandConfigurationHistory,
} from 'api/networkConfigurations.js';
import { getNtpConfigurationHistory } from 'api/ntpConfig.js';
import { getDeviceConfigurationHistory, getTeamConfigurationProfilesHistory } from 'api/teamConfigurationProfiles.js';
import { getUserIdleConfigurationHistory } from 'api/userIdleConfigurations.js';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Grid from 'components/Grid.jsx';
import { ConfigHistoryTypes, EndCallSource } from 'constants/configurationEnums.js';
import { Roles, TeamTypes, UserTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getFormattedDateWithHour } from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const ConfigurationsHistory = props => {
	const [isLoading, setLoading] = useState(true);
	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });
	const [activeConfig, setActiveConfig] = useState(null);
	const [pagination, setPagination] = useState({
		size: 10,
		index: 0,
	});

	const networkAcessRoles = Roles.filter(
		x => x.id !== UserTypes.ADMIN && x.id !== UserTypes.OWNER && x.id !== UserTypes.SUPER_ADMIN
	);

	const [configurations, setConfigurations] = useState({
		total: 0,
		configs: [],
		title: '',
	});

	const userIdleHeaders = [
		{ id: 'healthSystem', title: translator('healthSystem') },
		{ id: 'role', title: translator('role') },
		{ id: 'timeoutMinutes', title: translator('minutes') },
		{ id: 'createdBy', title: translator('editedBy') },
		{ id: 'dateCreated', title: translator('dateEdited') },
	];

	const deviceConfigHeaders = [
		{ title: translator('department'), id: 'hospital' },
		{ title: translator('hdmiPort'), id: 'hdmiPort' },
		{ title: translator('defaultSource'), id: 'defaultSource' },
		{ title: translator('ringtoneVolume'), id: 'ringtoneVolume' },
		{ title: 'TV', id: 'tv' },
		{ title: translator('profileName'), id: 'profileName' },
		{ title: translator('editedBy'), id: 'createdBy' },
		{ title: translator('dateEdited'), id: 'dateCreated' },
	];

	const networkBandwidthHeaders = [
		{ id: 'floor', title: translator('name') },
		{ id: 'minP2PBitrateKbps', title: translator('minBandwidth') },
		{ id: 'maxP2PBitrateKbps', title: translator('maxBandwidth') },
		{ id: 'createdBy', title: translator('editedBy') },
		{ id: 'dateCreated', title: translator('dateEdited') },
	];

	const networWiFiBandHeaders = [
		{ id: 'floor', title: translator('name') },
		{ id: 'wiFiBand', title: translator('wiFiBand') },
		{ id: 'createdBy', title: translator('editedBy') },
		{ id: 'dateCreated', title: translator('dateEdited') },
	];

	const networkAccessHeaders = [
		{ id: 'role', title: translator('role') },
		{ id: 'ipAddress', title: translator('ipAddress') },
		{ id: 'isCIDR', title: translator('cidr') },
		{ id: 'createdBy', title: translator('editedBy') },
		{ id: 'dateCreated', title: translator('dateEdited') },
	];

	const activeDirectoriesHeaders = [
		{ id: 'hospital', title: translator('hospital') },
		{ id: 'domain', title: translator('domain') },
		{ id: 'validGroupname', title: translator('validGroupName') },
		{ id: 'createdBy', title: translator('editedBy') },
		{ id: 'dateCreated', title: translator('dateEdited') },
	];

	const endCallSource = Object.values(EndCallSource(intl));

	const teamProfileHeaders = [
		{ title: translator('healthSystem'), id: 'healthSystem' },
		{ title: translator('profileName'), id: 'profileName' },
		{ title: 'URL', id: 'url' },
		{ title: translator('port'), id: 'port' },
		{ title: translator('siteId'), id: 'siteId' },
		{ title: translator('editedBy'), id: 'createdBy' },
		{ title: translator('dateEdited'), id: 'dateCreated' },
	];

	const ntpConfigHeaders = [
		{ id: 'healthSystemHospital', title: <>{`${translator('healthSystem')} ${translator('or')} ${translator('hospital')}`}</> },
		{ id: 'ntpUrl', title: translator('ntpUrl') },
		{ id: 'createdBy', title: translator('editedBy') },
		{ id: 'dateCreated', title: translator('dateEdited') },
	];

	const getClassName = config => {
		if (config.isActive) {
			return 'active-configuration';
		}
		if (config.isDeleted) {
			return 'deleted-configuration';
		}
		return '';
	};

	useEffect(() => {
		const localTranslator = id => intl.formatMessage({ id });
		(async () => {
			const { id, type } = props.match.params;
			const params = { id: id, pageSize: pagination.size, pageIndex: pagination.index };

			let configs = [];
			let total = 0;
			let title = '';

			if (type === ConfigHistoryTypes.UserIdle) {
				const result = await getUserIdleConfigurationHistory(params);
				if (!result.error) {
					setActiveConfig(result.userIdleConfiguration);
					configs = result.history;
					total = result.total;
					title = `${localTranslator('historyFor')} ${result.userIdleConfiguration?.team?.name || 'N/A'}`;
				}
			} else if (type === ConfigHistoryTypes.Devices) {
				const result = await getDeviceConfigurationHistory(id, {
					pageSize: pagination.size,
					pageIndex: pagination.index,
					teamTypeId: TeamTypes.DEPARTMENT,
				});
				configs = result.deviceConfigurations;
				total = result.total;
				title = `${localTranslator('historyFor')} ${result.deviceConfiguration?.team?.name || 'N/A'}`;
			} else if (type === ConfigHistoryTypes.ADs) {
				const result = await getActiveDirectoriesHistory({
					id,
					pageSize: pagination.size,
					pageIndex: pagination.index,
				});
				if (!result.error) {
					setActiveConfig(result.activeDirectory);
					configs = result.history;
					total = result.total;
					title = `${localTranslator('historyFor')} ${result.activeDirectory?.team?.name || 'N/A'}`;
				}
			} else if (type === ConfigHistoryTypes.TeamConfigurationProfiles) {
				const result = await getTeamConfigurationProfilesHistory({
					id,
					pageSize: pagination.size,
					pageIndex: pagination.index,
				});
				configs = result.history;
				total = result.total;
				title = `${localTranslator('historyFor')} ${result.teamConfigurationProfile?.team?.name || 'N/A'}`;
			} else if (type === ConfigHistoryTypes.NetworkBandwidth) {
				const result = await getTeamNetworkBandwidthConfigurationHistory(id, {
					pageSize: pagination.size,
					pageIndex: pagination.index,
				});
				if (!result.error) {
					setActiveConfig(result.teamNetworkBandwidthConfiguration);
					configs = result.history;
					total = result.total;
					title = `${localTranslator('historyFor')} ${result.teamNetworkBandwidthConfiguration?.team?.name || 'N/A'}`;
				}
			} else if (type === ConfigHistoryTypes.NetworkWiFiBand) {
				const result = await getTeamNetworkWiFiBandConfigurationHistory(id, {
					pageSize: pagination.size,
					pageIndex: pagination.index,
				});
				if (!result.error) {
					setActiveConfig(result.teamNetworkWiFiBandConfiguration);
					configs = result.history;
					total = result.total;
					title = `${localTranslator('historyFor')} ${result.teamNetworkWiFiBandConfiguration?.team?.name || 'N/A'}`;
				}
			} else if (type === ConfigHistoryTypes.NetworkAccess) {
				const result = await getTeamNetworkAccressConfigurationHistory({
					id,
					pageSize: pagination.size,
					pageIndex: pagination.index,
				});
				if (!result.error) {
					setActiveConfig(result.teamNetworkAccessConfiguration);
					configs = result.history;
					total = result.total;
					title = `${localTranslator('historyForConfig')}`;
				}
			} else if (type === ConfigHistoryTypes.NtpConfigurations) {
				const result = await getNtpConfigurationHistory(id, pagination.size, pagination.index);

				if (!result.error) {
					setActiveConfig(result.teamNetworkNtpConfiguration);
					configs = result.history;
					total = result.total;
					title = `${localTranslator('historyForConfig')}`;
				}
			}

			setConfigurations({
				configs,
				total,
				title,
			});
			setLoading(false);
		})();
	}, [intl, pagination, props.intl, props.match.params]);

	const onPaginationChange = (size, index) => {
		setLoading(true);
		setPagination({
			index,
			size,
		});
	};

	const convertDate = date => {
		const stillUtc = moment.utc(date).toDate();
		const localDate = moment(stillUtc);
		return moment(localDate).format('MM/DD/YYYY-hh:mm');
	};

	const transformConfigurations = () => {
		const configs = configurations.configs.map(config => {
			switch (props.match.params.type) {
				case ConfigHistoryTypes.UserIdle:
					return {
						healthSystem: config?.team?.name || 'N/A',
						role: config.role.name,
						timeoutMinutes: config.isEnabled ? config.timeoutMinutes : translate('disabled'),
						createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
						dateCreated: getFormattedDateWithHour(config.dateCreated),
						className: config.isActive ? 'active-configuration' : '',
						id: config.id,
					};
				case ConfigHistoryTypes.Devices:
					return {
						hospital: config?.team?.name || 'N/A',
						hdmiPort: config.hdmiPort,
						defaultSource: endCallSource.find(source => source.id === config.defaultSource)?.value,
						ringtoneVolume: config.ringtoneVolume,
						tv: config.tv,
						profileName: config.teamConfigurationProfile ? config.teamConfigurationProfile.profileName : '',
						createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
						dateCreated: getFormattedDateWithHour(config.dateCreated),
						className: getClassName(config),
					};
				case ConfigHistoryTypes.ADs:
					return {
						hospital: config?.team?.name || 'N/A',
						domain: config.domain,
						validGroupname: config.validGroupName,
						createdBy: `${config?.userEdited?.firstName} ${config?.userEdited?.lastName}`,
						dateCreated: getFormattedDateWithHour(config.dateCreated),
						className: config.isActive ? 'active-configuration' : '',
						id: config.id,
					};
				case ConfigHistoryTypes.TeamConfigurationProfiles:
					return {
						healthSystem: config?.team?.name || 'N/A',
						profileName: config.profileName,
						url: config.url,
						port: config.port,
						siteId: config.siteId,
						createdBy: config.userEdited ? `${config.userEdited.firstName} ${config.userEdited.lastName}` : '',
						dateCreated: getFormattedDateWithHour(config.dateCreated),
						className: config.isActive ? 'active-configuration' : '',
					};
				case ConfigHistoryTypes.NetworkBandwidth:
					return {
						floor: config?.team?.name || 'N/A',
						minP2PBitrateKbps: config.minP2PBitrateKbps,
						maxP2PBitrateKbps: config.maxP2PBitrateKbps,
						createdBy: `${config.userEdited.firstName} ${config.userEdited.lastName}`,
						dateCreated: getFormattedDateWithHour(config.dateEdited),
						className: getClassName(config),
						id: config.id,
					};
				case ConfigHistoryTypes.NetworkWiFiBand:
					return {
						floor: config?.team?.name || 'N/A',
						wiFiBand: config.wiFiBand.description,
						createdBy: `${config.userEdited.firstName} ${config.userEdited.lastName}`,
						dateCreated: getFormattedDateWithHour(config.dateEdited),
						className: getClassName(config),
						id: config.id,
					};
				case ConfigHistoryTypes.NetworkAccess:
					return {
						role: networkAcessRoles.find(x => x.id === config.roleId)?.role,
						ipAddress: config.ipAddress,
						isCIDR: config.isCIDR ? (
							<span
								className='material-icons-outlined cursor-pointer'
								data-tooltip={translator('representsRangeOfIps')}
								data-position='top'>
								layers
							</span>
						) : (
							''
						),
						createdBy: `${config.userEdited.firstName} ${config.userEdited.lastName}`,
						dateCreated: getFormattedDateWithHour(config.dateEdited),
						className: getClassName(config),
						id: config.id,
					};
				case ConfigHistoryTypes.NtpConfigurations:
					return {
						healthSystemHospital: `${translator(
							config?.team.typeId === TeamTypes.HEALTH_SYSTEM ? 'healthSystem' : 'hospital'
						)}: ${config?.team?.name || 'N/A'}`,
						ntpUrl: (
							<>
								{config.ntpUrls.map(el => (
									<>
										<span>{el}</span>
										<br />
									</>
								))}
							</>
						),
						createdBy: `${config.userEdited.firstName} ${config.userEdited.lastName}`,
						dateCreated: getFormattedDateWithHour(config.dateEdited),
						id: config.id,
					};
				default:
					return null;
			}
		});

		if (configs.length) {
			const ac = getActiveConfig();
			if (ac) {
				// @ts-ignore
				configs.unshift(ac);
			}
		}
		return configs;
	};

	const getActiveConfig = () => {
		switch (props.match.params.type) {
			case ConfigHistoryTypes.NetworkBandwidth:
				return {
					floor: activeConfig?.team?.name || 'N/A',
					minP2PBitrateKbps: activeConfig.minP2PBitrateKbps,
					maxP2PBitrateKbps: activeConfig.maxP2PBitrateKbps,
					createdBy: `${activeConfig.userCreated.firstName} ${activeConfig.userCreated.lastName}`,
					dateCreated: getFormattedDateWithHour(activeConfig.dateCreated),
					className: 'active-configuration',
					id: activeConfig.id,
				};
			case ConfigHistoryTypes.NetworkWiFiBand:
				return {
					floor: activeConfig?.team?.name || 'N/A',
					wiFiBand: activeConfig.wiFiBand.description,
					createdBy: `${activeConfig.userCreated.firstName} ${activeConfig.userCreated.lastName}`,
					dateCreated: getFormattedDateWithHour(activeConfig.dateCreated),
					className: 'active-configuration',
					id: activeConfig.id,
				};
			case ConfigHistoryTypes.ADs:
				return {
					hospital: activeConfig?.team?.name || 'N/A',
					domain: activeConfig.domain,
					validGroupname: activeConfig.validGroupName,
					createdBy: `${activeConfig?.userCreated?.firstName} ${activeConfig?.userCreated?.lastName}`,
					dateCreated: moment(moment.utc(activeConfig.dateCreated).toDate()).format('MM/DD/YYYY-HH:mm'),
					className: 'active-configuration',
					id: activeConfig.id,
				};
			case ConfigHistoryTypes.NetworkAccess:
				return {
					role: networkAcessRoles.find(x => x.id === activeConfig?.roleId)?.role,
					ipAddress: activeConfig.ipAddress,
					isCIDR: activeConfig.isCIDR ? (
						<span
							className='material-icons-outlined cursor-pointer'
							data-tooltip={translator('representsRangeOfIps')}
							data-position='top'>
							layers
						</span>
					) : (
						''
					),
					createdBy: `${activeConfig.userCreated.firstName} ${activeConfig.userCreated.lastName}`,
					dateCreated: getFormattedDateWithHour(activeConfig.dateCreated),
					className: 'active-configuration',
					id: activeConfig.id,
				};
			case ConfigHistoryTypes.NtpConfigurations:
				return {
					healthSystemHospital: `${translator(
						activeConfig?.team.typeId === TeamTypes.HEALTH_SYSTEM ? 'healthSystem' : 'hospital'
					)}: ${activeConfig?.team?.name || 'N/A'}`,
					ntpUrl: (
						<>
							{activeConfig.ntpUrls.map(el => (
								<>
									<span>{el}</span>
									<br />
								</>
							))}
						</>
					),
					createdBy: `${activeConfig.userCreated.firstName} ${activeConfig.userCreated.lastName}`,
					dateCreated: getFormattedDateWithHour(activeConfig.dateCreated),
					id: activeConfig.id,
					className: 'active-configuration',
				};
			default:
				return null;
		}
	};

	const getHeaders = () => {
		switch (props.match.params.type) {
			case ConfigHistoryTypes.UserIdle:
				return userIdleHeaders;
			case ConfigHistoryTypes.Devices:
				return deviceConfigHeaders;
			case ConfigHistoryTypes.NetworkBandwidth:
				return networkBandwidthHeaders;
			case ConfigHistoryTypes.ADs:
				return activeDirectoriesHeaders;
			case ConfigHistoryTypes.NetworkWiFiBand:
				return networWiFiBandHeaders;
			case ConfigHistoryTypes.NetworkAccess:
				return networkAccessHeaders;
			case ConfigHistoryTypes.TeamConfigurationProfiles:
				return teamProfileHeaders;
			case ConfigHistoryTypes.NtpConfigurations:
				return ntpConfigHeaders;
			default:
				return [];
		}
	};

	return (
		<MainLayout>
			<div className='users-view'>
				<Grid stretch='100%'>
					<main className='main-view'>
						<section>
							<Grid width='100%' vertAlign='start'>
								<div>
									<span className='configurations-back-btn' data-cy='configurationsBackBtn' onClick={() => window.history.back()}>
										<i className='material-icons'>keyboard_arrow_left</i>
										{translate('backToConfigs')}
									</span>
									<h3
										style={{
											marginLeft: 5,
										}}>
										{configurations.title}
									</h3>
								</div>
								<CustomTable isLoading={isLoading} headers={getHeaders()} rows={transformConfigurations()} />
								<Pagination
									totalCount={configurations.total}
									pageSize={pagination.size}
									pageIndex={pagination.index}
									onChange={(pageSize, pageIndex) => onPaginationChange(pageSize, pageIndex)}
								/>
							</Grid>
						</section>
					</main>
				</Grid>
			</div>
		</MainLayout>
	);
};

export default ConfigurationsHistory;
