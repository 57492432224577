import React, { Fragment, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import Select from 'components/Common/FormElements/Select.jsx';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import { createTranslationProvider, getTranslationProviders } from 'api/translationServices.js';
import { default as FormElementInput } from 'components/Common/FormElements/Input.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';

const amnNumberExtension = '@csip.stratusvideo.com';

const TranslationServicesForm = props => {
	const intl = useIntl();
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [translationProviders, setTranslationProviders] = useState([]);

	const translationServices = [
		getConfigurationValue(companyConfigurations[IntegrationTypesSettings.GLOBO]) && { value: 'Globo' },
		getConfigurationValue(companyConfigurations[IntegrationTypesSettings.AMN]) && { value: 'AMN' },
		{ value: 'Language Line' },
	].filter(Boolean);

	useEffect(() => {
		const fetchTranslationServices = async () => {
			const response = await getTranslationProviders();
			if (response.error) {
				setError(response.error.message);
			} else {
				const filteredResponse = response.translationProviders.reduce((acc, item) => {
					if (translationServices.some(el => el.value === item.name)) {
						acc.push({
							id: item.id,
							value: item.name,
							fields: item.fields,
						});
					}
					return acc;
				}, []);
				setTranslationProviders(filteredResponse);
			}
			setIsLoading(false);
		};
		fetchTranslationServices();
	}, []);

	const handleSubmitMyForm = async (values, { resetForm }) => {
		const fieldsOfService = translationProviders.find(item => item.id === values.selectedTranslator);
		const params = {
			translationProviderId: values.selectedTranslator,
			values: fieldsOfService.fields.map(field => ({
				translationProviderFieldId: field.id,
				value: field.name === 'PHONE_NUMBER' ? `${values[field.name]}${amnNumberExtension}` : values[field.name],
			})),
		};
		const response = await createTranslationProvider(values.healthSystem, params);
		if (response.error) {
			setError(response.error.message);
		} else {
			resetForm();
			props.onSucceeded();
			props.toggleModal();
		}
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{ healthSystem: props.selectedHealthSystem.value, selectedTranslator: null }}
			validationSchema={Yup.object().shape({
				healthSystem: Yup.string().required(intl.formatMessage({ id: 'healthSystemRequired' })),
			})}
			validateOnBlur={true}
			onSubmit={handleSubmitMyForm}>
			{({ values, handleSubmit, isSubmitting, isValid }) => {
				const fieldsOfService = translationProviders.find(item => item.id === values.selectedTranslator);
				return (
					<Modal
						display={props.isModalOpen}
						position='right'
						onModalSubmit={handleSubmit}
						onModalClose={props.toggleModal}
						shouldSubmitOnEnter={false}
						isSubmitDisabled={!isValid || !values.selectedTranslator}
						isLoading={isSubmitting || isLoading}>
						<Form>
							<h3>{translate('addTranslationService')}</h3>
							<Field
								name='selectedTranslator'
								type='select'
								label={`${intl.formatMessage({ id: 'selectedTranslator' })}*`}
								placeholder={intl.formatMessage({ id: 'selectedTranslator' })}
								items={translationProviders}
								component={Select}
							/>
							<Field
								name='healthSystem'
								type='select'
								label={`${intl.formatMessage({ id: 'selectHealthSystem' })}*`}
								placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
								description={intl.formatMessage({ id: 'selectHealthSystemOrAll' })}
								items={props.healthSystems}
								component={Select}
							/>
							{fieldsOfService?.fields.map((field, index) => (
								<Fragment key={field.name}>
									{field.name !== 'PHONE_NUMBER' && (
										<Field
											validate={value => (!value ? intl.formatMessage({ id: 'requiredField' }) : null)}
											name={field.name}
											type='select'
											label={`${field.name}*`}
											placeholder={field.name}
											value={field.value}
											items={props.healthSystems}
											component={FormElementInput}
											autoFocus={index === 0}
										/>
									)}
									{field.name === 'PHONE_NUMBER' && (
										<div className='flex right-align-items sip-default'>
											<Field
												validate={value => (!value ? intl.formatMessage({ id: 'requiredField' }) : null)}
												name={field.name}
												type='text'
												label={`${intl.formatMessage({ id: 'sipEndpoint' })}*`}
												placeholder='E.g. 123456'
												value={field.value}
												component={FormElementInput}
												autoFocus={true}
											/>
											<span>{amnNumberExtension}</span>
										</div>
									)}
								</Fragment>
							))}
							<Alert
								alertSelector='networkAccessConfigsMessage'
								display={error}
								message={error}
								variant='error'
								onClose={() => setError('')}
							/>
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default TranslationServicesForm;
