import { gatewayApi, localApiWithCookies } from 'constants/global-variables.js';

export const getEhrData = async () => {
	try {
		const url = `api/fhir/patient/observers`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCurrentSessionPatientData = async () => {
	try {
		const url = `/ehr-session/fhir/patient?sid=${sessionStorage.getItem('ehr.sid')}`;
		const { data } = await localApiWithCookies.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCurrentSessionPractitionerData = async () => {
	try {
		const url = `/ehr-session/fhir/practitioner?sid=${sessionStorage.getItem('ehr.sid')}`;
		const { data } = await localApiWithCookies.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomInfo = async (conferenceId, refToken) => {
	try {
		const { data } = await gatewayApi.get(`v1/virtual-sessions/${conferenceId}/otk/${refToken}/metadata`);
		return data;
	} catch (error) {
		return { error };
	}
};
