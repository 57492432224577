import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { Alert, Button } from 'components/index.js';
import { DeviceListLevel } from 'constants/enums.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { doNotAllowSpaceAsFirstCharacter, isThumbnailUrl, isValidUrl } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const PatientInfotainment = ({ itemToEdit, setConfigParams, configurationTypeId }) => {
	const intl = useIntl();
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(
		itemToEdit?.healthSystemId
			? {
					value: itemToEdit.healthSystemId,
					label: allHealthSystems.find(item => item.id === itemToEdit.healthSystemId).name,
			  }
			: { value: '0', label: 'All' }
	);
	const [selectedHospital, setSelectedHospital] = useState({ value: '0', label: 'All' });
	const [hospitals, setHospitals] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [items, setItems] = useState(
		itemToEdit?.items ?? [
			{
				link: { label: intl.formatMessage({ id: 'link' }), value: '' },
				thumbnail: { label: intl.formatMessage({ id: 'thumbnail' }), value: '' },
				content: { label: intl.formatMessage({ id: 'title' }), value: '' },
			},
		]
	);
	const [validations, setValidations] = useState({});

	useEffect(() => {
		const fetchHospitals = async () => {
			if (!selectedHealthSystem) {
				return;
			}
			let hsHospitals = [];
			if (selectedHealthSystem.value !== '0') {
				setIsLoading(true);
				const res = await getHealthSystemHospitals(selectedHealthSystem.value);
				if (res.error) {
					setError(res.error.message);
				} else {
					hsHospitals = res;
				}
			}
			const hospitalArray = hsHospitals.map(hospital => ({ value: hospital.id, label: hospital.name }));
			hospitalArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
			const foundHospital = hospitalArray.find(item => item.value === itemToEdit?.hospitalId);
			setHospitals(hospitalArray);
			setSelectedHospital(foundHospital || hospitalArray[0]);
			setIsLoading(false);
		};
		fetchHospitals();
	}, [selectedHealthSystem]);

	useEffect(() => {
		const configJson = items.filter(item => !Object.entries(item).every(([, value]) => !value.value));

		setConfigParams({
			configurationTypeId,
			nodeId: getNodeId().value !== '0' ? getNodeId().value : null,
			parentNodeId: selectedHospital.value !== '0' ? selectedHealthSystem.value : null,
			nodeName: getNodeId().label,
			nodeLevel: getNodeLevel(),
			configJson: JSON.stringify({ items: configJson }),
			validations,
		});
	}, [selectedHealthSystem, selectedHospital, configurationTypeId, items]);

	const getNodeId = () => (selectedHospital.value !== '0' ? selectedHospital : selectedHealthSystem);

	const getNodeLevel = () => {
		if (selectedHospital.value !== '0') {
			return DeviceListLevel.HOSPITAL;
		}
		if (selectedHealthSystem.value !== '0') {
			return DeviceListLevel.HEALTH_SYSTEM;
		}
		return null;
	};

	const transformArray = array => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		return newArray;
	};

	const getType = () => {
		let result = intl.formatMessage({ id: 'movies' });
		if (configurationTypeId === configurationTypeIds.MUSIC) {
			result = intl.formatMessage({ id: 'music' });
		}
		if (configurationTypeId === configurationTypeIds.LIVE_TV_CHANNELS) {
			result = intl.formatMessage({ id: 'liveTvChannels' });
		}
		return result;
	};

	const handleDeleteItem = (index, key) => {
		setItems(prevState => {
			const array = [...prevState.map(item => item)];
			array.splice(index, 1);
			return array;
		});
		setValidations(prevState => {
			const copiedState = { ...prevState };
			Object.keys(copiedState).forEach(key => {
				if (key.startsWith(index)) {
					delete copiedState[key];
				}
			});
			return copiedState;
		});
	};

	const handleValidations = (index, key, value) => {
		if (key === 'link' && !isValidUrl(value)) {
			setValidations(prevState => ({ ...prevState, [`${index}${key}`]: intl.formatMessage({ id: 'enterValidUrl' }) }));
		} else if (key === 'thumbnail' && !isThumbnailUrl(value)) {
			setValidations(prevState => ({
				...prevState,
				[`${index}${key}`]: translate('supportedFormats', { value: `'.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'` }),
			}));
		} else if (value === '') {
			setValidations(prevState => ({ ...prevState, [`${index}${key}`]: intl.formatMessage({ id: 'cannotBeEmpty' }) }));
		} else if (value.length > 200) {
			setValidations(prevState => ({ ...prevState, [`${index}${key}`]: `${intl.formatMessage({ id: 'maxLengthIs' })} 200` }));
		} else {
			setValidations(prevState => {
				const copiedState = { ...prevState };
				delete copiedState[`${index}${key}`];
				return copiedState;
			});
		}
	};

	return (
		<>
			<div className='input'>
				<p className='label'>{translate('selectHealthSystem')}</p>
				<p className='font-14'>{translate('selectHSForConfiguration')}</p>
				<Select
					value={selectedHealthSystem}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={transformArray(allHealthSystems)}
					onChange={setSelectedHealthSystem}
				/>
			</div>

			<div className='input'>
				<p className='label'>{translate('selectHospital')}</p>
				<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
				<Select
					isDisabled={hospitals.length === 1 || isLoading}
					value={selectedHospital}
					placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
					classNamePrefix='react-select'
					options={hospitals}
					onChange={setSelectedHospital}
				/>
			</div>
			<div className='infotainment-items'>
				{items.map((item, index) => (
					<div className='flex'>
						<span className='sequence-number'>{index + 1}.</span>
						{Object.keys(item).map(key => (
							<div key={key}>
								<input
									onKeyDown={doNotAllowSpaceAsFirstCharacter}
									type='text'
									value={item[key].value}
									placeholder={item[key].label}
									onChange={event => {
										setItems(prevState => {
											const itemState = [...prevState.map(elem => ({ ...elem }))];
											itemState[index][key].value = event.target.value;
											return itemState;
										});
										handleValidations(index, key, item[key].value);
									}}
								/>
								<span className='error font-12'>{validations[`${index}${key}`]}</span>
							</div>
						))}
						<Button onClick={() => handleDeleteItem(index)} icon='delete' background='transparent' color='red' />
					</div>
				))}
			</div>

			{items.length < 200 && (
				<div className='flex right-align-content top-20'>
					<Button
						text={translate('addRole', { value: getType() })}
						onClick={() => {
							setItems(prevState => [
								...prevState,
								{
									link: { label: intl.formatMessage({ id: 'link' }), value: '' },
									thumbnail: { label: intl.formatMessage({ id: 'thumbnail' }), value: '' },
									content: { label: intl.formatMessage({ id: 'title' }), value: '' },
								},
							]);
						}}
					/>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default PatientInfotainment;
