import Input from 'components/Input.jsx';
import TreeView from 'components/TreeView.jsx';
import translate from 'i18n-translations/translate.jsx';
import { CareEventPriority, CareEventStatusType } from 'constants/care-events.js';
import { useIntl } from 'react-intl';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { SingleValue, formatOptionLabel, getPriorityColor } from 'infrastructure/helpers/careEventsHelper.js';
import classNames from 'classnames';

const AddPatient = ({
	tree,
	sectorSearchValue,
	onSearchRoom,
	expandAllTreeItems,
	isTreeViewShown,
	onLinkClick,
	handleChange,
	values,
	errors,
	roomTypeId,
	getNurses,
	nurses,
	selectedNurse,
	setSelectedNurse,
	setSelectedPriority,
	selectedPriority,
}) => {
	const intl = useIntl();

	const priorities = [
		{ label: intl.formatMessage({ id: 'low' }), value: CareEventPriority.LOW },
		{ label: intl.formatMessage({ id: 'medium' }), value: CareEventPriority.MEDIUM },
		{ label: intl.formatMessage({ id: 'high' }), value: CareEventPriority.HIGH },
	];

	return (
		<>
			<label className='care-events-select'>
				<p className='care-event-title'>{translate('addPatientWithEvent')}</p>
				<span className='font-13 semi-bold'>{translate('firstName')}</span>
				<input
					type='text'
					name='firstName'
					placeholder={intl.formatMessage({ id: 'firstName' })}
					onChange={handleChange}
					className='first-name-input'
					value={values.firstName}
				/>
				<div>
					<span className='red-error'>{errors.firstName}</span>
				</div>
			</label>
			<label className='care-events-select'>
				<span className='font-13 semi-bold'>{translate('lastName')}</span>
				<input
					type='text'
					name='lastName'
					placeholder={intl.formatMessage({ id: 'lastName' })}
					value={values.lastName}
					onChange={handleChange}
					className='first-name-input'
				/>
				<div>
					<span className='red-error'>{errors.lastName}</span>
				</div>
			</label>
			<label className='care-events-select'>
				<span className='font-13 semi-bold'>{translate('emailAddress')}</span>
				<input
					type='text'
					name='email'
					placeholder={intl.formatMessage({ id: 'emailAddress' })}
					value={values.email}
					onChange={handleChange}
					className='first-name-input'
				/>
				<div>
					<span className='red-error'>{errors.email}</span>
				</div>
			</label>
			<label className='care-events-select'>
				<span className='font-13 semi-bold'>{translate('room')}</span>
				<Input
					className='tree-search'
					type='text'
					name='helloDeviceId'
					placeholder={intl.formatMessage({ id: 'searchRoomName' })}
					value={sectorSearchValue}
					onChange={onSearchRoom}
					validationOptions={{}}
					bottomSpace='15px'
					autoComplete='off'
				/>
				{!roomTypeId && Object.keys(errors).length > 0 && (
					<div>
						<span className='red-error'>{translate('roomCannotBeEmpty')}</span>
					</div>
				)}
				{isTreeViewShown && <TreeView data={tree} onLinkClick={onLinkClick} expandAll={expandAllTreeItems} isCareEvents={true} />}
			</label>
			<label className='care-events-select'>
				<label onClick={() => getNurses('')}>
					<span className='font-13 semi-bold'>{translate('careTeam')}</span>
					<AsyncSelect
						loadOptions={value => getNurses(value)}
						placeholder={intl.formatMessage({ id: 'selectCareTeam' })}
						onChange={setSelectedNurse}
						getOptionValue={option => option.value}
						classNamePrefix='custom-select'
						defaultOptions={nurses}
						isClearable
						value={selectedNurse}
						formatOptionLabel={formatOptionLabel}
						components={{ SingleValue }}
					/>
				</label>
			</label>
			{!selectedNurse && Object.keys(errors).length > 0 && (
				<div>
					<span className='red-error'>{translate('nurseCannotBeEmpty')}</span>
				</div>
			)}

			<label className={classNames('care-events-select', getPriorityColor(selectedPriority?.value))}>
				<span className='font-13 semi-bold'>{translate('priority')}</span>
				<Select
					value={selectedPriority}
					classNamePrefix='custom-select'
					options={priorities}
					onChange={setSelectedPriority}
					placeholder={intl.formatMessage({ id: 'selectPriority' })}
				/>
			</label>

			<label className='care-events-select new'>
				<span className='font-13 semi-bold'>{translate('status')}</span>
				<Input
					className='tree-search'
					type='text'
					value={intl.formatMessage({ id: CareEventStatusType.NEW })}
					validationOptions={{}}
					bottomSpace='15px'
					autoComplete='off'
					readonly
					disabled
				/>
			</label>
		</>
	);
};

export default AddPatient;
