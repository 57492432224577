import { useState, useEffect } from 'react';

function useHasFocus() {
	const [hasFocus, setHasFocus] = useState(document.hasFocus());

	useEffect(() => {
		const handleFocusChange = () => {
			setHasFocus(document.hasFocus());
		};

		const handleVisibilityChange = () => {
			setHasFocus(document.visibilityState === 'visible');
		};

		window.addEventListener('focus', handleFocusChange);
		window.addEventListener('blur', handleFocusChange);
		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			window.removeEventListener('focus', handleFocusChange);
			window.removeEventListener('blur', handleFocusChange);
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return hasFocus;
}

export default useHasFocus;
