import React, { useState } from 'react';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { setUserPreference } from 'api/users.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { AiSetting } from 'constants/configurationEnums.js';

const MonitoringSettingTab = {
	GENERAL: 0,
	AI: 1,
};

const ConfigurableButtonsMonitoring = ({
	setIsMonitoringSettingsOpen,
	roomId,
	adminConfigurations,
	updateMonitoringSetting,
	deviceId,
	adminAiSettingsConfigurations,
}) => {
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const editablePanels = [
		'nightVision',
		'rails',
		'getOutOfBed',
		'fallDetection',
		'patientMobility',
		'pressureInjury',
		'aiPrivacyMode',
		'handWashing',
		'ewsAiVitalSigns',
		'ivBagFluidLevel',
		'patientInfoBox',
		'whiteboard',
		'careEventsForSafetyCompanions',
		'precautions',
		'enablePrivacyMode',
	];

	const dispatch = useDispatch();
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const [currentTab, setCurrentTab] = useState(MonitoringSettingTab.GENERAL);

	const toggleConfigurableAlert = async key => {
		const configuration = currentTab === MonitoringSettingTab.GENERAL ? adminConfigurations : adminAiSettingsConfigurations;
		const value = !getConfigurationValue(configuration[key]);
		const dataToSend = {
			preference: {
				preferenceType: key,
				value: value.toString(),
			},
			roomId,
		};
		const response = await setUserPreference(dataToSend);
		if (response.error) {
			return;
		}
		updateMonitoringSetting({ ...configuration[key], value }, key);
		if (currentTab === MonitoringSettingTab.GENERAL && isNewExperience) {
			updatePatientData(configuration[key].title);
		}
	};

	const updatePatientData = property => {
		if (!editablePanels.includes(property)) {
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientNotes[deviceId],
					[property]: !patientNotes[deviceId][property],
				},
				deviceId
			)
		);
	};

	const shouldShowAiTab =
		Object.values(adminAiSettingsConfigurations)?.filter(item => !item?.isNotUserPreference && !item.isLocked).length &&
		!getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SILENT_MODE]);

	const getMonitoringSettingTabs = () =>
		[
			{ id: MonitoringSettingTab.GENERAL, title: 'general' },
			shouldShowAiTab && {
				id: MonitoringSettingTab.AI,
				title: 'aiSettings',
			},
		].filter(Boolean);

	return (
		<Modal
			display={true}
			position='center'
			className='configurable-settings-modal border-radius-modal-wrapper standard-modal-wrapper'
			onModalClose={setIsMonitoringSettingsOpen}
			onModalSubmit={setIsMonitoringSettingsOpen}>
			<div className='configurable-settings-modal-inner no-padding'>
				<div className='configurable-settings-main-title'>
					<h3>{translate('monitoringSettings')}</h3>
				</div>
				<div className='flex overflow-hidden'>
					{getMonitoringSettingTabs().length > 1 &&
						getMonitoringSettingTabs().map(item => (
							<div
								key={item.id}
								className={classNames('flex-1 monitoring-settings-tab', currentTab === item.id ? 'active' : '')}
								onClick={() => setCurrentTab(item.id)}>
								{translate(item.title)}
							</div>
						))}
				</div>
				<div className='flex flex-wrap'>
					{Object.entries(currentTab === MonitoringSettingTab.GENERAL ? adminConfigurations : adminAiSettingsConfigurations).map(
						([key, item]) => (
							<React.Fragment key={key}>
								{!item.isLocked && !item.isNotUserPreference && (
									<div className='flex' key={key} onClick={() => toggleConfigurableAlert(+key)}>
										<p className='flex-1'>{translate(item.title)}</p>
										<div className='rounded-slider-switch'>
											<input type='checkbox' checked={item.value} onChange={() => null} />
											<span className='rounded-slider' />
										</div>
									</div>
								)}
							</React.Fragment>
						)
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ConfigurableButtonsMonitoring;
