import React from 'react';
import { injectIntl } from 'react-intl';
import Button from 'components/Button.jsx';
import { IDENTITY_CONFIG } from 'constants/auth-constants.js';
import translate from 'i18n-translations/translate.jsx';

class IntegrateAppTab extends React.Component {
	openFitBit = () => {
		const clientId = IDENTITY_CONFIG.fitbit_client_id;
		const redirectUrl = IDENTITY_CONFIG.public_uri;
		const link = `https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUrl}health-data&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800`;
		window.open(link, '_blank');
	};

	render() {
		return (
			<div className='add-app-tab-wrapper'>
				<div className='add-app-tab-content' data-cy='addNewAppTab'>
					<div className='add-tab-title flex'>
						<div>
							<h3>{translate('addTab')}</h3>
							<p>{translate('addFavTabQuickerAccess')}</p>
						</div>
						<i className='material-icons cursor-pointer' onClick={this.props.onCloseAppsModal}>
							close
						</i>
					</div>
					<div className='add-tab-search'>
						<input
							type='text'
							placeholder={this.props.intl.formatMessage({ id: 'searchAppsFiles' })}
							maxLength={100}
							onChange={() => null}
						/>
					</div>
					<div className='add-tab-my-apps'>
						<h4>{translate('myApps')}</h4>
						<div className='flex add-tab-my-apps-grid' onClick={this.openFitBit}>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/1.png' />
								</div>
								<p>Fitbit</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/2.png' />
								</div>
								<p>Esquared Fitness</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/3.png' />
								</div>
								<p>MyFitnessPal</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/4.png' />
								</div>
								<p>FIIT</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/5.png' />
								</div>
								<p>moveGB</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/6.png' />
								</div>
								<p>Sleep++</p>
							</div>
						</div>
					</div>
					<div className='add-tab-other-apps'>
						<h4>{translate('otherApps')}</h4>
						<div className='flex add-tab-other-apps-grid'>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/7.png' />
								</div>
								<p>Headspace</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/8.png' />
								</div>
								<p>Edo</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/9.png' />
								</div>
								<p>TruBe</p>
							</div>
							<div>
								<div>
									<img alt='logo' src='https://static.solaborate.com/homecare-system/add-apps-modal/10.png' />
								</div>
								<p>Rise Today</p>
							</div>
						</div>
					</div>
					<div className='add-tab-buttons'>
						<Button
							className='cancel-btn'
							text={translate('cancel')}
							variant='white'
							size='medium'
							onClick={this.props.onCloseAppsModal}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default injectIntl(IntegrateAppTab);
