const FallDetection = props => (
	<svg width='26' height='27' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M1.65187 11.5607L2.19582 15.7261C2.30988 16.195 2.59355 16.5157 3.03612 16.6122C3.21527 16.6513 3.40099 16.649 3.5791 16.6054C4.23223 16.4475 4.52858 15.8577 4.37163 15.2046L3.96318 11.8902L6.46165 10.5254L9.73023 16.6746C9.87227 16.8333 10.0268 16.9803 10.1923 17.1143L17.7891 22.4534C18.0762 22.6552 18.4317 22.7346 18.7774 22.6743C19.1231 22.614 19.4307 22.4188 19.6325 22.1317C19.8343 21.8446 19.9137 21.4891 19.8534 21.1434C19.7931 20.7977 19.5979 20.4901 19.3108 20.2883L13.2503 16.0283L17.2822 14.1157L22.3152 16.197C22.4757 16.2634 22.6478 16.2976 22.8215 16.2975C22.9953 16.2975 23.1673 16.2632 23.3278 16.1967C23.4883 16.1301 23.6341 16.0326 23.7569 15.9098C23.8797 15.7869 23.9771 15.641 24.0436 15.4805C24.11 15.3199 24.1442 15.1479 24.1441 14.9742C24.1441 14.8004 24.1098 14.6284 24.0433 14.4679C23.9767 14.3074 23.8792 14.1616 23.7564 14.0388C23.6335 13.916 23.4876 13.8185 23.3271 13.7521L17.7491 11.4447C17.5784 11.3743 17.3948 11.3404 17.2101 11.3451C17.0254 11.3498 16.8438 11.393 16.6768 11.472L13.8723 12.8026C13.4619 12.944 13.106 12.749 12.8068 12.4127C11.7822 11.2722 10.3609 8.6957 11.0365 8.37498L13.4043 7.26271C13.5505 7.19431 13.6816 7.09743 13.7899 6.97775C13.8982 6.85807 13.9816 6.71799 14.0351 6.56571L15.5217 2.3535C15.575 2.2027 15.598 2.04288 15.5895 1.88317C15.581 1.72345 15.5412 1.56697 15.4722 1.42267C15.4032 1.27838 15.3065 1.14908 15.1875 1.04218C15.0685 0.935274 14.9297 0.852857 14.7788 0.799634C14.1442 0.57445 13.6091 1.15252 13.3829 1.78713L12.0181 5.20778L2.33912 10.2652C2.10903 10.3887 1.9208 10.5778 1.79827 10.8084C1.67575 11.0391 1.62543 11.3009 1.65187 11.5607Z'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeMiterlimit='10'
		/>
		<path
			d='M5.65918 6.375C6.8673 6.375 7.84668 5.39562 7.84668 4.1875C7.84668 2.97938 6.8673 2 5.65918 2C4.45106 2 3.47168 2.97938 3.47168 4.1875C3.47168 5.39562 4.45106 6.375 5.65918 6.375Z'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeMiterlimit='10'
		/>
		<path d='M24.7217 25.75L2.22168 25.75' stroke='#E0AF4C' strokeWidth='1.5' strokeLinecap='round' />
	</svg>
);
export default FallDetection;
