import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getAlertingSessions = async ({ healthSystemId, userId }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/saved-subscriptions`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAlertingSessionDevices = async ({ healthSystemId, userId, savedSubscriptionId }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/saved-subscriptions/${savedSubscriptionId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const saveAlertingSession = async ({ name, healthSystemId, userId }) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/saved-subscriptions`,
			{
				name,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteAlertingSession = async ({ healthSystemId, userId, savedSubscriptionId }) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/saved-subscriptions/${savedSubscriptionId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateAlertingSessionDevices = async ({ healthSystemId, userId, savedSubscriptionId }) => {
	try {
		const { data } = await gatewayApi.put(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/saved-subscriptions/${savedSubscriptionId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const subscribeToDeviceAlerts = async ({ healthSystemId, userId, deviceId, patientId }) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/devices/${deviceId}/subscriptions`,
			{
				deviceAssignedPatient: patientId,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const unsubscribeFromDeviceAlerts = async ({ healthSystemId, userId, deviceId }) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/devices/${deviceId}/subscriptions`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const unsubscribeFromAllDeviceAlerts = async ({ healthSystemId, userId }) => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/alerts/organizations/${getCompanyId()}/health-systems/${healthSystemId}/users/${userId}/devices/subscriptions`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
