import LightTheme from 'calls/styles/LightTheme.js';

const PressureInjuryHistoryIcon = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='17'
		viewBox='0 0 16 17'
		fill='none'
		color={LightTheme.colors.grayZero}
		{...props}>
		<path
			d='M8.99984 1L7.6665 2.33333L8.99984 3.66667'
			stroke='currentColor'
			strokeOpacity='0.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.99992 2.33301C11.6818 2.33301 14.6666 5.31778 14.6666 8.99967C14.6666 12.6816 11.6818 15.6663 7.99992 15.6663C4.31802 15.6663 1.33325 12.6816 1.33325 8.99967C1.33325 7.40127 1.89577 5.93425 2.83365 4.78579'
			stroke='currentColor'
			strokeOpacity='0.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.6665 6.66699V10.0003L8.99984 11.0003'
			stroke='currentColor'
			strokeOpacity='0.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default PressureInjuryHistoryIcon;
