import React from 'react';
import { useIntl } from 'react-intl';

const TableResendInviteButton = props => {
	const intl = useIntl();
	return (
		<i
			className='material-icons-outlined boxed-icon resend-icon'
			data-cy='resendInviteBtn'
			onClick={props.onClick}
			data-tooltip={intl.formatMessage({ id: 'resendInvite' })}
			data-position='top'>
			email
		</i>
	);
};

export default TableResendInviteButton;
