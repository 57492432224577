import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getFormattedValidDate } from 'infrastructure/helpers/dateHelper.js';

const FleetManagementDeviceStatus = ({ onlineDevices, solHelloDeviceId, lastOnline }) => {
	const intl = useIntl();
	const isChildOnline = onlineDevices.includes(solHelloDeviceId);

	return (
		<div
			data-tooltip={
				!isChildOnline ? `${intl.formatMessage({ id: 'lastOnline' })} ${getFormattedValidDate(lastOnline)} UTC` : null
			}
			data-position='top'
			className={classNames('status', { online: isChildOnline })}>
			{intl.formatMessage({ id: isChildOnline ? 'online' : 'offline' })}
		</div>
	);
};

export default FleetManagementDeviceStatus;
