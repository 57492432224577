import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useRouteMatch } from 'react-router';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import Conversation from 'views/Chat/Conversation.jsx';
import Files from 'views/Partials/Files.jsx';
import { decodeHtml, getGender, validateAge } from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as doctorActionCreators } from 'state/doctors/actions.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { getConversationByType, updateReadStatus } from 'api/messenger.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import IntegrateAppTab from 'components/IntegrateAppTab.jsx';
import translate from 'i18n-translations/translate.jsx';
import { ConversationType, PatientTabTypes } from 'constants/enums.js';
import Modal from 'components/Modal.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const DoctorsMain = () => {
	const [showDoctorData, setShowDoctorData] = useState(false);
	const [isIntegrateAppTabVisible, setIsIntegrateAppTabVisible] = useState(false);
	const [isDoctorProfilePictureVisible, setIsDoctorProfilePictureVisible] = useState(false);
	const [conversationId, setConversationId] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const userInfo = getUserInfo();
	const patientTabLinks = [
		{ link: 'Conversation', type: 'conversation' },
		{ link: 'Files', type: 'files' },
		{ link: 'Health Records', type: 'patient-history' },
	];
	const match = useRouteMatch();
	const myDoctors = useSelector(state => state.myDoctors);
	const user = useSelector(state => state.user);
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();

	useEffect(() => {
		const { params } = match;
		if (params.tab) {
			const tabIndex = patientTabLinks.findIndex(item => item.type === params.tab);
			openTab(tabIndex);
		}
		loadConversationId();
	}, []);

	useEffect(() => {
		const handleNewMessage = async data => {
			const { message } = data;
			const doctors = [...myDoctors.doctors];
			const isMe = parseInt(message.sender.objectId, 10) === userInfo.userId;
			const doctorId = isMe ? myDoctors.selectedDoctor.userId : parseInt(message.sender.objectId, 10);
			const index = doctors.findIndex(item => item.userId === doctorId);
			const isCurrentTabOpened =
				match.url.includes(`recent/${message.sender.objectId}`) &&
				PatientTabTypes.CONVERSATION === patientTabLinks[currentTab].type;
			if (index === -1) {
				return;
			}
			if (isCurrentTabOpened) {
				await updateReadStatus(data.conversationId);
				return;
			}
			const [recentDoctor] = doctors.splice(index, 1);
			const unReadMessageCount = isMe
				? recentDoctor?.lastActivity?.unReadMessageCount
				: recentDoctor?.lastActivity?.unReadMessageCount + 1;
			recentDoctor.lastActivity = {
				...recentDoctor.lastActivity,
				message: message.content,
				unReadMessageCount: isCurrentTabOpened ? 0 : unReadMessageCount || 0,
			};
			doctors.unshift(recentDoctor);
			dispatch(doctorActionCreators.setDoctors(doctors));
		};

		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [socket, currentTab, myDoctors.selectedDoctor.userId]);

	useEffect(() => {
		loadConversationId();
	}, [myDoctors.selectedDoctor.userId]);

	useEffect(() => {
		loadConversationId();
	}, [match.url]);

	const updateIsReadStatus = tabIndex => {
		const selectedDoctor = { ...myDoctors.selectedDoctor };
		if (PatientTabTypes.CONVERSATION === patientTabLinks[tabIndex].type && selectedDoctor.lastActivity) {
			selectedDoctor.lastActivity.unReadMessageCount = 0;
			dispatch(doctorActionCreators.setSelectedDoctor(selectedDoctor));
			const conversationNotifications = [...user.conversationNotifications];
			const selectedNotification = conversationNotifications.find(
				item => +item.contact.objectId === myDoctors.selectedDoctor.userId
			);
			if (!selectedNotification) {
				return;
			}
			selectedNotification.unreadMessagesCount = 0;
			dispatch(userActionCreators.setConversationNotifications(conversationNotifications));
		}
	};

	const openTab = tabIndex => {
		updateIsReadStatus(tabIndex);
		setCurrentTab(tabIndex);
	};

	const loadConversationId = async () => {
		const response = await getConversationByType(ConversationType.INDIVIDUAL, myDoctors.selectedDoctor.userId, 0, false);
		setConversationId(response.conversation.id);
		updateIsReadStatus(0);
	};

	const toggleDoctorProfilePictureModal = () => {
		if (myDoctors.selectedDoctor.profilePicture) {
			setIsDoctorProfilePictureVisible(prevState => !prevState);
		}
	};

	return (
		<div className='patient-convo doctors-convo full-height'>
			<div className={!showDoctorData ? 'expanded-patient-view' : 'unexpanded-patient-view'}>
				<span
					className='toggle-patient-data'
					data-cy='hideUsersProfile'
					onClick={() => setShowDoctorData(prevState => !prevState)}>
					{showDoctorData && (
						<>
							<img src={`${healthCareCdnUrl}hide-profile.svg`} alt='icon' />
							<span>
								{translate('hideProfile', {
									value: myDoctors.selectedDoctor && decodeHtml(myDoctors.selectedDoctor.firstName),
								})}
							</span>
						</>
					)}
					{!showDoctorData && (
						<>
							<img src={`${healthCareCdnUrl}show-profile.svg`} alt='icon' />
							<span>
								{translate('showProfile', {
									value: myDoctors.selectedDoctor && decodeHtml(myDoctors.selectedDoctor.firstName),
								})}
							</span>
						</>
					)}
				</span>
				<Tabs
					activeIndex={currentTab}
					onChange={index => {
						openTab(index);
					}}>
					<TabList>
						<Tab>
							{myDoctors.selectedDoctor.lastActivity?.unReadMessageCount > 0 && (
								<div className='conversation-label-blue'>{myDoctors.selectedDoctor.lastActivity.unReadMessageCount}</div>
							)}
							{translate('conversation')}
						</Tab>
						<Tab> {translate('files')}</Tab>
						<li className='cursor-pointer'>
							<span onClick={() => setIsIntegrateAppTabVisible(prevState => !prevState)}>+</span>
						</li>
					</TabList>
					<TabPanels>
						<TabPanel>
							{conversationId && (
								<Conversation
									selectedUser={{
										id: myDoctors.selectedDoctor.userId,
										name: `${myDoctors.selectedDoctor.firstName} ${myDoctors.selectedDoctor.lastName}`,
										profilePicture: myDoctors.selectedDoctor.profilePicture,
									}}
									conversationId={conversationId}
									hideRightSide={true}
									isPatientDoctorChat={true}
								/>
							)}
						</TabPanel>
						<TabPanel>
							<Files
								selectedUser={{
									owner: {
										id: myDoctors.selectedDoctor.userId,
										fullName: `${myDoctors.selectedDoctor.firstName} ${myDoctors.selectedDoctor.lastName}`,
										profilePicture: myDoctors.selectedDoctor.profilePicture,
									},
								}}
							/>
						</TabPanel>
						<TabPanel />
					</TabPanels>
				</Tabs>
				<div className='tabs-box-shadow' />
				{myDoctors.selectedDoctor && showDoctorData && (
					<>
						<div className='patient-info'>
							<div>
								<div className='patient-info-header'>
									<div
										className={`${myDoctors.selectedDoctor.profilePicture ? 'cursor-pointer' : ''}`}
										onClick={toggleDoctorProfilePictureModal}>
										<ProfilePicture
											firstName={myDoctors.selectedDoctor.firstName}
											lastName={myDoctors.selectedDoctor.lastName}
											profilePicture={myDoctors.selectedDoctor.profilePicture}
										/>
									</div>
									<h3>{decodeHtml(`${myDoctors.selectedDoctor.firstName} ${myDoctors.selectedDoctor.lastName}`)}</h3>
									<div className='flex patient-info-call'>
										{currentTab !== 0 && <div className='patient-info-open-convo' onClick={() => openTab(1)} />}
									</div>
								</div>
								<div className='patient-info-body'>
									<div>
										<p>{translate('sex')}</p>
										<p>{getGender(myDoctors.selectedDoctor.genderId).description}</p>
									</div>
									{validateAge(myDoctors.selectedDoctor.dateOfBirth) && (
										<div>
											<p>{translate('age')}</p>
											<p>{moment().diff(myDoctors.selectedDoctor.dateOfBirth, 'years', false)}</p>
										</div>
									)}
									<div>
										<p>{translate('phoneNumber')}</p>
										<p>{myDoctors.selectedDoctor.phoneNr}</p>
									</div>
								</div>
							</div>
						</div>
						<Modal
							display={isDoctorProfilePictureVisible && myDoctors.selectedDoctor.profilePicture}
							onModalClose={toggleDoctorProfilePictureModal}
							position='center'
							className='standard-modal-wrapper modal-wrapper-wo-btn maximize-profile-modal'>
							<ProfilePicture
								fullName={`${myDoctors.selectedDoctor.firstName} ${myDoctors.selectedDoctor.lastName}`}
								profilePicture={myDoctors.selectedDoctor.profilePicture}
								dynamicSize={true}
							/>
						</Modal>
					</>
				)}
				{isIntegrateAppTabVisible && (
					<IntegrateAppTab onCloseAppsModal={() => setIsIntegrateAppTabVisible(prevState => !prevState)} />
				)}
			</div>
		</div>
	);
};

export default DoctorsMain;
