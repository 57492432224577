import { deleteTVDeviceConfiguration, getTeamConfigurations } from 'api/deviceConfig.js';
import { getHospitalDepartments } from 'api/healthSystems.js';
import { deleteTeamConfigurationProfile, getTeamConfigurationProfiles } from 'api/teamConfigurationProfiles.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { deleteSleepScheduleConfiguration, getSleepScheduleConfigurations } from 'api/whiteboardConfigurations.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Modal from 'components/Modal.jsx';
import {
	ConfigHistoryTypes,
	EndCallSource,
	IntegrationTypesSettings,
	TeamConfigurationIntegrationTypes,
} from 'constants/configurationEnums.js';
import { SectorTypes, TeamTypes, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import HealthSystemConfigForm from 'containers/Configurations/HealthSystemConfigForm.jsx';
import SleepSchedule from 'containers/Configurations/SleepSchedule.jsx';
import TVConfig from 'containers/Configurations/TVConfig.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { formattedDate, formatTimeStringTo12hrFormat } from 'infrastructure/helpers/dateHelper.js';
import { capitalizeFirstLetter, getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import SleepScheduleTooltip from 'containers/Configurations/SleepScheduleTooltip.jsx';
import WhiteboarConfigFilters from 'containers/Configurations/WhiteboardConfigFilters.jsx';
import TVConfigFilters from 'containers/Configurations/TVConfigFilters.jsx';
import TableHistoryButton from 'icons/Admin/TableHistoryButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';

const delayToMinutes = value => {
	if (value < 0) {
		return '';
	}
	return value / 60 >= 1 ? value / 60 : value;
};
const translatedKey = value => {
	let translatedTimeKey = 'seconds';
	if (value === 60) {
		translatedTimeKey = 'minute';
	} else if (value > 60) {
		translatedTimeKey = 'nextMinutes';
	} else if (value < 0) {
		translatedTimeKey = 'never';
	}

	return translatedTimeKey;
};

const deviceConfigHeaders = [
	{ id: 'healthSystem', title: translate('healthSystem') },
	{ id: 'hospitalName', title: translate('hospital') },
	{ id: 'hospital', title: translate('sector') },
	{ id: 'hdmiPort', title: translate('hdmiPort') },
	{ id: 'defaultSource', title: translate('defaultSource') },
	{ id: 'ringtoneVolume', title: translate('ringtoneVolume') },
	{ id: 'tv', title: 'TV' },
	{ id: 'profileName', title: translate('profileName') },
	{ id: 'createdBy', title: translate('createdBy') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'actions', title: '' },
];

const healthSystemConfigHeaders = [
	{ id: 'healthSystem', title: translate('healthSystem') },
	{ id: 'profileName', title: translate('profileName') },
	{ id: 'url', title: 'URL' },
	{ id: 'port_or_siteId', title: translate('port/siteId') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'actions', title: '' },
];

const whiteboardConfigHeaders = [
	{ id: 'healthSystem', title: translate('healthSystem') },
	{ id: 'hospital', title: translate('hospital') },
	{ id: 'department', title: translate('department') },
	{ id: 'displayOffTime', title: translate('startTime') },
	{ id: 'displayOnTime', title: translate('endTime') },
	{ id: 'screenDelay', title: translate('screenTimeout') },
	{ id: 'dateCreated', title: translate('dateCreated') },
	{ id: 'actions', title: '' },
];

const DeviceConfig = props => {
	const intl = useIntl();
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const deviceHSTabEnabled = getSomeConfigurationsValues(companyConfigurations, [
		IntegrationTypesSettings.TELEHEALTH,
		IntegrationTypesSettings.EVIDEON,
		IntegrationTypesSettings.PCARE,
		IntegrationTypesSettings.GET_WELL,
		IntegrationTypesSettings.MDM,
		IntegrationTypesSettings.SONIFI,
	]);
	const activeSubTab = {
		...(deviceHSTabEnabled && { HealthSystem: 0 }),
		TV: 1,
		Whiteboard: 2,
	};

	const [isLoading, setIsLoading] = useState(true);
	const [hospitals, setHospitals] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [teamProfiles, setTeamProfiles] = useState([]); //11
	const [isHospitalDropdownDisabled, setIsHospitalDropdownDisabled] = useState(true);
	const [isDepartmentDropdownDisabled, setIsDepartmentDropdownDisabled] = useState(true);
	const [configurations, setConfigurations] = useState([]);
	const [healthSystemConfigs, setHealthSystemConfigs] = useState([]); //2
	const [whiteboardConfigs, setWhiteboardConfigs] = useState([]);
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [totalConfigs, setTotalConfigs] = useState(null);
	const [isDeleteConfigModalOpen, setIsDeleteConfigModalOpen] = useState(false);
	const [deleteConfig, setDeleteConfig] = useState(null);
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editConfig, setEditConfig] = useState(null);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
	const [currentTab, setCurrentTab] = useState(Object.values(activeSubTab)[0]);
	const endCallSource = Object.values(EndCallSource(intl));

	useEffect(() => {
		getConfigurations();
	}, [props.selectedHealthSystem, props.selectedHospitalId, props.selectedDepartmentId, pagination, currentTab]);

	const getHospitals = async healthSystem => {
		if (healthSystem.value !== '0') {
			const hospitalsList = await getHealthSystemHospitals(healthSystem.value);
			if (hospitalsList.error) {
				setError(hospitalsList.error.message);
				return;
			}
			setHospitals(transformSectorForSelect(hospitalsList));
		}
		setIsHospitalDropdownDisabled(healthSystem.value === '0');
		setIsDepartmentDropdownDisabled(healthSystem.value === '0' || isDepartmentDropdownDisabled);
	};

	const getDepartments = async (healthSystem, hospitalId) => {
		if (healthSystem.value !== '0' && hospitalId !== 0) {
			const response = await getHospitalDepartments(healthSystem.value, hospitalId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDepartments(transformSectorForSelect(response.hospital?.departments));
		}
		setIsDepartmentDropdownDisabled(healthSystem.value === '0' || hospitalId === 0);
	};

	const getConfigurations = async () => {
		const { selectedHealthSystem, selectedHospitalId, selectedDepartmentId } = props;
		const response = await getTeamConfigurationProfiles({
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
			teamId: selectedHealthSystem.value,
			teamTypeId: TeamTypes.HEALTH_SYSTEM,
		});

		if (response.error) {
			setError(response.error.message);
			return;
		}
		setTeamProfiles(response.teamConfigurationProfiles.map(config => ({ id: config.id, value: config.profileName })));

		if (currentTab === activeSubTab.HealthSystem) {
			getHealthSystemConfigs(response.teamConfigurationProfiles);
			setTotalConfigs(response.total);
		}

		if (currentTab === activeSubTab.TV) {
			const response = await getTeamConfigurations({
				healthSystemId: selectedHealthSystem.value,
				hospitalId: selectedHospitalId || null,
				departmentId: selectedDepartmentId || null,
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				teamTypeId: TeamTypes.HOSPITAL,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (selectedHealthSystem) {
				await getHospitals(selectedHealthSystem);
			}
			if (selectedHealthSystem && selectedHospitalId) {
				await getDepartments(selectedHealthSystem, selectedHospitalId);
			}
			getDeviceConfigurations(response.deviceConfigurations);
			setTotalConfigs(response.total);
		} else if (currentTab === activeSubTab.Whiteboard) {
			let teamTypeId = TeamTypes.HEALTH_SYSTEM;
			let hospitalId = null;
			let departmentId = null;
			if (selectedHospitalId && selectedHospitalId !== '0') {
				hospitalId = selectedHospitalId;
				teamTypeId = TeamTypes.HOSPITAL;
			}
			if (selectedDepartmentId && selectedDepartmentId !== '0') {
				departmentId = selectedDepartmentId;
				teamTypeId = TeamTypes.DEPARTMENT;
			}
			const response = await getSleepScheduleConfigurations({
				teamId: departmentId || hospitalId || selectedHealthSystem.value,
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				teamTypeId,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (selectedHealthSystem) {
				await getHospitals(selectedHealthSystem);
			}
			if (selectedHealthSystem && hospitalId) {
				await getDepartments(selectedHealthSystem, hospitalId !== '0' ? hospitalId : 0);
			}
			getWhiteboardSystemConfigs(response.teamSleepScheduleConfigurations);
			setTotalConfigs(response.total);
		}
	};

	const transformSectorForSelect = sectors => sectors.map(sector => ({ label: sector.name, value: sector.id }));

	const getDeviceConfigurations = configurationsList => {
		if (!configurationsList?.length) {
			setConfigurations([]);
			setIsLoading(false);
			return;
		}
		const transformConfigs = configurationsList.map(config => ({
			id: config.id,
			healthSystem: config.team.healthSystemName,
			hospitalName: config.team.hospitalName,
			hospital: config.team.name,
			hdmiPort: config.hdmiPort,
			defaultSource: endCallSource.find(source => source.id === config.defaultSource)?.value,
			ringtoneVolume: config.ringtoneVolume,
			tv: config.tv,
			profileName: config.teamConfigurationProfile ? config.teamConfigurationProfile.profileName : '',
			createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
			dateCreated: formattedDate(config.dateCreated),
			actions: (
				<div className='wrapped' key={config.id}>
					<Link to={`/configurations/${config.team.id}/type/${ConfigHistoryTypes.Devices}`}>
						<div
							className='material-icons-outlined boxed-icon view-history-icon'
							data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
							data-position='top'>
							<TableHistoryButton />
						</div>
					</Link>
					<TableEditButton onClick={() => openEditModal(config)} />
					<TableDeleteButton onClick={() => openDeleteModal(config)} />
				</div>
			),
		}));
		setConfigurations(transformConfigs);
		setIsLoading(false);
	};

	const transformArray = (array, allHealthSystems = false) => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		if (allHealthSystems && getUserRole() === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'allHealthSystems' }) });
		}
		return newArray;
	};

	const getTabsConfigurations = () => {
		if (currentTab === activeSubTab.HealthSystem) {
			return healthSystemConfigs;
		} else if (currentTab === activeSubTab.Whiteboard) {
			return whiteboardConfigs;
		}
		return configurations;
	};

	const openDeleteModal = deleteConf => {
		setDeleteConfig(deleteConf);
		setIsDeleteConfigModalOpen(true);
	};

	const getIntegrationName = integrationTypeId => {
		let label = '';
		switch (integrationTypeId) {
			case TeamConfigurationIntegrationTypes.TELEHEALTH:
				label = 'TeleHealth';
				break;
			case TeamConfigurationIntegrationTypes.PCARE:
				label = 'pCare';
				break;
			case TeamConfigurationIntegrationTypes.EVIDEON:
				label = 'eVideon';
				break;
			case TeamConfigurationIntegrationTypes.GET_WELL:
				label = 'Get Well';
				break;
			case TeamConfigurationIntegrationTypes.SONIFI:
				label = 'Sonifi';
				break;
			case TeamConfigurationIntegrationTypes.MDM:
				label = 'MDM';
				break;
			default:
				break;
		}
		return label;
	};

	const getIntegrationType = integrationTypeId => ({
		label: getIntegrationName(integrationTypeId),
		value: integrationTypeId,
	});

	const getHealthSystemConfigs = configurationsList => {
		if (!configurationsList.length) {
			setHealthSystemConfigs([]);
			setIsLoading(false);
			return;
		}
		const transformConfigs = configurationsList.map(config => ({
			id: config.id,
			healthSystem: config.team.name,
			profileName: config.profileName,
			url: config.url,
			port_or_siteId: config?.port || config.siteId,
			dateCreated: formattedDate(config.dateCreated),
			actions: (
				<div className='wrapped' key={config.id}>
					<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.TeamConfigurationProfiles}`}>
						<div
							className='material-icons-outlined boxed-icon view-history-icon'
							data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
							data-position='top'>
							<TableHistoryButton />
						</div>
					</Link>
					<TableEditButton
						onClick={() =>
							openEditModal({
								...config,
								integrationType: getIntegrationType(config.integrationTypeId),
							})
						}
					/>
					<TableDeleteButton onClick={() => openDeleteModal(config)} />
				</div>
			),
		}));
		setHealthSystemConfigs(transformConfigs);
		setIsLoading(false);
	};

	const getWhiteboardSystemConfigs = configurationsList => {
		if (!configurationsList.length) {
			setWhiteboardConfigs([]);
			setIsLoading(false);
			return;
		}

		const getAffectedSectors = (sectorType, affectedSectors, affectedSectorsNames) => {
			if (affectedSectors && affectedSectorsNames?.length) {
				return (
					<SleepScheduleTooltip
						sectorType={capitalizeFirstLetter(sectorType)}
						sectorCount={affectedSectors}
						sectorNames={affectedSectorsNames}
					/>
				);
			}
			return '';
		};

		const getHospitalName = config => {
			let hospitalName = '';
			switch (config.team?.typeId) {
				case TeamTypes.HEALTH_SYSTEM: {
					return getAffectedSectors(SectorTypes.HOSPITAL, config.affectedHospitals, config.affectedHospitalsNames);
				}
				case TeamTypes.HOSPITAL: {
					hospitalName = config.team?.name;
					break;
				}
				case TeamTypes.DEPARTMENT: {
					hospitalName = config.team?.hospitalName;
					break;
				}
				default:
					break;
			}
			return hospitalName;
		};

		const getDepartmentName = config => {
			switch (config.team?.typeId) {
				case TeamTypes.HEALTH_SYSTEM:
				case TeamTypes.HOSPITAL: {
					return getAffectedSectors(SectorTypes.DEPARTMENT, config.affectedDepartments, config.affectedDepartmentsNames);
				}
				case TeamTypes.DEPARTMENT: {
					return config.team?.name;
				}
				default:
					return '';
			}
		};

		const transformConfigs = configurationsList.map(config => ({
			id: config.id,
			healthSystem: config.team?.typeId === TeamTypes.HEALTH_SYSTEM ? config.team?.name : config.team?.healthSystemName,
			hospital: getHospitalName(config),
			department: getDepartmentName(config),
			displayOffTime: formatTimeStringTo12hrFormat(config.sleepSchedule?.displayOffTime),
			displayOnTime: formatTimeStringTo12hrFormat(config.sleepSchedule?.displayOnTime),
			screenDelay: `${delayToMinutes(config.sleepSchedule?.screenDelay)} ${intl.formatMessage({
				id: translatedKey(config?.sleepSchedule?.screenDelay),
			})}`,
			dateCreated: formattedDate(config.dateCreated),
			actions: (
				<div className='wrapped' key={config.id}>
					<TableEditButton onClick={() => openWhiteboardEditModal(config)} />
					<TableDeleteButton onClick={() => openDeleteModal(config)} />
				</div>
			),
		}));
		setWhiteboardConfigs(transformConfigs);
		setIsLoading(false);
	};

	const deleteTeamProfile = async () => {
		setIsLoading(true);
		setIsDeleteConfigModalOpen(false);
		let response;
		if (currentTab === activeSubTab.HealthSystem) {
			response = await deleteTeamConfigurationProfile(deleteConfig.team.id, deleteConfig.team.typeId, deleteConfig.id);
		} else if (currentTab === activeSubTab.TV) {
			response = await deleteTVDeviceConfiguration(deleteConfig.team.id, deleteConfig.id);
		} else if (currentTab === activeSubTab.Whiteboard) {
			response = await deleteSleepScheduleConfiguration({
				teamId: deleteConfig.team.id,
				itemId: deleteConfig.id,
				teamTypeId: deleteConfig.team.typeId,
			});
		}
		if (response.error) {
			setError(response.error.message);
			return;
		}
		getConfigurations();
	};

	const toggleConfigModal = () => {
		setIsModalOpen(prevState => !prevState);
		setEditConfig(null);
	};

	const openEditModal = config => {
		setEditConfig(config);
		setIsModalOpen(true);
	};

	const openWhiteboardEditModal = config => {
		const [startHour, startMinute, startSecond] = config.sleepSchedule.displayOffTime.split(':');
		const [endHour, endMinute, endSecond] = config.sleepSchedule.displayOnTime.split(':');
		openEditModal({
			...config,
			sleepSchedule: {
				...config.sleepSchedule,
				displayOffTime: new Date().setHours(startHour, startMinute, startSecond),
				displayOnTime: new Date().setHours(endHour, endMinute, endSecond),
			},
		});
	};

	const tableHeaders = useMemo(() => {
		let headers = deviceConfigHeaders;

		if (currentTab === activeSubTab.HealthSystem) {
			headers = healthSystemConfigHeaders;
		} else if (currentTab === activeSubTab.Whiteboard) {
			headers = whiteboardConfigHeaders;
		}

		return headers;
	}, [currentTab]);

	const showDepartmentsFilter =
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_DEPT_ROOM].includes(
			props.treeHierarchyTypeId
		) || !props.treeHierarchyTypeId;

	return (
		<div className='device-config'>
			<CustomTable isLoading={isLoading} headers={tableHeaders} rows={isLoading ? [] : getTabsConfigurations()}>
				<div className='configurations-headers flex'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={intl.formatMessage({ id: 'allHealthSystems' })}
						classNamePrefix='react-select'
						options={transformArray(props.healthSystems, true)}
						onChange={props.setSelectedHealthSystem}
						isDisabled={getUserRole() === UserRoles.SUPER_USER}
					/>
					{currentTab === activeSubTab.TV && (
						<TVConfigFilters
							selectedHospitalId={props.selectedHospitalId}
							selectedDepartmentId={props.selectedDepartmentId}
							hospitals={hospitals}
							departments={departments}
							isHospitalDropdownDisabled={isHospitalDropdownDisabled}
							isDepartmentDropdownDisabled={isDepartmentDropdownDisabled}
							setSelectedHospital={props.setSelectedHospital}
							setSelectedDepartment={props.setSelectedDepartment}
							showDepartmentsFilter={showDepartmentsFilter}
							toggleConfigModal={toggleConfigModal}
						/>
					)}
					{currentTab === activeSubTab.Whiteboard && (
						<WhiteboarConfigFilters
							selectedHospitalId={props.selectedHospitalId}
							selectedDepartmentId={props.selectedDepartmentId}
							hospitals={hospitals}
							departments={departments}
							isHospitalDropdownDisabled={isHospitalDropdownDisabled}
							isDepartmentDropdownDisabled={isDepartmentDropdownDisabled}
							setSelectedHospital={props.setSelectedHospital}
							setSelectedDepartment={props.setSelectedDepartment}
							selectedHealthSystem={props.selectedHealthSystem}
							showDepartmentsFilter={showDepartmentsFilter}
							intl={intl}
							toggleConfigModal={toggleConfigModal}
						/>
					)}
					{currentTab === activeSubTab.HealthSystem && (
						<Button text={translate('addHSConfiguration')} onClick={toggleConfigModal} />
					)}
				</div>
				<div>
					<ul className='tabs active-directory-tabs' data-cy='activeDirectoryTabs'>
						{deviceHSTabEnabled && (
							<li className={currentTab === activeSubTab.HealthSystem ? 'active' : ''}>
								<span onClick={() => setCurrentTab(activeSubTab.HealthSystem)}>{translate('healthSystem')}</span>
							</li>
						)}
						<li className={currentTab === activeSubTab.TV ? 'active' : ''}>
							<span onClick={() => setCurrentTab(activeSubTab.TV)}>TV</span>
						</li>
						<li className={currentTab === activeSubTab.Whiteboard ? 'active' : ''}>
							<span onClick={() => setCurrentTab(activeSubTab.Whiteboard)}>{translate('whiteboard')}</span>
						</li>
					</ul>
				</div>
			</CustomTable>
			<Pagination
				totalCount={totalConfigs}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(pageSize, pageIndex) => setPagination({ pageSize, pageIndex })}
			/>
			{currentTab === activeSubTab.HealthSystem && (
				<HealthSystemConfigForm
					isModalOpen={isModalOpen}
					toggleModal={() => {
						setIsModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					isFormLoading={isFormLoading}
					setIsFormLoading={setIsFormLoading}
					getConfigurations={getConfigurations}
					transformArray={transformArray}
					healthSystems={props.healthSystems}
					selectedHealthSystem={props.selectedHealthSystem}
					initialValues={editConfig}
				/>
			)}
			{currentTab === activeSubTab.TV && (
				<TVConfig
					isModalOpen={isModalOpen}
					toggleModal={() => {
						setIsModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					isFormLoading={isFormLoading}
					setIsFormLoading={setIsFormLoading}
					getConfigurations={getConfigurations}
					transformArray={transformArray}
					healthSystems={props.healthSystems}
					teamProfiles={teamProfiles}
					initialValues={editConfig}
					selectedHealthSystem={props.selectedHealthSystem}
				/>
			)}
			{currentTab === activeSubTab.Whiteboard && (
				<SleepSchedule
					isModalOpen={isModalOpen}
					toggleModal={() => {
						setIsModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					isFormLoading={isFormLoading}
					setIsFormLoading={setIsFormLoading}
					getConfigurations={getConfigurations}
					transformArray={transformArray}
					healthSystems={props.healthSystems}
					teamProfiles={teamProfiles}
					initialValues={editConfig}
				/>
			)}
			<Modal
				modalSelector='deleteTeamProfileModal'
				display={isDeleteConfigModalOpen}
				position='center'
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				onModalSubmit={deleteTeamProfile}
				onModalClose={() => {
					setIsDeleteConfigModalOpen(false);
					setDeleteConfig(null);
				}}
				shouldSubmitOnEnter={false}
				primaryButtonLabel={translate('yes')}>
				<form>
					<h3>{translate('deleteConfiguration')}</h3>
					<p>{translate('areYouSureToDeleteConfig')}</p>
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default DeviceConfig;
