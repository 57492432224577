import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import SummaryDetail from 'containers/HealthMeasurements/SummaryDetail.jsx';
import Loader from 'components/Loader.jsx';
import { getGalaxyWatchMeasurements, getLatestMeasurements, getMeasurementsByRpm } from 'api/measurements.js';
import translate from 'i18n-translations/translate.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Alert from 'components/Alert.jsx';
import { getGalaxyWatchMeasurementTypes, getMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { ExamType, MeasurementTypes } from 'constants/enums.js';

const HealthDataSummary = props => {
	const [isLatestMeasurementLoading, setIsLatestMeasurementLoading] = useState(true);
	const [measurements, setMeasurements] = useState([]);
	const [error, setError] = useState(null);

	const intl = useIntl();

	useEffect(() => {
		const getMeasurements = async () => {
			setIsLatestMeasurementLoading(true);
			let measurementResponse = [];

			if (props.isGalaxyWatch) {
				measurementResponse = await getGalaxyWatchMeasurements({ patientId: props.selectedPatient?.userId, latest: true });
			} else if (props.patientRpmProgram) {
				measurementResponse = await getMeasurementsByRpm({
					patientId: props.selectedPatient?.userId,
					measurementTypes: props.measurementTypeArray,
				});
			} else {
				measurementResponse = await getLatestMeasurements(props.selectedPatient?.userId);
			}
			if (measurementResponse.error) {
				setError(measurementResponse.error.message);
			} else {
				const measurementList = measurementResponse.measurements;
				if (measurementResponse.stethoscopeRecordings?.length) {
					const newStethoscopeRecording = measurementResponse.stethoscopeRecordings.map(item => ({
						endDate: item.recordedAt,
						id: item.id,
						isManuallyInserted: false,
						measurementType: item.examinationType === ExamType.HEART ? MeasurementTypes.HEART : MeasurementTypes.LUNGS,
						measurementUnit: 's',
						measurementValue: item.recordDuration,
						source: 'web',
						userId: props.selectedPatient?.userId,
					}));
					setMeasurements([...measurementList, ...newStethoscopeRecording]);
				} else {
					setMeasurements(measurementList);
				}
				setIsLatestMeasurementLoading(false);
			}
		};
		getMeasurements();
	}, [props.selectedPatient?.id, props.selectedPatient?.userId, props.isGalaxyWatch]);

	const getMeasurementByType = type => {
		const foundMeasurement = measurements.find(measurement => measurement.measurementType === type);
		return foundMeasurement || { measurementValue: 'No Data', measurementType: type };
	};

	const getMeasurementTypesByRpm = () => {
		const measurementTypes = getMeasurementTypes(intl);

		if (props.patientRpmProgram) {
			return props.measurementTypeArray.map(type => {
				if (type === MeasurementTypes.STETHOSCOPE) {
					return measurementTypes.filter(measurement =>
						[MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(measurement.type)
					);
				}
				if (type === MeasurementTypes.WEIGHT) {
					return measurementTypes.filter(measurement =>
						[
							MeasurementTypes.WEIGHT,
							MeasurementTypes.BODY_MASS_INDEX,
							MeasurementTypes.BODY_FAT,
							MeasurementTypes.BODY_MASS_INDEX,
							MeasurementTypes.WAIST_CIRCUMFERENCE,
							MeasurementTypes.LEAN_BODY_MASS,
						].includes(measurement.type)
					);
				}
				if (type === MeasurementTypes.HEART_RATE) {
					return measurementTypes.filter(measurement =>
						[MeasurementTypes.HEART_RATE, MeasurementTypes.PI].includes(measurement.type)
					);
				}
				return measurementTypes.find(measurement => measurement.type === type);
			});
		}
		return measurementTypes;
	};

	return (
		<>
			{!isLatestMeasurementLoading && measurements.length > 0 && (
				<div className='health-data-summary flex' data-cy='healthDataSummary'>
					{!props.isGalaxyWatch &&
						getMeasurementTypesByRpm().map(measurement => (
							<>
								{Array.isArray(measurement) &&
									measurement.map(measurement => (
										<SummaryDetail
											measurement={measurement}
											key={measurement.id}
											currentMeasurement={getMeasurementByType(measurement.type)}
											isDoctorView={props.isDoctorView}
											selectedPatient={props.selectedPatient}
											isCallView={props.isCallView}
											isFromPrimaryCare={props.isFromPrimaryCare}
											isHealthShown={props.isHealthShown}
										/>
									))}
								{!Array.isArray(measurement) && (
									<SummaryDetail
										measurement={measurement}
										key={measurement.id}
										currentMeasurement={getMeasurementByType(measurement.type)}
										isDoctorView={props.isDoctorView}
										selectedPatient={props.selectedPatient}
										isCallView={props.isCallView}
										isFromPrimaryCare={props.isFromPrimaryCare}
										isHealthShown={props.isHealthShown}
									/>
								)}
							</>
						))}
					{props.isGalaxyWatch &&
						getGalaxyWatchMeasurementTypes(intl).map(measurement => (
							<SummaryDetail
								measurement={measurement}
								key={measurement.id}
								currentMeasurement={getMeasurementByType(measurement.type)}
								isDoctorView={props.isDoctorView}
								selectedPatient={props.selectedPatient}
								isCallView={props.isCallView}
								isFromPrimaryCare={props.isFromPrimaryCare}
							/>
						))}
				</div>
			)}
			{!isLatestMeasurementLoading && measurements.length === 0 && (
				<div className='empty-state-wrapper-percent'>
					<EmptyState
						title={translate('noHealthDataFound')}
						paragraph={translate('noHealthDataCurrently')}
						image='no-health-data.svg'
					/>
				</div>
			)}
			{isLatestMeasurementLoading && <Loader />}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default HealthDataSummary;
