import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { default as ReactSelect } from 'react-select';
import Input from 'components/Common/FormElements/Input.jsx';
import { Alert, Form, Modal, Input as CustomInput } from 'components/index.js';
import { addActiveDirectory, addGroupRole, updateActiveDirectory, updateGroupRole } from 'api/activeDirectory.js';
import { CompanyRoles, ExternalIdentityProviders, KeyCodes } from 'constants/enums.js';
import Select from 'components/Common/FormElements/Select.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { isValidEmailDomain } from 'infrastructure/helpers/validationHelper.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';

const userRolesList = [
	{
		id: CompanyRoles.ADMIN,
		value: translate('admin'),
	},
	{
		id: CompanyRoles.SUPER_USER,
		value: translate('superUser'),
	},
	{
		id: CompanyRoles.DOCTOR,
		value: translate('doctor'),
	},
	{
		id: CompanyRoles.VIRTUAL_CARE_PROVIDER,
		value: translate('virtualCareProvider'),
	},
	{
		id: CompanyRoles.VIRTUAL_SITTER,
		value: translate('virtualSitter'),
	},
	{
		id: CompanyRoles.DIGITAL_CLINICIAN,
		value: translate('digitalClinician'),
	},
];

const ActiveDirectoryForm = props => {
	const intl = useIntl();
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [isCompanyLevelConfiguration, setIsCompanyLevelConfiguration] = useState(
		props.initialValues?.isCompanyLevelConfiguration ?? true
	);
	const [error, setError] = useState('');
	const [formHospitals, setFormHospitals] = useState([]);
	const translator = id => intl.formatMessage({ id });

	const shouldShowConfigType = settingTypeId =>
		getConfigurationValue(companyConfigurations[settingTypeId]) || props.initialValues;

	const configTypes = [
		shouldShowConfigType(IntegrationTypesSettings.AZURE_AD) && {
			value: ExternalIdentityProviders.AZURE,
			label: 'Azure',
		},
		shouldShowConfigType(IntegrationTypesSettings.PING_FEDERATE) && {
			value: ExternalIdentityProviders.PING_FEDERATE,
			label: 'Ping Federate',
		},
		shouldShowConfigType(IntegrationTypesSettings.OKTA) && {
			value: ExternalIdentityProviders.OKTA,
			label: 'OKTA',
		},
		shouldShowConfigType(IntegrationTypesSettings.DUO_SSO) && {
			value: ExternalIdentityProviders.DUO_SSO,
			label: 'Duo',
		},
	].filter(Boolean);

	const [selectedConfig, setSelectedConfig] = useState(
		props.initialValues?.adConfigurationTypeId
			? configTypes.find(item => item.value === props.initialValues?.adConfigurationTypeId)
			: configTypes[0]
	);

	const getValidationSchema = () => {
		const validation = {};
		if (!props.isGroupRoleModal && !props.initialValues && !isCompanyLevelConfiguration) {
			validation.healthSystemId = Yup.string().required(translator('healthSystemRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
				validation.username = Yup.string().required(translator('clientIdRequired'));
				validation.password = Yup.string().required(translator('clientSecretRequired'));
				validation.domain = Yup.string().required(translator('domainRequired'));
				validation.emailDomains = Yup.array().when('autoSyncOnLogin', {
					is: true,
					then: emailDomains =>
						emailDomains.test('required', translator('formShouldContainOneDomainEmail'), value => value && value.length > 0),
				});
			}
			return validation;
		}
		if (!props.isGroupRoleModal && isCompanyLevelConfiguration) {
			validation.domain = Yup.string().required(translator('domainRequired'));
			validation.username = Yup.string().required(translator('clientIdRequired'));
			validation.password = Yup.string().required(translator('clientSecretRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
			}
			return validation;
		}
		if (!props.isGroupRoleModal && props.initialValues) {
			validation.domain = Yup.string().required(translator('domainRequired'));
			validation.username = Yup.string().required(translator('clientIdRequired'));
			validation.password = Yup.string().required(translator('clientSecretRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
			}
			return validation;
		}
		if (!props.isGroupRoleModal && !props.initialValues) {
			validation.healthSystemId = Yup.object().required(translator('healthSystemRequired'));
			validation.domain = Yup.string().required(translator('domainRequired'));
			validation.username = Yup.string().required(translator('clientIdRequired'));
			validation.password = Yup.string().required(translator('clientSecretRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
			}
			return validation;
		}
		if (props.isGroupRoleModal && props.initialValues) {
			validation.roleId = Yup.string().required(translator('poolRoleRequired'));
			validation.group = Yup.string().required(translator('groupRolesRequired'));
			return validation;
		}
		validation.healthSystemId = Yup.string().required(translator('healthSystemRequired'));
		validation.roleId = Yup.string().required(translator('poolRoleRequired'));
		validation.group = Yup.string().required(translator('groupRolesRequired'));
		return validation;
	};

	const getDomainDescription = config => {
		const configDomain = {
			[ExternalIdentityProviders.AZURE]: 'https://login.microsoftonline.com/[tenantId]',
			[ExternalIdentityProviders.PING_FEDERATE]: 'https://fed.sample.com',
			[ExternalIdentityProviders.OKTA]: 'https://[sub-domain].okta.com/',
			[ExternalIdentityProviders.DUO_SSO]: 'https://sso-[tenant-subdomain].sso.duosecurity.com/oidc',
		};
		return configDomain[+config];
	};

	const getInitialValues = () => {
		if (props.initialValues) {
			return props.initialValues;
		}
		if (props.isGroupRoleModal) {
			return {
				healthSystemId: null,
				group: '',
				hospitalId: null,
				roleId: '',
			};
		}
		return {
			healthSystemId: null,
			hospitalId: null,
			validGroupName: '',
			username: '',
			password: '',
			domain: '',
			includeRoles: false,
			autoSyncOnLogin: false,
			emailDomains: [],
			emailDomain: '',
			isSingleSignOutEnabled: false,
			autoAdd: true,
			autoUpdate: true,
			autoDelete: true,
			isCompanyLevelConfiguration: true,
		};
	};

	const handleSubmitMyForm = async (values, { resetForm }) => {
		let response;
		if (props.isGroupRoleModal) {
			if (props.initialValues) {
				response = await updateGroupRole(values.id, { roleId: values.roleId, group: values.group });
			} else {
				response = await addGroupRole(values);
			}
		}
		const getIsAutoAdd = () => {
			if (!isCompanyLevelConfiguration) {
				return values.autoAdd;
			}
			return false;
		};

		if (!props.isGroupRoleModal) {
			if (props.initialValues) {
				response = await updateActiveDirectory(values.id, {
					domain: values.domain,
					username: values.username,
					password: values.password,
					validGroupName: values.validGroupName,
					adConfigurationTypeId: +selectedConfig.value,
					isCompanyLevelConfiguration:
						+selectedConfig.value === ExternalIdentityProviders.AZURE ? isCompanyLevelConfiguration : true,
					includeRoles: values.includeRoles,
					autoAdd: getIsAutoAdd(),
					autoUpdate: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoUpdate : false,
					autoDelete: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoDelete : false,
					isSingleSignOutEnabled: [ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(
						+selectedConfig.value
					)
						? values.isSingleSignOutEnabled
						: false,
					emailDomains: values.emailDomains.map(item => ({ emailDomain: item })),
					autoSyncOnLogin: values.emailDomains.length > 0,
				});
			} else {
				const hospitalId = values.hospitalId || null;
				delete values.emailDomain;
				response = await addActiveDirectory({
					...values,
					hospitalId: isCompanyLevelConfiguration ? null : hospitalId,
					healthSystemId: isCompanyLevelConfiguration ? null : values.healthSystemId,
					validGroupName: values.validGroupName,
					adConfigurationTypeId: +selectedConfig.value,
					isCompanyLevelConfiguration:
						+selectedConfig.value === ExternalIdentityProviders.AZURE ? isCompanyLevelConfiguration : true,
					includeRoles: isCompanyLevelConfiguration ? null : values.includeRoles,
					autoAdd: getIsAutoAdd(),
					autoDelete: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoDelete : false,
					autoUpdate: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoUpdate : false,
					isSingleSignOutEnabled: [ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(
						+selectedConfig.value
					)
						? values.isSingleSignOutEnabled
						: false,
					emailDomains: values.emailDomains.map(item => ({ emailDomain: item })),
					autoSyncOnLogin: values.emailDomains.length > 0,
				});
			}
		}
		if (response.error) {
			setError(response.error.message);
			return;
		}
		resetForm();
		props.onSucceeded();
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
	};

	const handleDomainInput = ({ value, setFieldValue, setFieldError, name, emailDomains }) => {
		if (!value) {
			return;
		}

		if (emailDomains.length === 5) {
			setFieldError(name, intl.formatMessage({ id: 'maximumEmailDomainsAllowedAre5' }));
			return;
		}

		if (emailDomains.includes(`@${value}`)) {
			setFieldError(name, intl.formatMessage({ id: 'youAlreadyHaveAddedThisDomain' }));
			return;
		}

		if (!isValidEmailDomain(value)) {
			setFieldError(name, intl.formatMessage({ id: 'theProvidedDomainIsInvalid' }));
			return;
		}

		setFieldValue(name, [...emailDomains, `@${value}`]);
		setFieldValue('emailDomain', '');
		setFieldError(name, '');
	};

	const removeEmailFromList = (value, setFieldValue, emailDomains) => {
		const newArray = emailDomains.filter(item => item !== value);
		setFieldValue('emailDomains', newArray);
	};

	const onHealthSystemChange = async e => {
		const { value } = e.target;
		const hsHospitals = value !== '0' ? await getHealthSystemHospitals(value) : [];
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
		} else {
			const hospitalArray = hsHospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));
			setFormHospitals(hospitalArray);
		}
	};

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={true}
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={Yup.object().shape({ ...getValidationSchema() })}
			onSubmit={handleSubmitMyForm}>
			{({ values, handleSubmit, isSubmitting, resetForm, setFieldValue, setFieldError, errors }) => (
				<Modal
					display={props.isModalOpen}
					position='right'
					onModalSubmit={handleSubmit}
					className='active-directory-form'
					onModalClose={() => onCloseModal(resetForm)}
					shouldSubmitOnEnter={false}
					isLoading={isSubmitting}>
					<Form>
						{props.isGroupRoleModal && (
							<>
								{!props.initialValues && (
									<Field
										name='healthSystemId'
										type='select'
										label={translator('selectHealthSystem')}
										placeholder={translator('selectHealthSystem')}
										items={props.healthSystems}
										onChange={onHealthSystemChange}
										value={values.healthSystemId}
										component={Select}
									/>
								)}
								{!props.initialValues && (
									<Field
										name='hospitalId'
										type='select'
										label={translator('selectHospital')}
										placeholder={translator('hospital')}
										items={formHospitals}
										value={values.hospitalId}
										onChange={props.onHospitalChange}
										component={Select}
									/>
								)}
								{props.initialValues && (
									<Field name='hospitalId' label={translator('name')} description='' disabled={true} component={Input} />
								)}
								<Field
									name='roleId'
									type='select'
									label={translator('selectGroupRole')}
									placeholder={translator('selectGroupRole')}
									items={userRolesList}
									value={values.roleId}
									component={Select}
								/>
								<Field
									name='group'
									type='text'
									label={translator('group')}
									description={translator('typeNameForPool')}
									component={Input}
									value={values.group}
								/>
							</>
						)}
						{!props.isGroupRoleModal && (
							<>
								<h3>{translator('externalIdentityProviders')}</h3>
								<div className='input'>
									<p className='label'>{translator('selectConfiguration')}</p>
									<p className='font-14'>{translator('selectConfigurationDesc')}</p>
									<ReactSelect
										isDisabled={props.initialValues?.adConfigurationTypeId}
										value={selectedConfig}
										placeholder={intl.formatMessage({ id: 'selectConfiguration' })}
										classNamePrefix='react-select'
										options={configTypes}
										onChange={event => {
											setIsCompanyLevelConfiguration(true);
											setSelectedConfig(event);
										}}
									/>
								</div>
								{+selectedConfig.value === ExternalIdentityProviders.AZURE && (
									<div className='bottom-30'>
										<Field
											type='checkbox'
											checked={isCompanyLevelConfiguration}
											name='isCompanyLevelConfiguration'
											onChange={() => {
												setIsCompanyLevelConfiguration(prevState => !prevState);
												setFieldValue('autoUpdate', !isCompanyLevelConfiguration);
												setFieldValue('autoDelete', !isCompanyLevelConfiguration);
											}}
											disabled={props.initialValues}
										/>
										<label className='font-14'>{translator('isCompanyLevelConfiguration')}</label>
									</div>
								)}
								{!props.initialValues && !isCompanyLevelConfiguration && (
									<>
										<Field
											name='healthSystemId'
											type='select'
											label={translator('selectHealthSystem')}
											placeholder={translator('selectHealthSystem')}
											items={props.healthSystems}
											onChange={onHealthSystemChange}
											value={values.healthSystemId}
											component={Select}
										/>
										<Field
											name='hospitalId'
											type='select'
											label={translator('selectHospital')}
											placeholder={translator('hospital')}
											items={formHospitals}
											value={values.hospitalId}
											onChange={props.onHospitalChange}
											component={Select}
										/>
									</>
								)}
								{props.initialValues && !isCompanyLevelConfiguration && (
									<Field
										name='hospitalId'
										label={translator('name')}
										value={values.hospitalId}
										disabled={true}
										component={Input}
									/>
								)}
								<Field
									name='domain'
									type='text'
									label={translator(+selectedConfig.value === ExternalIdentityProviders.AZURE ? 'azureAdUrl' : 'domain')}
									description={getDomainDescription(selectedConfig.value)}
									value={values.domain}
									component={Input}
								/>
								{+selectedConfig.value === ExternalIdentityProviders.AZURE && (
									<Field
										name='validGroupName'
										type='text'
										label={translator('setValidGroupName')}
										description={translator('setValidGroupNameDescription')}
										value={values.validGroupName}
										component={Input}
									/>
								)}
								<Field
									name='username'
									type='text'
									label={translator('clientId')}
									description={translate('clientIdDescription', {
										value:
											+selectedConfig.value === ExternalIdentityProviders.AZURE
												? translator('activeDirectory')
												: selectedConfig.label,
									})}
									component={Input}
									value={values.username}
								/>
								<Field
									name='password'
									type='password'
									label={translator('clientSecret')}
									description={translate('clientSecretDescription', {
										value:
											+selectedConfig.value === ExternalIdentityProviders.AZURE
												? translator('activeDirectory')
												: selectedConfig.label,
									})}
									component={Input}
									value={values.password}
								/>
								{+selectedConfig.value === ExternalIdentityProviders.AZURE && !isCompanyLevelConfiguration && (
									<>
										<div>
											<Field
												type='checkbox'
												name='autoSyncOnLogin'
												onChange={() => {
													if (!values.autoSyncOnLogin) {
														setFieldValue('autoAdd', false);
														setFieldValue('autoDelete', false);
														setFieldValue('autoUpdate', false);
													}
													setFieldValue('autoSyncOnLogin', !values.autoSyncOnLogin);
												}}
											/>
											<label className='font-14'>{translator('autoSynchronizeLogin')}</label>
										</div>
										{values.autoSyncOnLogin && (
											<>
												<CustomInput
													className='ed-input'
													value={values.emailDomain}
													onChange={event => setFieldValue('emailDomain', event.target.value)}
													postfixTooltip={intl.formatMessage({ id: 'addDomain' })}
													tooltipPosition='top'
													type='text'
													prefixIcon='alternate_email'
													postfixIcon='library_add'
													placeholder={intl.formatMessage({ id: 'enterEmailDomain' })}
													name='emailDomain'
													onPostfixClick={() => {
														handleDomainInput({
															value: values.emailDomain,
															setFieldValue,
															setFieldError,
															name: 'emailDomains',
															emailDomains: values.emailDomains,
														});
													}}
													onKeyDown={event =>
														event.keyCode === KeyCodes.ENTER
															? handleDomainInput({
																	value: event.target.value,
																	setFieldValue,
																	setFieldError,
																	name: 'emailDomains',
																	emailDomains: values.emailDomains,
																})
															: null
													}
													error={errors.emailDomains}
												/>
												<div className='email-list'>
													{values.emailDomains.map(item => (
														<div key={item}>
															<span>{item}</span>
															<i
																className='material-icons prefix-icon'
																onClick={() => removeEmailFromList(item, setFieldValue, values.emailDomains)}>
																close
															</i>
														</div>
													))}
												</div>
											</>
										)}
									</>
								)}
								{[ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(+selectedConfig.value) && (
									<div>
										<Field type='checkbox' name='isSingleSignOutEnabled' />
										<label className='font-14'>{translator('isSingleSignOutEnabled')}</label>
									</div>
								)}
								{+selectedConfig.value === ExternalIdentityProviders.AZURE && (
									<>
										{!isCompanyLevelConfiguration && (
											<div>
												<Field type='checkbox' name='includeRoles' />
												<label className='font-14'>{translator('includeRoles')}</label>
											</div>
										)}
										{!isCompanyLevelConfiguration && (
											<div>
												<Field
													type='checkbox'
													name='autoAdd'
													onChange={() => {
														if (!values.autoAdd) {
															setFieldValue('autoSyncOnLogin', false);
														}
														setFieldValue('autoAdd', !values.autoAdd);
													}}
												/>
												<label className='font-14'>{translator('autoAdd')}</label>
											</div>
										)}
										<div>
											<Field
												type='checkbox'
												name='autoUpdate'
												onChange={() => {
													if (!values.autoUpdate) {
														setFieldValue('autoSyncOnLogin', false);
													}
													setFieldValue('autoUpdate', !values.autoUpdate);
												}}
											/>
											<label className='font-14'>{translator('autoUpdate')}</label>
										</div>
										<div>
											<Field
												type='checkbox'
												name='autoDelete'
												onChange={() => {
													if (!values.autoDelete) {
														setFieldValue('autoSyncOnLogin', false);
													}
													setFieldValue('autoDelete', !values.autoDelete);
												}}
											/>
											<label className='font-14'>{translator('autoDelete')}</label>
										</div>
									</>
								)}
							</>
						)}
						<Alert
							alertSelector='networkAccessConfigsMessage'
							display={!!error}
							message={error}
							variant='error'
							onClose={() => setError('')}
						/>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default ActiveDirectoryForm;
