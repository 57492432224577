import { CompanyTypes } from 'constants/action-types.js';
import { mobileSettings } from 'constants/configurationEnums.js';
import { AuthenticationOptions } from 'constants/enums.js';

const initialState = {
	companyDetails: { logo: '', logoToPreview: '' },
	companySettings: {
		background: '#ffffff',
		helloName: 'Hello',
		huddleName: '20x',
		companyConfigurations: {},
		roomModesConfigurations: {},
		routeSetting: [],
		twoFactorAuthenticator: AuthenticationOptions.DEFAULT,
		nurseDisplayName: 'Nurse',
		doctorDisplayName: 'Doctor',
	},
	mobileConfigurations: {
		[mobileSettings.Ear]: false,
		[mobileSettings.Throat]: false,
		[mobileSettings.Heart]: false,
		[mobileSettings.Lungs]: false,
		[mobileSettings.HeartRate]: false,
		[mobileSettings.OxygenSaturation]: false,
		[mobileSettings.Temperature]: false,
		[mobileSettings.Skin]: false,
		[mobileSettings.BloodPressure]: false,
		[mobileSettings.BloodGlucose]: false,
		[mobileSettings.ECG]: false,
		[mobileSettings.Weight]: false,
		[mobileSettings.Spirometer]: false,
		[mobileSettings.Abdomen]: false,
	},
};

export const company = (state = initialState, action) => {
	switch (action.type) {
		case CompanyTypes.SET_COMPANY_DETAILS:
			return {
				...state,
				companyDetails: action.payload,
			};
		case CompanyTypes.SET_COMPANY_SETTINGS:
			return {
				...state,
				companySettings: { ...state.companySettings, ...action.payload },
			};
		case CompanyTypes.SET_MOBILE_CONFIGURATIONS:
			return {
				...state,
				mobileConfigurations: { ...state.mobileConfigurations, ...action.payload },
			};
		default:
			return state;
	}
};
