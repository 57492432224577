import { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { getDeviceRebootLogs } from 'api/devices.js';
import Alert from 'components/Alert.jsx';
import { exportArrayToCsv, getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';

const hasNameChanges = logs => {
	const hasChanges = {
		user: false,
		device: false,
	};
	logs.forEach(log => {
		if (!hasChanges.user) {
			if (log.user.fullName !== log.user.currentName) {
				hasChanges.user = true;
			}
		}
		if (!hasChanges.device) {
			if (log.device.name !== log.device.currentName) {
				hasChanges.device = true;
			}
		}
	});

	return hasChanges;
};

const exportLogsAsCsv = async ({ solHelloDeviceId, serialNumber, setError, intl }) => {
	const logs = await getDeviceRebootLogs(solHelloDeviceId);
	if (logs.error) {
		setError(logs.error.message);
		return [];
	}
	const fileName = `${serialNumber}-${(+new Date()).toString()}`;
	const sheetName = `${intl.formatMessage({ id: 'reboot' })} ${intl.formatMessage({ id: 'logs' })}`;

	if (logs.length === 0) {
		const emptyLogs = [{ Info: intl.formatMessage({ id: 'exportRebootDeviceLogs' }) }];
		return exportArrayToCsv(emptyLogs, fileName, sheetName);
	}
	const hasChanges = hasNameChanges(logs);

	const mappedLogs = logs.map(log => {
		const user = log.user.fullName !== log.user.currentName ? `${log.user.fullName}` : '';
		const device = log.device.name !== log.device.currentName ? `${log.device.name}` : '';
		const report = {
			User: log.user.currentName,
		};
		if (hasChanges.user) {
			report['Old user name'] = user;
		}
		report.Device = log.device.currentName;
		if (hasChanges.device) {
			report['Old device name'] = device;
		}

		report['Serial number'] = log.device.sn;
		report.Date = moment.utc(log.createdDate).locale(getPreferredLanguageLocale()).local().format('MM/DD/YYYY-hh:mm A');
		report.Reason = log.reason || 'Not specified';
		return report;
	});
	return exportArrayToCsv(mappedLogs, fileName, sheetName);
};

const RebootLogExport = ({ solHelloDeviceId, serialNumber }) => {
	const intl = useIntl();
	const [error, setError] = useState('');
	return (
		<>
			<div className='device-option-details' onClick={() => exportLogsAsCsv({ solHelloDeviceId, serialNumber, setError, intl })}>
				<i
					className='material-icons-outlined boxed-icon device-icon'
					data-cy='exportRebootLogsBtn'
					id={solHelloDeviceId}
					data-position='top'>
					get_app
				</i>
				<span>{intl.formatMessage({ id: 'rebootExportLogsButton' })}</span>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default RebootLogExport;
