import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { PlayIcon, VoiceIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getAudioDuration, setSimplifiedLanguageLabels } from 'infrastructure/helpers/commonHelpers.js';
import { getHealthSystemVerbalRedirectionLanguages, getVerbalRedirections } from 'api/verbalRedirections.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';

const VerbalRedirections = ({ feed, conferenceInfo, preferredLanguage, healthSystemId }) => {
	const intl = useIntl();
	const [expandedBox, setExpandedBox] = useState(true);
	const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
	const [error, setError] = useState('');
	const [verbalRedirections, setVerbalRedirections] = useState([]);
	const baseUrl = `${APP_CONFIG.blobStorageUrl}verbal-redirections/`;
	const socket = useContext(SocketContext);
	const user = useSelector(state => state.user);
	const [isVoicePlaying, setIsVoicePlaying] = useState(false);
	const timeout = useRef(null);
	const [selectedLanguage, setSelectedLanguage] = useState(null);
	const [languages, setLanguages] = useState([]);

	useEffect(() => {
		return () => {
			if (timeout) {
				clearTimeout(timeout.current);
			}
		};
	}, []);

	useEffect(() => {
		const fetchVerbalRedirections = async () => {
			const response = await getVerbalRedirections(healthSystemId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = response.healthSystemVerbalRedirections.map(item => ({
				...item,
				id: item.title,
			}));

			setVerbalRedirections(mapped);
			if (mapped.length > 0) {
				setSelectedAnnouncement({ ...mapped[0], label: mapped[0].title, value: mapped[0].title });
			}
		};

		const fetchSelectedLanguages = async () => {
			const response = await getHealthSystemVerbalRedirectionLanguages(healthSystemId);
			if (response.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			const mapped = setSimplifiedLanguageLabels(response.languages);
			setLanguages(mapped);
			if (!preferredLanguage) {
				setSelectedLanguage(mapped[0]);
			}
		};

		if (healthSystemId) {
			fetchVerbalRedirections();
			fetchSelectedLanguages();
		}
	}, [intl, healthSystemId]);

	useEffect(() => {
		const found = languages.find(item => item.id === preferredLanguage?.value);

		const state = found
			? {
					label: found.label,
					value: found.id,
					locale: found.locale,
			  }
			: languages[0];

		setSelectedLanguage(state);
	}, [languages, preferredLanguage]);

	const playVoiceAnnouncements = () => {
		if (!feed || isVoicePlaying) {
			return;
		}
		const url = selectedAnnouncement.sounds?.find(sound => selectedLanguage?.value === sound.languageId)?.fileName;
		const audioUrl = `${baseUrl}${url}`;
		getAudioDuration(audioUrl, duration => {
			socket.emit(SocketEvents.Conference.PLAY_VERBAL_REDIRECTION, {
				conferenceId: conferenceInfo.conferenceId,
				participantId: conferenceInfo.participantId,
				actioneeParticipantId: feed.participantId,
				data: audioUrl,
				shouldForward: true,
				language: selectedLanguage?.label || '',
			});
			setIsVoicePlaying(true);

			timeout.current = setTimeout(() => {
				setIsVoicePlaying(false);
			}, duration * 1000);
		});
	};

	return (
		<>
			<div className='monitoring-timeline-box voice-announcements'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title flex-1'>
						<VoiceIcon />
						{translate('voiceAnnouncements')}
					</p>
					<ReactSelect
						options={languages.map(item => ({
							...item,
							label: item.label,
							value: item.id,
							locale: item.locale,
						}))}
						value={selectedLanguage}
						onChange={setSelectedLanguage}
						id='select'
						className='select-voice-announcements'
						classNamePrefix='react-select'
						styles={generateCustomStyles({ darkMode: user.darkMode })}
					/>
					<div className='timeline-box-actions'>
						<Button
							border='none'
							onClick={() => setExpandedBox(prevState => !prevState)}
							icon={expandedBox ? 'expand_less' : 'expand_more'}
						/>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='flex'>
							<ReactSelect
								options={verbalRedirections.map(item => ({
									...item,
									label: item.title,
									value: item.title,
								}))}
								isDisabled={isVoicePlaying}
								value={selectedAnnouncement}
								onChange={item => setSelectedAnnouncement(item)}
								id='select'
								className='select-voice-announcements'
								classNamePrefix='react-select'
								styles={generateCustomStyles({ darkMode: user.darkMode, menuListMaxHeight: 175, menuMaxHeight: 175 })}
							/>
							<Button
								className={classNames('play-voice-announcements flex-shrink-0', { played: isVoicePlaying })}
								onClick={playVoiceAnnouncements}
								disabled={!selectedAnnouncement}
								svgIcon={!isVoicePlaying && <PlayIcon />}
							/>
						</div>
					</div>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default VerbalRedirections;
