import { SkeletonPart } from 'constants/enums.js';
import { fallDetectionConstants } from './fallDetectionConstants.js';

function drawPoint(ctx, y, x, color) {
	ctx.beginPath();
	ctx.arc(x, y, fallDetectionConstants.pointWidth, 0, 2 * Math.PI);
	ctx.strokeStyle = color;
	ctx.stroke();
}

function drawSkeleton(points, color, ctx) {
	// Defined the connections between points to form the skeleton
	const connections = [
		['leftShoulder', 'rightShoulder'],
		['leftShoulder', 'leftHip'],
		['rightShoulder', 'rightHip'],
		['leftHip', 'rightHip'],
		['leftShoulder', 'leftElbow'],
		['leftElbow', 'leftWrist'],
		['rightShoulder', 'rightElbow'],
		['rightElbow', 'rightWrist'],
		['leftHip', 'leftKnee'],
		['leftKnee', 'leftAnkle'],
		['rightHip', 'rightKnee'],
		['rightKnee', 'rightAnkle'],
		['leftShoulder', 'nose'],
		['rightShoulder', 'nose'],
	];

	// Draw bigger circle for the head
	// Consider "nose" as a point of reference
	ctx.fillStyle = color;
	const point = points.find(p => p.part === 'nose');
	if (point) {
		ctx.beginPath();
		ctx.arc(point.position.x, point.position.y, 30, 0, Math.PI * 2);
		ctx.stroke();
	}

	// Loop through connections and draw lines
	for (const [part1, part2] of connections) {
		const point1 = points.find(p => p.part === part1);
		const point2 = points.find(p => p.part === part2);

		if (point1 && point2) {
			ctx.beginPath();
			ctx.moveTo(point1.position.x, point1.position.y);
			ctx.lineTo(point2.position.x, point2.position.y);
			ctx.strokeStyle = color;
			ctx.lineWidth = fallDetectionConstants.lineWidth;
			ctx.stroke();
		}
	}
}

function drawKeypoints(keypoints, color, ctx) {
	for (let i = 0; i < keypoints.length; i += 1) {
		const keypoint = keypoints[i];

		if (keypoint.score < fallDetectionConstants.skeletonMinConfidence) {
			// eslint-disable-next-line no-continue
			continue;
		}
		const { y, x } = keypoint.position;
		const excludedPoints = ['rightEar', 'leftEar', 'nose', 'rightEye', 'leftEye'];
		if (excludedPoints.find(item => item === keypoint.part)) {
			continue;
		}
		drawPoint(ctx, y, x, color);
	}
}

function addSkeleton(ctx, skeletonArray) {
	let { color } = fallDetectionConstants;
	skeletonArray.forEach(skeleton => {
		color = fallDetectionConstants.color;
		if (skeleton.score > fallDetectionConstants.skeletonMinConfidence) {
			drawKeypoints(skeleton.keypoints, color, ctx);
			drawSkeleton(skeleton.keypoints, color, ctx);
			const filteredItem = skeleton.keypoints.find(item => item.part === SkeletonPart.RIGHT_EAR);
			if (!filteredItem) {
				return;
			}
			const xAxes = filteredItem.position.x;
			const yAxes = filteredItem.position.y;
			const { action } = skeleton;
			if (action) {
				ctx.font = 'bold 25px Arial';
				ctx.fillText(`Position: ${action}`, xAxes, yAxes);
			}
		}
	});
}

function drawSkeletonDetections(options) {
	addSkeleton(options.ctx, options.skeleton);
}

export default drawSkeletonDetections;
