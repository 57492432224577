import React, { useState, useEffect } from 'react';
import { enums } from '@solaborate/calls';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import { getLatestMeasurements } from 'api/measurements.js';
import { ExamType, MeasurementTypes } from 'constants/enums.js';
import SummaryDetail from 'containers/HealthMeasurements/SummaryDetail.jsx';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import Loader from 'components/Loader.jsx';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import PatientDevices from 'containers/PatientDevices.jsx';

const Overview = props => {
	const [error, setError] = useState('');
	const [latestMeasurements, setLatestMeasurements] = useState([]);
	const [isMeasurementsLoading, setMeasurementsLoading] = useState(true);

	const medicalData = [
		{ title: 'diagnoses', id: 0, image: `${healthCareCdnUrl}footer-icons/diagnosis-active.svg` },
		{ title: 'procedures', id: 1, image: `${healthCareCdnUrl}footer-icons/procedures-active.svg` },
		{ title: 'notes', id: 2, image: `${healthCareCdnUrl}footer-icons/notes-active.svg` },
		{ title: 'prescriptions', id: 3, image: `${healthCareCdnUrl}footer-icons/prescriptions-active.svg` },
		APP_CONFIG.useFeaturePhysicalExercises === 'true' &&
			props.isPhysicalTherapyShown && {
				title: 'physicalTherapy',
				id: 4,
				image: `${healthCareCdnUrl}physical-exercises/add-exercise-active.svg`,
			},
	].filter(Boolean);

	const healthData = [
		{ id: 0, title: translate('heartRate'), type: MeasurementTypes.HEART_RATE },
		{ id: 1, title: translate('bloodPressure'), type: MeasurementTypes.BLOOD_PRESSURE },
		{ id: 2, title: translate('perfusionIndex'), type: MeasurementTypes.PI },
		{ id: 3, title: translate('oxygenSaturation'), type: MeasurementTypes.OXYGEN },
		{ id: 4, title: translate('bloodGlucose'), type: MeasurementTypes.DIABETES },
		{ id: 5, title: translate('weight'), type: MeasurementTypes.WEIGHT },
		{ id: 6, title: translate('bodyTemperature'), type: MeasurementTypes.TEMPERATURE },
		{ id: 7, title: translate('sleepAnalysis'), type: MeasurementTypes.SLEEP },
		{ id: 8, title: translate('walkingRunningDistance'), type: MeasurementTypes.DISTANCE_WALKING_RUNNING },
		{ id: 9, title: translate('steps'), type: MeasurementTypes.STEPS },
		{ id: 10, title: translate('leanBodyMass'), type: MeasurementTypes.LEAN_BODY_MASS },
		{ id: 11, title: translate('numberTimesFallen'), type: MeasurementTypes.FALLEN_TIMES },
		{ id: 11, title: translate('respiratoryRate'), type: MeasurementTypes.RESPIRATORY_RATE },
		{ id: 12, title: translate('bodyMassIndex'), type: MeasurementTypes.BODY_MASS_INDEX },
		{ id: 13, title: translate('stethoscope'), type: MeasurementTypes.STETHOSCOPE },
		{ id: 14, title: translate('flightsClimbed'), type: MeasurementTypes.FLIGHTS_CLIMBED },
		{ id: 15, title: translate('bodyFatPercentage'), type: MeasurementTypes.BODY_FAT },
		{ id: 16, title: translate('waistCircumference'), type: MeasurementTypes.WAIST_CIRCUMFERENCE },
		{ id: 17, title: translate('totalCholesterol'), type: MeasurementTypes.TOTAL_CHOLESTEROL },
		{ id: 18, title: translate('hdlCholesterol'), type: MeasurementTypes.HDL_CHOLESTEROL },
	];

	useEffect(() => {
		getMeasurements(props.patient);
	}, [props.patient]);

	const getMeasurements = async patient => {
		setMeasurementsLoading(true);
		const response = await getLatestMeasurements(patient.userId);
		if (response.error) {
			setError(response.error.message);
		} else {
			const measurementList = response.measurements;
			if (response.stethoscopeRecordings?.length) {
				const newStethoscopeRecording = response.stethoscopeRecordings.map(item => ({
					endDate: item.recordedAt,
					id: item.id,
					isManuallyInserted: false,
					measurementType: item.examinationType === ExamType.HEART ? MeasurementTypes.HEART : MeasurementTypes.LUNGS,
					measurementUnit: 's',
					measurementValue: item.recordDuration,
					source: 'web',
					userId: props.selectedPatient?.userId,
				}));
				setLatestMeasurements([...measurementList, ...newStethoscopeRecording]);
			} else {
				setLatestMeasurements(measurementList);
			}
		}
		setMeasurementsLoading(false);
	};

	const callPatient = type => {
		const { patient } = props;
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: patient.userId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
			[StartQueryStringKeys.CONFERENCE_NAME]: `${patient.firstName} ${patient.lastName}`,
			[StartQueryStringKeys.CALL_TYPE]: type,
		});
		window.open(`/call?${queryParams.toString()}`, '_blank');
	};

	const baseMeasurements = latestMeasurements.filter(measurement =>
		[MeasurementTypes.HEART_RATE, MeasurementTypes.BLOOD_PRESSURE, MeasurementTypes.PI, !MeasurementTypes.HEIGHT].includes(
			measurement.measurementType
		)
	);
	const secondaryMeasurements = latestMeasurements.filter(
		measurement =>
			![MeasurementTypes.HEART_RATE, MeasurementTypes.BLOOD_PRESSURE, MeasurementTypes.PI, MeasurementTypes.HEIGHT].includes(
				measurement.measurementType
			)
	);
	const maxMeasurementsGrid = 3;

	return (
		<>
			<div className='overview-wrapper'>
				<div className={`flex${props.patient.devicesInfos?.length === 0 ? ' no-devices-overview' : ''}`}>
					{props.patient.devicesInfos?.length > 0 && <PatientDevices patient={props.patient} />}
					<div className='overview-mob-lap'>
						<div className='hello-items overview-hello-items overview-mob-lap-items' data-cy='mobLapOverviewHelloItems'>
							<div>
								<div className='hello-device-description flex'>
									<img src={`${healthCareCdnUrl}overview/mobile-laptop.svg`} alt='mobile-laptop-icon' />
									<h3>
										{translate('mobile')} &amp; {translate('laptop')}
									</h3>
								</div>
							</div>
							<div className='hello-feature-grid'>
								<HelloFeatureBlock
									className='hello-feature-audio'
									onClick={() => callPatient(enums.CallTypes.VIDEO)}
									title={translate('talkToPatient')}
								/>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className='flex'>
						<h4>{translate('vitalSigns')}</h4>
						{props.isHealthShown && (
							<span
								style={{ alignContent: 'center' }}
								className='--blue-color bold font-14 cursor-pointer'
								data-cy='goToTheVitalSignsBtn'
								onClick={props.showVitalSigns}>
								{translate('goToThe')} {translate('vitalSigns')}
							</span>
						)}
					</div>
					{!props.isHealthShown && !isMeasurementsLoading && <p>{translate('checkFeatureAvailability')}</p>}
					{!isMeasurementsLoading && props.isHealthShown && (
						<div className='flex flex-wrap'>
							{baseMeasurements.length === 0 && secondaryMeasurements.length === 0 && (
								<p className='no-vital-signs'>{translate('currentlyNoHealthDataMainAnalysis')}</p>
							)}
							{baseMeasurements.length > 0 &&
								baseMeasurements.map(measurement => (
									<SummaryDetail
										measurement={measurement}
										key={measurement.id}
										currentMeasurement={measurement}
										selectedPatient={props.patient}
										isOverViewSummary={true}
										overviewMeasurementTitle={healthData.find(item => measurement.measurementType === item.type)?.title}
										isDoctorView={true}
										isOverviewSummaryDetail={true}
									/>
								))}
							{secondaryMeasurements.length > 0 &&
								secondaryMeasurements
									.slice(0, maxMeasurementsGrid - baseMeasurements.length)
									.map(measurement => (
										<SummaryDetail
											measurement={measurement}
											key={`${measurement.id}${Date.now()}`}
											currentMeasurement={measurement}
											selectedPatient={props.patient}
											isOverViewSummary={true}
											overviewMeasurementTitle={healthData.find(item => measurement.measurementType === item.type)?.title}
											isDoctorView={true}
											isOverviewSummaryDetail={true}
										/>
									))}
						</div>
					)}
					{isMeasurementsLoading && <Loader />}
				</div>
				<div>
					<h4>{translate('otherMedicalInfo')}</h4>
					<div className='flex flex-align-center'>
						{medicalData.map(item => (
							<div
								key={item.id}
								onClick={() => props.changeTab(item.id)}
								className={`flex column-direction cursor-pointer ${
									APP_CONFIG.useFeaturePhysicalExercises === 'true' ? '' : 'medical-data-wrapper'
								}`}
								data-cy='otherMedicalInfo'>
								<img src={item.image} alt='medical info icon' />
								<p>{translate(item.title)}</p>
							</div>
						))}
					</div>
				</div>
			</div>

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default Overview;
