import Select from 'react-select';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import MultiSelect from 'components/Common/FormElements/MultiSelect.jsx';
import { Form, Modal } from 'components/index.js';
import * as Yup from 'yup';
import { CompanyRoles } from 'constants/enums.js';

const AddMemberRole = props => {
	const intl = useIntl();
	const healthSystemsArr = props.healthSystems.map(hs => ({ value: hs.id, label: hs.value }));
	const maxRolesLengthWithoutAdmin = 5;

	const allRoles = [
		{ label: intl.formatMessage({ id: 'nurseOnList' }), value: CompanyRoles.VIRTUAL_CARE_PROVIDER },
		{ label: intl.formatMessage({ id: 'digitalClinicianOnList' }), value: CompanyRoles.DIGITAL_CLINICIAN },
		{ label: intl.formatMessage({ id: 'doctorOnList' }), value: CompanyRoles.DOCTOR },
		{ label: intl.formatMessage({ id: 'adminOnList' }), value: CompanyRoles.ADMIN },
		{ label: intl.formatMessage({ id: 'virtualSitterOnList' }), value: CompanyRoles.VIRTUAL_SITTER },
		{ label: intl.formatMessage({ id: 'superUserOnList' }), value: CompanyRoles.SUPER_USER },
	];

	const showHealthSystem = selectedRole => {
		const rolesExcludingAdmin = getRolesToBeAdded().filter(role => role.value !== CompanyRoles.ADMIN);
		const allNonAdminRolesFilled = rolesExcludingAdmin.length === maxRolesLengthWithoutAdmin;
		const isSuperUserWithoutHealthSystems =
			selectedRole?.value === CompanyRoles.SUPER_USER && props.currentMemberObj.healthSystems?.length === 0;
		return allNonAdminRolesFilled || isSuperUserWithoutHealthSystems;
	};

	const getRolesToBeAdded = () =>
		props.currentMemberObj
			? allRoles.filter(role => !props.currentMemberObj.roles.map(item => item.id).includes(role.value))
			: [];

	const getValidationSchema = () =>
		Yup.object().shape({
			selectedRole: Yup.object()
				.nullable()
				.test('selectedRole', intl.formatMessage({ id: 'superUserRoleValidation' }), role =>
					role?.value === CompanyRoles.SUPER_USER ? props.currentMemberObj?.healthSystems?.length <= 1 : true
				),
		});

	return (
		<Formik
			initialValues={{
				healthSystem: null,
				selectedRole: null,
			}}
			onSubmit={props.onAddMemberRole}
			validationSchema={getValidationSchema()}>
			{formikProps => {
				const { values, touched, errors, isValid, setFieldValue, setFieldTouched, handleSubmit, resetForm } = formikProps;
				return (
					<Modal
						className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
						display={props.isAddRoleModalOpen}
						position='right'
						onModalSubmit={handleSubmit}
						primaryButtonLabel={translate('add')}
						onModalClose={() => {
							resetForm();
							props.onModalClose();
						}}
						shouldSubmitOnEnter={false}
						isSubmitDisabled={!isValid}
						primaryButtonLoading={props.isBtnLoading}>
						<Form className='add-a-role'>
							<h3>{translate('addARole')}</h3>
							<p>{translate('pleaseSelectRole')}</p>
							<Select
								options={getRolesToBeAdded()}
								value={values.selectedRole}
								onChange={val => setFieldValue('selectedRole', val)}
								classNamePrefix='react-select'
								placeholder={`${intl.formatMessage({ id: 'selectRole' })}...`}
								name='selectedRole'
							/>
							{errors.selectedRole && <span className='red-error'>{errors.selectedRole}</span>}
							{showHealthSystem(values.selectedRole) && (
								<MultiSelect
									name='healthSystem'
									placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
									description={translate('selectAtLeastOneHSToAddRole', { value: translate('memberRole') })}
									options={healthSystemsArr}
									value={values.healthSystem}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									touched={touched.healthSystem}
									error={errors.healthSystem}
									backspaceRemovesValue={true}
								/>
							)}
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default AddMemberRole;
