import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { PatientAiSetting, PatientPositionTypes, BodyPositions } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import LeftLateralIcon from 'icons/Monitoring/LeftLateral.jsx';
import ProneIcon from 'icons/Monitoring/Prone.jsx';
import RightLateralIcon from 'icons/Monitoring/RightLateral.jsx';
import SupineIcon from 'icons/Monitoring/Supine.jsx';
import UpInAChairIcon from 'icons/Monitoring/UpInAChair.jsx';
import { getAnalyticsTypeById } from 'infrastructure/helpers/aiHelper.js';
import { getDurationFormat } from 'infrastructure/helpers/dateHelper.js';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

const StyledCurrentPosition = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0px 10px 10px 10px;
	color: var(--gray-0);

	${props =>
		props.$hasExceededTimer &&
		css`
			background: var(--red-3-o20);
			* {
				color: var(--red-3) !important;
			}
		`}

	${props =>
		props.$warningTimer &&
		css`
			background: var(--orange-1-o20);
			* {
				color: var(--orange-1) !important;
			}
		`}

	.left-panel-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		border-right: 1px solid ${props => (props.$hasExceededTimer ? '#F15E5E' : '#fff')};

		p {
			color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
			font-weight: 500;
			font-size: 14px;
		}
	}

	.current-position {
		display: flex;
		align-items: center;

		svg {
			margin-right: 15px;
			width: 48px !important;
			height: 48px !important;
			margin-left: var(--spacing-m);

			${props =>
				props.$hasExceededTimer &&
				css`
					* {
						stroke: #f15e5e;
					}
				`}
		}
	}

	.timer {
		&-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 4px;
		}

		p {
			font-weight: 300;
			font-size: 22px;
			line-height: 16px;
			color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};
		}

		h4 {
			font-weight: 500;
			font-size: 14px;
			line-height: 16.41px;
			margin-bottom: 6px;
		}

		span {
			font-weight: 400;
			font-size: 12px;
			line-height: 14.06px;
		}
	}

	.right-panel-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-left: var(--spacing-m);
		padding-top: 4px;
	}

	.configuration {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive)};

		p:first-of-type {
			font-weight: 500;
		}

		p:last-of-type {
			font-weight: 300;
		}
	}

	.configured-positions {
		display: flex;
		flex-direction: column;
		font-size: 14px;

		p:first-of-type {
			font-weight: 500;
		}

		p:last-of-type {
			font-weight: 300;
		}
	}
`;

const CurrentPosition = ({ deviceId, isDarkMode }) => {
	const [currentPositionData, setCurrentPositionData] = useState(null);
	const [timer, setTimer] = useState(0);
	const [hasExceededTimer, setHasExceededTimer] = useState(false);
	const [warningTimer, setWarningTimer] = useState(false);

	const user = useSelector(state => state.user);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const socket = useContext(SocketContext);

	const { NA, SUPINE, PRONE, LEFT_LATERAL, RIGHT_LATERAL, UP_IN_A_CHAIR } = PatientPositionTypes;

	const intervalRef = useRef(null);

	const pressureInjurySettings = aiSettingList
		.find(item => item.deviceId === deviceId)
		?.settings.find(setting => setting.settingTypeId === PatientAiSetting.PRESSURE_INJURY);

	const getCurrentPositionIcon = () => {
		switch (currentPositionData?.position) {
			case NA:
				return <></>;
			case SUPINE:
				return (
					<SupineIcon color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)} />
				);
			case PRONE:
				return (
					<ProneIcon color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)} />
				);
			case UP_IN_A_CHAIR:
				return (
					<UpInAChairIcon
						color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
					/>
				);
			case LEFT_LATERAL:
				return (
					<LeftLateralIcon
						color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
					/>
				);
			case RIGHT_LATERAL:
				return (
					<RightLateralIcon
						color={isDarkMode ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
					/>
				);
			default:
				return <></>;
		}
	};

	useEffect(() => {
		const onCurrentPatientPosition = data => {
			if (data.helloDeviceId === deviceId) {
				if (intervalRef.current) {
					clearInterval(intervalRef.current);
				}
				setCurrentPositionData(data);
				setTimer(data.timer);
				if ([SUPINE, PRONE, LEFT_LATERAL, RIGHT_LATERAL, UP_IN_A_CHAIR].includes(data?.position)) {
					intervalRef.current = setInterval(() => {
						setTimer(prev => prev + 1);
					}, 1000);
				}
				if (data?.position === NA) {
					setTimer(0);
					clearInterval(intervalRef.current);
				}
			}
		};

		socket.on(SocketEvents.HelloDevice.ON_CURRENT_PATIENT_POSITION, onCurrentPatientPosition);

		return () => {
			clearInterval(intervalRef.current);
			socket.off(SocketEvents.HelloDevice.ON_CURRENT_PATIENT_POSITION, onCurrentPatientPosition);
		};
	}, [socket]);

	useEffect(() => {
		socket.emitWithAck(SocketEvents.HelloDevice.REQUEST_CURRENT_PATIENT_POSITION, { helloDeviceId: deviceId });
	}, []);

	useEffect(() => {
		const existingConfiguredPosition = Object.values(BodyPositions).find(
			position => position.patientPositionId === currentPositionData?.position
		)?.bodyPositionId;
		const configuredTime = +pressureInjurySettings?.value;
		const warningTime = Math.round((80 / 100) * configuredTime);

		if (pressureInjurySettings.pressureInjuryBodyPositions?.includes(existingConfiguredPosition)) {
			if (timer >= warningTime && timer <= configuredTime) {
				setWarningTimer(true);
			} else if (timer > configuredTime) {
				setWarningTimer(false);
				setHasExceededTimer(true);
			}
		}

		return () => {
			setHasExceededTimer(false);
			setWarningTimer(false);
		};
	}, [pressureInjurySettings?.value, timer]);

	return (
		<StyledCurrentPosition $hasExceededTimer={hasExceededTimer} $warningTimer={warningTimer} $isDarkMode={isDarkMode}>
			<div className='left-panel-container'>
				<p>{translate('currentPosition')}</p>
				<div className='current-position'>
					{getCurrentPositionIcon()}
					<div className='timer'>
						<h4>{translate(getAnalyticsTypeById(currentPositionData?.position))}</h4>
						<div className='timer-grid'>
							<p>{Math.floor(timer / 60)}</p>
							<p>{timer % 60}</p>
							<span>min</span>
							<span>sec</span>
						</div>
					</div>
				</div>
			</div>
			<div className='right-panel-container'>
				<div className='configuration'>
					<p>{translate('configuredFor')}</p>
					<p>{pressureInjurySettings?.value ? getDurationFormat(pressureInjurySettings.value) : 0}</p>
				</div>
				<div className='configured-positions'>
					<p>{translate('configuredPositions')}</p>
					<p>{pressureInjurySettings?.pressureInjuryBodyPositions.map(item => BodyPositions[item].label).join(', ')}</p>
				</div>
			</div>
		</StyledCurrentPosition>
	);
};

export default CurrentPosition;
