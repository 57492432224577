import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { RiskLevelValues } from 'constants/enums.js';

const CircularPercentage = ({
	isSemiCircle = false,
	radius = 220,
	semiCircleCyCoordinante = 245,
	maximumValue = 100,
	...props
}) => {
	const activeCircle = useRef();

	// @ts-ignore
	const offset = activeCircle.current?.getTotalLength();
	const dasharray = ((props.value / maximumValue ?? 100) * offset) / (isSemiCircle ? 2 : 1);

	const getGraphColor = riskLevel => {
		switch (riskLevel) {
			case RiskLevelValues.NORMAL:
				return 'great-condition';
			case RiskLevelValues.MODERATE:
				return 'moderate-condition';
			case RiskLevelValues.HIGH:
				return 'risk-condition';
			default:
				return 'great-condition';
		}
	};

	return (
		<svg
			viewBox={`0 0 500 ${isSemiCircle ? '250' : '500'}`}
			xmlns='http://www.w3.org/2000/svg'
			className={isSemiCircle ? 'semi-circluar-percentage' : 'circluar-percentage'}>
			<circle cx='250' cy={isSemiCircle ? semiCircleCyCoordinante : '250'} r={radius - 15} />
			<circle
				ref={activeCircle}
				cx='250'
				cy={isSemiCircle ? semiCircleCyCoordinante : '250'}
				r={radius}
				className={`${getGraphColor()} active`}
				strokeDashoffset={(isSemiCircle ? 0.5 : 0.25) * offset}
				strokeDasharray={`${dasharray}, ${offset - dasharray}`}
				style={{
					transformOrigin: `${isSemiCircle ? `${(semiCircleCyCoordinante / 500) * 100}%` : '50%'} 50%`,
				}}
			/>
		</svg>
	);
};

CircularPercentage.propTypes = {
	value: PropTypes.number.isRequired,
	isSemiCircle: PropTypes.bool,
	radius: PropTypes.number,
	semiCircleCyCoordinante: PropTypes.number,
	maximumValue: PropTypes.number,
};

export default CircularPercentage;
