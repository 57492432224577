import { participant } from '@solaborate/calls';

class LocalParticipant extends participant.Local {
	/**
	 * @param {object} model
	 * @param {string} model.id
	 * @param {number} model.objectId
	 * @param {import('@solaborate/calls').types.ObjectType} model.objectType
	 * @param {string} model.name
	 * @param {string} model.picture
	 * @param {string} [model.alias]
	 * @param {string} [model.interpreterId]
	 */
	constructor(model, params) {
		super(params);

		this.id = model.id;
		this.objectId = model.objectId;
		this.objectType = model.objectType;
		this.name = model.name;
		this.picture = model.picture;
		if (model.interpreterId) {
			this.interpreterId = model.interpreterId;
		}
		if (model.alias) {
			this.alias = model.alias;
		}
	}

	isGuest = false;

	requestedLocalTracks = [];
}

export default LocalParticipant;
