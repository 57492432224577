import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import translate from 'i18n-translations/translate.jsx';
import { AlertTypes, InviteTypes, VisitorType } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { createInvite } from 'api/users.js';

const AssignFamilyMemberOrVisitor = props => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);

	const validateForm = () => {
		return Yup.object().shape({
			email: Yup.string()
				.required(intl.formatMessage({ id: 'emailRequired' }))
				.email(),
		});
	};

	const addFamilyMemberSubmit = async (values, { resetForm }) => {
		setIsLoading(true);
		const { email } = values;
		const formData = {
			users: [{ email: email, inviteTypeId: props.visitorType }],
			inviteInfo: {
				channels: [
					{
						id: props.sectorData.roomId,
						name: props.sectorData.roomName,
						visitorTypeId: props.visitorType === InviteTypes.FAMILY_MEMBER.type ? VisitorType.FAMILY_MEMBER : VisitorType.VISITOR,
					},
				],
				teamId: userSession.healthSystem.id,
				teamName: userSession.healthSystem.name,
			},
		};
		const response = await createInvite(formData);
		const emailExistsErrorCode = 1008;
		if (response.error) {
			if (response.error.response.data.code === emailExistsErrorCode) {
				setError(`${intl.formatMessage({ id: 'emailExists' })}.`);
				return;
			}
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		resetForm();
		setIsLoading(false);
		props.onSubmitVisitingHoursSucceed();
		props.toggleAddFamilyMemberModal();
	};

	const modalClose = () => {
		props.toggleAddFamilyMemberModal();
		setError(null);
	};

	return (
		<Formik
			initialValues={{
				email: '',
			}}
			onSubmit={addFamilyMemberSubmit}
			validationSchema={validateForm}
			validateOnBlur={false}
			validateOnChange={false}>
			{formikProps => {
				const { values, errors, handleSubmit, handleChange } = formikProps;
				return (
					<Modal
						display={true}
						position='right'
						onModalClose={() => modalClose()}
						onModalSubmit={handleSubmit}
						isLoading={isLoading}>
						<Form
							title={translate(props.visitorType === InviteTypes.FAMILY_MEMBER.type ? 'assignFamilyMember' : 'assignVisitor')}
							description={translate(
								props.visitorType === InviteTypes.FAMILY_MEMBER.type ? 'assignFamilyMemberModalDesc' : 'assignVisitorModalDesc'
							)}>
							<Input
								type='text'
								label={translate('email')}
								validationOptions={{}}
								value={values.email}
								onChange={event => {
									if (event.key === 'Enter') {
										event.preventDefault();
										return;
									}
									handleChange(event);
								}}
								name='email'
								placeholder={intl.formatMessage({ id: 'email' })}
								maxLength={127}
								error={errors.email}
							/>
							<PopUpAlert
								alertType={AlertTypes.DANGER}
								display={error}
								onAlertClose={() => setError(null)}
								contentText={error}
								isSilent={true}
								center={true}
							/>
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default AssignFamilyMemberOrVisitor;
