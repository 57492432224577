import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import React from 'react';
import { useIntl } from 'react-intl';
import Button from 'components/Button.jsx';

const AiConfigCheckbox = ({
	array,
	onCheckboxChange,
	onSetAsDefault,
	arrayName,
	header = null,
	title = null,
	subtitle = null,
	errors = null,
}) => {
	const intl = useIntl();
	return (
		<div className='top-15'>
			{header && (
				<p className='bottom-5'>
					{translate(header, {
						value: intl.formatMessage({ id: title }),
					})}
				</p>
			)}

			<div className='doctor-profile-settings ai-configurations'>
				{subtitle && <p className='bottom-5'>{translate(subtitle)}</p>}
				<div className='flex flex-wrap'>
					{array.map(aiConfig => (
						<div className='tick-box-active-input flex bottom-5 margin-right-m flex-align-center' key={aiConfig.value}>
							<label className='flex remember-me cursor-pointer no-margin'>
								<input
									type='checkbox'
									checked={aiConfig.isChecked}
									name={aiConfig.label}
									onChange={event => onCheckboxChange({ event, aiConfigValue: aiConfig.value, arrayName })}
								/>
								<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
									<div className='onb-custom-checkbox blue-check-box' />
								</div>
								<span className='left-s margin-right-m'>{aiConfig.label}</span>
							</label>
							{aiConfig.isChecked && (
								<Button
									className={classNames('set-ai-default-btn', { 'default-btn': aiConfig.isDefault })}
									onClick={() => onSetAsDefault(aiConfig.value, arrayName)}
									icon={aiConfig.isDefault ?? 'done'}
									text={translate(aiConfig.isDefault ? 'default' : 'setAsDefault')}
								/>
							)}
						</div>
					))}
				</div>
				{errors && <span className='red-error flex margin-top-s'>{errors}</span>}
			</div>
		</div>
	);
};

export default AiConfigCheckbox;
