import { healthCareCdnUrl } from 'constants/global-variables.js';
import { LOCALES } from 'i18n-translations/index.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';

const getLanguagePrefix = () => {
	let result = '';
	const locale = getPreferredLanguageLocale();
	if (locale === LOCALES.ARABIC) {
		result = 'arabic-';
	}
	if (locale === LOCALES.GERMAN) {
		result = 'german-';
	}
	if (locale === LOCALES.SPANISH) {
		result = 'spanish-';
	}
	return result;
};

const fallAttentionSound = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}fall-detection-attention.mp3`);

const railSound = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}rail-down-attention.mp3`);

const personPositionSound = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}patient-at-risk.mp3`);

const extremelyHighPressure = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}extremely-high-pressure.mp3`);

const extremelyLowPressure = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}extremely-low-pressure.mp3`);

const extremelyHighTemperature = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}extremely-high-temperature.mp3`);

const highBloodGlucose = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}high-blood-glucose.mp3`);

const highBloodPressure = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}high-blood-pressure.mp3`);

const highHeartRate = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}high-heart-rate.mp3`);

const highTemperature = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}high-temperature.mp3`);

const lowBloodPressure = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}low-blood-pressure.mp3`);

const lowHeartRate = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}low-heart-rate.mp3`);

const lowTemperature = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}low-temperature.mp3`);

const obese = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}obese.mp3`);

const overWeight = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}over-weight.mp3`);

const sleepDeficient = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}sleep-deficient.mp3`);

const underWeight = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}under-weight.mp3`);

const lowOxygen = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}low-saturation.mp3`);

const extremelyLowOxygen = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}extremely-low-saturation.mp3`);

const dangerSound = new Audio(`${healthCareCdnUrl}sound/alert-danger-sound.mp3`);

const ivBagSound = new Audio(`${healthCareCdnUrl}sound/${getLanguagePrefix()}iv-bag.mp3`);

export {
	fallAttentionSound,
	railSound,
	personPositionSound,
	extremelyHighPressure,
	extremelyLowPressure,
	extremelyHighTemperature,
	highBloodGlucose,
	highBloodPressure,
	highHeartRate,
	highTemperature,
	lowBloodPressure,
	lowHeartRate,
	lowTemperature,
	obese,
	overWeight,
	sleepDeficient,
	underWeight,
	lowOxygen,
	extremelyLowOxygen,
	dangerSound,
	ivBagSound,
};
