import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { addAlertActivity } from 'api/alerts.js';
import Alert from 'components/Alert.jsx';
import { MeasurementActivityTypes } from 'constants/enums.js';
import { Button } from 'components/index.js';

const AmbientMonitoringAlertModal = ({ feed, display, onCloseClick, onForwardToNurses, showForwardToNurses }) => {
	const ref = useRef(null);
	const [alertType, setAlertType] = useState(null);
	const [error, setError] = useState('');

	const onSubmit = async activityType => {
		const params = {
			alertId: feed.warning.alertId,
			activityType,
			isAiAlert: feed.warning.isAiAlert,
			comment: '',
			isDraft: true,
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
		}
		setAlertType(null);
		onCloseClick(feed);
	};

	return (
		<>
			{!alertType && (
				<div className={classNames('flex column-direction ambient-monitoring-alert-modal', display ? 'show' : '')}>
					<div className='flex column-direction ambient-monitoring-alert-modal-wrapper' ref={ref}>
						<div className='flex flex-space-between flex-align-center'>
							<span>
								{feed?.roomName} {translate('alert')}
							</span>
							<span className='flex flex-align-center flex-justify-center' onClick={onCloseClick}>
								<i className='material-icons-outlined'>close</i>
							</span>
						</div>
						<div className='flex flex-justify-center flex-align-center'>
							<span className='flex flex-align-center flex-justify-center'>{feed?.warning?.icon}</span>
							<span>{feed?.warning?.text}</span>
						</div>

						<div className='flex flex-justify-center'>
							<Button
								onClick={() => {
									setAlertType(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
									onSubmit(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
								}}
								className='false-alert'
								text={translate('falseAlert')}
							/>

							<Button
								onClick={() => {
									setAlertType(MeasurementActivityTypes.ACKNOWLEDGED);
									onSubmit(MeasurementActivityTypes.ACKNOWLEDGED);
								}}
								className='acknowledged-alert'
								text={translate('acknowledge')}
							/>
						</div>
						{showForwardToNurses && (
							<Button onClick={() => onForwardToNurses(feed)} className='forward-to-nurses' text={translate('forwardToNurses')} />
						)}
					</div>
				</div>
			)}
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};
export default AmbientMonitoringAlertModal;
