import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getOrganizationTranslationProviders = async ({ pageIndex, pageSize, healthSystemId }) => {
	const teamId = healthSystemId ? `&teamId=${healthSystemId}` : '';
	try {
		const baseUrl = `v1/healthcare/organizations/${getCompanyId()}/translation-providers?pageIndex=${pageIndex}&pageSize=${pageSize}${teamId}`;
		const { data } = await gatewayApi.get(baseUrl);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTranslationProviders = async () => {
	// to get baseURL
	try {
		const baseUrl = `v1/healthcare/organizations/translation-providers`;
		const { data } = await gatewayApi.get(baseUrl);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createTranslationProvider = async (healthSystemId, params) => {
	try {
		const baseUrl = `v1/healthcare/organizations/${getCompanyId()}/teams/${healthSystemId}/translation-providers`;
		const { data } = await gatewayApi.post(baseUrl, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteTranslationProvider = async (translationProviderId, healthSystemId) => {
	try {
		const baseUrl = `v1/healthcare/organizations/${getCompanyId()}/teams/${healthSystemId}/translation-providers/${translationProviderId}`;
		const { data } = await gatewayApi.delete(baseUrl);
		return data;
	} catch (error) {
		return { error };
	}
};
