import { getPatientReport } from 'api/dashboard.js';
import LightTheme from 'calls/styles/LightTheme.js';
import Session from 'components/DashboardCharts/PatientCentric/Session.jsx';
import DateRangePicker from 'components/DateRangePicker.jsx';
import { Alert, Button } from 'components/index.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import ToastMessage from 'components/ToastMessage.jsx';
import { AiSetting, DashboardSettings, SettingsCategory } from 'constants/configurationEnums.js';
import { DefaultTimezone, DetailedReportType } from 'constants/dashboard.js';
import { DateRangePresets, DeviceListLevel, TaskStatus } from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import Download from 'icons/Dashboard/Download.jsx';
import Timer from 'icons/Dashboard/Timer.jsx';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { getMonthDayYearDateFormat } from 'infrastructure/helpers/dateHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import AiSummaryWrapper from 'components/DashboardCharts/PatientCentric/AI/AiSummaryWrapper.jsx';
import { getTeamSettings } from 'api/adminConfigurations.js';

const StickyHeader = styled.div`
	position: sticky;
	z-index: 50;
	top: 0;
	border-bottom: 1px solid var(--gray-2);
	display: flex;
	align-items: center;
	gap: var(--spacing-s);
	padding: var(--spacing-xl);
	background: var(--gray-0);

	> div {
		&:first-of-type {
			align-items: center;
			background: var(--gray-12);
			border-radius: var(--spacing-s);
			cursor: pointer;
			display: flex;
			flex-shrink: 0;
			height: 25px;
			justify-content: center;
			margin-right: var(--spacing-s);
			width: 25px;
			i {
				font-size: 16px;
			}
		}

		&:nth-of-type(3) {
			margin-left: var(--spacing-s);
			text-align: left;
			p {
				margin: 0;
				padding: 0;
				text-align: left;
				color: var(--blue-2);
			}
		}

		&:last-of-type {
			margin-left: var(--spacing-sl);
			font-size: 14px;
			text-align: left;
			p {
				margin: 0;
				padding: 0;
			}
		}
	}
	button {
		background: transparent;
		border: 1px solid var(--blue-2);
		padding: var(--spacing-l);
		display: flex;
		align-items: center;
		height: 40px;
		color: var(--blue-2);
		margin-left: auto;
		span {
			color: var(--blue-2);
			margin-left: var(--spacing-s);
			font-weight: 500;
		}
	}
`;

const tabs = [
	{
		id: DetailedReportType.SESSIONS,
		description: 'sessions',
	},
	{
		id: DetailedReportType.AI,
		description: 'ai',
	},
];

const fromDefault = new Date();
const toDefault = new Date();
const maximumRangeOfDays = 90;

const DetailedReport = () => {
	const history = useHistory();
	const location = useLocation();
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const currentHealthSystem = useSelector(state => state.user.userSession.healthSystem);
	const [currentTab, setCurrentTab] = useState(DetailedReportType.SESSIONS);
	const [dateRange, setDateRange] = useState({ from: fromDefault, to: toDefault, preset: DateRangePresets.LAST_30_DAYS });
	const [taskId, setTaskId] = useState(null);
	const [reportTaskStatus, setReportTaskStatus] = useState(null);
	const [error, setError] = useState(null);
	const [selectedTimezone, setSelectedTimezone] = useState(DefaultTimezone);
	const [isEvidenceEnabled, setIsEvidenceEnabled] = useState(false);
	const socket = useContext(SocketContext);

	const getPatientProperty = property => location.state.patient?.[property] ?? '';

	useEffect(() => {
		const getAiFeatureFlags = async () => {
			const response = await getTeamSettings({
				teamId: currentHealthSystem?.id,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.AI_SETTINGS],
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setIsEvidenceEnabled(
				response.settings.some(item => item.settingTypeId === AiSetting.ALERTS_EVIDENCE && item.value === 'true')
			);
		};
		getAiFeatureFlags();
	}, []);

	useEffect(() => {
		const { state } = location;
		if (state && currentTab === DetailedReportType.AI) {
			setDateRange(prevState => state.filters?.dateRange || prevState);
			setSelectedTimezone(location.state.filters.selectedTimezone);
		}
	}, [location, currentTab]);

	const downloadPatientReport = async () => {
		const {
			patient,
			healthSystemId,
			filters: { selectedTimezone },
		} = location.state;
		const response = await getPatientReport({
			healthSystemId,
			patientId: patient.patientId,
			from: Math.floor(moment(patient.admissionDate).utc(true).valueOf() / 1000),
			to: Math.floor(
				(patient.dischargeDate ? moment(patient.dischargeDate).utc(true).valueOf() : moment().utc().valueOf()) / 1000
			),
			timezone: selectedTimezone?.zone,
		});

		if (response.error) {
			setError(response.error.message);
			return;
		}
		setTaskId(response.taskId);
	};

	useEffect(() => {
		const handleTaskStatusUpdated = data => {
			if (taskId === data.taskId && [TaskStatus.RUNNING, TaskStatus.COMPLETED].includes(data.taskStatusId)) {
				setReportTaskStatus(data.taskStatusId);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket, taskId]);

	return (
		<MainLayout>
			<div className='main-view'>
				<ToastMessage
					key={reportTaskStatus}
					className='dashboard-report'
					onClose={() => setReportTaskStatus(null)}
					showToast={reportTaskStatus}
					timer={10000}>
					<span>
						{reportTaskStatus === TaskStatus.COMPLETED && <Download color={LightTheme.colors.greenTwo} />}
						{reportTaskStatus === TaskStatus.RUNNING && <Timer color={LightTheme.colors.greenTwo} />}
					</span>
					<div>
						{reportTaskStatus === TaskStatus.COMPLETED && (
							<>
								<span>{translate('reportSentSuccessfully')}</span>
								<p>{translate('toAccessCheckEmail')}</p>
							</>
						)}
						{reportTaskStatus === TaskStatus.RUNNING && (
							<>
								<span>{translate('reportIsUnderway')}</span>
								<p>{translate('reportUnderwayDescription')}</p>
								<p>{translate('thankYouForPatience')}</p>
							</>
						)}
					</div>
				</ToastMessage>
				<StickyHeader>
					<div
						onClick={() =>
							history.push({
								pathname: location.state.prevPath,
								search: location.state.prevSearch,
								state: { ...location.state, prevPath: location.pathname },
							})
						}>
						<i className='material-icons-outlined'>arrow_back</i>
					</div>
					<ProfilePicture
						fullName={getPatientProperty('fullName')}
						profilePicture={getPatientProperty('profilePicture')}
						className='provider-intervening-img-wrapper'
					/>
					<div>
						<span>{getPatientProperty('fullName')}</span>
						{getPatientProperty('mrn') && <p>MRN {getPatientProperty('mrn')}</p>}
					</div>
					<div>
						<label>{translate('dateOfBirth')}</label>
						<p>
							{location.state.patient?.birthDate ? getMonthDayYearDateFormat(location.state.patient.birthDate) : translate('na')}
						</p>
					</div>
					{getConfigurationValue(companyConfigurations[DashboardSettings.PATIENT_REPORT_DOWNLOAD_BUTTON]) && (
						<Button svgIcon={<Download />} text={translate('downloadPatientReport')} onClick={downloadPatientReport} />
					)}
				</StickyHeader>

				<div className='dashboard-tabs position-relative'>
					{currentTab === DetailedReportType.AI && (
						<div className='flex flex-align-center position-absolute report-calendar'>
							<label>
								<DateRangePicker
									defaultFrom={dateRange.from}
									defaultTo={dateRange.to}
									defaultPreset={dateRange.preset}
									handleRangeChange={setDateRange}
									maxDays={maximumRangeOfDays}
									selectedTimezone={selectedTimezone}
								/>
							</label>
						</div>
					)}
					<Tabs activeIndex={currentTab} onChange={setCurrentTab}>
						<TabList className='detailed-report-tabs'>
							{tabs.map(tab => (
								<Tab key={tab.id}>{translate(tab.description)}</Tab>
							))}
						</TabList>
						<TabPanels>
							<TabPanel>
								<Session />
							</TabPanel>
							<TabPanel>
								<AiSummaryWrapper
									dateRange={dateRange}
									selectedTimezone={selectedTimezone}
									isEvidenceEnabled={isEvidenceEnabled}
								/>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</MainLayout>
	);
};

export default DetailedReport;
