import { deleteActiveDirectory, deleteGroupRole, getADGroupRoles, getActiveDirectories } from 'api/activeDirectory.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Grid from 'components/Grid.jsx';
import Modal from 'components/Modal.jsx';
import { ConfigHistoryTypes } from 'constants/configurationEnums.js';
import { ExternalIdentityProviders, TeamTypes, UserRoles } from 'constants/enums.js';
import ActiveDirectoryForm from 'containers/Configurations/ActiveDirectoryForm.jsx';
import TableDeleteButton from 'icons/Admin/TableDeleteButton.jsx';
import TableEditButton from 'icons/Admin/TableEditButton.jsx';
import TableHistoryButton from 'icons/Admin/TableHistoryButton.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';

const ActiveDirectory = props => {
	const role = getUserRole();
	const [activeDirectories, setActiveDirectories] = useState([]);
	const [groupRoles, setGroupRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const organizationState = useSelector(state => state.healthSystems);
	const [error, setError] = useState(null);
	const [isHospitalDisabled, setHospitalDisabled] = useState(true);
	const [hospitals, setHospitals] = useState([]);
	const [isModalOpen, setModalOpen] = useState(false);
	const [isGroupRoleModal, setIsGroupRoleModal] = useState(false);
	const [editConfig, setEditConfig] = useState(null);
	const intl = useIntl();
	const [total, setTotal] = useState(0);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [hasDeleteError, setDeleteError] = useState(false);

	const translator = id => intl.formatMessage({ id });

	const activeSubTab = {
		ActiveDirectory: 0,
		GroupRoles: 1,
	};

	const activeDirectoriesHeaders = [
		{ id: 'hospital', title: translator('hospital') },
		{ id: 'domain', title: translator('domain') },
		{ id: 'validGroupName', title: translator('validGroupName') },
		{ id: 'createdBy', title: translator('createdBy') },
		{ id: 'dateCreated', title: translator('dateCreated') },
		{ id: 'adConfigurationTypeId', title: translator('type') },
		{ id: 'edit', title: '' },
	];

	const groupRoleHeaders = [
		{ id: 'healthSystem', title: translator('healthSystem') },
		{ id: 'hospital', title: translator('hospital') },
		{ id: 'role', title: translator('role') },
		{ id: 'group', title: translator('group') },
		{ id: 'createdBy', title: translator('createdBy') },
		{ id: 'dateCreated', title: translator('dateCreated') },
		{ id: 'edit', title: '' },
	];

	const onPaginationChange = value => {
		setIsLoading(true);
		props.onPaginationChange(value);
	};

	const transformRows = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			if (activeDirectories.length === 0) {
				return [];
			}
			return activeDirectories.map(item => ({
				id: item.id,
				hospital: item?.team?.name,
				domain: item.domain,
				validGroupName: item.validGroupName,
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: formattedDate(item.dateCreated),
				adConfigurationTypeId: getActiveConfigurationTypeId(item.adConfigurationTypeId),
				edit: getConfigButtons(item),
			}));
		}
		if (groupRoles.length === 0) {
			return [];
		}
		return groupRoles.map(item => ({
			id: item.id,
			healthSystem: TeamTypes.HEALTH_SYSTEM === item?.team?.typeId ? item?.team?.name : 'N/A',
			hospital: TeamTypes.HOSPITAL === item?.team?.typeId ? item?.team?.name : 'N/A',
			role: item.role?.name ? translator(stringToCamelCase(item.role?.name)) : 'N/A',
			group: item.group,
			createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
			dateCreated: formattedDate(item.dateCreated),
			edit: getConfigButtons(item),
		}));
	};

	const transformArray = (array, id = 'value', label = 'label') => {
		const newArray = array.map(item => ({ [id]: item.id, [label]: item.name }));
		if (role === UserRoles.ADMIN) {
			newArray.unshift({ [id]: '0', [label]: translator('all') });
		}

		return newArray;
	};

	const onHealthSystemSelect = healthSystem => {
		setActiveDirectories([]);
		setGroupRoles([]);
		setIsLoading(true);
		props.setSelectedHealthSystem(healthSystem);
	};

	useEffect(() => {
		const getHospitals = async healthSystem => {
			let hospitalsArray;
			if (healthSystem.value !== '0') {
				hospitalsArray = await getHealthSystemHospitals(healthSystem?.value || healthSystem?.id);
			}
			setHospitalDisabled(healthSystem.value === '0');
			setHospitals(transformArray(hospitalsArray));
		};

		getHospitals(props.selectedHealthSystem);
	}, [props.selectedHealthSystem]);

	const DropdownIndicator = () => {
		return <i className='material-icons-outlined'>arrow_drop_down</i>;
	};

	const onHospitalSelect = selection => {
		setActiveDirectories([]);
		setGroupRoles([]);
		setIsLoading(true);
		props.setSelectedHospital(selection);
	};

	const onTabChange = async tabIndex => {
		setActiveDirectories([]);
		setGroupRoles([]);
		setIsLoading(true);
		await props.onSubTabChange(tabIndex);
	};

	const fetchDirectories = useCallback(async () => {
		setIsLoading(true);
		const formData = {
			pageSize: props.pagination.size,
			pageIndex: props.pagination.index,
			healthSystemId: props.selectedHealthSystem.value === '0' ? null : props.selectedHealthSystem.value,
			hospitalId: props.selectedHospital?.value === '0' ? null : props.selectedHospital?.value,
		};
		const response = await getActiveDirectories(formData);
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setActiveDirectories(response.activeDirectories);
		setTotal(response.total);
		setIsLoading(false);
	}, [props.pagination.index, props.pagination.size, props.selectedHealthSystem.value, props.selectedHospital]);

	const fetchGroupRoles = useCallback(async () => {
		setIsLoading(true);
		const formData = {
			pageSize: props.pagination.size,
			pageIndex: props.pagination.index,
			healthSystemId: props.selectedHealthSystem.value === '0' ? null : props.selectedHealthSystem.value,
			hospitalId: props.selectedHospital?.value === '0' ? null : props.selectedHospital?.value,
		};

		const response = await getADGroupRoles(formData);
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setGroupRoles(response.adGroupRoles);
		setTotal(response.total);
		setIsLoading(false);
	}, [props.pagination.index, props.pagination.size, props.selectedHealthSystem.value, props.selectedHospital]);

	useEffect(() => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			fetchDirectories();
			return;
		}
		fetchGroupRoles();
	}, [props.activeSubTab, fetchGroupRoles, fetchDirectories]);

	const getActiveConfigurationTypeId = config => {
		const configDomain = {
			[ExternalIdentityProviders.AZURE]: translator('azureAd'),
			[ExternalIdentityProviders.PING_FEDERATE]: translator('pingFederate'),
			[ExternalIdentityProviders.OKTA]: translator('okta'),
			[ExternalIdentityProviders.DUO_SSO]: translator('duo'),
		};
		return configDomain[config];
	};

	const getConfigButtons = config => {
		let editConfiguration;
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			editConfiguration = {
				id: config.id,
				hospitalId: config?.team?.name,
				validGroupName: config.validGroupName,
				username: config.username,
				password: config.password,
				domain: config.domain,
				includeRoles: config.includeRoles,
				isSingleSignOutEnabled: config.isSingleSignOutEnabled,
				autoSyncOnLogin: config.autoSyncOnLogin,
				emailDomains: config.adEmailDomains?.map(item => item.domain) || [],
				emailDomain: '',
				autoAdd: config.autoAdd,
				autoDelete: config.autoDelete,
				autoUpdate: config.autoUpdate,
				isCompanyLevelConfiguration: config.isCompanyLevelConfiguration,
				adConfigurationTypeId: config.adConfigurationTypeId,
			};
		} else {
			editConfiguration = {
				id: config.id,
				hospitalId: config?.team?.name,
				roleId: config.role.id,
				group: config.group,
			};
		}

		return (
			<div className='wrapped'>
				{props.activeSubTab === activeSubTab.ActiveDirectory && (
					<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.ADs}`} onClick={props.onHistoryClick}>
						<div
							className='material-icons-outlined boxed-icon view-history-icon'
							data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
							data-position='top'>
							<TableHistoryButton />
						</div>
					</Link>
				)}
				<TableEditButton onClick={() => openEditModal(editConfiguration)} />
				<TableDeleteButton onClick={() => openDeleteModal(editConfiguration)} />
			</div>
		);
	};

	const openEditModal = config => {
		setHospitals([]);
		setEditConfig(config);
		setModalOpen(true);
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			setIsGroupRoleModal(false);
			return;
		}
		setIsGroupRoleModal(true);
	};

	const openModal = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			setIsGroupRoleModal(false);
			setModalOpen(prevState => !prevState);
			return;
		}
		setIsGroupRoleModal(true);
		setModalOpen(prevState => !prevState);
	};
	const getHeaders = () => {
		switch (props.activeSubTab) {
			case activeSubTab.ActiveDirectory:
				return activeDirectoriesHeaders;
			case activeSubTab.GroupRoles:
				return groupRoleHeaders;
			default:
				return [''];
		}
	};

	const openDeleteModal = async values => {
		setEditConfig(values);
		setIsDeleteModalOpen(true);
	};

	const handleDelete = async () => {
		setIsLoading(true);
		setDeleteError(false);

		let res = null;
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			res = await deleteActiveDirectory(editConfig.id);
		} else if (props.activeSubTab === activeSubTab.GroupRoles) {
			res = await deleteGroupRole(editConfig.id);
		}

		if (res.error) {
			setDeleteError(true);
		} else {
			setDeleteError(false);
			setEditConfig(null);
			setIsDeleteModalOpen(false);
			if (props.activeSubTab === activeSubTab.ActiveDirectory) {
				setActiveDirectories(prevState => prevState.filter(item => item.id !== editConfig.id));
			} else {
				setGroupRoles(prevState => prevState.filter(item => item.id !== editConfig.id));
			}
			setIsLoading(false);
		}
	};

	const fetchData = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			setActiveDirectories([]);
			fetchDirectories();
			toggleModal();
			return;
		}
		setGroupRoles([]);
		fetchGroupRoles();
		toggleModal();
	};

	const toggleModal = () => {
		setModalOpen(prevState => !prevState);
		setEditConfig(null);
	};

	return (
		<div>
			<CustomTable isLoading={isLoading} headers={getHeaders()} rows={isLoading ? [] : transformRows()}>
				<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={translator('all')}
						classNamePrefix='custom-select'
						options={transformArray(organizationState.allHealthSystems)}
						components={{ DropdownIndicator }}
						onChange={onHealthSystemSelect}
						isDisabled={role === UserRoles.SUPER_USER}
					/>
					<Select
						placeholder={translator('select')}
						value={props.selectedHospital ? hospitals.find(x => x.value === props.selectedHospital) : null}
						isDisabled={isHospitalDisabled}
						classNamePrefix='custom-select'
						options={hospitals}
						components={{ DropdownIndicator }}
						onChange={onHospitalSelect}
					/>
					{activeDirectories.length === 0 && !isLoading && (
						<Button
							onClick={openModal}
							horizAlign='end'
							text={translator(props.activeSubTab === activeSubTab.ActiveDirectory ? 'addConfiguration' : 'addGroupRoles')}
							className='text-transform-none'
						/>
					)}
				</Grid>
				<div>
					<ul className='tabs active-directory-tabs' data-cy='activeDirectoryTabs'>
						<li className={props.activeSubTab === activeSubTab.ActiveDirectory ? 'active' : ''}>
							<span onClick={() => onTabChange(activeSubTab.ActiveDirectory)}>{translator('externalIdentityProviders')}</span>
						</li>
						<li className={props.activeSubTab === activeSubTab.GroupRoles ? 'active' : ''}>
							<span onClick={() => onTabChange(activeSubTab.GroupRoles)}>{translator('groupRoles')}</span>
						</li>
					</ul>
				</div>
			</CustomTable>
			{!isLoading && (
				<Pagination
					totalCount={total}
					pageSize={props.pagination.size}
					pageIndex={props.pagination.index}
					onChange={(pageSize, pageIndex) => onPaginationChange({ pageSize, pageIndex })}
				/>
			)}
			{isModalOpen && (
				<ActiveDirectoryForm
					activeTab={props.activeSubTab}
					isModalOpen={isModalOpen}
					isSuperUser={role === UserRoles.SUPER_USER}
					healthSystems={transformArray(organizationState.allHealthSystems, 'id', 'value')}
					toggleModal={toggleModal}
					initialValues={editConfig}
					isGroupRoleModal={isGroupRoleModal}
					onSucceeded={fetchData}
				/>
			)}
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<Modal
				modalSelector='delete'
				display={isDeleteModalOpen}
				position='center'
				submitButtonText='Delete'
				onModalSubmit={() => handleDelete()}
				onModalClose={() => {
					setIsDeleteModalOpen(false);
					setEditConfig(null);
				}}>
				<form>
					<h3>{translator('warning')}</h3>
					<p>{translator('areYouSureToDeleteConfig')}</p>
					{hasDeleteError && <p className='error'>{translator('somethingWentWrong')}</p>}
				</form>
			</Modal>
		</div>
	);
};

export default ActiveDirectory;
