const Rails = props => (
	<svg width='30' height='21' viewBox='0 0 30 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M8.8584 8.75H6.3584V16.25H8.8584V13.75H21.3584V16.25H23.8584V8.75H21.3584V11.25H8.8584V8.75Z'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeLinejoin='round'
		/>
		<rect x='2.6084' y='6.25' width='25' height='2.5' stroke='#E0AF4C' strokeWidth='1.5' strokeLinejoin='round' />
		<path
			d='M7.6084 20C8.64393 20 9.4834 19.1605 9.4834 18.125C9.4834 17.0895 8.64393 16.25 7.6084 16.25C6.57286 16.25 5.7334 17.0895 5.7334 18.125C5.7334 19.1605 6.57286 20 7.6084 20Z'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeMiterlimit='10'
		/>
		<path
			d='M22.6084 20C23.6439 20 24.4834 19.1605 24.4834 18.125C24.4834 17.0895 23.6439 16.25 22.6084 16.25C21.5729 16.25 20.7334 17.0895 20.7334 18.125C20.7334 19.1605 21.5729 20 22.6084 20Z'
			stroke='#E0AF4C'
			strokeWidth='1.5'
			strokeMiterlimit='10'
		/>
		<path
			d='M2.6084 6.25H2.3584C1.80611 6.25 1.3584 5.80228 1.3584 5.25V2.25C1.3584 1.69772 1.80611 1.25 2.3584 1.25H4.69418C4.9594 1.25 5.21376 1.35536 5.40129 1.54289L7.31551 3.45711C7.50304 3.64464 7.7574 3.75 8.02261 3.75H27.8584C28.4107 3.75 28.8584 4.19772 28.8584 4.75V5.25C28.8584 5.80228 28.4107 6.25 27.8584 6.25H27.6084'
			stroke='#E0AF4C'
			strokeWidth='1.5'
		/>
	</svg>
);
export default Rails;
