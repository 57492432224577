import { getPatientAiAlerts } from 'api/alerts.js';
import { EvidenceIcon } from 'calls/icons/index.js';
import { Alert, Button, Grid, Loader } from 'components/index.js';
import { ActivityTypes, FallPreventionAlertTypes } from 'constants/ai.js';
import EvidenceReport from 'containers/Monitoring/EvidenceReport.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getDetailsByLastActivity, getAlertMessage } from 'infrastructure/helpers/alertsHelper.js';
import { timezoneToUTCTimestamp, utcToTimezone } from 'infrastructure/helpers/dateHelper.js';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

const ChartWrapper = styled.div`
	border: 1px solid var(--gray-15);
	background: var(--gray-0);
	padding: var(--spacing-l);
	height: 250px;
	${props =>
		props.$hasScroll &&
		css`
			> div {
				overflow: auto;
				max-height: 200px;
			}
		`}
`;

const getActivityType = types => ActivityTypes.find(type => types?.includes(type)) ?? null;

const AiAlertsHistory = ({ dateRange, selectedTimezone, isEvidenceEnabled }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [selectedEvidence, setSelectedEvidence] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [alerts, setAlerts] = useState([]);
	const [error, setError] = useState('');
	const hasReachedEnd = useRef(null);
	const location = useLocation();

	const fetchAiAlerts = useCallback(
		debounce(async params => {
			const response = await getPatientAiAlerts(params);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setAlerts(prevState => [...prevState, ...response.aiAlerts]);
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
			hasReachedEnd.current = response?.aiAlerts?.length < pagination.pageSize;
		}, 500),
		[]
	);
	useEffect(() => {
		const { patient } = location.state;
		const params = {
			pageSize: pagination.pageSize,
			pageIndex: pagination.pageIndex,
			userId: patient?.healthcareUserId,
			activityTypes: ActivityTypes,
			alertTypes: FallPreventionAlertTypes,
			start: timezoneToUTCTimestamp(dateRange.from, selectedTimezone.zone),
			end: timezoneToUTCTimestamp(dateRange.to, selectedTimezone.zone),
		};

		fetchAiAlerts(params);
	}, [location, dateRange, fetchAiAlerts, pagination?.pageIndex, pagination?.pageSize, selectedTimezone.zone]);

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
		}
	};

	useEffect(() => {
		setIsLoading(true);
		setAlerts([]);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	}, [dateRange]);

	return (
		<>
			<ChartWrapper $hasScroll={alerts.length > 0}>
				<h3 className='semi-bold font-14 flex'>{translate('aiAlerts')}</h3>
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='180px' horizAlign='center' vertAlign='center'>
						<div className='text-align-center'>
							<Loader />
						</div>
					</Grid>
				)}
				{!isLoading && alerts.length === 0 && (
					<Grid columns='1fr' rows='1fr' stretch='180px' horizAlign='center' vertAlign='center'>
						<div className='text-align-center'>
							<p className='semi-bold font-14'>{translate('noData')}</p>
						</div>
					</Grid>
				)}
				<div onScroll={handleScroll}>
					{!isLoading &&
						alerts.map(item => {
							const activityType = getActivityType(item?.activityTypes);
							return (
								<div key={item.id} className='ai-alerts-history'>
									<div>
										<p>{getAlertMessage(item.aiAlertType?.id)}</p>
										<span className='no-padding'>{utcToTimezone(item.dateCreated, selectedTimezone.zone)}</span>
									</div>
									<div className='flex row-direction gap-xl'>
										<Button
											text={getDetailsByLastActivity(activityType)?.text}
											className='alert-button'
											borderColor={getDetailsByLastActivity(activityType)?.color}
											color={getDetailsByLastActivity(activityType)?.color}
											icon={getDetailsByLastActivity(activityType)?.icon}
										/>
										{isEvidenceEnabled && (
											<Button
												className='evidence-btn'
												svgIcon={<EvidenceIcon />}
												onClick={() =>
													setSelectedEvidence({
														helloDeviceSerialNumber: item.serialNumber,
														id: item.id,
														startTime: utcToTimezone(item.dateCreated, selectedTimezone.zone),
														lastActivityType: activityType,
														label: getAlertMessage(item.aiAlertType?.id),
													})
												}
											/>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</ChartWrapper>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			{selectedEvidence && <EvidenceReport setIsModalOpen={setSelectedEvidence} evidenceDetails={selectedEvidence} isSlideshow />}
		</>
	);
};

export default AiAlertsHistory;
