import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Alert, Select } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as companyConfigurationActionCreators } from 'state/company/actions.js';
import { updateOrganizationSettings } from 'api/companies.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { CompanySettings } from 'constants/configurationEnums.js';
import { AuthenticationOptions } from 'constants/enums.js';

const OtherConfigurations = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const twoFactorAuthenticator = useSelector(state => state.company.companySettings.twoFactorAuthenticator);

	const toggleItem = async value => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		const params = {
			companySettings: [
				{
					settingTypeId: CompanySettings.TWO_FACTOR_TYPE,
					value: value,
				},
			],
			companyId: getCompanyId(),
		};
		const response = await updateOrganizationSettings(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		dispatch(
			companyConfigurationActionCreators.setCompanySettings({
				twoFactorAuthenticator: value,
			})
		);
		setIsLoading(false);
	};

	return (
		<div>
			<div className='flex two-fa-options'>
				<Select
					label={translate('selectAuth')}
					name='twoFactor'
					items={[
						{ id: 'none', auth: intl.formatMessage({ id: 'authNone' }) },
						{
							id: AuthenticationOptions.EXTERNAL_AUTHENTICATOR,
							auth: intl.formatMessage({ id: 'authExternalProvider' }),
							selected: twoFactorAuthenticator === AuthenticationOptions.EXTERNAL_AUTHENTICATOR,
						},
						{
							id: 'email',
							auth: intl.formatMessage({ id: 'authEmail' }),
							selected: twoFactorAuthenticator === AuthenticationOptions.EMAIL,
						},
					]}
					valueField='id'
					textField='auth'
					placeholder={intl.formatMessage({ id: 'select' })}
					onSelect={evt => {
						toggleItem(evt.target.value);
					}}
				/>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default OtherConfigurations;
