import { downloadConferencesCsv } from 'api/dashboard.js';
import CallDetails from 'components/CallLogs/CallDetails.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Modal from 'components/Modal.jsx';
import { ObjectType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { getSessionTypeString } from 'infrastructure/helpers/callLogsHelper.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import {
	adjustTimestampForUTC,
	convertToTimestampSecondsWithMaxTime,
	formatTimeDurationWithSeconds,
} from 'infrastructure/helpers/dateHelper.js';
import moment from 'moment';
import { useState } from 'react';

const PastCallsViewActions = props => {
	const [showListOfStatAlarmsModal, setShowListOfStatAlarmsModal] = useState(false);
	const [selectedParticipant, setSelectedParticipant] = useState(null);
	const userProfile = getUserInfo();

	const openListOfStatAlarmsModal = participant => {
		setSelectedParticipant(participant);
		setShowListOfStatAlarmsModal(true);
	};

	const getStatAlarmsList = () =>
		selectedParticipant?.statAlarms.map(statAlarm => ({
			activationTime: statAlarm.dateEnabled
				? moment.utc(statAlarm.dateEnabled).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
				: 'N/A',
			deactivationTime: statAlarm.dateDisabled
				? moment.utc(statAlarm.dateDisabled).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
				: 'N/A',
			duration:
				statAlarm.dateEnabled && statAlarm.dateDisabled
					? formatTimeDurationWithSeconds({ seconds: statAlarm.durationInSeconds, joinWith: ' ' })
					: 'N/A',
		}));

	const showListOfUnavailableNurses = () => {
		const headers = [
			{ title: translate('name'), id: 'name' },
			{ title: translate('status'), id: 'status' },
		];
		const unavailableNurses = props.modal.currentCallLogQueue.map(({ name, state, id }) => ({
			name,
			status: props.getParticipantState(state),
			id,
		}));
		return <CustomTable isLoading={props.isLoading} headers={headers} rows={unavailableNurses} />;
	};

	const showListOfRooms = () => {
		const headers = [
			{ title: translate('room'), id: 'room' },
			{ title: translate('status'), id: 'status' },
			{ title: translate('sessionType'), id: 'sessionType' },
			{ title: translate('statAlarms'), id: 'statAlarms' },
		];
		if (!props.selectedConference || !props.selectedConference.participants) {
			return console.error('Error while getting list of participants!');
		}

		const roomsList = props.selectedConference.participants.reduce((acc, participant) => {
			if (participant.objectType === ObjectType.HELLO_DEVICE) {
				acc.push({
					id: participant.conferenceId,
					room: participant.name,
					status: props.getParticipantState(participant.state),
					sessionType: getSessionTypeString(participant.sessionType),
					statAlarms:
						participant?.statAlarms?.length > 0 ? (
							<span className='text-underline cursor-pointer' onClick={() => openListOfStatAlarmsModal(participant)}>
								{translate('viewAll')}
							</span>
						) : (
							'N/A'
						),
				});
			}
			return acc;
		}, []);

		return (
			<CustomTable
				isLoading={props.isLoading}
				headers={headers}
				rows={roomsList}
				tableEmptyText={translate('noRooms')}
				className='call-details-table'
			/>
		);
	};

	const showListOfParticipants = () => {
		const headers = [
			{ title: translate('participant'), id: 'room' },
			{ title: translate('status'), id: 'status' },
			{ title: translate('statAlarms'), id: 'statAlarms' },
		];
		if (!props.selectedConference || !props.selectedConference.participants) {
			return console.error('Error while getting list of participants!');
		}

		const participantsList = props.selectedConference.participants.reduce((acc, participant) => {
			if (!participant.isInitiator) {
				acc.push({
					room: participant.name,
					status: translate(props.getParticipantState(participant.state).toLowerCase()),
					statAlarms:
						participant?.statAlarms?.length > 0 ? (
							<span onClick={() => openListOfStatAlarmsModal(participant.statAlarms)}>{translate('viewAll')}</span>
						) : (
							'N/A'
						),
				});
			}
			return acc;
		}, []);

		return (
			<CustomTable
				isLoading={props.isLoading}
				headers={headers}
				rows={participantsList}
				tableEmptyText={translate('noParticipants')}
				className='call-details-table'
			/>
		);
	};

	const downloadCsv = async () => {
		props.setIsExportLogsModalLoading(true);
		const response = await downloadConferencesCsv({
			start: props.dates.toDate && props.dates.fromDate && adjustTimestampForUTC(props.dates.fromDate),
			end:
				props.dates.toDate &&
				props.dates.fromDate &&
				adjustTimestampForUTC(convertToTimestampSecondsWithMaxTime(props.dates.toDate) * 1000),
			healthSystemId: props.selectedSector.healthSystem?.value,
			regionId: props.selectedSector.region?.value,
			hospitalId: props.selectedSector.hospital?.value,
			departmentId: props.selectedSector.department?.value,
			floorId: props.selectedSector.floor?.value,
			roomId: props.selectedSector.room?.value,
		});

		if (!response.error) {
			props.setHasExportLogsModalErrors(!!response.error);
			props.setIsExportLogsModalOpen(!!response.error);
		}
		props.setIsExportLogsModalLoading(false);
	};

	return (
		<>
			<Modal
				display={props.modal.showCallQueueModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeCallQueueModal}>
				<form>
					<h3>{translate('queueOfNursesThatWereCalled')}</h3>
					{showListOfUnavailableNurses()}
				</form>
			</Modal>
			<Modal
				modalSelector='callDetailsModal'
				display={props.modal.showCallDetailsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeCallDetailsModal}>
				<CallDetails selectedConference={props.selectedConference} />
			</Modal>
			<Modal
				modalSelector='listOfRoomsModal'
				display={props.modal.showListOfRoomsModal && !showListOfStatAlarmsModal}
				position='center'
				className='table-modal'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeListOfRooms}>
				<form>
					<>
						<h3>{translate('listOfRooms')}</h3>
						{props.modal.showListOfRoomsModal && showListOfRooms()}
					</>
				</form>
			</Modal>
			<Modal
				modalSelector='listOfParticipantsModal'
				display={props.modal.showListOfParticipantsModal && !showListOfStatAlarmsModal}
				position='center'
				className='table-modal'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeListOfParticipants}>
				<form>
					<>
						<h3>{translate('listOfParticipants')}</h3>
						{props.modal.showListOfParticipantsModal && showListOfParticipants()}
					</>
				</form>
			</Modal>
			<Modal
				modalSelector='listOfStatAlarmsModal'
				display={showListOfStatAlarmsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={() => setShowListOfStatAlarmsModal(false)}>
				<form>
					<>
						<h3>{translate('statAlarms')}</h3>
						{showListOfStatAlarmsModal && (
							<CustomTable
								headers={[
									{ title: translate('activationTime'), id: 'activationTime' },
									{ title: translate('deactivationTime'), id: 'deactivationTime' },
									{ title: translate('duration'), id: 'duration' },
								]}
								rows={getStatAlarmsList()}
								tableEmptyText={translate('noStatAlarmsActivated')}
							/>
						)}
					</>
				</form>
			</Modal>
			<Modal
				display={props.modal.isExportLogsModalOpen}
				position='center'
				isLoading={props.isExportLogsModalLoading}
				onModalSubmit={downloadCsv}
				primaryButtonLabel={translate('continue')}
				onModalClose={() => props.closeExportLogsModal(false)}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'>
				<form>
					<h3>{translate('exportCSV')}</h3>
					{!props.hasExportLogsModalErrors && userProfile && (
						<p>{translate('exportCSVInBackground', { value: <strong>{userProfile.email}</strong> })}</p>
					)}
					{props.hasExportLogsModalErrors && <p>{translate('somethingWentWrong')}</p>}
				</form>
			</Modal>
		</>
	);
};

export default PastCallsViewActions;
