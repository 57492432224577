import { gatewayApi } from 'constants/global-variables.js';

export const getAuditLogs = async params => {
	try {
		const { data } = await gatewayApi.get('v1/logs', {
			params,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getLogTypes = async () => {
	try {
		const { data } = await gatewayApi.get('v1/log-types');
		return data;
	} catch (error) {
		return { error };
	}
};
