import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const addConfig = async params => {
	try {
		const { data } = await gatewayApi.post(`/api/tenant-configurations`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConfigs = async () => {
	try {
		const { data } = await gatewayApi.get(`/api/tenant-configurations/${getCompanyId()}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConfigsTypes = async () => {
	try {
		const { data } = await gatewayApi.get(`/api/tenant-configurations/configTypes`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConfigsTypesByType = async configTypeId => {
	try {
		const { data } = await gatewayApi.get(`/api/tenant-configurations/config-types/${configTypeId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteConfiguration = async configurationId => {
	try {
		const { data } = await gatewayApi.delete(`/api/tenant-configurations/${configurationId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateConfig = async params => {
	try {
		const { data } = await gatewayApi.put(`/api/tenant-configurations`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConfigsByGroup = async groupId => {
	try {
		const { data } = await gatewayApi.get(`/api/tenant-configurations/${getCompanyId()}/groups/${groupId}/configurations`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getConfigsGroups = async () => {
	try {
		const { data } = await gatewayApi.get(`/api/tenant-configurations/config-groups`);
		return data;
	} catch (error) {
		return { error };
	}
};
