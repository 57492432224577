import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { getUserMeasurementUnits, editUserMeasurementUnits } from 'api/measurements.js';
import { MeasurementUnitOptions, AlertTypes, UnitCategoryTypes, UnitSystemTypes, UserRoles } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import Button from 'components/Button.jsx';

const MeasurementUnits = () => {
	const isDarkMode = useSelector(state => state.user.darkMode);
	const darkModeRoles = [UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.VIRTUAL_SITTER].includes(getUserRole());
	const applyDarkModeProperties = darkModeRoles && isDarkMode;
	const [errorMessage, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const userGuidId = getStorage().getItem('userId');
	const intl = useIntl();
	const unitPreferences = useSelector(state => state.user.unitPreferences);
	const [preferredPreferences, setPreferredPreferences] = useState(unitPreferences);

	const measurementUnits = [
		{
			id: 0,
			title: {
				text: translate('weight'),
				icon: `${healthCareCdnUrl}vsm/body-measurements.svg`,
				description: translate('weightAndLeanBodyMass'),
			},
			unit: null,
			unitDescription: null,
			allUnits: MeasurementUnitOptions.WEIGHT.value,
			edit: true,
			name: 'weight',
			value: null,
			unitCategoryId: UnitCategoryTypes.WEIGHT,
		},
		{
			id: 1,
			title: {
				text: translate('temperature'),
				icon: `${healthCareCdnUrl}vsm/body-measurements.svg`,
				description: translate('relatedBodyTemperature'),
			},
			unit: null,
			unitDescription: null,
			allUnits: MeasurementUnitOptions.TEMPERATURE.value,
			edit: true,
			name: 'temperature',
			value: null,
			unitCategoryId: UnitCategoryTypes.TEMPERATURE,
		},
		{
			id: 2,
			title: {
				text: translate('height'),
				icon: `${healthCareCdnUrl}vsm/body-measurements.svg`,
				description: translate('heightAndWaistCircumference'),
			},
			unit: null,
			unitDescription: null,
			allUnits: MeasurementUnitOptions.HEIGHT.value,
			edit: true,
			name: 'height',
			value: null,
			unitCategoryId: UnitCategoryTypes.HEIGHT,
		},
		{
			id: 3,
			title: {
				text: translate('distance'),
				icon: `${healthCareCdnUrl}vsm/distance.svg`,
				description: translate('walkingAndRunning'),
			},
			unit: null,
			unitDescription: null,
			allUnits: MeasurementUnitOptions.DISTANCE_WALKING_RUNNING.value,
			edit: true,
			name: 'distanceWalkingRunning',
			value: null,
			unitCategoryId: UnitCategoryTypes.DISTANCE,
		},
		{
			id: 4,
			title: {
				text: translate('chemicals'),
				icon: `${healthCareCdnUrl}vsm/other-data.svg`,
				description: translate('bloodGlucoseAndCholesterol'),
			},
			unit: null,
			unitDescription: null,
			allUnits: MeasurementUnitOptions.BLOOD_GLUCOSE.value,
			edit: true,
			name: 'diabetes',
			value: null,
			unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
		},
	];

	useEffect(() => {
		let timeout;
		if (success || errorMessage) {
			timeout = setTimeout(() => {
				closeResponseAlert();
			}, 1500);
		}
		return () => clearTimeout(timeout);
	}, [success, errorMessage]);

	const saveUnit = async () => {
		const unitPreferences = [...preferredPreferences].map(item => ({
			unitCategoryId: item.unitCategoryId,
			unitSystemId: item.unitSystemId,
		}));

		const response = await editUserMeasurementUnits(userGuidId, {
			unitPreferences,
		});
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setSuccess(intl.formatMessage({ id: 'changesSaved' }));
	};

	const getUnits = item => {
		return Object.keys(item).map(key => ({ value: item[key].unitValue, label: item[key].text }));
	};

	const getUnitBasedOnCategoryId = unitCategoryId => {
		switch (unitCategoryId) {
			case UnitCategoryTypes.BLOOD_GLUCOSE:
				return MeasurementUnitOptions.BLOOD_GLUCOSE;
			case UnitCategoryTypes.DISTANCE:
				return MeasurementUnitOptions.DISTANCE_WALKING_RUNNING;
			case UnitCategoryTypes.TEMPERATURE:
				return MeasurementUnitOptions.TEMPERATURE;
			case UnitCategoryTypes.HEIGHT:
				return MeasurementUnitOptions.WAIST_CIRCUMFERENCE;
			case UnitCategoryTypes.WEIGHT:
				return MeasurementUnitOptions.WEIGHT;
			default:
				return '';
		}
	};

	const getValue = unitCategoryId => {
		const unit = getUnitBasedOnCategoryId(unitCategoryId);
		return {
			value: preferredPreferences.find(item => item.unitCategoryId === unitCategoryId)?.unitSystemId,
			label:
				preferredPreferences.find(item => item.unitCategoryId === unitCategoryId)?.unitSystemId === UnitSystemTypes.METRIC
					? unit?.value[1].text
					: unit?.value[2].text,
		};
	};

	const selectUnit = (unitSystemId, unitCategoryId) =>
		setPreferredPreferences(prevState => {
			const preferencesCopy = [...prevState];
			const foundIndex = preferredPreferences.findIndex(el => el.unitCategoryId === unitCategoryId);
			preferencesCopy[foundIndex].unitSystemId = unitSystemId;
			return preferencesCopy;
		});

	const closeResponseAlert = () => {
		setError(null);
		setSuccess(null);
	};

	return (
		<>
			<div className='measurement-units-wrapper account-settings-inner-wrapper'>
				<div className='measurement-units-table unit-types'>
					{measurementUnits.map(item => (
						<div key={item.id} className='flex position-relative active'>
							<img src={item.title.icon} alt='icon' />
							<div className='flex column-direction left-10'>
								<p>{item.title.text}</p>
								<span>{item.title.description}</span>
							</div>
							<div className='edit-measurement-unit cursor-pointer'>
								<Select
									value={getValue(item.unitCategoryId)}
									placeholder={intl.formatMessage({ id: 'selectUnit' })}
									classNamePrefix='react-select'
									options={getUnits(item.allUnits)}
									onChange={unit => selectUnit(unit.value, item.unitCategoryId)}
									isSearchable={false}
									styles={{
										...generateCustomStyles({ darkMode: applyDarkModeProperties }),
									}}
								/>
							</div>
						</div>
					))}
				</div>
				<div className='flex right-align-content'>
					<Button
						className='flex flex-justify-center flex-align-center save-changes-btn'
						onClick={saveUnit}
						text={translate('saveChanges')}
					/>
				</div>
			</div>
			<PopUpAlert
				alertType={success ? AlertTypes.SUCCESS : AlertTypes.DANGER}
				display={success || errorMessage}
				onAlertClose={closeResponseAlert}
				contentText={success || errorMessage}
				isSilent={true}
				center={true}
			/>
		</>
	);
};

export default MeasurementUnits;
