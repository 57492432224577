import { getAiBlobs, getAiFrames, getToken } from 'api/ai.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import Modal from 'components/Modal.jsx';
import { XMLParser } from 'fast-xml-parser';
import translate from 'i18n-translations/translate.jsx';
import Pause from 'icons/Monitoring/Pause.jsx';
import Play from 'icons/Monitoring/Play.jsx';
import { drawFrameDetections, setupCanvas } from 'infrastructure/helpers/aiDetectionHelper.js';
import { arrayBufferToBase64, getCallsButtonColor } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate, getDateWithSeconds, getFormattedLocalTime } from 'infrastructure/helpers/dateHelper.js';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import SliderWithMarks from 'components/Monitoring/SliderWithMarks.jsx';
import classNames from 'classnames';
import { getDetailsByLastActivity } from 'infrastructure/helpers/alertsHelper.js';

const EvidenceReport = ({ setIsModalOpen, evidenceDetails, configurationValue = null, isSlideshow = false }) => {
	const [isAnimating, setIsAnimating] = useState(false);
	const [frames, setFrames] = useState([]);
	const [currentFrame, setCurrentFrame] = useState(null);
	const [noDataAvailable, setNoDataAvaliable] = useState(false);
	const [frameCounter, setFrameCounter] = useState(1);
	const [animationSpeed, setAnimationSpeed] = useState(100);
	const [token, setToken] = useState(null);
	const [filesList, setFilesList] = useState({ detections: [], images: new Map() });
	const [timeRange, setTimeRange] = useState(0);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const lastImageRef = useRef(new Image());
	const canvasRef = useRef(null);
	const animationTimer = useRef(null);
	const counterRef = useRef(0);
	const darkMode = useSelector(state => state.user.darkMode);
	const intl = useIntl();

	useEffect(() => {
		const fetchToken = async () => {
			const response = await getToken();
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setToken(response);
		};
		fetchToken();
	}, []);

	useEffect(() => {
		const getFilesList = async () => {
			const params = {
				serialNumber: evidenceDetails?.helloDeviceSerialNumber,
				evidenceId: evidenceDetails?.id,
				containerUri: token.containerUri,
				sasToken: token.sasToken,
			};
			const [aiLogsRes, aiImagesRes] = await Promise.all([
				getAiBlobs({ ...params, frameType: 'logs' }),
				getAiBlobs({ ...params, frameType: 'blurred' }),
			]);
			const resError = aiLogsRes.error || aiImagesRes.error;
			if (resError) {
				setError(resError.message);
				return;
			}
			const parsedFiles = await parseXmlFiles([aiLogsRes, aiImagesRes]);
			setFilesList(prevState => ({
				...prevState,
				detections: parsedFiles?.detections,
				images: parsedFiles?.images,
			}));
			if (parsedFiles.detections?.length === 0 && parsedFiles.images?.size === 0) {
				setNoDataAvaliable(true);
				setIsLoading(false);
			}
		};

		const parseXmlFiles = xmlFiles => {
			const parser = new XMLParser();
			const availableImagesMap = new Map();
			const resultingFiles = xmlFiles
				.map(xml => {
					const doc = parser.parse(xml);
					const blob = doc?.EnumerationResults?.Blobs?.Blob || [];
					const blobs = Array.isArray(blob) ? blob : [blob];
					const jsonArr = [];
					const jsonMap = new Map();
					blobs.forEach(({ Name: content }) => {
						if (!content) {
							return;
						}
						if (content.endsWith('.json')) {
							jsonArr.push(content);
						} else if (content.endsWith('.json.gz')) {
							jsonArr.push(content.replace('.gz', ''));
							jsonMap.set(content.replace('.gz', ''), content);
						} else if (content.endsWith('.jpg')) {
							const imageFile = content.substring(content.lastIndexOf('/') + 1);
							availableImagesMap.set(imageFile, content);
						}
					});
					return jsonArr.map(frame => jsonMap.get(frame) ?? frame);
				})
				.flat();
			return { detections: resultingFiles, images: availableImagesMap };
		};

		if (token) {
			getFilesList();
		}
	}, [token, evidenceDetails?.helloDeviceSerialNumber, evidenceDetails?.id]);

	useEffect(() => {
		const createImageUrl = imagePath => `${token.containerUri}/${imagePath}?${token.sasToken}`;

		const getFrames = async () => {
			let response = [];
			const { results, errorMessage } = await makeRequestsInBatches({
				...(isSlideshow && { responseType: 'arraybuffer', fileListType: 'images' }),
			});
			if (errorMessage) {
				setError(errorMessage);
				setIsLoading(false);
				return;
			}
			response = results;

			if (isSlideshow && results.length > 0) {
				setFrames(response);
				setIsLoading(false);
				return;
			}

			if (isSlideshow && results.length === 0) {
				const { results, errorMessage } = await makeRequestsInBatches({
					batchSize: 200,
					responseType: 'json',
					fileListType: 'detections',
				});

				if (errorMessage) {
					setError(errorMessage);
					setIsLoading(false);
					return;
				}
				response = results;
			}

			const framesRes =
				typeof response?.[0] === 'object'
					? response.flatMap(file =>
							file.frames.map(frame => ({
								...frame,
								imageUrl: filesList.images.has(frame.imageFile)
									? createImageUrl(filesList.images.get(frame.imageFile) || '')
									: '',
								info: file.info,
							}))
					  )
					: [];

			setNoDataAvaliable(framesRes.length === 0);
			setFrames(framesRes);
			setCurrentFrame(framesRes.length > 0 ? framesRes[0] : null);
			setIsLoading(false);
		};

		const makeRequestsInBatches = async ({ batchSize = 200, responseType = 'json', fileListType = 'detections' }) => {
			const results = [];
			let errorMessage = null;
			const fileList = fileListType === 'detections' ? filesList.detections : Array.from(filesList.images.values());

			for (let i = 0; i < fileList.length; i += batchSize) {
				const batchUrls = fileList.slice(i, i + batchSize);
				const batchResults = await Promise.all(
					batchUrls.map(filePath =>
						getAiFrames({ containerUri: token.containerUri, filePath, sasToken: token.sasToken, responseType })
					)
				);
				for (const response of batchResults) {
					if (response.error) {
						errorMessage = response.error.message;
						return { results, errorMessage };
					}
					results.push(response);
				}
			}
			return { results, errorMessage };
		};
		if (filesList.detections?.length > 0 || filesList.images?.size > 0) {
			getFrames();
		}
	}, [filesList.detections, filesList.images, token]);

	useEffect(() => {
		if (frames.length === 0) {
			return;
		}
		if (filesList.images.size > 0) {
			createCanvas(frames[0]);
		}
		if (filesList.detections.length > 0) {
			setupAndDrawCanvas(frames[0]);
		}
	}, [frames]);

	const startAnimationTimer = (interval = 100) => {
		if (animationTimer.current) {
			clearInterval(animationTimer.current);
		}
		animationTimer.current = setInterval(() => {
			if (counterRef.current >= frames?.length) {
				stopAnimationTimer();
			} else {
				setTimeRange(counterRef.current);
				handleSetTimeRange((counterRef.current += 1));
			}
		}, interval);
		setIsAnimating(true);
		setAnimationSpeed(interval);
		return () => stopAnimationTimer();
	};

	const stopAnimationTimer = () => {
		if (animationTimer.current) {
			clearInterval(animationTimer.current);
		}
		animationTimer.current = null;
		setIsAnimating(false);
	};

	const setupAndDrawCanvas = async (frame, base64Image = null) => {
		if (!frame || !canvasRef.current) {
			return;
		}
		const context = canvasRef.current?.getContext('2d');
		if (!context) {
			return;
		}

		const scaleDownBy = 1.5;
		const scaledWidth = (frame.info?.width || 1200) / scaleDownBy;
		const scaledHeight = (frame.info?.height || 700) / scaleDownBy;

		setupCanvas(scaledWidth, scaledHeight, canvasRef);
		setCurrentFrame(frame);

		const imageSrc = base64Image || frame.imageUrl;
		if (imageSrc) {
			lastImageRef.current.onload = () => {
				context?.drawImage(lastImageRef.current, 0, 0, scaledWidth, scaledHeight);
			};
			lastImageRef.current.src = imageSrc;
		}

		if (frame.info) {
			const sx = scaledWidth / frame.info.modelInputWidth;
			const sy = scaledHeight / frame.info.modelInputHeight;
			drawFrameDetections({ context, frame, sx, sy, scaleDownBy });
		}
	};

	const createCanvas = async currentFrame => {
		const base64Image = await arrayBufferToBase64(currentFrame);
		setupAndDrawCanvas(currentFrame, base64Image);
	};

	const handleSetTimeRange = val => {
		const frame = frames.at(typeof val === 'number' ? val : 0);
		setupAndDrawCanvas(frame);
	};

	const handleAnimationToggle = () => {
		if (animationTimer.current === null) {
			startAnimationTimer(animationSpeed);
		} else {
			stopAnimationTimer();
		}
	};

	const handleFrameClick = value => {
		stopAnimationTimer();
		counterRef.current = value;
		handleSetTimeRange(value);
		setTimeRange(value);
	};

	const getFromDate = () => {
		const date = new Date(evidenceDetails?.dateCreated);
		date.setSeconds(date.getSeconds() - configurationValue);
		return date.toLocaleString();
	};

	const getFrameDates = () => (
		<p className='font-14 margin-right-m'>{`${intl.formatMessage({ id: 'from' })} ${getDateWithSeconds(
			getFromDate()
		)} ${intl.formatMessage({ id: 'to' })} ${
			evidenceDetails?.dateCreated ? getDateWithSeconds(evidenceDetails?.dateCreated) : null
		}`}</p>
	);

	const getSlideshowSubTitle = () => {
		if (evidenceDetails?.startTime && evidenceDetails?.endTime) {
			return `${intl.formatMessage({ id: 'from' })} ${evidenceDetails?.startTime} ${intl.formatMessage({ id: 'to' })} ${
				evidenceDetails?.endTime
			}`;
		} else {
			return (
				<div className='flex gap-m flex-align-center'>
					{evidenceDetails?.startTime || formattedDate(evidenceDetails?.dateCreated)} <ActivityButton />
				</div>
			);
		}
	};

	const decrementFrameCounter = () => {
		const currentIndex = Math.max(frameCounter - 1, 1);
		setFrameCounter(currentIndex);
		createCanvas(frames[currentIndex - 1]);
	};

	const incrementFrameCounter = () => {
		const currentIndex = Math.min(frameCounter + 1, frames.length);
		setFrameCounter(currentIndex);
		createCanvas(frames[currentIndex - 1]);
	};

	const ActivityButton = () => {
		const lastActivityDetails = getDetailsByLastActivity(evidenceDetails?.lastActivityType);

		return (
			<Button
				text={lastActivityDetails?.text}
				className='alert-button'
				borderColor={lastActivityDetails?.color}
				color={lastActivityDetails?.color}
				icon={lastActivityDetails?.icon}
			/>
		);
	};

	return (
		<Modal
			display={true}
			position='center'
			onModalClose={() => setIsModalOpen(false)}
			onModalSubmit={() => null}
			className={classNames('wrapper-modal', {
				'evidence-report-modal-dashboard': isSlideshow,
				'evidence-report-modal': !isSlideshow,
			})}
			isViewOnly={true}>
			<div className={isSlideshow ? 'full-height' : 'padding-l'}>
				<h3 className='margin-bottom-l title'>
					{translate('evidenceReport')}: {evidenceDetails?.label}
				</h3>
				{!isSlideshow && (
					<div className='flex flex-align-center margin-bottom-m'>
						{getFrameDates()}
						<ActivityButton />
					</div>
				)}
				{!isLoading && noDataAvailable && (
					<Grid columns='1fr' rows='1fr' stretch='500px' horizAlign='center' vertAlign='center'>
						<div className='text-align-center'>
							<p>{translate('noDataAvailable')}</p>
						</div>
					</Grid>
				)}
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='500px' horizAlign='center' vertAlign='center'>
						<div className='text-align-center'>
							<Loader />
						</div>
					</Grid>
				)}
				{isSlideshow && !isLoading && !noDataAvailable && (
					<div>
						<p className='font-14 margin-right-m subtitle'>{getSlideshowSubTitle()}</p>
						<div className='flex padding-m full-width full-height frame-container'>
							<canvas className='full-width' ref={canvasRef} />
						</div>
						<div className='frame-footer'>
							<Button icon='arrow_back_ios_new' onClick={decrementFrameCounter} />
							<p className='counter'>
								<span>{frameCounter}</span> {translate('of')} {frames.length}
							</p>
							<Button icon='arrow_forward_ios_new' onClick={incrementFrameCounter} />
						</div>
					</div>
				)}

				{currentFrame && !isSlideshow && (
					<>
						<canvas className='full-width' ref={canvasRef} />
						<div className='flex flex-align-center top-10 evidence-control-wrapper'>
							<Button
								onClick={handleAnimationToggle}
								svgIcon={
									!isAnimating ? <Play color={getCallsButtonColor(darkMode)} /> : <Pause color={getCallsButtonColor(darkMode)} />
								}
								background='var(--dark-theme-gray-2)'
							/>
							<SliderWithMarks
								min={0}
								max={frames.length > 0 ? frames.length - 1 : 0}
								step={1}
								value={timeRange}
								onChange={handleFrameClick}
								framesFromAlert={frames}
							/>
							<div className='flex flex-align-center flex-shrink-0 left-s'>
								<p className='font-13'>
									{`${getFormattedLocalTime(currentFrame?.timeStamp)} /
										${getFormattedLocalTime(frames[frames.length - 1]?.timeStamp)}`}
								</p>
							</div>
						</div>
						<div className='flex flex-align-center evidence-radio-btn-wrapper'>
							<input
								type='radio'
								name='animation-speed'
								checked={animationSpeed === 100}
								onChange={() => startAnimationTimer(100)}
							/>
							<label>0.1s</label>
							<input
								type='radio'
								name='animation-speed'
								checked={animationSpeed === 500}
								onChange={() => startAnimationTimer(500)}
							/>
							<label>0.5s</label>
							<input
								type='radio'
								name='animation-speed'
								checked={animationSpeed === 1000}
								onChange={() => startAnimationTimer(1000)}
							/>
							<label>1s</label>
						</div>
					</>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</Modal>
	);
};

export default EvidenceReport;
