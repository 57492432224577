import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import Conversation from 'views/Chat/Conversation.jsx';
import { getAllNurses } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import Input from 'components/Input.jsx';
import NurseChatList from 'views/Partials/NurseChatList.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { getConversationByType, updateReadStatus } from 'api/messenger.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { ConversationType } from 'constants/enums.js';

const Nurses = () => {
	const params = useParams();
	const history = useHistory();
	const socket = useContext(SocketContext);

	const [pagination, setPagination] = useState({ size: 20, index: 0, totalCount: 0 });
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [selectedNurse, setSelectedNurse] = useState(null);
	const [conversationId, setConversationId] = useState(null);
	const [nurses, setNurses] = useState([]);
	const [error, setError] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const bottomOfListRef = useRef(null);
	const timeout = useRef(null);
	const intl = useIntl();

	useEffect(() => {
		const fetchNurses = async () => {
			const response = await getAllNurses(pagination.size, pagination.index);
			if (response.error) {
				setError(response.error.message);
			} else {
				setTotalCount(response.totalCount);
				setNurses(prevState => prevState.concat(response.nurses));
				if (response.nurses.length === 0) {
					setIsLoading(false);
					return;
				}
				const firstNurse = response.nurses[0];
				setSelectedNurse(firstNurse);
				window.history.replaceState(null, 'healthcare', `nurses/${firstNurse.userId}`);
			}
			setIsLoading(false);
		};
		fetchNurses();
	}, [pagination.index, pagination.size]);

	useEffect(() => {
		const getConversationId = async () => {
			const response = await getConversationByType(ConversationType.INDIVIDUAL, selectedNurse.userId, 0);
			if (response) {
				setConversationId(response.conversation.id);
			} else {
				setError(response.error.message);
			}
		};
		const handleNewMessage = async data => {
			const { userId } = getUserInfo();
			const { message } = data;
			const newNurses = [...nurses];
			const senderId = parseInt(message.sender.objectId, 10);
			const isMe = senderId === userId;
			const nurseId = isMe ? selectedNurse.userId : parseInt(message.sender.objectId, 10);
			const index = newNurses.findIndex(item => item.userId === nurseId);
			if (index === -1) {
				return;
			}
			const [recentNurse] = newNurses.splice(index, 1);
			if (!recentNurse.lastActivity) {
				recentNurse.lastActivity = {};
			}
			const unReadMessageCount = isMe
				? recentNurse && recentNurse.lastActivity.unReadMessageCount
				: recentNurse && recentNurse.lastActivity.unReadMessageCount + 1;

			recentNurse.lastActivity.unReadMessageCount = unReadMessageCount || 0;

			recentNurse.lastActivity.message = message.content;
			newNurses.unshift(recentNurse);
			setNurses(newNurses);
			if (senderId === selectedNurse.userId) {
				const response = await updateReadStatus(conversationId);
				if (response.error) {
					setError(response.error.message);
				}
			}
		};
		if (selectedNurse) {
			getConversationId();
		}
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [selectedNurse, conversationId, nurses, socket]);

	useEffect(() => {
		const searchNurses = async () => {
			if (timeout.current) clearTimeout(timeout.current);
			timeout.current = setTimeout(async () => {
				const response = await getAllNurses(pagination.size, 0, searchValue);
				if (response.error) {
					setError(response.error.message);
				} else {
					setNurses(response.nurses);
					setTotalCount(response.totalCount);
				}
			}, 500);
		};
		searchNurses();
	}, [searchValue, pagination.size]);

	useEffect(() => {
		let initialSelectedNurse = nurses.find(item => item.userId === parseInt(params.id, 10));
		if (!initialSelectedNurse) {
			initialSelectedNurse = nurses ? nurses[0] : null;
		}
		setSelectedNurse(initialSelectedNurse);
	}, [params, nurses]);

	const handleScroll = async e => {
		const isBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
		const hasReachedEnd = totalCount - nurses.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPagination(prevState => ({ ...prevState, index: prevState.index + 1 }));
		}
	};

	const setNurse = async nurse => {
		history.push(`/nurses/${nurse.userId}`);
		if (!nurse.lastActivity) {
			setSelectedNurse(nurse);
			return;
		}
		const response = await updateReadStatus(conversationId);
		if (response.error) {
			setError(response.error.message);
		}
		const newNurses = [...nurses];
		const index = newNurses.findIndex(item => item.userId === nurse.userId);
		const [recentNurse] = newNurses.splice(index, 1);
		recentNurse.lastActivity.unReadMessageCount = 0;
		newNurses.unshift(recentNurse);
		setSelectedNurse(nurse);
		history.push(`/nurses/${nurse.userId}`);
	};

	return (
		<MainLayout>
			<Grid columns='1fr 3fr' stretch='100%'>
				<aside className='patients-list nurses-list'>
					<div>
						<div>
							<div className='left-nav-header'>
								<h4>{translate('nurses')}</h4>
							</div>
							<Input
								className='tree-search'
								type='text'
								name='sectorSearchValue'
								placeholder={intl.formatMessage({ id: 'search' })}
								value={searchValue}
								onChange={event => setSearchValue(event.target.value)}
								validationOptions={{}}
								bottomSpace='0px'
								autoComplete='off'
							/>
						</div>

						<div className='chat-items-area scroll-hover' ref={bottomOfListRef} onScroll={handleScroll}>
							<NurseChatList selectedNurse={selectedNurse} isLoading={isLoading} setNurse={setNurse} nurses={nurses} />
						</div>
					</div>
				</aside>
				<main className='main-view nurses-wrapper'>
					<div className='full-height'>
						{selectedNurse && (
							<div className='patient-convo full-height'>
								<Conversation
									selectedUser={{
										id: selectedNurse.userId,
										name: `${selectedNurse.firstName} ${selectedNurse.lastName}`,
										profilePicture: selectedNurse.profilePicture,
									}}
									conversationId={conversationId}
									hideRightSide={true}
								/>
							</div>
						)}
					</div>
				</main>
			</Grid>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default Nurses;
