import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getPatientQueueRequest, updatePatientQueueRequestStatus } from 'api/patientRequests.js';
import Alert from 'components/Alert.jsx';
import CustomTable from 'components/CustomTable.jsx';
import Input from 'components/Input.jsx';
import Modal from 'components/Modal.jsx';
import ToastMessage from 'components/ToastMessage.jsx';
import { PatientQueuedRequestStatus, RequestAcuity, RequestType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getGender } from 'infrastructure/helpers/commonHelpers.js';
import { getMonthDayYearDateFormat } from 'infrastructure/helpers/dateHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import Button from 'components/Button.jsx';
import { useSelector } from 'react-redux';
import AssignToDoctor from 'views/QueueManagement/AssignToDoctor.jsx';
import PatientRisk from 'views/QueueManagement/PatientRisk.jsx';

const UnAssignedPatients = () => {
	const userSession = useSelector(state => state.user.userSession);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [requestsList, setRequestsList] = useState([]);
	const [newPatientRequest, setNewPatientRequest] = useState(false);
	const [assignedCallRequests, setAssignedCallRequests] = useState({
		totalPendingRequests: 0,
		totalRapidRequests: 0,
		totalAdmissionRequests: 0,
		totalRegularRequests: 0,
	});
	const [selectedRequest, setSelectedRequest] = useState(null);
	const [itemToRemove, setItemToRemove] = useState(null);
	const [rejectMessage, setRejectMessage] = useState('');
	const intl = useIntl();
	const [searchQuery, setSearchQuery] = useState('');
	const [isPatientTransferSuccessful, setIsPatientTransferSuccessful] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 20, pageIndex: 0, totalCount: 0 });
	const onSearchTypeTimeout = useRef(null);
	const socket = useContext(SocketContext);

	const patientHeaders = [
		{ title: translate('name'), id: 'member' },
		{ title: 'MRN', id: 'mrn' },
		{ title: translate('healthSystem'), id: 'healthSystem' },
		{ title: translate('gender'), id: 'gender' },
		{ title: translate('dateOfBirth'), id: 'dateOfBirth' },
		{ title: translate('reasonOfVisit'), id: 'reasonOfVisit' },
		{ title: translate('acuity'), id: 'acuity' },
		{ title: translate('clinicalConcern'), id: 'clinicalConcern' },
		{ title: '', id: 'actions' },
	];
	const hasReachedEnd = useRef(null);

	const VisitReasons = {
		1: 'instruction',
		2: 'therapyDescription',
		3: 'checkUp',
		4: 'reCheckUp',
		5: 'referral',
		6: 'code',
		7: 'rapidResponse',
		8: 'edAdmitDiscussion',
		9: 'drugOrderOrClarification',
		10: 'abnormalVital',
		11: 'abnormalLab',
		12: 'criticalLab',
		13: 'patientDeclining',
		14: 'otherEmergent',
		15: 'reviewOutsideFacilityTransfer',
		16: 'drnOrDniClarification',
		17: 'otherNonEmergent',
		18: 'admitIcu',
		19: 'admitNonIcu',
		20: 'admitBackup',
		21: 'rounding',
		22: 'other',
	};

	const getRequestType = type => {
		const requestTypes = {
			[RequestType.RAPID]: 'totalRapidRequests',
			[RequestType.ADMISSION]: 'totalAdmissionRequests',
			[RequestType.REGULAR]: 'totalRegularRequests',
		};
		return requestTypes[type];
	};

	const removeFromQueue = request => {
		const requestIndex = requestsList.indexOf(request);
		if (requestIndex !== -1) {
			requestsList.splice(requestIndex, 1);
			setRequestsList([...requestsList]);
		}

		const requestType = getRequestType(request.requestType);
		setAssignedCallRequests(prevState => ({
			...prevState,
			totalPendingRequests: prevState.totalPendingRequests - 1,
			[requestType]: prevState[requestType] - 1,
		}));

		setItemToRemove(null);
	};

	const cancelQueueRequest = async request => {
		const response = await updatePatientQueueRequestStatus({
			healthSystemId: userSession.healthSystem.id,
			patientQueuedRequestId: request.id,
			statusId: PatientQueuedRequestStatus.CANCELLED,
			message: rejectMessage.trim() || null,
		});

		if (response.error) {
			setError(response.error.message);
			return;
		}
		removeFromQueue(request);
	};

	const handleAddToQueue = request => {
		const { totalCount, pageSize, pageIndex } = pagination;
		if (totalCount < pageSize * (pageIndex + 1)) {
			setRequestsList(prevState => [...prevState, request]);
		}
		const requestType = getRequestType(request.requestType);
		setAssignedCallRequests(prevState => ({
			...prevState,
			totalPendingRequests: prevState.totalPendingRequests + 1,
			[requestType]: prevState[requestType] + 1,
		}));
		setNewPatientRequest(true);
	};

	useEffect(() => {
		socket.on(SocketEvents.HealthCare.PATIENT_QUEUE_REQUEST_ADDED, handleAddToQueue);
		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_QUEUE_REQUEST_ADDED, handleAddToQueue);
		};
	}, [socket]);

	const getReasonOfVisit = type => {
		if (!VisitReasons[type]) {
			return 'N/A';
		}
		return intl.formatMessage({ id: VisitReasons[type] });
	};

	const getAcuityDesc = typeId => {
		let result = '';
		switch (typeId) {
			case RequestAcuity.IMMEDIATE: {
				result = intl.formatMessage({ id: 'immediate' });
				break;
			}
			case RequestAcuity.TEN_MINUTES: {
				result = `10 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.THIRTY_MINUTES: {
				result = `30 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.SIXTY_MINUTES: {
				result = `60 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.ROUNDING: {
				result = intl.formatMessage({ id: 'rounding' });
				break;
			}
			default: {
				result = 'N/A';
				break;
			}
		}
		return result;
	};

	const displayRequests = () => {
		if (!requestsList || !requestsList.length) {
			return [];
		}
		return requestsList.map(request => ({
			member: `${request?.patient?.firstName} ${request?.patient?.lastName}` || 'N/A',
			mrn: request?.patientExtendedProfile?.mrn,
			healthSystem: userSession.healthSystem.name,
			gender: getGender(request?.patient?.genderId).description,
			dateOfBirth: getMonthDayYearDateFormat(request?.patient?.dateOfBirth),
			reasonOfVisit: getReasonOfVisit(request.requestReason),
			acuity: getAcuityDesc(request.requestAcuity),
			clinicalConcern: request.description || 'N/A',
			actions: [
				<span
					className='material-icons-outlined cursor-pointer mr-20'
					data-tooltip={intl.formatMessage({ id: 'transferToDoctor' })}
					data-position='top'
					onClick={() => setSelectedRequest(request)}>
					person_add
				</span>,
				<span
					className='material-icons-outlined cursor-pointer mr-20 --red-color'
					data-tooltip={intl.formatMessage({ id: 'rejectSession' })}
					data-position='top'
					onClick={() => setItemToRemove(request)}>
					cancel
				</span>,
			],
		}));
	};

	const handleSearchChange = value => {
		setSearchQuery(value);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
	};

	useEffect(() => {
		const fetchPatients = async () => {
			if (onSearchTypeTimeout.current) clearTimeout(onSearchTypeTimeout.current);
			onSearchTypeTimeout.current = setTimeout(async () => {
				const response = await getPatientQueueRequest({
					pageIndex: pagination.pageIndex,
					pageSize: pagination.pageSize,
					search: searchQuery,
					healthSystemId: userSession.healthSystem.id,
				});
				if (response.error) {
					setError(response.error.message);
					setIsLoading(false);
					return;
				}
				hasReachedEnd.current = response.patientsQueuedRequests.length < pagination.pageSize;

				setRequestsList(prevState =>
					pagination.pageIndex === 0 ? response.patientsQueuedRequests : [...prevState, ...response.patientsQueuedRequests]
				);

				setAssignedCallRequests({
					totalPendingRequests: response.totalCount || 0,
					totalRapidRequests: response.totalRapidRequests || 0,
					totalAdmissionRequests: response.totalAdmissionRequests || 0,
					totalRegularRequests: response.totalRegularRequests || 0,
				});
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
				setIsLoading(false);
			}, 500);
		};
		fetchPatients();
	}, [pagination.pageIndex, searchQuery]);

	useEffect(() => {
		return () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
		};
	}, []);

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
			setIsLoading(true);
		}
	};

	return (
		<>
			<div className='queue-wrapper' onScroll={handleScroll}>
				<PatientRisk assignedCallRequests={assignedCallRequests} />
				<div className='flex patients-alert-filter margin-bottom-xl'>
					<Input
						validationOptions={{}}
						type='text'
						placeholder={intl.formatMessage({ id: 'search' })}
						bottomSpace='0'
						variant='filled'
						name='searchUsers'
						maxLength={100}
						value={searchQuery}
						onChange={event => handleSearchChange(event.target.value)}
					/>
				</div>
				<CustomTable
					isLoading={isLoading}
					headers={patientHeaders}
					rows={displayRequests()}
					className='admin-table'
					isEditable={false}
				/>
			</div>
			{selectedRequest && (
				<AssignToDoctor
					selectedRequest={selectedRequest}
					setSelectedRequest={setSelectedRequest}
					updateListing={removeFromQueue}
					healthSystemId={userSession.healthSystem.id}
				/>
			)}
			{itemToRemove && (
				<Modal
					display={true}
					position='center'
					className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal delete-queue-modal'
					onModalSubmit={() => cancelQueueRequest(itemToRemove)}
					onModalClose={() => setItemToRemove(null)}
					shouldSubmitOnEnter={false}
					primaryButtonLabel={translate('reject')}>
					<form>
						<h3>{translate('rejectSession')}</h3>
						<p>{translate('confirmRejectSession')}</p>
						<Input
							type='text'
							value={rejectMessage}
							placeholder={intl.formatMessage({ id: 'rejectSessionReason' })}
							onChange={e => setRejectMessage(e.target.value)}
						/>
					</form>
				</Modal>
			)}
			{isPatientTransferSuccessful && (
				<div className='successful-request-wrapper' data-cy='successfulRequestModal'>
					<div className='successful-request'>
						<img src={`${healthCareCdnUrl}doctor-request/request-success.svg`} alt='icon' />
						<h3>{translate('successfullyTransferPatient')}</h3>
						<p>{translate('requestSuccessfullyTransferPatient')}</p>
						<Button text={translate('done')} onClick={() => setIsPatientTransferSuccessful(false)} />
					</div>
				</div>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<ToastMessage
				showToast={newPatientRequest}
				className='toast-patient-request'
				onClose={() => setNewPatientRequest(false)}
				timer={10000}>
				<span>{translate('incomingPatientRequest')}</span>
			</ToastMessage>
		</>
	);
};

export default UnAssignedPatients;
