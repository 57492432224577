import styled from 'styled-components';

export const Wrapper = styled.div`
	background: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-3)' : 'var(--gray-1)')};
	width: 100%;
	min-height: 100%;
	display: flex;
	padding-top: var(--spacing-sl);
	justify-content: center;

	.flex {
		display: flex;
	}

	> div {
		width: 400px;
		display: flex;
		flex-direction: column;
		max-width: 90%;
		padding-bottom: var(--spacing-l);

		h3,
		p,
		h4 {
			margin: 0;
			padding: 0;
		}

		h3 {
			font-size: 20px;
			font-weight: 500;

			&:nth-of-type(2) {
				margin-top: var(--spacing-l);
			}
		}

		h4 {
			font-size: 18px;
			font-weight: 500;
		}

		p {
			font-size: 14px;
			color: var(--gray-4);
			margin-top: var(--spacing-s);
		}

		h4 {
			text-align: left;
			margin-top: var(--spacing-l);
		}

		.select-wrapper {
			position: relative;
			select {
				margin: var(--spacing-m) 0;
				padding: var(--spacing-m);
				border: 1px solid var(--gray-2);
				-webkit-appearance: none;
				appearance: none;
				width: 100%;
				background: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-2)' : 'var(--gray-1)')};
				color: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-0)' : 'var(--gray-6)')};

				&:hover {
					outline: none;
				}
			}

			&:after {
				content: '▼';
				font-size: 10px;
				top: 50%;
				right: var(--spacing-m);
				transform: translate(0, -50%);
				position: absolute;
			}
		}
	}

	button:not(.rct-collapse-btn),
	a.button {
		width: 100%;
		border: 0;
		padding: var(--spacing-m);
		border-radius: var(--spacing-s);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: var(--spacing-m);
		margin-bottom: var(--spacing-m);
	}

	button:first-of-type {
		margin-right: var(--spacing-m);
	}

	button:last-of-type {
		margin-right: 0;
	}
`;

export const HealthSystemsListItem = styled.label`
	margin-bottom: var(--spacing-m);
	padding: var(--spacing-m);
	border: 1px solid var(--gray-2);
	background: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-2)' : 'var(--gray-1)')};
	color: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-0)' : 'var(--gray-6)')};
	display: flex;
	justify-content: space-between;
	font-size: 14px;

	&:first-of-type {
		margin-top: var(--spacing-m);
	}
`;

export const TreeViewListWrapper = styled.div`
	input[type='text'] {
		padding: var(--spacing-m);
		width: 100%;
		border: none;
		border: 1px solid var(--gray-2);
		font-size: 14px;
		color: var(--gray-5);
		margin-top: var(--spacing-m);
		background: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-2)' : 'var(--gray-0)')};
	}

	.circle-loader {
		position: absolute;
		top: 50%;
		left: 50%;
	}

	label {
		width: 100%;
		text-align: left;
	}

	.react-checkbox-tree {
		input {
			display: initial !important;
		}

		label {
			&:hover {
				background: none;
			}
		}
		.rct-icon.rct-icon-check,
		.rct-checkbox {
			display: none;
		}
	}

	> div {
		> ol {
			li {
				background: ${props => (props.$isDarkMode ? 'var(--dark-theme-gray-2)' : 'var(--gray-0)')};
				width: 100%;
				margin-top: var(--spacing-m);
				padding: var(--spacing-m);

				.rct-node-icon > i {
					position: relative;
					top: 1px;
				}

				i {
					cursor: pointer;
					font-size: 18px;
					-webkit-user-select: none;
					user-select: none;
					margin-right: var(--spacing-s);
					color: var(--gray-4);
				}
			}
		}
	}
`;
