import { useState, useEffect } from 'react';
import { ScreenTypes, WindowSize } from 'calls/enums/index.js';
import { isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';

const useScreenType = () => {
	const getScreenType = () => {
		// Checking if the client is a Mobile or Tablet device, and returning the MOBILE screen type 
		// even if the window size is larger than a typical small screen
		if (isMobileOrTablet()) {
			return ScreenTypes.MOBILE;
		}
		if (window.innerWidth <= WindowSize.TABLET && window.innerWidth > WindowSize.MOBILE) {
			return ScreenTypes.TABLET;
		}
		if (window.innerWidth <= WindowSize.MOBILE) {
			return ScreenTypes.MOBILE;
		}

		return ScreenTypes.LAPTOP;
	};
	const [screenType, setScreenType] = useState(getScreenType);

	useEffect(() => {
		const handleResize = () => {
			setScreenType(getScreenType);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return screenType;
};

export default useScreenType;
