import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { enums } from '@solaborate/calls';
import { useDispatch, useSelector } from 'react-redux';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { DeviceAvailability, MediaTypes, PrivacyMode } from 'constants/enums.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js'; 
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';

const HelloItems = props => {
	const tree = useSelector(state => state.healthSystems.treeData.tree);
	const socket = useContext(SocketContext);
	const [isOnline, setIsOnline] = useState(props.isOnline);
	const [isMicrophoneOn, setIsMicrophoneOn] = useState(props.isMicrophoneOn);
	const [isCameraOn, setIsCameraOn] = useState(props.isCameraOn);
	const dispatch = useDispatch();
	const setSelectedPatientDevice = device => dispatch(patientActionCreators.setSelectedPatientDevice(device));

	useEffect(() => {
		if (!props.selectedHelloDevice) {
			return;
		}
		setSelectedPatientDevice({ device: props.selectedHelloDevice, userId: props.patient?.userId });
	}, [props.selectedHelloDevice]);

	useEffect(() => {
		const onDeviceOffline = data => {
			if (data.helloDeviceId === props.deviceId) {
				setIsOnline(DeviceAvailability.OFFLINE);
			}
		};

		const onDeviceOnline = data => {
			if (data.helloDeviceId === props.deviceId) {
				setIsOnline(DeviceAvailability.ONLINE);
			}
		};

		const onDeviceStateUpdate = data => {
			if (data.deviceId !== props.deviceId) {
				return;
			}
			if (data.name === MediaTypes.MICROPHONE) {
				setIsMicrophoneOn(data.value);
				if (!props.selectedHelloDevice) {
					return;
				}

				props.setSelectedHelloDevice(prevState => ({
					...prevState,
					state: {
						...prevState.state,
						microphone: data.value,
					},
				}));
			}
			if (data.name === MediaTypes.CAMERA) {
				setIsCameraOn(data.value);
				if (!props.selectedHelloDevice) {
					return;
				}

				props.setSelectedHelloDevice(prevState => ({
					...prevState,
					state: {
						...prevState.state,
						camera: data.value,
					},
				}));
			}
		};
		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		socket.on(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
			socket.off(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
		};
	}, [socket, tree, props.deviceId]);

	useEffect(() => {
		setIsOnline(props.isOnline);
	}, [props.isOnline, props.deviceId]);

	const redirect = action => {
		let link = '';
		const { deviceName, deviceId } = props;
		if (action === 'feed') {
			const queryParams = new URLSearchParams({
				[StartQueryStringKeys.OBJECT_ID]: deviceId,
				[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
				[StartQueryStringKeys.CONFERENCE_NAME]: deviceName,
				[StartQueryStringKeys.CALL_TYPE]: enums.CallTypes.SECURITYCAM,
			});
			link = `/patient-feed?${queryParams.toString()}`;
		}
		if (action === 'audio') {
			const queryParams = new URLSearchParams({
				[StartQueryStringKeys.OBJECT_ID]: deviceId,
				[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
				[StartQueryStringKeys.CONFERENCE_NAME]: deviceName,
				[StartQueryStringKeys.CALL_TYPE]: enums.CallTypes.AUDIO,
			});
			link = `/call?${queryParams.toString()}`;
		}
		if (action === 'video') {
			const queryParams = new URLSearchParams({
				[StartQueryStringKeys.OBJECT_ID]: deviceId,
				[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
				[StartQueryStringKeys.CONFERENCE_NAME]: deviceName,
			});
			link = `/call?${queryParams.toString()}`;
		}
		if (action === 'call-logs') {
			link = '/call-logs';
		}
		window.open(link, '_blank');
	};

	const renderCameraOrMic = url =>
		props.isOnline ? (
			<div className='flex'>
				<img style={{ marginRight: 0 }} src={`${healthCareCdnUrl}${url}.svg`} alt='icon' />
			</div>
		) : null;

	return (
		<div className={`hello-items${props.isOverviewHelloItem ? ' overview-hello-items' : ''}`} data-cy='overviewHelloItems'>
			{props.deviceName && (
				<div>
					{!props.isOverviewHelloItem && (
						<div className='hello-device-description'>
							<h3>{props.deviceName}</h3>
						</div>
					)}
					<div className='hello-device-description hello-device-description-info'>
						<div className='flex'>
							<div className='flex'>
								<div className='flex'>
									<span>
										<i
											style={{ marginLeft: '5px' }}
											className={classNames('material-icons', isOnline ? '--green-color' : '--red-color')}>
											fiber_manual_record
										</i>
									</span>
									<span>{isOnline ? translate('available') : translate('unavailable')}</span>
								</div>
								{props.privacyMode === PrivacyMode.ON && !!isOnline && (
									<div className='flex'>
										<img src={`${healthCareCdnUrl}privacy-mode/privacy.svg?v3`} alt='icon' />
										<span>{translate('privacyMode')}</span>
									</div>
								)}
							</div>
							<div className='flex'>
								{renderCameraOrMic(isMicrophoneOn ? 'microphone/mic-black' : 'microphone/mic_off-black')}
								{renderCameraOrMic(isCameraOn ? 'camera/videocam-black' : 'camera/videocam_off-black')}
							</div>
						</div>
					</div>
				</div>
			)}
			{!props.isFromMyHelloDevices && (
				<div className='hello-feature-grid'>
					<HelloFeatureBlock
						onClick={() => redirect('video')}
						title={translate('talkToPatient')}
						className='hello-feature-audio'
					/>
					{props.isHelloDevice && (
						<HelloFeatureBlock
							onClick={() => redirect('feed')}
							title={translate('viewPatient')}
							className='hello-feature-camera-feed'
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default HelloItems;
