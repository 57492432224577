import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';
import { getConfiguredRoleDisplayName } from 'infrastructure/helpers/configurationsHelpers.js';

const UserRolesList = forwardRef((props, ref) => {
	const userRoles = useSelector(state => state.user.userRoles);
	const companySettings = useSelector(state => state.company.companySettings);

	// @ts-ignore
	const { showDropdownOnTouch, openDropdownModal, switchRole } = props;

	return (
		<>
			{userRoles.length >= 2 && (
				<li ref={ref} className={showDropdownOnTouch === 'user-roles' ? showDropdownOnTouch : ''}>
					<span onClick={() => openDropdownModal('user-roles')}>
						<div className='users-lists-title'>
							<img src={`${healthCareCdnUrl}settings/my-roles.svg`} alt='icon' />
							<div className={classNames('flex', props.isLeftText ? 'text-align-left' : '')}>
								{translate('switchRole')}
								<span> {translate('providerOrPatient')}</span>
							</div>
						</div>
						<div className='list-group-wrapper'>
							<ul className='list-group workflow-list-group user-role'>
								{userRoles.map(role => (
									<li
										key={role.id}
										className={classNames('switch-presence-li', getStorage().getItem('userRole') === role.name ? 'active' : '')}>
										<span onClick={() => switchRole(role)}>
											<img
												src={`${healthCareCdnUrl}user-types/${
													getStorage().getItem('userRole') === role.name
														? `${role.name.toLowerCase()}-active`
														: role.name.toLowerCase()
												}.svg?v3`}
												alt='icon'
											/>
											<span className='role-display-name'>{getConfiguredRoleDisplayName(role.name, companySettings)}</span>
											<div>
												<i className='material-icons'>done</i>
											</div>
										</span>
									</li>
								))}
							</ul>
						</div>
					</span>
				</li>
			)}
		</>
	);
});

UserRolesList.displayName = 'UserRolesList';

export default UserRolesList;
