const WindowSize = {
	LAPTOP: 1024,
	TABLET: 769,
	MOBILE: 425,
};

// Adjusted the TABLET breakpoint to 769 to ensure styles are applied correctly 
// for devices with an exact width of 768

export default WindowSize;
