// eslint-disable-next-line max-classes-per-file
import { getCompanyId } from 'infrastructure/auth.js';

export class Offer {
	constructor(
		actioneeParticipantId,
		callType,
		conferenceId,
		forScreenShare,
		isAudio,
		isVideo,
		isScreen,
		toScreenSharingSocket,
		participantId
	) {
		this.actioneeParticipantId = actioneeParticipantId;

		this.callType = callType;

		this.conferenceId = conferenceId;

		this.forScreenShare = forScreenShare;

		this.isAudio = isAudio;

		this.isVideo = isVideo;

		this.isScreen = isScreen;

		this.toScreenSharingSocket = toScreenSharingSocket;

		this.participantId = participantId;
	}
}

export class ConferenceInfo {
	constructor(
		callType,
		conferenceId,
		conferenceName,
		conversationId,
		from,
		isAudio,
		isChat,
		isDirectToHello,
		isMeetingRoom,
		isMultiparty,
		isScreen,
		isVideo,
		participantId,
		participants,
		inputDevices,
		isSecurityCam = false,
		instigator,
		source = 'WEB'
	) {
		this.callType = callType;

		this.conferenceId = conferenceId;

		this.conferenceName = conferenceName;

		this.conversationId = conversationId;

		this.from = from;

		this.isAudio = isAudio;

		this.isChat = isChat;

		this.isDirectToHello = isDirectToHello;

		this.isMeetingRoom = isMeetingRoom;

		this.isMultiparty = isMultiparty;

		this.isScreen = isScreen;

		this.isVideo = isVideo;

		this.participantId = participantId;

		this.participants = participants;

		this.inputDevices = inputDevices;

		this.isSecurityCam = isSecurityCam;

		this.source = source;

		this.tenantId = getCompanyId();

		this.instigator = instigator;
	}
}

export class FromUser {
	constructor(name, jobTitle, picture, objectGuidId, friendlyUrl, objectId, id) {
		this.name = name;

		this.jobTitle = jobTitle;

		this.picture = picture;

		this.objectGuidId = objectGuidId;

		this.friendlyUrl = friendlyUrl;

		this.objectId = objectId;

		this.id = id;
	}
}

export class ConferenceParticipant {
	constructor(objectId, objectType, name, id = undefined, objectGuidId = undefined) {
		this.objectId = objectId;

		this.objectType = objectType;

		this.name = name;

		this.id = id;

		this.objectGuidId = objectGuidId;
	}
}
