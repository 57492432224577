import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import PressureInjurySummary from 'components/DashboardCharts/PatientCentric/AI/PressureInjurySummary.jsx';
import PatientMobilitySummary from 'components/DashboardCharts/PatientCentric/AI/PatientMobilitySummary.jsx';
import FallPreventionAlerts from 'components/DashboardCharts/PatientCentric/AI/FallPreventionAlerts.jsx';
import PressureInjuryHistory from 'components/DashboardCharts/PatientCentric/AI/PressureInjuryHistory.jsx';
import AiAlertsStats from 'components/DashboardCharts/PatientCentric/AI/AiAlertsStats.jsx';
import AiAlertsHistory from 'components/DashboardCharts/PatientCentric/AI/AiAlertsHistory.jsx';

const AiSummaryWrapper = ({ dateRange, selectedTimezone, isEvidenceEnabled }) => (
	<div className='session-wrapper'>
		<label className='session-label'>{translate('summary')}</label>
		<Grid columns='repeat(2, 1fr)' gridGap='var(--spacing-m)'>
			<PressureInjurySummary dateRange={dateRange} selectedTimezone={selectedTimezone} />
			<PatientMobilitySummary dateRange={dateRange} selectedTimezone={selectedTimezone} />
		</Grid>
		<Grid columns='1.2fr 1.8fr' gridGap='var(--spacing-m)' className='top-10'>
			<AiAlertsHistory dateRange={dateRange} selectedTimezone={selectedTimezone} isEvidenceEnabled={isEvidenceEnabled} />
			<FallPreventionAlerts dateRange={dateRange} selectedTimezone={selectedTimezone} />
		</Grid>
		<AiAlertsStats dateRange={dateRange} selectedTimezone={selectedTimezone} />
		<PressureInjuryHistory dateRange={dateRange} selectedTimezone={selectedTimezone} isEvidenceEnabled={isEvidenceEnabled} />
	</div>
);

export default AiSummaryWrapper;
