/* eslint-disable no-console */
import { sendStats } from 'api/callStats.js';

class CallStats {
	constructor() {
		this.outgoingMetricsBuffer = [];
		this.flushInterval = 30000; // ms
	}

	addPeerConnection = async ({ pcObject, pushInterval, conferenceId, participantId, remoteParticipantId, userId }) => {
		this.startSendingStats();

		const getStatsInterval = setInterval(async () => {
			if (pcObject.connectionState === 'closed') {
				this.removePeerConnection(getStatsInterval);
				this.stop();
				return;
			}

			const stats = await pcObject.getStats();

			this.pushStats({ stats, conferenceId, participantId, remoteParticipantId, userId });
		}, pushInterval);
	};

	stop = () => {
		// Send the remaining stats
		this.sendStats();
	};

	removePeerConnection = getStatsInterval => {
		clearInterval(getStatsInterval);
	};

	startSendingStats = () => {
		setTimeout(this.sendStats, this.flushInterval);
	};

	sendStats = async () => {
		if (this.outgoingMetricsBuffer.length === 0) {
			return;
		}

		const batch = this.prepareBatch();

		try {
			await sendStats(batch);
		} catch (ex) {
			console.error('error while sending call stats logs', ex);
		} finally {
			setTimeout(this.sendStats, this.flushInterval);
		}
	};

	prepareBatch = () => {
		const batchSize = this.outgoingMetricsBuffer.length;
		return {
			data: { values: this.outgoingMetricsBuffer.splice(0, batchSize) },
		};
	};

	pushStats = ({ stats, conferenceId, participantId, remoteParticipantId, userId }) => {
		const result = [];

		stats.forEach(report => {
			if (report.type === 'certificate') {
				return;
			}

			result.push(report);
		});

		this.outgoingMetricsBuffer.push({
			conferenceId: conferenceId,
			userId: userId,
			participantId: participantId,
			remoteParticipantId: remoteParticipantId,
			timeStamp: this.generateTimeStamp(),
			stats: result,
		});
	};

	generateTimeStamp = () => {
		return Math.round(new Date().getTime());
	};
}

export default CallStats;
