import classNames from 'classnames';

const Alert = ({ persist = false, message = '', ...props }) => (
	<div
		data-cy={props.alertSelector}
		className={classNames(
			'alert',
			props.variant,
			props.fixed ? 'fixed' : '',
			props.display && !persist ? 'show' : '',
			props.display && persist ? 'persist' : '',
			props.className
		)}>
		<span>{message}</span>
		{!props.hideCloseButton && (
			<i onClick={props.onClose} className='material-icons close-icon'>
				close
			</i>
		)}
	</div>
);

export default Alert;
