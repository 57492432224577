import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import { conference as Conference } from '@solaborate/calls';
import { Mic, Cam, ScreenShare } from '@solaborate/calls/webrtc';
import { ControlsActions } from 'calls/enums/index.js';
import {
	useConference,
	useConferenceConfigurations,
	useConferenceParticipants,
	useConferenceState,
	useControllerTracks,
	useLocalParticipant,
} from 'calls/hooks/index.js';
import LocalParticipant from 'calls/LocalParticipant.js';
import { Avatar, IconButton, ParticipantAudioLevel, StyledParticipantInfo, Tooltip } from 'calls/components/index.js';
import { PinIcon, UnPinIcon } from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';

import { StyledAllParticipantsAside } from 'calls/styles/styled-components/index.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import translate from 'i18n-translations/translate.jsx';
import { getCallsButtonColor } from 'infrastructure/helpers/commonHelpers.js';
import Button from 'components/Button.jsx';

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.participant
 * @param {(trackType: import('@solaborate/calls/webrtc').TrackType) => void} [props.onClick=null]
 */
const ParticipantRow = ({ participant, onClick = null }) => {
	const intl = useIntl();
	const conference = useConference();
	const conferenceConfigurations = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const isLocalParticipant = participant instanceof LocalParticipant;
	const tracks = useControllerTracks(isLocalParticipant ? participant.localTrackController : participant.remoteTrackController);
	const isCurrentlyPinnedParticipant = conferenceConfigurations.pinnedParticipantId === participant.id;
	const [participantDisplayedName] = useState(participant.alias || participant.name);
	const participantNameWithInterpreter = `${participantDisplayedName} ${
		participant.interpreterId ? `(${intl.formatMessage({ id: 'interpreter' })})` : ''
	}`;

	const togglePinnedParticipant = trackType => {
		conferenceConfigurations.setPinnedParticipantId(isCurrentlyPinnedParticipant ? '' : participant.id);

		if (!isCurrentlyPinnedParticipant) {
			onClick(trackType);
		}
	};

	const onRemoveParticipant = participantId => {
		if (isLocalParticipant || !localParticipant.isOwner) {
			return;
		}
		conferenceConfigurations.setRemoveParticipantModal({
			isOpen: true,
			modalMessage: `${intl.formatMessage({ id: 'confirmationOfRemovalParticipant' }, { value: participantDisplayedName })}`,
			onSubmit: () => {
				if (!localParticipant.isOwner) {
					return;
				}
				conference.removeParticipant(participantId);
				conferenceConfigurations.setRemoveParticipantModal({
					isOpen: false,
					message: '',
					onSubmit: () => {},
				});
			},
		});
	};

	const ParticipantInfo = ({ participant, screenShareInfo = false }) => (
		<>
			<Avatar
				size={40}
				src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
				name={participantDisplayedName}
			/>
			<h4>
				{isLocalParticipant
					? `${intl.formatMessage({ id: 'you' })} ${!screenShareInfo ? `(${participantNameWithInterpreter})` : ''}`
					: participantNameWithInterpreter}
				{screenShareInfo ? `(${intl.formatMessage({ id: 'presenting' })})` : ''}
			</h4>
		</>
	);

	const participantControls = (participant, togglePinnedParticipant, screenShareActions = false) => (
		<div className='flex'>
			{isCurrentlyPinnedParticipant && (
				<Tooltip text={intl.formatMessage({ id: 'unpinFeed' })} position='top-right'>
					<Button
						className='pin-participant'
						onClick={togglePinnedParticipant}
						svgIcon={<UnPinIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} width={18} height={18} />}
					/>
				</Tooltip>
			)}
			{!isCurrentlyPinnedParticipant && (
				<Tooltip text={intl.formatMessage({ id: 'pinFeed' })} position='top-right'>
					<Button
						className='pin-participant'
						onClick={() => togglePinnedParticipant(screenShareActions ? ScreenShare : Cam)}
						svgIcon={<PinIcon color={getCallsButtonColor(conferenceConfigurations.isDarkMode)} width={18} height={18} />}
					/>
				</Tooltip>
			)}

			{!screenShareActions && (
				<>
					<StyledParticipantInfo>
						{!tracks[Mic] && (
							<IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />
						)}
						{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
						{!tracks[Cam] && (
							<IconButton icon='videocam_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />
						)}
					</StyledParticipantInfo>
					{!isLocalParticipant && localParticipant.isOwner && (
						<Tooltip text={intl.formatMessage({ id: 'removeParticipant' })} position='top-right'>
							<IconButton
								icon='close'
								background='transparent'
								color={getCallsButtonColor(conferenceConfigurations.isDarkMode)}
								onClick={() => onRemoveParticipant(participant.id)}
							/>
						</Tooltip>
					)}
				</>
			)}
		</div>
	);

	return (
		<>
			<div className='flex' key={participant.id}>
				<ParticipantInfo participant={participant} />
				{participantControls(participant, togglePinnedParticipant)}
			</div>
			{tracks[ScreenShare] && (
				<div className='flex'>
					<ParticipantInfo participant={participant} screenShareInfo={true} />
					{participantControls(participant, togglePinnedParticipant, true)}
				</div>
			)}
		</>
	);
};

/**
 * @param {object} props
 * @param {(...args: any)=>void} [props.setMainParticipant] Set main participant callback
 * @param {(...args: any)=>void} props.setActiveTrackType Set main participant active track type callback
 */
const MoreParticipantsView = ({ setMainParticipant, setActiveTrackType }) => {
	const conferenceState = useConferenceState();
	const conferenceConfigurations = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const remoteParticipants = useConferenceParticipants();

	return (
		<>
			{!(conferenceState instanceof Conference.StateEnded) && conferenceConfigurations.isMoreParticipantsViewOpen && (
				<StyledAllParticipantsAside $isDarkMode={conferenceConfigurations.isDarkMode}>
					<h4>{translate('participants')}</h4>
					<p>
						{translate('currentlyInThisCall')} ({remoteParticipants.length + 1})
					</p>
					<div
						onClick={() => {
							conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_MORE_PARTICIPANTS, false);
						}}
						className='close-medical-info cursor-pointer'>
						<i className='material-icons-outlined'>close</i>
					</div>
					<div>
						<ParticipantRow
							participant={localParticipant}
							onClick={trackType => {
								if (_.isFunction(setMainParticipant)) {
									setMainParticipant(localParticipant);
								}
								if (_.isFunction(setActiveTrackType)) {
									setActiveTrackType(trackType);
								}
							}}
						/>
						{remoteParticipants.map(remoteParticipant => (
							<ParticipantRow
								participant={remoteParticipant}
								onClick={trackType => {
									if (_.isFunction(setMainParticipant)) {
										setMainParticipant(remoteParticipant);
									}
									if (_.isFunction(setActiveTrackType)) {
										setActiveTrackType(trackType);
									}
								}}
							/>
						))}
					</div>
				</StyledAllParticipantsAside>
			)}
		</>
	);
};

export default MoreParticipantsView;
