import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import Checkbox from 'components/Checkbox.jsx';
import Grid from 'components/Grid.jsx';
import { Button } from 'components/index.js';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { Collapse, Expand } from 'icons/General/index.js';
import { Fragment, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const TableWrapper = styled.div`
	height: ${props => props.$height};
`;

const TableHeader = styled.th`
	font-weight: normal;
	padding: var(--spacing-m) var(--spacing-l);
	color: var(--gray-3);
	${props =>
		props.$width &&
		css`
			white-space: normal !important;
			min-width: ${props.$width};
			max-width: ${props.$width};
			width: ${props.$width};
		`}
	&.sticky-column {
		position: sticky;
		z-index: 1;
		background: var(--gray-0);
		${({ $isLastStickyColumn }) =>
			$isLastStickyColumn &&
			css`
				border-right: 1px solid var(--gray-2);
			`}
		${({ $width = '200px', $index }) => css`
			left: calc(${$width} * ${$index});
			min-width: ${$width};
			max-width: ${$width};
		`}
		white-space: normal !important;
		word-break: break-word;
	}
`;

const TableCell = styled.td`
	&.sticky-column {
		position: sticky;
		z-index: 1;
		background: var(--gray-0);
		${({ $isLastStickyColumn }) =>
			$isLastStickyColumn &&
			css`
				border-right: 1px solid var(--gray-2);
			`}
		${({ $width = '200px', $index }) => css`
			left: calc(${$width} * ${$index});
			min-width: ${$width};
			max-width: ${$width};
		`}
		white-space: normal !important;
		word-break: break-word;
	}
`;

const TableRow = ({ row, ...props }) => {
	const expandRow = () =>
		props.setExpandedRows(prevState => ({
			...prevState,
			[row.id]: !prevState[row.id],
		}));

	return (
		<tr
			className={classNames(row.className, {
				'row-expanded': props.isExpanded,
				'row-nested': props.isChild,
				'row-nested-last': props.isChild && props.isLastChild,
			})}
			data-test-id='table-row'>
			{props.isEditable && (
				<td className='padding-s'>
					<Checkbox />
				</td>
			)}
			{props.isNested && (
				<td className='padding-s'>
					{!props.keepRowsExpanded && row.children?.length > 0 && (
						<Button
							svgIcon={props.isExpanded ? <Expand /> : <Collapse transform='rotate(90)' />}
							background='transparent'
							color={LightTheme.colors.grayTen}
							border='none'
							onClick={expandRow}
						/>
					)}
				</td>
			)}
			{props.children}
		</tr>
	);
};

const CustomTable = ({
	headers = [],
	stickyHeader = false,
	horizontalScroll = false,
	height = 'auto',
	className = '',
	children = null,
	headerClass = 'padding-l',
	isEditable = false,
	rows = [],
	isLoading = false,
	tableEmptyText = null,
	isNested = false,
	keepRowsExpanded = false,
	setPagination = null,
}) => {
	const [expandedRows, setExpandedRows] = useState({});
	const [allExpanded, setAllExpanded] = useState(false);
	const [loadNextPage, setLoadNextPage] = useState(false);
	const loadMoreRef = useRef(null);

	const expandAllRows = () => {
		setExpandedRows(
			rows.reduce((acc, row) => (row?.children?.length > 0 ? { ...acc, [row.id]: !allExpanded } : acc), expandedRows)
		);
		setAllExpanded(prevState => !prevState);
	};

	useEffect(() => {
		if (!isNested) {
			return;
		}
		setExpandedRows({});
		setAllExpanded(false);
	}, [rows]);

	useEffect(() => {
		if (!isNested) {
			return;
		}
		const expandedCount = Object.values(expandedRows).filter(Boolean).length;
		const expandableCount = rows.filter(row => row?.children?.length > 0).length;
		setAllExpanded(expandedCount !== 0 && expandedCount === expandableCount);
	}, [expandedRows]);

	const isLastStickyColumn = index => {
		const stickyHeaders = headers.filter(header => header.sticky);
		return stickyHeaders.length === index + 1;
	};

	const getRowDataByIndex = (row, cellIndex) => {
		const rowKeys = Object.keys(row).filter(key => !['id', 'className', 'children', 'cellClassName'].includes(key));
		return row?.[rowKeys[cellIndex]];
	};

	const shouldBreakWord = content => typeof content === 'string' && content?.split(' ').some(word => word.length > 45);

	useEffect(() => {
		if (setPagination && !isLoading && loadNextPage) {
			setPagination(prevState =>
				prevState.totalCount > rows.length ? { ...prevState, pageIndex: prevState.pageIndex + 1 } : prevState
			);
		}
	}, [loadNextPage, rows.length, isLoading]);

	useEffect(() => {
		if (!setPagination) {
			return undefined;
		}
		const loadMoreContainer = loadMoreRef.current;
		const handleIntersection = entries => {
			const [entry] = entries;
			setLoadNextPage(entry.isIntersecting);
		};

		const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
		if (loadMoreRef.current) {
			observer.observe(loadMoreContainer);
		}
		return () => {
			if (setPagination && loadMoreContainer) {
				observer.unobserve(loadMoreContainer);
			}
		};
	}, [loadMoreRef]);

	return (
		<TableWrapper
			className={classNames('custom-table-wrapper', {
				'sticky-header': stickyHeader,
				'horizontal-scroll': horizontalScroll,
			})}
			$height={height}>
			{children && <header className={headerClass}>{children}</header>}
			<main>
				<table className={classNames('custom-table', className)}>
					<thead>
						<tr>
							{isEditable && (
								<TableHeader>
									<Checkbox />
								</TableHeader>
							)}
							{isNested && (
								<TableHeader id='expand-all'>
									{!keepRowsExpanded && rows.some(row => row.children.length > 0) && (
										<Button
											svgIcon={allExpanded ? <Expand /> : <Collapse transform='rotate(90)' />}
											background='transparent'
											color={LightTheme.colors.grayTen}
											border='none'
											onClick={expandAllRows}
										/>
									)}
								</TableHeader>
							)}
							{headers.map((header, headerIndex) => (
								<TableHeader
									key={header.id || `header-${headerIndex}`}
									className={classNames(header.thClass || '', { 'sticky-column': header?.sticky })}
									$width={header.columnWidth}
									$index={headerIndex}
									$isLastStickyColumn={isLastStickyColumn(headerIndex)}>
									{header.title}
								</TableHeader>
							))}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, rowIndex) => (
							<Fragment key={row?.id || `row-${rowIndex}`}>
								<TableRow
									key={row?.id || `row-${rowIndex}`}
									row={row}
									isEditable={isEditable}
									isNested={isNested}
									keepRowsExpanded={keepRowsExpanded}
									isExpanded={expandedRows?.[row?.id] || (keepRowsExpanded && row?.children?.length > 0)}
									setExpandedRows={setExpandedRows}>
									{headers.map((header, columnIndex) => (
										<TableCell
											key={header.id || `cell-${columnIndex}`}
											className={classNames(
												header?.columnClass || '',
												{
													'sticky-column': header?.sticky,
													'word-break-all': shouldBreakWord(row?.[header.id] ?? getRowDataByIndex(row, columnIndex)),
												},
												row.cellClassName ? row.cellClassName[columnIndex] : ''
											)}
											$width={header?.columnWidth}
											$index={columnIndex}
											$isLastStickyColumn={isLastStickyColumn(columnIndex)}>
											{row?.[header.id] ?? getRowDataByIndex(row, columnIndex)}
										</TableCell>
									))}
								</TableRow>
								{isNested &&
									row?.children &&
									(expandedRows?.[row.id] || keepRowsExpanded) &&
									row.children.map((child, childIndex) => (
										<TableRow
											key={child?.id || `row-${rowIndex}-${childIndex}`}
											row={child}
											isEditable={isEditable}
											isNested={isNested}
											isChild={true}
											isLastChild={childIndex === row.children.length - 1}>
											{headers.map((header, columnIndex) => (
												<TableCell
													key={header.id || `cell-${columnIndex}`}
													className={classNames(header?.columnClass || '', {
														'sticky-column': header?.sticky,
														'word-break-all': shouldBreakWord(child?.[header.id] ?? getRowDataByIndex(child, columnIndex)),
													})}
													$width={header?.columnWidth}
													$index={columnIndex}
													$isLastStickyColumn={isLastStickyColumn(columnIndex)}>
													{child?.[header.id] ?? getRowDataByIndex(child, columnIndex)}
												</TableCell>
											))}
										</TableRow>
									))}
							</Fragment>
						))}
						{!!isLoading && (
							<tr>
								<td colSpan={headers.length + [isEditable, isNested].filter(Boolean).length}>
									<Grid width='100%' horizAlign='center'>
										<SkeletonLoader rows={setPagination && rows.length !== 0 ? 3 : 10} padding='35px 20px' />
									</Grid>
								</td>
							</tr>
						)}
						{!isLoading && rows?.length === 0 && (
							<tr>
								<td colSpan={headers.length + [isEditable, isNested].filter(Boolean).length} className='empty-state-content'>
									{tableEmptyText || translate('noData')}
								</td>
							</tr>
						)}
					</tbody>
					{setPagination && <tfoot ref={loadMoreRef}></tfoot>}
				</table>
			</main>
		</TableWrapper>
	);
};

export default CustomTable;
