import { CallWorkflowType } from 'constants/configurationEnums.js';
import { legacyApi } from '../constants/global-variables.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export function getMissedCallsNotificationsCounter(userId) {
	return new Promise((res, rej) => {
		const url = `/v2.0/api/users/${userId}/notifications/count`;
		legacyApi
			.get(url)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export const getAlertsNotifications = async ({ pageIndex, levelId, pageSize = 10, workflowType = CallWorkflowType.ROUNDING }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${getCompanyId()}/levels/${levelId}/ai-alerts?page-index=${pageIndex}&page-size=${pageSize}&workflow-type=${workflowType}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
