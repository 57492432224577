import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const Tabs = props => {
	const [activeIndex, setActiveIndex] = useState(props.defaultActiveIndex || 0);

	const selectTabIndex = index => {
		props.onChange(index);
		if (!props.activeIndex === null) {
			setActiveIndex(index);
		}
	};

	return (
		<div
			className={classNames(props.isVertical ? 'vertical-tabs' : '', props.className ? props.className : '')}
			data-cy='verticalTabs'>
			{React.Children.map(props.children, child =>
				React.cloneElement(child, {
					activeIndex: props.activeIndex != null ? props.activeIndex : activeIndex,
					onSelectTab: selectTabIndex,
				})
			)}
		</div>
	);
};

Tabs.propTypes = {
	activeIndex: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

export const TabList = props => {
	const visibleChildren = props.children.length ? props.children.filter(x => x) : props.children;
	return (
		<ul className={`tabs ${props.className}`} data-cy='tabs'>
			{React.Children.map(visibleChildren, (child, index) =>
				React.cloneElement(child, {
					isActive: index === props.activeIndex,
					onSelect: () => props.onSelectTab(index),
				})
			)}
		</ul>
	);
};

export const Tab = props => (
	<li
		data-cy={props.dataCypress}
		className={classNames(props.isDisabled && 'tab disabled', props.isActive ? 'tab active' : 'tab', props.className)}
		onClick={props.isDisabled ? null : props.onSelect}>
		<a>{props.children}</a>
	</li>
);

export const TabPanels = props => (
	<div className={classNames('panels', props.className)} style={{ height: props.height }}>
		{props.children.filter(child => child)[props.activeIndex]}
	</div>
);

export const TabPanel = props => props.children;
