import { gatewayApi } from 'constants/global-variables.js';

export const getUserRoles = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/roles`);
		return data.result;
	} catch (error) {
		return { error };
	}
};

export const addMemberRole = async ({ companyId, memberId, role, teamId }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/companies/${companyId}/members/${memberId}/roles`, {
			role,
			teamId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMemberRole = async ({ companyId, memberId, roleId }) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/companies/${companyId}/members/${memberId}/roles/${roleId}`);
		return data;
	} catch (error) {
		return { error };
	}
};
