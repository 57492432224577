import { useIntl } from 'react-intl';
import { CareMemberTypeValue } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { getDateWithSeconds, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';

const careLogsImage = `${healthCareCdnUrl}rpm/carelogs/`;
const careLogsMeasurementsImgUrl = `${careLogsImage}measurements/`;

const CareLogDetails = ({ selectedVisit, selectedPatient, preferredUnits }) => {
	const intl = useIntl();

	const getCareLogItem = (title, subTitle, icon, hasDate = false) => (
		<div className='flex flex-align-center'>
			<img src={`${careLogsImage}${icon}.svg`} alt='ico' />
			<div className='care-log-item'>
				<p>{translate(title)}</p>
				<p>{hasDate ? getDateWithSeconds(subTitle) : subTitle}</p>
			</div>
		</div>
	);

	const getCategoryPreference = categoryId => preferredUnits.find(unit => unit.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(preference => preference.unitSystemId === selectedPreference.unitSystemId);
	};

	const getConvertedValue = item =>
		convertMeasurementTypes(item.unitCategoryId, item.measurementValue, getUnitPreference(item.unitCategoryId)?.unitSystemId);

	return (
		<>
			{selectedVisit && (
				<>
					<div className='flex flex-space-between care-log-details'>
						<div className='care-log-detail'>
							<p className='care-log-title'>{translate('visitInfo')}</p>
							{getCareLogItem('visitStart', selectedVisit.startedAt, 'date-time', true)}
							{getCareLogItem('visitEnd', selectedVisit.endedAt, 'date-time', true)}
							{getCareLogItem('duration', selectedVisit.duration || 'N/A', 'time-tracker-on')}
						</div>
						<div className='care-log-detail'>
							<p className='care-log-title'>{translate('patientInfo')}</p>
							{getCareLogItem('medicalRecordNumber', selectedPatient.mrn, 'diagnose')}
							{getCareLogItem('fullName', `${selectedPatient.firstName} ${selectedPatient.lastName}`, 'profile')}
							{getCareLogItem('dateOfBirth', monthDayYear(selectedPatient.dateOfBirth), 'appointments')}
						</div>
						<div className='care-log-detail'>
							<p className='care-log-title'>{translate('careTeam')}</p>
							{getCareLogItem(
								'fullName',
								`${selectedVisit.careMember.firstName} ${selectedVisit.careMember.lastName}`,
								'profile'
							)}
							{getCareLogItem(
								'careMemberType',
								intl.formatMessage({ id: CareMemberTypeValue[selectedVisit.careMember?.careMemberType] }),
								'nurse'
							)}
						</div>
					</div>
					<div className='care-log-detail live-measurements'>
						<p className='care-log-title'>{translate('liveMeasurements')}</p>
						{selectedVisit.measurements.length === 0 && (
							<div className='flex flex-justify-center'>
								<p>{translate('noData')}</p>
							</div>
						)}
						{selectedVisit.measurements.map(item => (
							<div className='flex flex-align-center'>
								<div className='flex flex-space-between flex-align-center full-width live-examination-logs'>
									<div className='flex flex-align-center'>
										<img src={`${careLogsMeasurementsImgUrl}${item.measurementType}.svg`} alt='ico' />
										<p>{translate(item.measurementType)}</p>
									</div>
									<div>
										<p>
											{getConvertedValue(item)}
											<span>{getUnitPreference(item.unitCategoryId)?.unit || item.measurementUnit}</span>
										</p>
									</div>
									<div className='flex'>
										<img src={`${healthCareCdnUrl}rpm/devices/${stringToCamelCase(item.device.type)}.svg`} alt='ico' />
										<p className='care-log-device'>{translate(stringToCamelCase(item.device.type))}</p>
									</div>
									<div>
										<p className='care-log-create-date'>{getDateWithSeconds(item.startDate)}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
			{!selectedVisit && (
				<div className='care-log-wrapper flex flex-justify-center flex-align-center'>
					<p>{translate('noVisitsForThisPatient')}</p>
				</div>
			)}
		</>
	);
};

export default CareLogDetails;
