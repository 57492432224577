import classNames from 'classnames';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { WarningIndexes } from 'constants/enums.js';
import { VitalSignsList } from 'constants/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import Pill from 'icons/RPM/Pill.jsx';
import { getPreferredLanguageLocale, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import React from 'react';
import { useIntl } from 'react-intl';
import OverviewDetails from 'containers/HospitalAtHome/OverviewDetails.jsx';
import RecentMetrics from 'containers/HospitalAtHome/RecentMetrics.jsx';
import moment from 'moment';
import Button from 'components/Button.jsx';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { TelemetryIcons } from 'constants/hospital-at-home.js';

const OverviewTab = ({
	goToRecentMetric,
	goToMedications,
	goToAlerts,
	goToCareTeam,
	goToTelemetry,
	measurements,
	averages,
	appointments,
	medications,
	careTeam,
	alerts,
	telemetry,
	setDateRange,
	dateRange,
	allExpanded,
}) => {
	const intl = useIntl();
	const isToday = dateRange.from === moment(new Date()).format('YYYY-MM-DD');

	return (
		<div className={classNames('overview-container patient-tab-wrapper', { 'expanded-patient-wrapper': allExpanded })}>
			<div className='left-items'>
				<div className='schedule-wrapper appointments'>
					<div className='flex flex-space-between'>
						<span>
							{translate('schedule')}
							{' ('}
							{intl.formatMessage({ id: isToday ? 'today' : 'tomorrow' }) + ' ' + monthDayYear(dateRange.from)}
							{')'}
						</span>
						<div className='flex gap-s'>
							<Button
								variant='white'
								text={translate(isToday ? 'next' : 'previous')}
								className='flex-align-center'
								onClick={() =>
									setDateRange({
										from: isToday
											? moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
											: moment(new Date()).format('YYYY-MM-DD'),
										to: isToday ? moment(new Date()).add(1, 'day').format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
									})
								}
							/>
						</div>
					</div>

					<div className='schedule-items'>
						{appointments?.length > 0 &&
							appointments.map(appointment => (
								<div className='flex schedule-item' key={appointment.id}>
									<ProfilePicture
										fullName={appointment?.createdBy.fullName}
										profilePicture={appointment?.createdBy.profilePicture}
										className='doctor-request-img'
									/>
									<div>
										<h5>
											{translate('appointmentWith')} Dr. {appointment?.createdBy.firstName} {appointment?.createdBy.lastName}
										</h5>
										<span>
											{translate('at', {
												value: moment
													.utc(appointment?.appointmentSlot?.startDateTime)
													.local()
													.locale(getPreferredLanguageLocale())
													.format('LT'),
											})}
										</span>
									</div>
								</div>
							))}
					</div>
					{appointments.length === 0 && (
						<div className='flex flex-justify-center'>
							<p className='semi-bold'>{translate('noResultsFound')}</p>
						</div>
					)}
				</div>

				<OverviewDetails className='top-10' title='medication' onClick={goToMedications}>
					{medications.length > 0 &&
						[...medications].splice(0, 4).map(item => (
							<div key={item.id} className='flex flex-space-between rpm-medication-wrapper'>
								<div className='flex right-align-items'>
									<Pill className='medication-icon' />
									<div className='column-direction'>
										<p className='no-margin'>{item.medicine?.name}</p>
										<span>
											{item.dosage} {item.medicine?.strengthAndForm}
										</span>
									</div>
								</div>
							</div>
						))}
					{medications.length === 0 && (
						<div className='flex flex-justify-center'>
							<p className='semi-bold'>{translate('noResultsFound')}</p>
						</div>
					)}
				</OverviewDetails>
				<OverviewDetails className='top-10' title='alerts' onClick={goToAlerts}>
					<div className='patient-summary-table-wrapper'>
						{alerts.map(item => (
							<div key={item.id} className='patient-request-list-item cursor-pointer'>
								<div className='alert-icon'>
									<img src={VitalSignsList.find(items => items.type === item.measurement.type)?.icon} alt='ico' />
								</div>
								<div className='flex column-direction'>
									<p>
										{`${intl.formatMessage({ id: stringToCamelCase(item.measurement.type) })} ${intl.formatMessage({
											id: 'wasDetected',
										})} ${item.measurement.value} ${item.measurement.unit}`}
									</p>
									<span
										className={classNames(
											[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex) ? 'moderate-risk' : 'high-risk'
										)}>
										{[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex)
											? translate('moderateRisk')
											: translate('highRisk')}
									</span>
								</div>
							</div>
						))}
						{alerts.length === 0 && (
							<div className='flex flex-justify-center'>
								<p className='semi-bold'>{translate('noResultsFound')}</p>
							</div>
						)}
					</div>
				</OverviewDetails>
			</div>
			<div className='right-items'>
				<RecentMetrics
					goToRecentMetric={goToRecentMetric}
					averages={averages}
					measurements={measurements}
					isFromOverview={true}
				/>
				<OverviewDetails className='top-10' title='telemetry' onClick={goToTelemetry}>
					<div className='telemetry-wrapper'>
						{telemetry[0].measurements.map(measurement => (
							<div className='flex gap-s padding-l'>
								<img src={TelemetryIcons.find(item => item.type === measurement.type)?.icon} alt='ico' />
								<span>{translate(measurement.value)}</span>
							</div>
						))}
					</div>
				</OverviewDetails>
				<OverviewDetails className='top-10' title='careTeam' onClick={goToCareTeam}>
					{[...careTeam].splice(0, 3).map(member => (
						<div className='flex flex-space-between'>
							<div className='flex flex-align-center'>
								<ProfilePicture
									firstName={member.firstName}
									lastName={member.lastName}
									profilePicture={member.profilePicture}
									className='doctor-request-img'
								/>
								<div className='care-team-details'>
									<p>
										{member.firstName} {member.lastName}
									</p>
									<span>{member.specialty?.name}</span>
								</div>
							</div>
						</div>
					))}
				</OverviewDetails>
			</div>
		</div>
	);
};

export default OverviewTab;
