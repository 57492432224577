import { useIntl } from 'react-intl';
import _ from 'lodash';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Button from 'components/Button.jsx';
import classNames from 'classnames';

const SelectedHeartLungRecording = props => {
	const intl = useIntl();
	return (
		<div className='flex position-relative stethoscope-measurement-details'>
			<div className='stethoscope-background position-relative flex'>
				{!props.isHeartMeasurement && (
					<Button
						className='position-absolute switch-btn flex'
						onClick={() => {
							props.setIsFrontView(prevState => !prevState);
							props.setSelectedExaminationPoint(null);
						}}
						imgIcon={`${healthCareCdnUrl}stetho/switch.svg`}
						alt='ico'
						text={translate('switchToFrontBack', {
							value: intl.formatMessage({ id: props.isFrontView ? 'backView' : 'frontView' }),
						})}
					/>
				)}
				<div
					className={classNames(
						'position-relative',
						props.isHeartMeasurement ? 'heart-stetho-wrapper' : 'lungs-stetho-wrapper',
						props.isFrontView ? 'is-front-view' : 'is-back-view'
					)}>
					{!props.selectedExaminationPoint && (
						<>
							{props.isHeartMeasurement && <img src={`${healthCareCdnUrl}vitals/stethoscope/heart-front.svg`} alt='ico' />}
							{!props.isHeartMeasurement && (
								<img src={`${healthCareCdnUrl}stetho/lungs-${props.isFrontView ? 'front' : 'back'}.svg`} alt='ico' />
							)}
						</>
					)}
					{props.selectedExaminationPoint && (
						<img
							src={`${healthCareCdnUrl}stetho/${
								props.examinationPoints.find(item => item.value === props.selectedRecording?.examinationPoint)?.value
							}.svg?v2`}
							alt='ico'
						/>
					)}
				</div>
			</div>
			<div className='patient-iot-history-graph stethoscope-measurement-graph full-width'>
				{props.selectedRecording && (
					<div className={`${props.isGraphShown ? '' : 'hidden'}`}>
						<p className='current-point'>
							{translate('point')}-
							{props.examinationPoints.find(item => item.value === props.selectedRecording.examinationPoint)?.point}{' '}
							{!props.isHeartMeasurement && (
								<>{translate(_.inRange(props.selectedRecording.examinationPoint, 5, 14) ? 'front' : 'back')}</>
							)}
						</p>
						<div className='stetho-content-chart' style={{ height: '35vh' }} ref={props.canvasParentRef}>
							<div>
								<canvas
									className='waveform-canvas'
									width={`${props.canvasWidth}px`}
									height={`${props.canvasHeight}px`}
									ref={props.canvasRef}
								/>
							</div>
						</div>
						<div className='stetho-content-controls stetho-measurement'>
							<div className='flex'>
								<span onClick={props.previousRecording} data-tooltip={intl.formatMessage({ id: 'prev' })} data-position='top' />
								<div className='stetho-content-chart'>
									{props.selectedRecording && !props.isLoading && (
										<audio controls ref={props.audioRef}>
											<source src={props.selectedRecording.recordUrl} type='audio/mpeg' />
										</audio>
									)}
								</div>
								<span onClick={props.nextRecording} data-tooltip={intl.formatMessage({ id: 'next' })} data-position='top' />
							</div>
						</div>
					</div>
				)}
				{!props.isGraphShown && (
					<div className='flex full-height flex-align-center flex-justify-center'>
						<p className='any-point'>{translate('clickAnyPoint')}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default SelectedHeartLungRecording;
