import LightTheme from 'calls/styles/LightTheme.js';
import * as React from 'react';

const NightVisionOn = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' color={LightTheme.colors.graySeven} {...props}>
		<path
			d='M4.2 11.652a8.4 8.4 0 0016.451 2.4s-3.852 1.8-8.052-2.4C8.4 7.452 10.2 3.6 10.2 3.6a8.404 8.404 0 00-6 8.052z'
			stroke='currentColor'
			strokeWidth={1.5}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default NightVisionOn;
