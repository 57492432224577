import { Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Select from 'react-select';
import { addDeviceConfiguration } from 'api/deviceConfig.js';
import { Alert, Form, Input, Modal } from 'components/index.js';
import { TVs, EndCallSource } from 'constants/configurationEnums.js';
import { SectorTypes, TeamTypes, TreeHierarchyType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import { getHospitalDepartments } from 'api/healthSystems.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { getHospitalFloors } from 'api/floors.js';
import { getHospitalRooms } from 'api/rooms.js';

const TVConfig = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const translator = id => intl.formatMessage({ id });
	const [hospitals, setHospitals] = useState([]);
	const [sectorList, setSectorList] = useState([]);
	const [selectedSectorName, setSelectedSectorName] = useState(SectorTypes.DEPARTMENT);
	const [configTeamTypeBasedOnHierarchy, setConfigTeamTypeBasedOnHierarchy] = useState(TeamTypes.DEPARTMENT);
	const [shouldShowEndCallOptions, setShouldShowEndCallOptions] = useState(false);

	const tvTypes = Object.values(TVs);

	const ports = [
		{ id: 1, value: 'HDMI 1' },
		{ id: 2, value: 'HDMI 2' },
		{ id: 3, value: 'HDMI 3' },
	];

	const endCallSource = EndCallSource(intl);
	const endCallOptions = Object.values(endCallSource);

	const getInitialValues = () => {
		if (props.initialValues) {
			const { team, teamConfigurationProfile, tv, hdmiPort, defaultSource, ringtoneVolume } = props.initialValues;

			return {
				...props.initialValues,
				selectedHealthSystem: {
					value: team.id,
					label: team.name,
				},
				selectedTeamProfile: { value: teamConfigurationProfile?.id, label: teamConfigurationProfile?.profileName },
				selectedTV: { value: tvTypes.find(item => item.value === tv)?.id, label: tv },
				selectedSector: { value: team.id, label: team.name },
				selectedHdmiPortValue: ports.find(port => hdmiPort === port.id).id,
				selectedEndCallSource: defaultSource !== undefined ? endCallOptions.find(ds => defaultSource === ds.id).id : null,
				hdmiPorts: ports,
				endCallSource: endCallOptions,
				tvs: tvTypes,
				ringtoneVolumeValue: ringtoneVolume,
			};
		}
		return {
			healthSystems: props.healthSystems,
			hdmiPorts: ports,
			endCallSource: endCallOptions,
			tvs: tvTypes,
			selectedHealthSystem: null,
			selectedHospital: null,
			selectedHdmiPortValue: null,
			selectedTV: null,
			selectedEndCallSource: null,
			selectedSector: null,
			selectedTeamProfile: null,
			ringtoneVolumeValue: 0,
		};
	};

	const getValidationSchema = () => {
		const validation = {};
		if (props.initialValues) {
			validation.selectedEndCallSource =
				shouldShowEndCallOptions && Yup.string().nullable().required(translator('pleaseSelectEndCallSource'));
		}
		if (!props.initialValues) {
			validation.selectedHealthSystem = Yup.object().nullable().required(translator('pleaseSelectHealthSystem'));
			validation.selectedHospital = Yup.object().nullable().required(translator('pleaseSelectHospital'));
			validation.selectedSector =
				selectedSectorName === SectorTypes.ROOM
					? null
					: Yup.object()
							.nullable()
							.required(translator(selectedSectorName === SectorTypes.FLOOR ? 'pleaseSelectFloor' : 'pleaseSelectDepartment'));
			validation.selectedHdmiPortValue = Yup.string().nullable().required(translator('pleaseSelectHDMIPort'));
			validation.selectedTV = Yup.object().nullable().required(translator('pleaseSelectTV'));
			validation.selectedEndCallSource =
				shouldShowEndCallOptions && Yup.string().nullable().required(translator('pleaseSelectEndCallSource'));
			validation.ringtoneVolumeValue = Yup.number().required(translator('ringtoneVolumeValidation'));
		}
		return validation;
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
	};

	const onFormHealthSystemSelect = async (values, setFieldValue) => {
		if (props.initialValues) {
			return;
		}
		const selectedHS = props.healthSystems.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedHealthSystem', selectedHS);
		const hsHospitals = await getHealthSystemHospitals(values.value);
		if (hsHospitals.error) {
			setError(hsHospitals.error.message);
			return;
		}
		setHospitals(hsHospitals);
		setFieldValue('selectedHospital', null);
		setFieldValue('selectedSector', null);
	};

	const onFormHospitalSelect = async (values, setFieldValue, hsValue) => {
		if (props.initialValues) {
			return;
		}
		const selectedHospital = hospitals.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedHospital', selectedHospital);
		getSectorsBasedTreeType(hsValue, values.value, setFieldValue);
	};

	const getSectorsBasedTreeType = async (hsValue, value, setFieldValue) => {
		const treeHierarchyTypeId = props.healthSystems.find(item => item.id === hsValue)?.treeHierarchyTypeId;
		switch (treeHierarchyTypeId) {
			case TreeHierarchyType.DEFAULT_TREE:
			case TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM:
			case TreeHierarchyType.HOSPITAL_DEPT_ROOM: {
				const response = await getHospitalDepartments(hsValue, value);
				if (response.error) {
					props.setIsFormLoading(false);
					setError(response.error.message);
					return;
				}
				setSectorList(response.hospital.departments);
				setSelectedSectorName(SectorTypes.DEPARTMENT);
				setConfigTeamTypeBasedOnHierarchy(TeamTypes.DEPARTMENT);
				break;
			}
			case TreeHierarchyType.HOSPITAL_FLOOR_ROOM: {
				const response = await getHospitalFloors(getCompanyId(), hsValue, value);
				if (response.error) {
					props.setIsFormLoading(false);
					setError(response.error.message);
					return;
				}
				setSectorList(response.floors);
				setSelectedSectorName(SectorTypes.FLOOR);
				setConfigTeamTypeBasedOnHierarchy(TeamTypes.FLOOR);
				break;
			}
			case TreeHierarchyType.HOSPITAL_ROOM: {
				const response = await getHospitalRooms(getCompanyId(), hsValue, value);
				if (response.error) {
					props.setIsFormLoading(false);
					setError(response.error.message);
					return;
				}
				setSectorList(response.rooms);
				setSelectedSectorName(SectorTypes.ROOM);
				setConfigTeamTypeBasedOnHierarchy(TeamTypes.HOSPITAL);
				break;
			}
			default: {
				const response = await getHospitalDepartments(hsValue, value);
				if (response.error) {
					props.setIsFormLoading(false);
					setError(response.error.message);
					return;
				}
				setSectorList(response.hospital.sectorList);
				setSelectedSectorName(SectorTypes.DEPARTMENT);
				setConfigTeamTypeBasedOnHierarchy(TeamTypes.DEPARTMENT);
				break;
			}
		}
		setFieldValue('selectedSector', null);
		setFieldValue('isSectorLoading', false);
	};

	const onFormDepartmentSelect = (values, setFieldValue) => {
		if (props.initialValues) {
			return;
		}
		const selectedSector = sectorList.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedSector', selectedSector);
	};

	const addDeviceConfigurations = async values => {
		const hdmiPort = values.selectedHdmiPortValue;
		const teamId = selectedSectorName !== SectorTypes.ROOM ? values.selectedSector?.value : values.selectedHospital?.value;
		const teamConfigurationProfileId = values.selectedTeamProfile ? values.selectedTeamProfile.value : null;
		let defaultSource = null;
		if (values.selectedEndCallSource != null) {
			defaultSource = values.endCallSource[values.selectedEndCallSource]?.id;
		}
		props.setIsFormLoading(true);
		const response = await addDeviceConfiguration(teamId, {
			teamTypeId: configTeamTypeBasedOnHierarchy,
			defaultSource,
			hdmiPort,
			ringtoneVolume: values.ringtoneVolumeValue,
			teamConfigurationProfileId,
			tv: values.selectedTV.label,
		});
		if (!response.hasSucceeded || response.error) {
			props.setIsFormLoading(false);
			setError(response.error.message);
			return;
		}
		props.setIsFormLoading(false);
		props.toggleModal();
		props.getConfigurations();
	};

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={getInitialValues()}
				validationSchema={Yup.object().shape({ ...getValidationSchema() })}
				onSubmit={(values, { resetForm }) => {
					addDeviceConfigurations(values);
					resetForm({ values: '' });
				}}>
				{formikProps => {
					const { values, errors, handleSubmit, resetForm, setFieldValue } = formikProps;

					if (values.selectedTV?.value) {
						setShouldShowEndCallOptions(
							![TVs.TELEHEALTH_CONTROLLED.id, TVs.GET_WELL.id, TVs.SONIFY.id].includes(values.selectedTV?.value)
						);
					}

					const filteredEndCallOptions = () =>
						endCallOptions.filter(item => {
							if ([TVs.LG.id, TVs.PCARE.id, TVs.SAMSUNG.id].includes(values.selectedTV?.value)) {
								return item;
							}
							if ([TVs.CEC.id].includes(values.selectedTV?.value)) {
								return ![endCallSource.PREVIOUS_STATE.id, endCallSource.TV_CHANNELS.id].includes(item.id);
							}
							return ![endCallSource.PREVIOUS_STATE.id].includes(item.id);
						});

					return (
						<Modal
							modalSelector='deviceConfigurationsModal'
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
							display={props.isModalOpen}
							position='right'
							onModalSubmit={handleSubmit}
							onModalClose={() => onCloseModal(resetForm)}
							isLoading={props.isFormLoading}
							shouldSubmitOnEnter={false}>
							<Form title='TV' onSubmit={event => event.preventDefault()} className='manage-hs-form'>
								{!props.initialValues && (
									<div className='input'>
										<p className='label'>{translate('selectHealthSystem')}</p>
										<p className='font-14'>{translate('selectHSForConfiguration')}</p>
										<Select
											value={values.selectedHealthSystem}
											placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
											classNamePrefix='react-select'
											options={props.transformArray(props.healthSystems)}
											onChange={event => onFormHealthSystemSelect(event, setFieldValue)}
										/>
										<small>{errors.selectedHealthSystem}</small>
									</div>
								)}
								{!props.initialValues && (
									<div className='input'>
										<p className='label'>{translate('selectHospital')}</p>
										<p className='font-14'>{translate('chooseHospitalForChanges')}</p>
										<Select
											value={values.selectedHospital}
											placeholder={intl.formatMessage({ id: 'selectHospital' })}
											classNamePrefix='react-select'
											options={props.transformArray(hospitals)}
											onChange={event => onFormHospitalSelect(event, setFieldValue, values.selectedHealthSystem?.value)}
										/>
										<small>{errors.selectedHospital}</small>
									</div>
								)}
								{!props.initialValues && selectedSectorName !== SectorTypes.ROOM && (
									<div className='input'>
										<p className='label'>
											{translate('selectSector', {
												value: intl.formatMessage({ id: selectedSectorName }),
											})}
										</p>
										<p className='font-14'>
											{translate('chooseSectorForChanges', {
												value: intl.formatMessage({ id: selectedSectorName }),
											})}
										</p>
										<Select
											value={values.selectedSector}
											placeholder={translate('selectSector', {
												value: intl.formatMessage({ id: selectedSectorName }),
											})}
											classNamePrefix='react-select'
											options={props.transformArray(sectorList)}
											onChange={event => onFormDepartmentSelect(event, setFieldValue)}
										/>
										<small>{errors.selectedSector}</small>
									</div>
								)}
								{props.initialValues && selectedSectorName !== SectorTypes.ROOM && (
									<Input
										type='text'
										label={translate('selectSector', {
											value: intl.formatMessage({ id: selectedSectorName }),
										})}
										name='selectedSector'
										placeholder={translate('selectSector', {
											value: intl.formatMessage({ id: selectedSectorName }),
										})}
										value={values.selectedSector ? values.selectedSector.label : ''}
										validationOptions={{}}
										disabled={true}
										error={errors.selectedSector}
									/>
								)}
								<div className='input'>
									<p className='label'>{translate('chooseTVSetup')}</p>
									<p className='font-14'>{translate('chooseTVSetupType')}</p>
									<Select
										value={values.selectedTV}
										placeholder={intl.formatMessage({ id: 'chooseTVSetup' })}
										classNamePrefix='react-select'
										options={props.transformArray(values.tvs)}
										onChange={event => {
											setFieldValue('selectedHdmiPortValue', null);
											setFieldValue('selectedEndCallSource', null);
											setFieldValue('selectedTV', event);
										}}
									/>
									<small>{errors.selectedTV}</small>
								</div>
								<div className='input-el'>
									<label>{translate('selectHelloCareHDMI')}</label>
									<p className='font-14'>{translate('selectHDMIPort')}</p>
									<main>
										{values.hdmiPorts.map(item => (
											<Fragment key={item.id}>
												<input
													type='radio'
													id={`hdmi${item.id}`}
													name='hdmi'
													value={item.id}
													onChange={event => setFieldValue('selectedHdmiPortValue', +event.target.value)}
													checked={values.selectedHdmiPortValue === item.id}
												/>
												<label htmlFor={`hdmi${item.id}`}>HDMI {item.id}</label>
											</Fragment>
										))}
									</main>
									<div className='input no-margin'>
										<small>{errors.selectedHdmiPortValue}</small>
									</div>
								</div>
								{shouldShowEndCallOptions && (
									<div className='input-el'>
										<label>{translate('tvManagement')}</label>
										<p>{translate('selectTVOption')}</p>
										<main>
											{filteredEndCallOptions().map(item => (
												<>
													<input
														type='radio'
														id={`endCall${item.id}`}
														name='endCall'
														value={item.id}
														onChange={event => setFieldValue('selectedEndCallSource', +event.target.value)}
														checked={values.selectedEndCallSource === item.id}
													/>
													<label htmlFor={`endCallSource${item.id}`}>{item.value}</label>
												</>
											))}
										</main>
										<div className='input no-margin'>
											<small>{errors.selectedEndCallSource}</small>
										</div>
									</div>
								)}
								<div className='input'>
									<p className='label'>{translate('selectTeleHealthProfile')}</p>
									<p className='font-14'>{translate('selectPreConfiguredProfile')}</p>
									<Select
										value={values.selectedTeamProfile}
										placeholder={intl.formatMessage({ id: 'selectTeleHealthProfile' })}
										classNamePrefix='react-select'
										options={props.transformArray(props.teamProfiles)}
										onChange={event => setFieldValue('selectedTeamProfile', event)}
									/>
								</div>
								<div className='input-el'>
									<label>{translate('helloCareRingtone')}</label>
									<p>{translate('applyToAllDevices')}</p>
									<div className='input-range-wrapper'>
										<input
											type='range'
											min={0}
											step={1}
											max={100}
											value={values.ringtoneVolumeValue}
											onChange={event => setFieldValue('ringtoneVolumeValue', +event.target.value)}
										/>
										<small>{values.ringtoneVolumeValue || 0}</small>
									</div>
									<small>{errors.ringtoneVolumeValue}</small>
								</div>
							</Form>
						</Modal>
					);
				}}
			</Formik>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default TVConfig;
